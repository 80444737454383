import React, { useState, useEffect } from "react";
import BackButton from "../BackButton/backButton";
import { Button, Card, Col, Row } from "antd";
import axiosApi from "../../../api/axiosApi";
import { useHistory } from "react-router-dom";
import "./Notification.css";

const gridStyle = {
  width: "50%",
  textAlign: "center",
  padding: "20px",
};

const Notifications = (props) => {
  const userId = props.location.state.userId;

  const [showUnread, setShowUnread] = useState(true);
  const [loading, setLoading] = useState(true);
  const [alerts, setAlerts] = useState([]);

  let history = useHistory();

  const fetchNotifications = () => {
    setLoading(true);
    axiosApi
      .get(`production/injectionMoulding/getAlerts?receiverId=${userId}`)
      .then((res) => {
        const alertData = res.data.data;
        setAlerts(() => alertData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const markAsRead = (notifId) => {
    axiosApi
      .post(`/production/injectionMoulding/updateAlerts`, {
        alertId: notifId,
        isRead: true,
        receiverId: userId,
      })
      .then((res) => {
        fetchNotifications();
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const onPress = (ele) => {
    history.push(
      `/${ele?.alerts?.data?.link}/${ele?.alerts?.data?.qrciData?.qrci_ref_no}`
    );
  };

  const Notifs = (type) => {
    let unreadAlerts = [];

    alerts.forEach((a) => {
      a.alerts.receiverIds.forEach((e) => {
        if (e.rId === userId && e.isRead === false) {
          unreadAlerts.push(a);
        }
      });
    });

    const final_map = type === "unread" ? unreadAlerts : alerts;
    console.log("Final Map : ", final_map);

    return final_map.map((ele, index) => (
      <div key={index}>
        <Card.Grid style={gridStyle}>
          <li className="nav-li">
            <p
              style={{
                fontFamily: "Garamond, serif",
                fontSize: "25px",
                marginBottom: "0.25em",
              }}
            >
              {ele.alerts.type === "system"
                ? "System Generated Alert"
                : `${ele.senderdata.senderRole} - ${ele.senderdata.senderName} from ${ele.senderdata.senderDept}`}
            </p>
          </li>
          {Object.entries(ele.alerts.data).map(([key, value], index) => {
            if (
              key === "qrciData" ||
              key === "qrci" ||
              key === "link" ||
              key === "name"
            )
              return " ";
            return (
              <div key={index}>
                <Row>
                  <Col span={12} style={{ textAlign: "right" }}>
                    {key.charAt(0).toUpperCase() + key.slice(1)} :
                  </Col>
                  <Col
                    span={12}
                    style={{ textAlign: "left", padding: "0 4px" }}
                  >
                    {value.charAt(0).toUpperCase() + value.slice(1)}
                  </Col>
                </Row>
              </div>
            );
          })}

          <Row>
            <Col span={12} style={{ textAlign: "right" }}>
              Color :
            </Col>
            <Col span={12} style={{ textAlign: "left", padding: "0 4px" }}>
              {ele.alerts.color}
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ textAlign: "right" }}>
              Resolved :
            </Col>
            <Col span={12} style={{ textAlign: "left", padding: "0 4px" }}>
              {ele.alerts.isResolved ? "True" : "False"}
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              {type === "unread" ? (
                <Button
                  type="primary"
                  onClick={() => markAsRead(ele.alerts._id)}
                >
                  Mark as Read
                </Button>
              ) : (
                ""
              )}
            </Col>

            {ele?.alerts?.data?.qrci === true && (
              <Col span={14}>
                <Button
                  type="primary"
                  onClick={() => onPress(ele)}
                  style={{ width: "100%", marginTop: "5%" }}
                >
                  {ele?.alerts?.data?.name}
                </Button>
              </Col>
            )}
          </Row>
        </Card.Grid>
      </div>
    ));
  };

  return (
    <>
      <BackButton styleClass="back-btn" />
      <div className="container">
        <Card title="Notifications" className="notification-card">
          <Button
            onClick={() => {
              setShowUnread(() => !showUnread);
            }}
          >
            {showUnread ? "Show All" : "Show Unread"}
          </Button>

          {loading
            ? "Loading Your Notifications"
            : alerts.length === 0
            ? "No Notifications"
            : showUnread
            ? Notifs("unread")
            : Notifs()}
        </Card>
      </div>
    </>
  );
};

export default Notifications;
