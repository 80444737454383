import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card } from "antd";

import axios from "../../../../api/axiosApi";

const GetToolMaintenanceForms = (props) => {
  const [allforms, getallforms] = useState([]);

  useEffect(() => {
    const fetchAllForms = async () => {
      await axios
        .get(`/production/injectionMoulding/getToolMaintDataManager`)
        .then((response) => {
          const allforms = response.data.data;
          console.log(allforms);
          getallforms(() => allforms);
        })
        .catch((error) => {
          console.log(`ERRORS : ${error}`);
          alert(error);
        });
    };
    fetchAllForms();
  }, []);

  const tableBody = Object.keys(allforms).map(function (key, index) {
    return (
      <div key={index}>
        <Card
          title={key}
          style={{ width: 300, margin: 15 }}
          headStyle={{
            backgroundColor: "#FFA500",
            borderRadius: "10px",
            color: "#ffffff",
          }}
          raised="true"
        >
          <Link
            to={{
              pathname: `/tool-maintenance-review`,
              state: {
                month: key,
              },
            }}
          >
            Review Maintenance
          </Link>
        </Card>
      </div>
    );
  });

  return (
    <div>
      {tableBody.length === 0 ? (
        <div>No forms present</div>
      ) : (
        <div className="maintenance-forms">{tableBody}</div>
      )}
    </div>
  );
};

export default GetToolMaintenanceForms;
