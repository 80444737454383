import "antd/dist/antd.css";
import "./App.css";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import LoginComponent from "./components/common/LoginComponent";
import Dashboards from "./routes/dashboards";
import ProtectedRoute from "./routes/protected_route";

import ShiftHandoverForm from "./components/forms/operator/shift_handover/shift_handover_form";
import ShiftHandoverPrefillForm from "./components/forms/operator/shift_handover/shift_handover_prefill_form";
import ShiftStartupForm from "./components/forms/operator/shift_startup/shift_startup_form";
// import { HourlyReportForm } from "./components/forms/operator/hourly_report_form";

import AllShiftHandoverForms from "./components/forms/operator/shift_handover/all_shift_handover_forms";
import QASupervisorAllOperators from "./components/forms/qa_supervisor/shift_startup/all_operators";
import QAOperatorInspectionForm from "./components/forms/qa_supervisor/shift_startup/operatorInspectionForm";

import ProductionManagerForm from "../src/components/forms/production_manager/production_manager";
import ProductionSupervisorDetailsPage from "../src/components/forms/production_manager/production_manager_details";
import DailyOperationForm from "./components/forms/production_manager/daily_operation";
import ProductionSupervisorPrefilledForm from "./components/forms/production_manager/production_manager_prefilled";
import form1_trial from "./components/forms/supervisor/form1_trial";
import HardwareView from "./hardware/hardware_view";
import OperatorDashboard from "./pages/operator/operator_dashboard";
import QASupervisorMachineDashboard from "./pages/quality_supervisor/qa_supervisor_machine_dashboard";

import MachineMaintenanceForms from "../src/components/forms/maintenanceManager/machineMaintenance/getMaintenanceForms";
import MachineMaintenanceReview from "../src/components/forms/maintenanceManager/machineMaintenance/machineMainetenanceReview";
import MachineMaintenanceView from "./components/forms/maintenanceOperator/machineMaintenance/machineMaintenanceView";
import MachineMaintenanceForm from "../src/components/forms/maintenanceOperator/machineMaintenance/machineMaintenance";
import ToolMaintenanceForm from "../src/components/forms/maintenanceOperator/toolMaintenance/toolMaintenance";
import MachineMaintenanceManager from "../src/pages/maintenance/maintenance_manager/maintenance_manager_machines";
import MachineMaintenanceDashboard from "../src/pages/maintenance/maintenance_operator/maintenance_operator_machines";
import MachineMaintHistory from "./components/forms/maintenanceOperator/machineMaintenance/machineMaintHistory";
import MachineMaintenanceSchedule from "./pages/maintenance/maintenance_manager/machine_maintenance_schedule";

import Notifications from "./components/common/Notifications/Notifications";
import OnJobTrainingForm from "./components/forms/supervisor/on_job_train_1";
import SupervisorOJTDashboard from "./pages/supervisor/supervisor_ojt_dashboard";
import TraineeDetails from "./pages/supervisor/trainee_details";

import ProductionManagerForms from "./components/forms/general_manager/production_manager_forms/production_manager_forms";
import Supervisor_Handover_Form from "./components/forms/supervisor/supervisor_handover_data";

import NewProductionPlan from "./components/forms/production_manager/production_planning/new_production_plan";
import ViewProductionPlan from "./components/forms/production_manager/production_planning/view_production_planning";
import HardwareAllDashboard from "./hardware/hardware_all_dash";

import DailyReportForm from "./components/forms/managingDirector/daily_report_form";

import MDLineQrciDashboard from "./components/forms/QRCI/line_qrci/line_qrci_dashboard";
import MDPlantQrciDashboard from "./components/forms/QRCI/plant_qrci/plant_qrci_dashboard";
import LineQrciForm from "./pages/QRCI/qrci-form";
import Qrci8DForm from "./pages/QRCI/qrci-8d-form";
import NCCForm from "./components/forms/qa_supervisor/ncc_form/ncc_form";
import alert_history from "./components/forms/production_manager/alert_dashboard/alert_history";
import create_alert from "./components/forms/production_manager/alert_dashboard/create_alert";
// import DCOpSelectMachinePage from "../pages/DC/Operator/dc_op_dashboard"

import ToolMaintenanceForms from "./components/forms/maintenanceManager/toolMaintenance/getToolMaintenanceForms";
import DCToolMaintenanceForms from "./components/forms/maintenanceManager/toolMaintenance/getDCToolMaintenanceForms";
import ToolMaintenanceReview from "./components/forms/maintenanceManager/toolMaintenance/toolMaintenanceReview";
import DCToolMaintenanceReview from "./components/forms/maintenanceManager/toolMaintenance/DCtoolMaintenanceReview";
import DCToolMaintenanceForm from "../src/components/forms/maintenanceOperator/toolMaintenance/DCtoolMaintenance";
//import getToolMaintenanceForm from "../src/components/forms/maintenanceManager/toolMaintenance/getToolMaintenanceForms";

import DCOperatorDashboard from "./pages/DC/Operator/dc_operator_dashboard"
import DCShiftStartup from "./components/forms/DC/operator/shiftStartupForm"
import DCShiftHandover from "./components/forms/DC/operator/shiftHandoverForm"
import DCShiftHandoverPrefillForm from "./components/forms/DC/operator/prefilledShiftHandoverForm"
import DCviewProdPlan from "./components/forms/DC/productionManager/view_production_planning"
import DCNewProdPlan from "./components/forms/DC/productionManager/new_production_plan"
import DCDailyOperationReview from "./components/forms/DC/productionManager/daily_operation"
import DCProductionSupervisorDetailsPage from "./components/forms/DC/productionManager/production_manager_details"
import DCProductionSupervisorPrefilledForm from "./components/forms/DC/productionManager/production_manager_prefilled"
import DCProductionManagerForm from "./components/forms/DC/productionManager/production_manager"

import DCProductionManagerForms from "./components/forms/general_manager/production_manager_forms/dc_production_manager_forms";



const App = () => {
	console.log("environment", process.env.NODE_ENV);
	return (
		<div className="App">
			<Router>
				<Switch>
					<Route path="/" exact component={LoginComponent} />

					<ProtectedRoute
						path="/dashboard"
						exact
						component={Dashboards}
					/>

					<ProtectedRoute
						path="/op-dashboard"
						exact
						component={OperatorDashboard}
					/>
					<ProtectedRoute
						path="/dc-op-dashboard"
						exact
						component={DCOperatorDashboard}
					/>

					{/* Operator Routes */}
					<ProtectedRoute
						path="/shift-startup"
						exact
						component={ShiftStartupForm}
					/>

					<ProtectedRoute
						path="/dc-shift-startup"
						exact
						component={DCShiftStartup}
					/>

					<ProtectedRoute
						path="/all-shift-handover-forms"
						exact
						component={AllShiftHandoverForms}
					/>

					<ProtectedRoute
						path="/prefill-shift-handover-form"
						exact
						component={ShiftHandoverPrefillForm}
					/>
					<ProtectedRoute
						path="/new-shift-handover-form"
						exact
						component={ShiftHandoverForm}
					/>
					<ProtectedRoute
						path="/dc-new-shift-handover-form"
						exact
						component={DCShiftHandover}
					/>
					<ProtectedRoute
						path="/dc-prefill-shift-handover-form"
						exact
						component={DCShiftHandoverPrefillForm}
					/>
					{/* <ProtectedRoute
            path="/hourly-report"
            exact
            component={HourlyReportForm}
          /> */}
					{/* Operator Routes */}

					{/* Supervisor Routes */}

					{/* <ProtectedRoute
            path="/machines/:id"
            exact
            component={QASupervisorMachinePage}
          /> */}

					<ProtectedRoute
						path="/machines/:id"
						exact
						component={QASupervisorMachineDashboard}
					/>

					<ProtectedRoute
						path="/shift_start_up"
						exact
						component={QASupervisorAllOperators}
					/>

					<ProtectedRoute
						path="/shift_start_up/:id"
						exact
						component={QAOperatorInspectionForm}
					/>

					<ProtectedRoute
						path="/ncc-form"
						exact
						component={NCCForm}
					/>
					{/* Supervisor Routes */}
					<ProtectedRoute
						path="/production-supervisor-ojt"
						exact
						component={SupervisorOJTDashboard}
					/>
					<ProtectedRoute
						path="/production-supervisor-ojt/:id"
						exact
						component={TraineeDetails}
					/>
					<ProtectedRoute
						path="/production-supervisor-ojt/form/level/:id"
						exact
						component={OnJobTrainingForm}
					/>

					{/* Production Supervisor */}
					<ProtectedRoute
						path="/production-supervisor/:id"
						exact
						component={Supervisor_Handover_Form}
					/>

					<ProtectedRoute
						path="/production-supervisor/machine-details"
						exact
						component={form1_trial}
					/>

					<ProtectedRoute
						path="/hardware"
						exact
						component={HardwareAllDashboard}
					/>

					<ProtectedRoute
						path="/hardware/:id"
						exact
						component={HardwareView}
					/>

					<ProtectedRoute
						path="/production-manager-form"
						exact
						component={ProductionManagerForm}
					/>

					<ProtectedRoute
						path="/view_production_plan"
						exact
						component={ViewProductionPlan}
					/>

					<ProtectedRoute
						path="/new_production_plan"
						exact
						component={NewProductionPlan}
					/>

					<ProtectedRoute
						path="/daily-operation-review"
						exact
						component={DailyOperationForm}
					/>
					<ProtectedRoute
						path="/production-manager-form/details"
						exact
						component={ProductionSupervisorDetailsPage}
					/>

					<ProtectedRoute
						path="/production-manager-form/prefilled"
						exact
						component={ProductionSupervisorPrefilledForm}
					/>

					<ProtectedRoute
						path="/dc-production-manager-form"
						exact
						component={DCProductionManagerForm}
					/>

					<ProtectedRoute
						path="/dc_view_production_plan"
						exact
						component={DCviewProdPlan}
					/>

					<ProtectedRoute
						path="/dc_new_production_plan"
						exact
						component={DCNewProdPlan}
					/>

					<ProtectedRoute
						path="/dc-daily-operation-review"
						exact
						component={DCDailyOperationReview}
					/>
					<ProtectedRoute
						path="/dc-production-manager-form/details"
						exact
						component={DCProductionSupervisorDetailsPage}
					/>

					<ProtectedRoute
						path="/dc-production-manager-form/prefilled"
						exact
						component={DCProductionSupervisorPrefilledForm}
					/>

					<ProtectedRoute
						path="/machine-maintenance/:dept"
						exact
						component={MachineMaintenanceDashboard}
					/>

					<ProtectedRoute
						path="/machine-maintenance-form/:dept"
						exact
						component={MachineMaintenanceForm}
					/>

					<ProtectedRoute
						path="/machine-maintenance-history/:dept"
						exact
						component={MachineMaintHistory}
					/>

					<ProtectedRoute
						path="/machine-maintenance-manager/:dept"
						exact
						component={MachineMaintenanceManager}
					/>

					<ProtectedRoute
						path="/machine-maintenance-review/:dept"
						exact
						component={MachineMaintenanceReview}
					/>

					<ProtectedRoute
						path="/machine-maintenance-view/:dept"
						exact
						component={MachineMaintenanceView}
					/>

					<ProtectedRoute
						path="/maintenance-schedule/:dept"
						exact
						component={MachineMaintenanceSchedule}
					/>

					<ProtectedRoute
						path="/machine-maintenance-review-forms/:dept"
						exact
						component={MachineMaintenanceForms}
					/>

					<ProtectedRoute
						path="/tool-maintenance-form"
						exact
						component={ToolMaintenanceForm}
					/>

					<ProtectedRoute
						path="/dc-tool-maintenance-form"
						exact
						component={DCToolMaintenanceForm}
					/>

					<ProtectedRoute
						path="/tool-maintenance-review"
						exact
						component={ToolMaintenanceReview}
					/>

					<ProtectedRoute
						path="/dc-tool-maintenance-review"
						exact
						component={DCToolMaintenanceReview}
					/>


					<ProtectedRoute
						path="/notifications"
						exact
						component={Notifications}
					/>

					<ProtectedRoute
						path="/general-manager/production-manager-form"
						exact
						component={ProductionManagerForms}
					/>

					<ProtectedRoute
						path="/general-manager-tool-maintenance-forms/injectionMoulding"
						exact
						component={ToolMaintenanceForms}
					/>

					<ProtectedRoute
						path="/general-manager-tool-maintenance-forms/die_casting"
						exact
						component={DCToolMaintenanceForms}
					/>

					<ProtectedRoute
						path="/general-manager/dc-production-manager-form"
						exact
						component={DCProductionManagerForms}
					/>

					<ProtectedRoute
						path="/managing-director/daily-report-form"
						exact
						component={DailyReportForm}
					/>

					<ProtectedRoute
						path="/lineqrci/all"
						exact
						component={MDLineQrciDashboard}
						
						
					/>

					<ProtectedRoute
						path="/plantqrci/all"
						exact
						component={MDPlantQrciDashboard}
					/>

					<ProtectedRoute
						path="/create_alerts"
						exact
						component={create_alert}
					/>

					<ProtectedRoute
						path="/alert_history"
						exact
						component={alert_history}
					/>
					<Route
						path="/lineqrci/:dept/:id"
						exact
						component={LineQrciForm}
					/>
					<Route path="/plantqrci/:id" exact component={Qrci8DForm} />

					<Route path="*" render={() => "You Are Lost Somewhere"} />
				</Switch>
			</Router>
		</div>
	);
};

export default App;
