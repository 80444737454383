import { Button, Col, Divider, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
// redux
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosApi from "../../../api/axiosApi";
import BackButton from "../../common/BackButton/backButton";
import DetailsModal from "./detailsModal";
import {DoubleRightOutlined} from '@ant-design/icons'
import "./prod_manager.css";

const DailyOperationForm = (props) => {
  const [form] = Form.useForm();
  // const { TextArea } = Input;
  const [data, setData] = useState();
  const [empty, setEmpty] = useState(false);
  const [modalData,setModalData]=useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  let history = useHistory();

  useEffect(() => {
    axiosApi
      .get(`production/injectionMoulding/getDailyReviewForm`, {
        headers: {
          dates: "2021-11-28T11:56:18.280+00:00",
        },
      })
      .then((response) => {
        console.log('res',response);
        setData(response.data.data[0]);
        if (response.data.data != null) setEmpty(true);
      })
      .catch((error) => console.log(`ERRORS : ${error}`));
  }, []);

  const onFinish = async (values) => {
    console.log("Success :", values);
    axiosApi
      .post("production/injectionMoulding/submitDailyReview", {
        dih: values.dih_moulding,
      })
      .then(function (response) {
        console.log(response);
        toast.success("🚀 Form Submitted Successfully", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        setTimeout(() => {
          history.goBack();
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
        alert("Error Occured : ", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    alert("Error Occured : ", errorInfo);
  };

  const showModal = (data_by_machines,data_by_parts,title) => {
    setIsModalVisible(true);
    setModalData({
      data_by_machines,
      data_by_parts,
      title
    })
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  return empty ? (
    <div className="card-holder">
      <BackButton styleClass="back-btn" />
      <ToastContainer />
      <div className="form-card-prod-manager">
        <h1
          style={{
            paddingBottom: "10px",
            letterSpacing: "0.3rem",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          DAILY OPERATION REVIEW
        </h1>

        <Form
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 16,
          }}
          form={form}
          layout="horizontal"
          colon={false}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Divider />
          <Row className="form-row">
            <Col span={4}>
              <h3>Description</h3>
            </Col>
            <Col span={4}>
              <h3>Unit</h3>
            </Col>
            <Col span={4}>
              <h3>Moulding</h3>
            </Col>
            <Col span={4}>
              <h3>Die Casting</h3>
            </Col>
            <Col span={4}>
              <h3>Paint Shop</h3>
            </Col>
            <Col span={4}>
              <h3>Assy</h3>
            </Col>
          </Row>

          <Row className="form-row">
            <Col span={4}>Alert RED</Col>
            <Col span={4}>Nos</Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
          </Row>
          <Row className="form-row">
            <Col span={4}>Production Value</Col>
            <Col span={4}>In Lacs</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder={data.sum_prod_value}
                disabled
                style={{ width: "70%" }}
              />
              <Button type="primary" onClick={() => showModal(data.prod_value_by_machines,data.prod_value_by_parts,"Production Value")}><DoubleRightOutlined /></Button>
            </Col>
            <DetailsModal isModalVisible={isModalVisible} handleCancel={handleCancel} handleOk={handleOk} data={modalData}/>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
          </Row>
          <Row className="form-row">
            <Col span={4}>No. Of Line/Machine</Col>
            <Col span={4}>Nos</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder={data?.Active_Machines.length}
                disabled
                style={{ width: "70%" }}
              />
              <Button type="primary" onClick={() => {setIsModalVisible(true); setModalData({Active_Machines: data.Active_Machines})}}><DoubleRightOutlined /></Button>
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
          </Row>
          <Row className="form-row">
            <Col span={4}>NEE</Col>
            <Col span={4}>%</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder="92.7"
                disabled
                style={{ width: "70%" }}
              />
              <Button type="primary" onClick={() => showModal(data.nee_by_machines,data.nee_by_parts,"NEE")}><DoubleRightOutlined /></Button>
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
          </Row>
          <Row className="form-row">
            <Col span={4}>DIH</Col>
            <Col span={4}>Days</Col>
            <Col span={4}>
              <Form.Item
                name="dih_moulding"
                labelAlign="left"
                className="form-item"
              >
                <Input type="string" style={{ width: "105%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="dih_die_casting"
                labelAlign="left"
                className="form-item"
              >
                <Input type="string" style={{ width: "105%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="dih_paint_shop"
                labelAlign="left"
                className="form-item"
              >
                <Input type="string" style={{ width: "105%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="dih_assy"
                labelAlign="left"
                className="form-item"
              >
                <Input type="string" style={{ width: "105%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-row">
            <Col span={4}>PPM</Col>
            <Col span={4}>Nos</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder={data.sum_ppm}
                disabled
                style={{ width: "70%" }}
              />
              <Button type="primary" onClick={() => showModal(data.moulding_ppm_machines,[],"PPM")}><DoubleRightOutlined /></Button>
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder="NA"
                disabled
                style={{ width: "70%" }}
              />
            </Col>
          </Row>
          <Row className="form-row">
            <Col span={4}>NCC Cost Total</Col>
            <Col span={4}>In Rs.</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder={data.sum_ncc}
                disabled
                style={{ width: "70%" }}
              />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
          </Row>
          <Row className="form-row">
            <Col span={4}>Scrap</Col>
            <Col span={4}>In Rs.</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder={data.sum_scrap}
                disabled
                style={{ width: "70%" }}
              />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder="NA"
                disabled
                style={{ width: "70%" }}
              />
            </Col>
          </Row>
          <Row className="form-row">
            <Col span={4}>Reworking</Col>
            <Col span={4}>In Rs.</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder={data.sum_rework}
                disabled
                style={{ width: "70%" }}
              />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder="NA"
                disabled
                style={{ width: "70%" }}
              />
            </Col>
          </Row>
          <Row className="form-row">
            <Col span={4}></Col>
            <Col span={4}></Col>
            <Col span={4} style={{ marginLeft: "3.3%" }}>
              <Input
                type="string"
                disabled
                placeholder="BL-"
                style={{ width: "50%" }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Input
                type="string"
                disabled
                placeholder="RP-"
                style={{ width: "50%" }}
              />
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row className="form-row">
            <Col span={4}>RFT</Col>
            <Col span={4}>%</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder={data.rft}
                disabled
                style={{ width: "70%" }}
              />
            </Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder="NA"
                disabled
                style={{ width: "70%" }}
              />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder="NA"
                disabled
                style={{ width: "70%" }}
              />
            </Col>
          </Row>
          <Row className="form-row">
            <Col span={4}>Through Put</Col>
            <Col span={4}>%</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder={data.sum_throughput}
                disabled
                style={{ width: "70%" }}
              />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder="NA"
                disabled
                style={{ width: "70%" }}
              />
            </Col>
          </Row>
          <Row className="form-row">
            <Col span={4}>Head Count</Col>
            <Col span={4}>Nos</Col>
            <Col span={4}>
              <Input type="string"  style={{ width: "70%" }} defaultValue={data.headcount}/>
            </Col>
            <Col span={4}>
              <Input type="string"  style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string"  style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string"  style={{ width: "70%" }} />
            </Col>
          </Row>
          <Row className="form-row">
            <Col span={4}>OT Hours</Col>
            <Col span={4}>Hours</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder={data.sum_rework}
                disabled
                style={{ width: "70%" }}
              />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
          </Row>
          <Row className="form-row">
            <Col span={4}>RP/RW Balance</Col>
            <Col span={4}>Nos</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder={data.sum_rp_rw}
                disabled
                style={{ width: "70%" }}
              />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
          </Row>

          <div className="submit-btn">
            <Button type="danger" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  ) : (
    <div>Error in fetching details</div>
  );
};

export default DailyOperationForm;
