import { Button, Col, Divider, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../../../api/axiosApi";
import { getUser } from "../../../../redux/users/userSlice";
import BackButton from "../../../common/BackButton/backButton";

import { useHistory } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosApi from "../../../../api/axiosApi";

// To get all users
import { getAllUsers } from "../../../../redux/dropDownData/dropDownDataSlice";

const QAOperatorInspectionForm = (props) => {
	const [prefill, setPrefill] = useState([]);
	const [idealData, setIdealData] = useState([]);
	const [idealPartData, setIdealPartData] = useState([]);

	const [form] = Form.useForm();

	// QA Supervisor
	const userData = useSelector(getUser);

	const machineId = props.location.state["machineId"];
	console.log("machine id: " + machineId);

	const [op_recordId, setop_recordId] = useState("");

	const [partId, setPartId] = useState("614d71a6103709ff47823936");

	// To get all users
	const allUsersData = useSelector(getAllUsers);

	let history = useHistory();
	let dept= props.location.state["dept"];

	useEffect(() => {
		const idealPartData = async (partId) => {
			await axios
				.get(
					`/production/${dept}/getPartDetails?id=${partId}`
				)
				.then(function (response) {
					const resData = response.data.data;
					// console.log("idealPartData resData : \n", resData);
					setIdealPartData(() => resData);
				})
				.catch(function (error) {
					console.log(error);
					alert(error);
				});
		};

		const machineData = async () => {
			await axios
				.get(
					`/production/${dept}/getOperatorData?id=${props.match.params.id}`
				)
				.then(function (response) {
					const resData = response.data.data;
					setPartId(() => resData["part_id"]);
					setPrefill(() => resData);
					// console.log("machineData resData:\n", resData);
					setop_recordId(() => resData["_id"]);
					idealPartData(resData["part_id"]);
				})
				.catch(function (error) {
					console.log(error);
					alert(error);
				});
		};

		const idealMachineData = async () => {
			await axiosApi
				.get(
					`/production/${dept}/getMachineInformation?id=${machineId}`
				)
				.then(function (response) {
					const resData = response.data.data[0]["parameter"];
					// console.log("idealMachineData resData:\n", response);
					setIdealData(() => resData);
				})
				.catch(function (error) {
					console.log(error);
					alert(error);
				});
		};
		machineData();
		idealMachineData();
	}, [machineId, partId, props.match.params.id, dept]);

	const onFinish = (values) => {
		const data = {
			data: {
				sup_empid: userData["empid"],
				// username: userData["opr_username"],
				status: values["app_status"],
				remarks: values["remarks"],
				machine_id: machineId,
			},
			formId: op_recordId,
		};
		console.log("data.data: ", data.data);

		// Sending alert after QA Supervisor Rejects Form
		let receiverIds = [];
		alert("status result " + values["app_status"]);
		if (values["app_status"] === "Rejected") {
			console.log("QA supervisor rejected shift statup form");

			console.log("prefill:\n", prefill);

			//Operator 
			Object.entries(allUsersData.Production.Operator).forEach((e) => {
				if(e[1].username===prefill["opr_username"]){
					receiverIds.push({ rId: e[1]._id, isRead: false });
				}
			});

			// All Production Supervisors
			Object.entries(allUsersData.Production.Supervisor).forEach((e) => {
				receiverIds.push({ rId: e[1]._id, isRead: false });
			});

			axiosApi.post(`production/${dept}/createQrciForms`, {
				senderId: userData._id,
				receiverIds: receiverIds,
				machineId: machineId,
				shift: prefill["shift"],
				color: "Yellow",
				type: "instant",
				data: {
					msg: "Shift Startup Form Rejected",
					comments: data.data.remarks,
				},
			});
		}

		axios
			.post(`/production/${dept}/submitQASupervisor`, data)
			.then(function (response) {
				form.resetFields();
				toast.success("🚀 Form Submitted Successfully", {
					position: "bottom-center",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					progress: undefined,
				});
				setTimeout(() => {
					history.goBack();
				}, 3000);
			})
			.catch(function (error) {
				console.log(error);
				alert(error);
			});

		form.resetFields();
	};
	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	if (!idealData || !prefill || !idealPartData || partId === "") {
		return (
			<div>
				<h1>Loading....</h1>
			</div>
		);
	} else
		return (
			<div className="card-holder">
				<ToastContainer />
				<BackButton styleClass={"back-btn"} />
				<div className="form-card">
					<h1
						style={{
							paddingBottom: "10px",
							letterSpacing: "0.3rem",
							fontWeight: "bold",
							textAlign: "center",
						}}
					>
						Quality&nbsp; Assurance
					</h1>
					<br />

					<Form
						labelCol={{
							span: 8,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						wrapperCol={{
							span: 9,
						}}
						layout="horizontal"
						form={form}
					>
						<Row>
							<span className="sub-headers">
								EMPLOYEE DETAILS:
							</span>
						</Row>
						<Row justify="center">
							<Col span={24}>
								<Form.Item
									label="Employee Name:"
									labelAlign="left"
								>
									<Input
										style={{
											maxWidth: "500px",
											marginRight: "2px",
										}}
										placeholder={prefill["emp_name"]}
										disabled
									/>
								</Form.Item>
							</Col>
						</Row>

						<Row justify="center">
							<Col span={24}>
								<Form.Item label="Shift" labelAlign="left">
									<Input
										style={{
											maxWidth: "500px",
											marginRight: "2px",
										}}
										placeholder={prefill["shift"]}
										disabled
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row justify="center">
							<Col span={24}>
								<Form.Item label="Part Name:" labelAlign="left">
									<Input
										style={{
											maxWidth: "500px",
											marginRight: "2px",
										}}
										placeholder={idealPartData["part_name"]}
										disabled
									/>
								</Form.Item>
							</Col>
						</Row>

						<Row justify="center">
							<Col span={24}>
								<Form.Item
									label="Reason for shift startup:"
									labelAlign="left"
								>
									<Input
										style={{
											maxWidth: "500px",
											marginRight: "2px",
										}}
										placeholder={
											prefill["reason_for_shift_startup"]
										}
										disabled
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row className="form-row">
							<Col span={8}></Col>
							<Col span={8}>
								<h3>MACHINE</h3>
							</Col>
							<Col span={8}>
								<h3>IDEAL</h3>
							</Col>
						</Row>
						<Row>
							<Col span={8}>Skill Level:</Col>
							<Col span={8}>
								<Form.Item>
									<Input
										style={{
											maxWidth: "200px",
											marginRight: "2%",
											width: "200%",
										}}
										placeholder={prefill["skill_level"]}
										disabled
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item>
									<Input
										style={{
											maxWidth: "200px",
											width: "200%",
										}}
										placeholder="3"
										disabled
									/>
								</Form.Item>
							</Col>
						</Row>

						<Divider />

						<Row>
							<span className="sub-headers">SAFETY:</span>
						</Row>

						<Row className="form-row">
							<Col span={8}></Col>
							<Col span={8}>
								<h3>MACHINE</h3>
							</Col>
							<Col span={8}>
								<h3>IDEAL</h3>
							</Col>
						</Row>
						<Row>
							<Col span={8}>No. of emergency switch:</Col>
							<Col span={8}>
								<Form.Item>
									<Input
										style={{
											maxWidth: "200px",
											marginRight: "2%",
											width: "200%",
										}}
										placeholder={
											prefill?.no_of_emergency_switch
										}
										disabled
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item>
									<Input
										style={{
											maxWidth: "200px",
											width: "200%",
										}}
										placeholder={
											idealData?.no_of_emergency_switch
										}
										disabled
									/>
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col span={8}>No of Limit Switch:</Col>
							<Col span={8}>
								<Form.Item>
									<Input
										style={{
											maxWidth: "200px",
											marginRight: "2%",
											width: "200%",
										}}
										placeholder={
											prefill["no_of_limit_switch"]
										}
										disabled
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item>
									<Input
										style={{
											maxWidth: "200px",
											width: "200%",
										}}
										placeholder={
											idealData["no_of_limit_switch"]
										}
										disabled
									/>
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col span={8}>PI Card Availability</Col>
							<Col span={8}>
								<Form.Item>
									<Input
										style={{
											maxWidth: "200px",
											marginRight: "2%",
											width: "200%",
										}}
										placeholder={
											prefill["pi_card_availability"]
										}
										disabled
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item>
									<Input
										style={{
											maxWidth: "200px",
											width: "200%",
										}}
										placeholder="YES"
										disabled
									/>
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col span={8}>PPE</Col>
							<Col span={8}>
								<Form.Item>
									<Input
										style={{
											maxWidth: "200px",
											marginRight: "2%",
											width: "200%",
										}}
										placeholder={prefill["no_of_ppe"]}
										disabled
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item>
									<Input
										style={{
											maxWidth: "200px",
											width: "200%",
										}}
										placeholder={idealData["no_of_ppe"]}
										disabled
									/>
								</Form.Item>
							</Col>
						</Row>
						<Divider />

						<Row>
							<span className="sub-headers">MATERIAL:</span>
						</Row>
						<Row className="form-row">
							<Col span={8}></Col>
							<Col span={8}>
								<h3>MACHINE</h3>
							</Col>
							<Col span={8}>
								<h3>IDEAL</h3>
							</Col>
						</Row>
						<Row>
							<Col span={8}>Raw Material Name:</Col>
							<Col span={8}>
								<Form.Item>
									<Input
										style={{
											maxWidth: "200px",
											marginRight: "2%",
											width: "200%",
										}}
										placeholder={prefill["raw_material_no"]}
										disabled
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item>
									<Input
										style={{
											maxWidth: "200px",
											width: "200%",
										}}
										placeholder={
											idealPartData["rm_part_name"]
										}
										disabled
									/>
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col span={8}>
								Moisture Content in Raw Material:
							</Col>
							<Col span={8}>
								<Form.Item>
									<Input
										style={{
											maxWidth: "200px",
											marginRight: "2%",
											width: "200%",
										}}
										placeholder={
											prefill[
												"moisture_content_in_raw_material"
											]
										}
										disabled
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item>
									<Input
										style={{
											maxWidth: "200px",
											width: "200%",
										}}
										placeholder={
											idealPartData["moisture_content"]
										}
										disabled
									/>
								</Form.Item>
							</Col>
						</Row>

						<Divider />

						<Row>
							<span className="sub-headers">METHOD:</span>
						</Row>

						<Row className="form-row">
							<Col span={8}></Col>
							<Col span={8}>
								<h3>MACHINE</h3>
							</Col>
							<Col span={8}>
								<h3>IDEAL</h3>
							</Col>
						</Row>
						<Row>
							<Col span={8}>
								Programmed Selection as per mould:
							</Col>
							<Col span={8}>
								<Form.Item>
									<Input
										style={{
											maxWidth: "200px",
											marginRight: "2%",
											width: "200%",
										}}
										placeholder={
											prefill["programme_selection"]
										}
										disabled
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item>
									<Input
										style={{
											maxWidth: "200px",
											width: "200%",
										}}
										placeholder={
											idealPartData["part_method"]
										}
										disabled
									/>
								</Form.Item>
							</Col>
						</Row>

						<Divider />

						<Row>
							<span className="sub-headers">
								MACHINE PARAMETER:
							</span>
						</Row>

						<Row className="form-row">
							<Col span={8}></Col>
							<Col span={8}>
								<h3>MACHINE</h3>
							</Col>
							<Col span={8}>
								<h3>IDEAL</h3>
							</Col>
						</Row>
						<Row>
							<Col span={8}>Machine Parameter as per PPS:</Col>
							<Col span={8}>
								<Form.Item>
									<Input
										style={{
											maxWidth: "200px",
											marginRight: "2%",
											width: "200%",
										}}
										placeholder={
											prefill["machine_parameter"]
										}
										disabled
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item>
									<Input
										style={{
											maxWidth: "200px",
											width: "200%",
										}}
										placeholder={idealPartData["pps_no"]}
										disabled
									/>
								</Form.Item>
							</Col>
						</Row>

						<Divider />

						<Form.Item
							label="Approval Status"
							name="app_status"
							rules={[
								{
									required: true,
									message: "Please Select Either one!",
								},
							]}
							labelAlign="left"
						>
							<Select>
								<Select.Option value="Approved">
									Approved
								</Select.Option>
								<Select.Option value="Rejected">
									Rejected
								</Select.Option>
							</Select>
						</Form.Item>

						<Form.Item
							label="Remarks"
							name="remarks"
							rules={[
								{
									required: true,
									message: "Please Enter Your Remarks!",
								},
							]}
							labelAlign="left"
						>
							<Input style={{ maxWidth: "500px" }} />
						</Form.Item>

						<div style={{ textAlign: "center" }}>
							<Button type="primary" danger htmlType="submit">
								Submit
							</Button>
						</div>
					</Form>
				</div>
			</div>
		);
};

export default QAOperatorInspectionForm;
