import { Select } from 'antd';
import React, { useEffect, useState } from "react"
import axiosApi from "../../../api/axiosApi"
import { DatePicker } from 'antd';
import { Bar } from 'react-chartjs-2';

const { Option } = Select;


const MRM = () => {
  const [graphValue, setGraphValue] = useState("total_throughput_data")
  const [year, setYear] = useState("2022")
  const [graphData, setGraphData] = useState("")
  
  useEffect(() => {
    axiosApi
      .get(
        `/production/injectionMoulding/getMrmInfo?year=${year}&filter=${graphValue}`
      )
      .then((response) => {
        setGraphData(response.data.data)        
      })
      .catch((error) => {
        console.log(`ERRORS : ${error}`)
        alert(`Error:"${error}`)
      })
  }, [year, graphValue])

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'MRM Data',
      },
    },
  };

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December']
  const data = {
    labels: labels,
    datasets: [{
      label: graphValue.split('_').join(" ").toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' '),
      data: graphData,
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
      ],
      borderWidth: 1
    }]
  };

  function handleGraphValue(value) {
    setGraphValue(value);
  }

  function handleGraphYear(date, dateString) {
    setYear(dateString);
  } 

  return (
    <div>
      <Select
        defaultValue="total_throughput_value"
        style={{ width: 200 }}
        onChange={handleGraphValue}
      >
        <Option value="total_production_value">Total Produciton Value</Option>
        <Option value="total_throughput_value">Total Throughput Value</Option>
        <Option value="total_ncc">Total NCC</Option>
        <Option value="dih">DIH</Option>
        <Option value="scrap">Scrap</Option>
        <Option value="total_reworked_quantity">Total Rework Quantity</Option>
        <Option value="total_material_used">Total Material Used</Option>
      </Select>

      <DatePicker onChange={handleGraphYear} picker="year" />

      <Bar options={options} data={data} />;
    </div>
  )
}

export default MRM
