import { React } from "react";

import { Tabs } from "antd";
import { FormOutlined, HistoryOutlined } from "@ant-design/icons";

import "./supervisor_dashboard.css";
// import BackButton from "../../components/common/BackButton/backButton";

import EditableCell from "./../../components/forms/supervisor/form1_trial";
import SupervisorOJTDashboard from "./supervisor_ojt_dashboard";
import AllHandoverForms from "../../components/forms/supervisor/get_all_handover_forms";
import HandoverHistory from "../../components/forms/supervisor/handoverhistory";
const { TabPane } = Tabs;

const SupervisorDashboard = (props) => {
  return (
    <div>
      {/* <BackButton styleClass={"back-btn"} /> */}
      {/* <h2 className="heading-qasupervisor">{props.location.state["machineName"]}</h2> */}
      <Tabs
        defaultActiveKey="1"
        tabPosition="left"
        size="large"
        style={{ marginTop: "5%" }}
      >
        <TabPane
          tab={
            <span>
              <FormOutlined />
              Forms
            </span>
          }
          key="1"
        >
          <AllHandoverForms />
          {/* <Card title="Shift Handover Forms" bordered={false}>
              <Button type="primary">
                <Link
                  to={{
                    pathname: "/production-supervisor"
                  }}
                >
                  Fill now
                </Link>
              </Button>
            </Card> */}
          {/* <QASupervisorCurrentShift
            machineId={props.location.state["machineId"]}
          /> */}
        </TabPane>
        <TabPane
          tab={
            <span>
              <FormOutlined />
              Details
            </span>
          }
          key="2"
        >
          <div className="site-card-wrapper">
            {/* <Row gutter={16}>
                <Col span={8}> */}
            <EditableCell />
            {/* <Card title="MachineWise Details Page" bordered={false}>
              <Button type="primary">
                <Link
                  to={{
                    pathname: "/production-supervisor/machine-details"
                  }}
                >
                  Fill now
                </Link>
              </Button>
            </Card> */}
          </div>
        </TabPane>
        <TabPane
          tab={
            <span>
              <HistoryOutlined />
              Training
            </span>
          }
          key="3"
        >
          <SupervisorOJTDashboard />
          {/* <QASupervisorHistory
            machineName={props.location.state["machineName"]}
            machineId={props.location.state["machineId"]}
          /> */}
        </TabPane>

        <TabPane
          tab={
            <span>
              <HistoryOutlined />
              History
            </span>
          }
          key="4"
        >
          <HandoverHistory />
        </TabPane>
      </Tabs>

      {/* <h2>You are Viewing Machine {props.location.state["machineName"]}</h2>

      <Link
        // to={`/shift_start_up`}

        to={{
          pathname: `/shift_start_up`,
          state: {
            machineId: props.location.state["machineId"],
          },
        }}
      >
        <Card
          className="card"
          key={1}
          style={{ margin: 15 }}
          headStyle={{
            backgroundColor: "#a71d31",
            borderRadius: "10px",
            color: "#ffffff",
          }}
          title={"Shift Startup Forms"}
          hoverable
        >
          <p>Click To View All Forms </p>
        </Card>
      </Link>

      <Link to={`/other_forms`}>
        <Card
          className="card"
          key={1}
          style={{ margin: 15 }}
          headStyle={{
            backgroundColor: "#a71d31",
            borderRadius: "10px",
            color: "#ffffff",
          }}
          title={"Other Forms"}
          hoverable
        >
          <p>Click To View All Forms </p>
        </Card>
      </Link> */}
    </div>
  );
};

export default SupervisorDashboard;
