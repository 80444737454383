import { React, useState } from "react";
import { Select, Button, Input, Col, Row, Radio, Typography, Card } from "antd";
import axios from "../../../../api/axiosApi";
import { toast } from 'react-toastify';

const MaintenanceCard = ({ item, machineName, machineId, dept }) => {
	const [isMaintained, setIsMaintained] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	
	const submitCheckpoint = () => {
		if(!item.remark){
            return toast.error("Enter Remark", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
            });
        }
		setIsSubmitted(true);
		const checkPointData = {
			// Machine details
			machineName,
			machineId,
			// previously maintData array's element contents
			checkPoint: item.checkPoint,
			maintType: item.maintType,
			monthly: item.monthly,
			quarterly: item.quarterly,
			sixMonthly: item.sixMonthly,
			remark: item.remark,
			_id: item._id, // Checkpoint Metadata ID
			isMaintained,
		};

		console.log("Submitting Checkpoint data:", checkPointData);

		axios
			.post(
				`/production/${dept}/submitMachineMaintainenceDataOperator`,
				checkPointData
			)
			.then(function (res) {
				if (res.data.msg === "Checkpoint saved successfully") {
					alert(
						"Maintenance Checkpoint Data Submitted Successfully!"
					);
					setIsSubmitted(true);
				}
			})
			.catch(function (error) {
				console.log(error);
				alert(error);
			});
	};

	return (
		<Card
			title={item.checkPoint}
			style={{
				boxShadow:
					isMaintained !== true
						? "5px 5px 5px 5px grey"
						: "2px 2px 2px 2px grey",
			}}
			headStyle={{
				backgroundColor: isMaintained === true ? "#29a329" : "#cc0000",
				borderRadius: "10px",
				color: "#ffffff",
			}}
			raised="true"
		>
			<Row
				gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
				style={{ marginBottom: "4%" }}
			>
				<Col className="gutter-row" span={12}>
					<Typography.Text strong>Maintenance Type</Typography.Text>
				</Col>
				<Col className="gutter-row" span={12}>
					<Select
						defaultValue={item.maintType}
						style={{ width: 120 }}
						disabled
					>
						<Select.Option value={item.maintType}>
							{item.maintType}
						</Select.Option>
					</Select>
				</Col>
			</Row>
			<Row
				gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
				style={{ marginBottom: "4%" }}
			>
				<Col className="gutter-row" span={12}>
					<Typography.Text strong>Period</Typography.Text>
				</Col>
				<Col className="gutter-row" span={12}>
					{item.monthly ? (
						<Select
							defaultValue={item.monthly}
							style={{ width: 120 }}
							disabled
						>
							<Select.Option value={item.monthly}>
								Monthly
							</Select.Option>
						</Select>
					) : item.quarterly ? (
						<Select
							defaultValue={item.quarterly}
							style={{ width: 120 }}
							disabled
						>
							<Select.Option value={item.quarterly}>
								Quarterly
							</Select.Option>
						</Select>
					) : (
						<Select
							defaultValue={item.sixMonthly}
							style={{ width: 120 }}
							disabled
						>
							<Select.Option value={item.sixMonthly}>
								Half Yearly
							</Select.Option>
						</Select>
					)}
				</Col>
			</Row>
			<Row
				gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
				style={{ marginBottom: "4%" }}
			>
				<Col className="gutter-row" span={24}>
					<Typography.Text>Completed</Typography.Text>

					<Radio.Group
						defaultValue="false"
						onChange={(e) => {
							setIsMaintained(
								e.target.value === "true" ? true : false
							);
							item.isMaintained =
								e.target.value === "true" ? true : false;
						}}
					>
						<Radio.Button
							value="true"
							style={{ margin: "0px 12px" }}
						>
							Yes
						</Radio.Button>
						<Radio.Button value="false">No</Radio.Button>
					</Radio.Group>
				</Col>
			</Row>
			<Row
				gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
				style={{ marginBottom: "4%" }}
			>
				<Col className="gutter-row" span={24}>
					<Input
						addonBefore="Operator Remark"
						placeholder="Enter Remark"
						onChange={(e) => {
							item.remark = e.target.value;
						}}
					/>
				</Col>
			</Row>
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<div
					className="submit-btn"
					style={{
						width: "100%",
						margin: "5px",
						padding: "10px",
					}}
				>
					<Button
						type="danger"
						htmlType="submit"
						disabled={isSubmitted}
						onClick={() => {
							
							// console.log("item._id", item._id);
							submitCheckpoint();
						}}
					>
						Submit
					</Button>
				</div>
			</Row>
		</Card>
	);
};

export default MaintenanceCard;
