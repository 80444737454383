import { React, useState, useEffect } from "react";
import { Table, Tag } from "antd";

import BackButton from "../../../components/common/BackButton/backButton";
import axios from "../../../api/axiosApi";

const MachineMaintenanceSchedule = (props) => {
  const [maintData, setMaintData] = useState([]);

  const { dept } = props.match.params;

  // useEffect(() => {
  //   const maintenanceData = () => {
  //     axios
  //       .get(`/production/${dept}/getMachineMaintScheduleDetails`)
  //       .then(function (response) {
  //         const resData = response.data.data.machineDetailsArray;
  //         console.log(resData);
  //         setMaintData(() => resData);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //         alert(error);
  //       });
  //   };
  //   maintenanceData();
  // }, []);
  useEffect(() => {
    const maintenanceData = () => {
      axios
        .get(`/production/${dept}/getMachineMaintScheduleDetails`)
        .then(function (response) {
          const resData = response.data.data.machineDetailsArray;
          console.log(resData);
          setMaintData(() => resData);
        })
        .catch(function (error) {
          console.log(error);
          alert(error);
        });
    };

    maintenanceData();
  }, [dept]); // Include 'dept' in the dependency array

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "Machine Name",
      dataIndex: "machineName",
      key: "machineName",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => {
        let color = "volcano";
        let machine_status = "Pending";
        if (status === 1) {
          color = "green";
          machine_status = "Complete";
        }
        return (
          <Tag color={color} key={status}>
            {machine_status.toUpperCase()}
          </Tag>
        );
      },
    },
  ];

  const data = [];
  for (let i = 0; i < maintData.length; i++) {
    data.push({
      key: i,
      serial: i + 1,
      machineName: maintData[i].machineName,
      status: maintData[i].status,
    });
  }

  return (
    <div>
      <BackButton styleClass="back-btn" />
      <div className="card-holder">
        <div className="form-card">
          <h1
            style={{
              paddingBottom: "10px",
              letterSpacing: "0.3rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            MACHINE MAINTENANCE SCHEDULE
          </h1>
          <div>
            {maintData.length === 0 ? (
              <div>Loading or Data not Found</div>
            ) : (
              <div className="site-card-wrapper">
                <Table columns={columns} dataSource={data} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineMaintenanceSchedule;
