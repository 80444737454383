let socketClient = null;

const getSocketClientLocally = (socket) => {
  return socketClient;
};

const setSocketClientLocally = (socket) => {
  socketClient = socket;
};

const removeSocketClientLocally = () => {
  socketClient = null;
};

// send socket alert
const socketSendAlerts = ({
  senderId,
  receiverIds,
  machineId,
  shift,
  color,
  type,
  data,
}) => {
  socketClient.emit("sendNotification", {
    senderId: senderId,
    receiverIds: receiverIds,
    machineId,
    shift,
    color,
    type,
    data,
  });
};

export {
  socketSendAlerts,
  getSocketClientLocally,
  setSocketClientLocally,
  removeSocketClientLocally,
};
