import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";
import { io } from "socket.io-client";
import auth from "../components/common/LoginComponent/auth";
import Navbar from "../components/common/Navbar/navbar";
import { getUser } from "../redux/users/userSlice";

import {
	removeSocketClientLocally,
	setSocketClientLocally,
} from "../api/socket";

const ProtectedRoute = ({ component: Component, ...rest }) => {
	const userData = useSelector(getUser);
	const [socketClient, setSocketClient] = useState(null);

	const link = (process.env.NODE_ENV === 'production')?"https://api.hufpict.in/":"http://localhost:4000/";
	
	// console.log("rest = ",component)
	
	useEffect(() => {
		const socketClient = io.connect(link, {
			transports: ["websocket", "polling", "flashsocket"],
			withCredentials: true,
		});

		setSocketClient(() => socketClient);
		setSocketClientLocally(socketClient);
		socketClient.emit("newUser", userData._id);

		return () => {
			socketClient.close();
			removeSocketClientLocally();
		};
	}, [userData._id,link]);

	return (
		<div>
			<Navbar socket={socketClient} />
			<Route
				{...rest}
				render={(props) => {
					if (auth.isAuthenticated()) {
						// console.log("Authenticated : ", auth.isAuthenticated());
						console.log("props= ", {...props})
						return <Component {...props} />;
					} else {
						return (
							<Redirect
								to={{
									pathname: "/",
								}}
							/>
						);
					}
				}}
			></Route>
		</div>
	);
};

export default withRouter(ProtectedRoute);
