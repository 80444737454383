import { React } from "react";



const PreventiveMaintenance = (props) => {
  let pm=[];
let dc=[];
// console.log(props)
if(props.PmData[0] && pm.push(...props.PmData[0])){}


if(props.PmData[1] && dc.push(...props.PmData[1])){}


  return (
    <>
      <div className="r-table" id="pm">
        <div className="pm-bar">
          <table id="pm-table-bar">
            <thead>
              <tr>
                <th colSpan="2">PM</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div id="lt">PM</div>
                </td>
                <td>
                  <div className="orange-bar">
                    <button onClick={() => { props.makePmVisible(0) }}>{pm.length}</button>
                  </div>
                </td>

              </tr>
              <tr>
                <td>
                  <div id="lt">DC</div>
                </td>
                <td>
                <div className="orange-bar">
                    <button onClick={() => { props.makePmVisible(1) }}>{dc.length}</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div id="lt">Assy</div>
                </td>
                <td>
                  <div className="green-bar">
                    <a href="/">X</a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div id="lt">Paint</div>
                </td>
                <td>
                  <div className="green-bar">
                    <a href="/">X</a>
                  </div>
                </td>
              </tr>
           
            
              <tr>
               
               
                
              
              </tr>
              </tbody>
          </table>

        </div>
      </div>
    </>
  )
}

export default PreventiveMaintenance;