/* App.js */
// import React, { Component } from 'react';
import CanvasJSReact from './canvasjs.react';
// var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const NCCkpi = (props) => {
    // console.log("props", props);
		const options = {
			title: {
				text: "NCC Cost"
			},
			axisX: {
                title: "Month"
            },
			axisY: {
                title: "NCC Cost"
            },
			data: [
			{
				type: "column",
				dataPoints: [
					{ label: "Jan",  y: 0 },
					{ label: "Feb",  y: 0  },
					{ label: "Mar",  y: 0  },
					{ label: "Apr",  y: 0  },
					{ label: "May",  y: 0  },
					{ label: "Jun",  y: 0  },
					{ label: "Jul",  y: 0  },
					{ label: "Aug",  y: 0  },
					{ label: "Sep",  y: 0  },
					{ label: "Oct",  y: 0  },
					{ label: "Nov",  y: 0  },
					{ label: "Dec",  y: 0  }
				]
			}
			]
		}
		let NCC = props.NCC;
		// console.log("option data", options.data[0].dataPoints[1].label); 
		for(let i = 0; i < NCC.length; i++){
			
            let z = NCC[i].date.substr(5,2);
            if(z === "01"){
                options.data[0].dataPoints[0].y = options.data[0].dataPoints[0].y + NCC[i].total_ncc;
            
            }else if(z === "02"){
                options.data[0].dataPoints[1].y = options.data[0].dataPoints[1].y + NCC[i].total_ncc;
            
            }else if(z === "03"){
                options.data[0].dataPoints[2].y = options.data[0].dataPoints[2].y + NCC[i].total_ncc;
            
            }else if(z === "04"){
                options.data[0].dataPoints[3].y = options.data[0].dataPoints[3].y + NCC[i].total_ncc;
            
            }else if(z === "05"){
                options.data[0].dataPoints[4].y = options.data[0].dataPoints[4].y + NCC[i].total_ncc;
            
            }else if(z === "06"){
                options.data[0].dataPoints[5].y = options.data[0].dataPoints[5].y + NCC[i].total_ncc;
            
            }else if(z === "07"){
                options.data[0].dataPoints[6].y = options.data[0].dataPoints[6].y + NCC[i].total_ncc;
            
            }else if(z === "08"){
                options.data[0].dataPoints[7].y = options.data[0].dataPoints[7].y + NCC[i].total_ncc;
            
            }else if(z === "09"){
                options.data[0].dataPoints[8].y = options.data[0].dataPoints[8].y + NCC[i].total_ncc;
            
            }else if(z === "10"){
                options.data[0].dataPoints[9].y = options.data[0].dataPoints[9].y + NCC[i].total_ncc;
                
            }else if(z === "11"){
                options.data[0].dataPoints[10].y = options.data[0].dataPoints[10].y + NCC[i].total_ncc;
                
            }else if(z === "12"){
                options.data[0].dataPoints[11].y = options.data[0].dataPoints[11].y + NCC[i].total_ncc;
                
            }
        }

		return (
		<div>
			<CanvasJSChart options = {options}
				/* onRef={ref => this.chart = ref} */
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		);
	}

    export default NCCkpi;