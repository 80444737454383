import { MinusCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Radio } from "antd";
import React from "react";

//ManagementActions form
const ManagementActions = () => {
  return (
    <>
      <Form.List name="management_action_list">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <>
                <Form.Item
                  label="Validated Retained Factor N"
                  name={[name, "validated_retained_factor_n"]}
                  fieldKey={[fieldKey, "validated_retained_factor_n"]}
                  // rules={[{ required: true, message: 'Please enter data!' }]}
                  labelAlign="left"
                >
                  <Input type="string" />
                </Form.Item>
                <Form.Item
                  label="Management Actions"
                  name={[name, "management_action"]}
                  fieldKey={[fieldKey, "management_action"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter Action' }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  label="Responsible"
                  name={[name, "responsible"]}
                  fieldKey={[fieldKey, "responsible"]}
                  // rules={[{ required: true, message: 'Please enter data!' }]}
                  labelAlign="left"
                >
                  <Input type="string" />
                </Form.Item>
                <Form.Item
                  label="Deadline."
                  name={[name, "deadline"]}
                  fieldKey={[fieldKey, "deadline"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter data' }]}
                >
                  <Input type="date" />
                </Form.Item>
                <Form.Item
                  label="Done Date"
                  name={[name, "done_date"]}
                  fieldKey={[fieldKey, "done_date"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter data' }]}
                >
                  <Input type="date" />
                </Form.Item>
                <Form.Item
                  label="Effective"
                  name={[name, "effective"]}
                  fieldKey={[fieldKey, "effective"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter data' }]}
                >
                  <Radio.Group name="effective">
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label="New Risk Detected?"
                  name={[name, "new_risk_detected"]}
                  fieldKey={[fieldKey, "new_risk_detected"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter data' }]}
                >
                  <Radio.Group name="new_risk_detected">
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>

                <MinusCircleTwoTone
                  twoToneColor="#eb2f96"
                  style={{ fontSize: "20px" }}
                  onClick={() => remove(name)}
                />
                <Divider />
              </>
            ))}
            <div className="add-button">
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            </div>
          </>
        )}
      </Form.List>
    </>
  );
};

export default ManagementActions;
