import { Table } from 'antd';
import React from "react";


const plantQrciColumns = [
  {
    title: 'Machine Name',
    dataIndex: 'machine_name',
    key: 'machine_name',
  },
  {
    title: 'Problem',
    dataIndex: 'problem_as_per_operator',
    key: 'problem_as_per_operator',
  },
	{
    title: 'Operator Comment',
    dataIndex: 'comment_from_operator',
    key: 'comment_from_operator',
  },
];

const lineQrciColumns = [
    {
      title: 'Machine Name',
      dataIndex: 'machine_name',
      key: 'machine_name',
    },
    {
      title: 'Problem',
      dataIndex: 'problem_as_per_operator',
      key: 'problem_as_per_operator',
    },
      {
      title: 'Operator Comment',
      dataIndex: 'comment_from_operator',
      key: 'comment_from_operator',
    },
  ];


const QrciStatsTable = ({plantQrciData,lineQrciData,isVisible,dept}) => {

  if(isVisible && lineQrciData){
    return (
        <>
            <h2>Plant QRCI</h2>
            <Table rowKey="id" columns={plantQrciColumns} dataSource={plantQrciData[dept]} />
            <h2>Line QRCI</h2>
            <Table rowKey="id" columns={lineQrciColumns} dataSource={lineQrciData[dept]} />
        </>

    )
  }
  else{
    return (<></>);
  }
}
export default QrciStatsTable;