import { Tabs } from "antd";
import { FormOutlined, HistoryOutlined } from "@ant-design/icons";

import QASupervisorCurrentShift from "./current_shift_forms";
import QASupervisorHistory from "./qa_supervisor_history";

import "./qa_supervisor_machine_dashboard.css";
import BackButton from "../../components/common/BackButton/backButton";

import ProductionStats from "../../components/common/ProductionStats/ProductionStats";
const { TabPane } = Tabs;

const QASupervisorMachineDashboard = (props) => {
  return (
    <div>
      <BackButton styleClass={"back-btn"} />
      <ProductionStats dept="Quality Assurance" role="Supervisor"/>

      <h2 className="heading-qasupervisor">{props.location.state["machineName"]}</h2>
      <Tabs
        defaultActiveKey="1"
        tabPosition="left"
        size="large"
        style={{ marginTop: "5%" }}
      >
        <TabPane
          tab={
            <span>
              <FormOutlined />
              Forms
            </span>
          }
          key="1"
        >
          <QASupervisorCurrentShift
            machineId={props.location.state["machineId"]}
            dept= {props.location.state["dept"]}
          />
        </TabPane>

        <TabPane
          tab={
            <span>
              <FormOutlined />
              Global Forms
            </span>
          }
          key="2"
        >
          {/* <div className="site-card-wrapper">
            <Card title="PPM Form" bordered={false}>
              <Button type="primary">
                <Link
                  to={{
                    pathname: "/ppm-form",
                    state: {
                      machineId: props.location.state["machineId"],
                    },
                  }}
                >
                  Fill now
                </Link>
              </Button>
            </Card>
          </div> */}
        </TabPane>

        <TabPane
          tab={
            <span>
              <HistoryOutlined />
              History
            </span>
          }
          key="3"
        >
          <QASupervisorHistory
            machineName={props.location.state["machineName"]}
            machineId={props.location.state["machineId"]}
            dept= {props.location.state["dept"]}
          />
        </TabPane>
      </Tabs>
      ,
      {/* <h2>You are Viewing Machine {props.location.state["machineName"]}</h2>

      <Link
        // to={`/shift_start_up`}

        to={{
          pathname: `/shift_start_up`,
          state: {
            machineId: props.location.state["machineId"],
          },
        }}
      >
        <Card
          className="card"
          key={1}
          style={{ margin: 15 }}
          headStyle={{
            backgroundColor: "#a71d31",
            borderRadius: "10px",
            color: "#ffffff",
          }}
          title={"Shift Startup Forms"}
          hoverable
        >
          <p>Click To View All Forms </p>
        </Card>
      </Link>

      <Link to={`/other_forms`}>
        <Card
          className="card"
          key={1}
          style={{ margin: 15 }}
          headStyle={{
            backgroundColor: "#a71d31",
            borderRadius: "10px",
            color: "#ffffff",
          }}
          title={"Other Forms"}
          hoverable
        >
          <p>Click To View All Forms </p>
        </Card>
      </Link> */}
    </div>
  );
};

export default QASupervisorMachineDashboard;
