import { Col, Divider, Form, Input, Row, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import BackButton from "../../../common/BackButton/backButton";
import "./prod_manager.css";

import auth from "../../../common/LoginComponent/auth";
import axios from "../../../../api/axiosApi";

const ProductionManagerPrefilledForm = (props) => {
	// const [form] = Form.useForm();
	// const [empty, setEmpty] = useState(false);
	const { TextArea } = Input;
	const [prefilledForm, setPrefilledForm] = useState(null);
	const [dateFilter, setDateFilter] = useState();

	const changeDate = (e) => {
		const date = new Date();
		const offsetDate = new Date(
			e.getTime() - date.getTimezoneOffset() * 60000
		);
		setDateFilter(offsetDate);
	};

	useEffect(() => {
		if (auth.isAuthenticated() && dateFilter) {
			let datee = dateFilter.toISOString().toString().slice(0, 10);
			console.log("datee changed to ", datee);
			const fetchprefilledForm = async () => {
				await axios
					.get(
						// `/production/injectionMoulding/getOperatorData?machineId=${machineId}&shift=${currentShift}&datee=${datee}`
						`production/die_casting/getManagerPrefilledForm?datee=${datee}`
					)
					.then((response) => {
						const fetchedPrefilledForm = response.data.data;
						console.log(
							"fetchedPrefilledForm",
							fetchedPrefilledForm
						);
						setPrefilledForm(fetchedPrefilledForm);
					})
					.catch((error) => {
						console.log(`ERRORS : ${error}`);
						alert(`Error:"${error}`);
					});
			};
			fetchprefilledForm();
		}
	}, [dateFilter]);

	return (
		<>
			<BackButton styleClass="back-btn" />

			<div className="card-holder">
				<div
					className="form-card-prod-manager "
					style={{
						marginBottom: "10px",
						marginTop: "25px",
					}}
				>
					<span
						style={{
							padding: "20px",
							margin: "10px",
							// textDecoration: "bold",
							fontWeight: "500",
							fontSize: "1.1rem",
						}}
					>
						Select Date
					</span>
					<DatePicker
						style={{
							fontSize: "1.5rem",
						}}
						placeholder="Date"
						onChange={(e) => {
							changeDate(e?._d);
						}}
					/>
				</div>
				{prefilledForm && Object.keys(prefilledForm).length !== 0 ? (
					// <div className="form-grid">Prefilled form</div>
					<div
						className="form-card-prod-manager"
						style={{
							marginTop: "0px",
						}}
					>
						<h1
							style={{
								paddingBottom: "10px",

								letterSpacing: "0.3rem",
								fontWeight: "bold",
								textAlign: "center",
								marginTop: "5px",
							}}
						>
							PRODUCTION MANAGER FORM
						</h1>
						{/* <div style={{ textAlign: "center" }}>
							<strong>Date: </strong> {prefilledForm?.date}
						</div> */}
						<Form
							labelCol={{
								span: 10,
							}}
							wrapperCol={{
								span: 16,
							}}
							// form={form}
							layout="horizontal"
							colon={false}
						>
							<Divider />

							{/* <div
								style={{ textAlign: "center" }}
								marginBottom="10px"
							>
								<PartsData
									name="production"
									production={prefilledForm?.production}
								/>
							</div> */}
							<Row>
								<Col span={8}>Downtime:</Col>
								<Col span={8}>
									<Form.Item
										name="Downtime_actual"
										labelAlign="left"
									>
										<Input
											type="string"
											placeholder={
												prefilledForm.total_downtime
											}
											disabled
										/>
									</Form.Item>
								</Col>
								<Col span={8}></Col>
							</Row>
							<Row>
								<Col span={8}>Throughput Data:</Col>
								<Col span={8}>
									<Form.Item
										name="total_throughput_data"
										labelAlign="left"
									>
										<Input
											type="string"
											placeholder={
												prefilledForm.total_throughput_data
											}
											disabled
										/>
									</Form.Item>
								</Col>
								<Col span={8}></Col>
							</Row>
							<Row>
								<Col span={8}>Material used:</Col>
								<Col span={8}>
									<Form.Item
										name="total_material_used"
										labelAlign="left"
									>
										<Input
											type="string"
											placeholder={
												prefilledForm.total_material_used
											}
											disabled
										/>
									</Form.Item>
								</Col>
								<Col span={8}></Col>
							</Row>
							<Row>
								<Col span={8}>Rejection assembly:</Col>
								<Col span={8}>
									<Form.Item
										name="rejection_assembly_actual"
										labelAlign="left"
									>
										<Input
											type="string"
											placeholder={
												prefilledForm.total_rejection_assembly
											}
											disabled
										/>
									</Form.Item>
								</Col>
								<Col span={8}></Col>
							</Row>
							<Row>
								<Col span={8}>Rejection paint shop:</Col>
								<Col span={8}>
									<Form.Item
										name="rejection_paint_shop_actual"
										labelAlign="left"
									>
										<Input
											type="string"
											placeholder={
												prefilledForm.total_rejection_paintshop
											}
											disabled
										/>
									</Form.Item>
								</Col>
								<Col span={8}></Col>
							</Row>
							<Divider className="divider" />
							<Row>
								<Col span={8}>Production Value:</Col>
								<Col span={8}>
									<Form.Item
										name="production_value"
										labelAlign="left"
									>
										<Input
											type="string"
											placeholder={
												prefilledForm?.total_production_value
											}
											disabled
										/>
									</Form.Item>
								</Col>
								<Col span={8}></Col>
							</Row>
							<Row>
								<Col span={8}>No. of lines/machines:</Col>
								<Col span={8}>
									<Form.Item
										name="no_of_lines"
										labelAlign="left"
									>
										<Input
											type="string"
											placeholder={
												prefilledForm?.detailData
													?.length
											}
											disabled
										/>
									</Form.Item>
								</Col>
								<Col span={8}></Col>
							</Row>
							<Row>
								<Col span={8}>NEE:</Col>
								<Col span={8}>
									<Form.Item name="nee" labelAlign="left">
										<Input
											type="string"
											placeholder={
												prefilledForm?.total_nee
											}
											disabled
										/>
									</Form.Item>
								</Col>
								<Col span={8}></Col>
							</Row>
							<Row>
								<Col span={8}>PPM:</Col>
								<Col span={8}>
									<Form.Item name="ppm" labelAlign="left">
										<Input
											type="string"
											placeholder={
												prefilledForm?.total_ppm
											}
											disabled
										/>
									</Form.Item>
								</Col>
								<Col span={8}></Col>
							</Row>
							<Row>
								<Col span={8}>NCC Total Cost:</Col>
								<Col span={8}>
									<Form.Item name="ncc" labelAlign="left">
										<Input
											type="string"
											placeholder={
												prefilledForm?.total_ncc
											}
											disabled
										/>
									</Form.Item>
								</Col>
								<Col span={8}></Col>
							</Row>
							<Row>
								<Col span={8}>Scrap:</Col>
								<Col span={8}>
									<Form.Item name="scrap" labelAlign="left">
										<Input
											type="string"
											placeholder={prefilledForm?.scrap}
											disabled
										/>
									</Form.Item>
								</Col>
								<Col span={8}></Col>
							</Row>
							<Row>
								<Col span={8}>Reworking:</Col>
								<Col span={8}>
									<Form.Item
										name="total_reworked_quantity"
										labelAlign="left"
									>
										<Input
											type="string"
											placeholder={
												prefilledForm?.total_reworked_quantity
											}
											disabled
										/>
									</Form.Item>
								</Col>
								<Col span={8}></Col>
							</Row>
							<Row>
								<Col span={8}>RFT:</Col>
								<Col span={8}>
									<Form.Item name="rft" labelAlign="left">
										<Input
											type="string"
											placeholder={prefilledForm?.rft}
											disabled
										/>
									</Form.Item>
								</Col>
								<Col span={8}></Col>
							</Row>
							<Row>
								<Col span={8}>Headcount:</Col>
								<Col span={8}>
									<Form.Item
										name="headcount"
										labelAlign="left"
									>
										<Input
											type="string"
											placeholder={
												prefilledForm?.headcount
											}
											disabled
										/>
									</Form.Item>
								</Col>
								<Col span={8}></Col>
							</Row>
							<Divider className="divider" />
							<Row>
								<Col span={8}>Alerts:</Col>
								<Col span={16}>
									<Form.Item name="alert" labelAlign="left">
										<Input
											type="string"
											disabled
											placeholder={
												prefilledForm?.redAlerts?.length
											}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col span={8}>Overtime hours</Col>
								<Col span={16}>
									<Form.Item
										name="ot_hours"
										labelAlign="left"
									>
										<Input
											type="number"
											disabled
											placeholder={
												prefilledForm?.ot_hours
											}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col span={8}>RP & RW Balance</Col>
								<Col span={16}>
									<Form.Item
										name="rp_rw_balance"
										labelAlign="left"
									>
										<Input
											type="string"
											disabled
											placeholder={
												prefilledForm?.rp_rw_balance
											}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col span={8}>Review with Supervisor:</Col>
								<Col span={16}>
									<Form.Item
										name="review_supervisor"
										labelAlign="left"
									>
										<TextArea
											type="string"
											rows={4}
											disabled
											placeholder={
												prefilledForm?.Review_with_supervisor
											}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col span={8}>Tool Issue:</Col>
								<Col span={16}>
									<Form.Item
										name="tool_issue"
										labelAlign="left"
									>
										<TextArea
											type="string"
											rows={4}
											disabled
											placeholder={
												prefilledForm?.tool_issue
											}
										/>
									</Form.Item>
								</Col>
							</Row>
							{/* <div style={{ textAlign: "center" }}>
								<Button type="primary">
									<Link
										to={{
											pathname:
												"/production-manager-form/details",
										}}
									>
										View Details
									</Link>
								</Button>
							</div> */}
						</Form>
					</div>
				) : (
					<h2
						style={{
							padding: "10px",
							color: "white",
						}}
					>
						No form to be displayed
					</h2>
				)}
			</div>
		</>
	);
};

export default ProductionManagerPrefilledForm;
