import { Table,Collapse } from 'antd';

const { Panel } = Collapse;

const PMColumns = [
    {
        title: 'Check Point',
        dataIndex: 'checkPoint',
        key: 'checkPoint',
    },
    {
        title: 'Maintained At',
        dataIndex: 'maintainedAt',
        key: 'maintainedAt',
    },
    {
        title: 'Maintenance Type',
        dataIndex: 'maintType',
        key: 'maintType',
    },
];


const PreventiveMaintenanceTable = ({ PmData, isVisible, dept }) => {

    if (PmData && isVisible) {
        // console.log(PmData)
        return (
            <>
                <h2>Preventive Maintenance</h2>
                <Collapse>
                    { PmData[dept]?.map((item,i)=>(
                        <Panel key={i} header={item.machineName} >

                            <Table rowKey="idx" columns={PMColumns} dataSource={item.maintData} />
                        </Panel>
                    ))}
                    {!PmData[dept] && <p>No data found</p>}
                    
                </Collapse>
            </>

        )
    }
    else {
        return (<></>);
    }
}
export default PreventiveMaintenanceTable;