import React, { useEffect, useState } from "react";
import axiosApi from "../../../api/axiosApi";
import moment from "moment";
import {
  Button,
  Divider,
  Row,
  Col,
  Select,
  DatePicker,
  Descriptions,
  Modal,
} from "antd";
import "./style.css";
import { toast, ToastContainer } from "react-toastify";

const OnJobTrainingForm = (props) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const { Option } = Select;
  const [trainee, setTrainee] = useState();
  const dateFormat = "YYYY/MM/DD";
  const [isModalVisible, setIsModalVisible] = useState(false);

  function handleEvaluationChange(value, index1, index2) {
    let form = trainee.form;
    form[index1].evaluation[index2] = value;
    setTrainee({ ...trainee, form });
  }

  function handleActualLevelChange(value, index) {
    let form = trainee.form;
    form[index].actual_level = value;
    setTrainee({ ...trainee, form });
  }

  useEffect(() => {
    setLoading(true);
    axiosApi
      .get(`training/all/getTraineeForm?_id=${props.location.state["_id"]}`)
      .then(function (response) {
        setTrainee(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = (value, index) => {
    let dates = trainee.dates;
    dates[index] = value;
    setTrainee({ ...trainee, dates });
  };

  const handleAddColumnClicked = (event, index) => {
    let form = trainee.form.map((form) => {
      let item = form;
      item.evaluation.push("Unsatisfactory");
      return item;
    });

    let dates = trainee.dates;
    dates.push("");

    setTrainee({ ...trainee, dates, form });
  };

  const onModalOk = () => {
    for (let i = 0; i < trainee?.form.length; i++) {
      if (trainee?.form[i].actual_level === "") {
        toast.error("🚀 Enter all actual levels", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        setIsModalVisible(false);
        return;
      }
    }
    if (trainee?.overall === "") {
      return toast.error("🚀 Enter overall rating", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    let index = trainee.dates.findIndex((date) => date === "");
    if (index !== -1) {
      setIsModalVisible(false);
      return toast.error("🚀 Enter all dates", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    axiosApi
      .post("training/all/updateTraineeForm", trainee)
      .then((res) => {
        toast.success("🚀 Form submitted successfully!!", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        setIsModalVisible(false);
      })
      .catch((err) => {
        toast.error("🚀 Error while submitting form", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      });
  };

  const onSave = () => {
    let index = trainee.dates.findIndex((date) => date === "");
    if (index !== -1) {
      return toast.error("🚀 Enter all dates", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    axiosApi
      .post("training/all/updateTraineeForm", trainee)
      .then((res) => {
        toast.success("🚀 Form updated successfully!!", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error("🚀 Error while updating form", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      });
  };

  return !loading ? (
    <div className="card-holder">
      <ToastContainer />
      <div className="form-card training-card">
        <h1
          style={{
            paddingBottom: "10px",
            letterSpacing: "0.3rem",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          On Job Training Level {trainee?.level}
        </h1>
        <Descriptions
          bordered
          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        >
          <Descriptions.Item label="Trainee Name:" span={1.5}>
            {trainee?.trainee?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Training Status:" span={1.5}>
            {trainee?.trainee?.status}
          </Descriptions.Item>
          <Descriptions.Item label="Trainee Number:" span={1.5}>
            {trainee?.trainee?.employee_number}
          </Descriptions.Item>
          <Descriptions.Item label="Department:" span={1.5}>
            {trainee?.trainee?.dept}
          </Descriptions.Item>
          <Descriptions.Item label="Designation:" span={1.5}>
            {trainee?.trainee?.designation}
          </Descriptions.Item>
          <Descriptions.Item label="Line:" span={1.5}>
            {trainee?.trainee?.line}
          </Descriptions.Item>
        </Descriptions>

        <Divider />
        <div className="training">
          <table className="training-table">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Training Questions</th>
                <th>Required Level after OJT</th>
                <th>
                  Evaluation/Understanding <Divider />
                  {trainee?.dates.map((date, index) => {
                    return (
                      <td key={index}>
                        {" "}
                        <DatePicker
                          defaultValue={
                            date === "" ? "" : moment(date, dateFormat)
                          }
                          format={dateFormat}
                          onChange={(e, value) =>
                            handleDateChange(value, index)
                          }
                        />
                      </td>
                    );
                  })}
                </th>
                <th>Actual Level after OJT</th>
              </tr>
            </thead>
            <tbody>
              {trainee?.form.map((form, index1) => {
                return (
                  <tr key={form?._id}>
                    <td>{index1 + 1}</td>
                    <td>{form?.question}</td>
                    <td>Satisfactory</td>
                    <td>
                      {form?.evaluation.map((item, index2) => {
                        return (
                          <td key={index2}>
                            <Select
                              defaultValue={item}
                              onChange={(value) =>
                                handleEvaluationChange(value, index1, index2)
                              }
                            >
                              <Option value="Unsatisfactory">
                                Unsatisfactory
                              </Option>
                              <Option value="Satisfactory">Satisfactory</Option>
                              <Option value="Good">Good</Option>
                            </Select>
                          </td>
                        );
                      })}
                    </td>
                    <td>
                      <Select
                        defaultValue={form.actual_level}
                        onChange={(value) =>
                          handleActualLevelChange(value, index1)
                        }
                        style={{ width: 140, textAlign: "left" }}
                      >
                        <Option value="Unsatisfactory">Unsatisfactory</Option>
                        <Option value="Satisfactory">Satisfactory</Option>
                        <Option value="Good">Good</Option>
                      </Select>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div style={{ textAlign: "right", marginTop: "10px" }}>
          <Button type="primary" onClick={handleAddColumnClicked}>
            Add Column
          </Button>
        </div>

        <Modal
          title="Submit Form"
          visible={isModalVisible}
          footer={null}
          onOk={onModalOk}
          onCancel={() => setIsModalVisible(false)}
          okText="Submit"
        >
          <Row>
            <Col span={6} style={{ padding: "4px" }}>
              Overall Rating:
            </Col>
            <Col span={16}>
              <Select
                value={trainee?.overall}
                style={{ width: 140, textAlign: "left" }}
                onChange={(value) => setTrainee({ ...trainee, overall: value })}
              >
                <Option value="Unsatisfactory">Unsatisfactory</Option>
                <Option value="Satisfactory">Satisfactory</Option>
                <Option value="Good">Good</Option>
              </Select>
            </Col>
          </Row>
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <Button type="danger" onClick={() => setIsModalVisible(false)}>
              Cancel
            </Button>
            <Button type="primary" onClick={onModalOk}>
              Submit
            </Button>
          </div>
        </Modal>
        <div className="submit-btn">
          <Button type="primary" htmlType="submit" onClick={onSave}>
            Save
          </Button>
          <Button
            type="danger"
            htmlType="submit"
            onClick={() => setIsModalVisible(true)}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  ) : !error ? (
    <p>Loading...</p>
  ) : (
    <div>Error while fetching questions...</div>
  );
};

export default OnJobTrainingForm;
