import "antd/dist/antd.css"
import { Row, Col,  Divider } from "antd"

import { Descriptions, Image } from "antd"
import Logo from "../assets/img/hufLogo.jpg"
// import axios from "axios"
import { useEffect, useState } from "react"
import "./hardware_dashboard.css"
import BackButton from "../components/common/BackButton/backButton"


import { BulbTwoTone } from "@ant-design/icons"
import axiosApi from "../api/axiosApi"
// import { getMachinesData } from "../redux/dropDownData/dropDownDataSlice";
// import { useSelector } from "react-redux";
import LineChartCompare from "./line_chart_compare"

const HardwareDashboard = (props) => {
  // machineName -> shift -> part running
  const [propsData,setPropsData] = useState(); 
  // const machinesData = useSelector(getMachinesData);

 
  // const [statusData, setStatusData] = useState({
  //   timeStamps: [],
  //   status: [],
  // })
  const [productionData, setProductionData] = useState()
  const [currentPartDetails, setCurrentPartDetails] = useState()
  // const [count, setCountData] = useState(0)
  // const [rejectedVal, setRejectedVal] = useState(0)

  var dateTemp =
    new Date().toLocaleTimeString("en-US") +
    " " +
    new Date().toLocaleDateString("en-US")

  const [currentDate, setCurrentDate] = useState(dateTemp)
  const [updatedDate, setUpdatedDate] = useState(dateTemp)

  // const onChange = (value) => {
  //   // console.log("Rejected Value : ", value)
  //   setRejectedVal(() => value)
  // }


  // const fetchData = async (count) => {

    // axios
    //   .get(
    //     `https://api.thingspeak.com/channels/1532534/fields/1.json?api_key=S6WVG7WQNOQQJZM6&results=${count}`
    //   )
    //   .then((res) => {
    //     const resData = res.data
    //     // console.log("Status Data From API : ", resData);

    //     resData.feeds.forEach((ele) => {
    //       const rawDate = new Date(ele.created_at)
    //       const options = { hour12: false }
    //       const date = rawDate.toLocaleTimeString("en-US", options)
    //       if (
    //         date !== statusData.timeStamps[statusData.timeStamps.length - 1]
    //       ) {
    //         let timeStampsNew = statusData.timeStamps
    //         timeStampsNew.push(date)
    //         let statusNew = statusData.status
    //         statusNew.push(ele.field1)

    //         // setStatusData(() => ({
    //         //   timeStamps: timeStampsNew,
    //         //   status: statusNew,
    //         // }))
    //       }
    //     })
    //   })
    //   .catch((e) => {
    //     console.log("Error in fetching Machine Status : ", e)
    //   })

    // let currentMachine = machinesData.filter(item => item._id === props.locationState["machineId"]);

    // await axios
    //   .get(
    //     `https://api.thingspeak.com/channels/${currentMachine[0].count_read_api_number}/feeds.json?api_key=${currentMachine[0].count_read_key}&results=1`
    //   )
    //   .then((res) => {


    //     // const resData = res.data;
    //    // console.log("Count Data from API : ", resData);
    //     // let countNew = resData.feeds[0]["field1"];


    //     // setCountData(() => (count = countNew))
    //   })
    //   .catch((e) => {
    //     console.log("Error in fetching Machine Count : ", e)
    //   })
  // }
  





  function getData(){
    setPropsData(props?.locationState);
    // fetchData(5)
   
    axiosApi
      .get(
        `production/${props.locationState["dept"]}/getHardwareDashboardData?id=${props.locationState["machineId"]}`
      )
      .then((response) => {
        
        setProductionData(response.data.data)
       
        
      })
      .catch((error) => console.log(`ERRORS : ${error}`))

    axiosApi
      .get(
        `production/${props.locationState["dept"]}/getHardwareDashboardDetails?machine_id=${props.locationState["machineId"]}`
      )
      .then((response) => {
      //  console.log("dash det", response.data)
        let current = response.data.data
        const changeover =
          new Date(current.next_changeover_planned)
      //  console.log(changeover.toLocaleString())
        current.next_changeover_planned = changeover.toLocaleString()
        setCurrentPartDetails(current)
      })
      .catch((error) => console.log(`ERRORS : ${error}`))
  }
 

  useEffect(() => {
    setPropsData(props?.locationState);
    // fetchData(5)
    // axiosApi
    //   .get(
    //     `production/injectionMoulding/getHardwareDashboardData?id=${props.locationState["machineId"]}`
    //   )
    //   .then((response) => {
        
    //     setProductionData(response.data.data)
       
        
    //   })
    //   .catch((error) => console.log(`ERRORS : ${error}`))

    // axiosApi
    //   .get(
    //     `production/injectionMoulding/getHardwareDashboardDetails?machine_id=${props.locationState["machineId"]}`
    //   )
    //   .then((response) => {
    //   //  console.log("dash det", response.data)
    //     let current = response.data.data
    //     const changeover =
    //       new Date(current.next_changeover_planned)
    //   //  console.log(changeover.toLocaleString())
    //     current.next_changeover_planned = changeover.toLocaleString()
    //     setCurrentPartDetails(current)
    //   })
    //   .catch((error) => console.log(`ERRORS : ${error}`))

    const interval = setInterval(() => {
      // fetchData(1)
      getData();
      var date =
        new Date().toLocaleTimeString("en-US") +
        " " +
        new Date().toLocaleDateString("en-US")

      setUpdatedDate(() => date)
    }, 1000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
 // console.log('prod',productionData);
  useEffect(() => {
    const interval = setInterval(() => {
      var date =
        new Date().toLocaleTimeString("en-US") +
        " " +
        new Date().toLocaleDateString("en-US")
      setCurrentDate(() => date)
    }, 1000 * 60)
    return () => clearInterval(interval)
  }, [])


  return (
    <>
      <BackButton styleClass={"back-btn"} />
      <div style={{ padding: "0.5em" }}>
        <Image style={{ height: "15vh" }} src={Logo} />
      </div>

      <Row>
        <Col span={11}>Current Time : {updatedDate}</Col>
        <Col span={11}>Last Data Updated : {currentDate}</Col>
        <Col span={2}></Col>
      </Row>

      <div
        className="title-chart"
        style={{ fontSize: "30px", padding: "10px" }}
      >
        Machine Status{"  :  "}
        <BulbTwoTone
          twoToneColor={
            productionData?.plannedData?.qtyRequired 
              ? "#00ff00"
              : "#ff3333"
          }
        />
        {productionData?.plannedData?.qtyRequired 
          ? "MOULD OPEN"
          : "MOULD CLOSE"}
      </div>

      <Descriptions
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
      >
        <Descriptions.Item label="Machine Name">
          {propsData?.machineName}
        </Descriptions.Item>

        <Descriptions.Item label="Shift">
          {productionData?.partData?.shift ? productionData?.partData?.shift : "N/A"}
        </Descriptions.Item>

        <Descriptions.Item label="Running Part">
          {/* {propsData.partName} */}
          {productionData?.partData?.part_id?.part_name ? productionData?.partData?.part_id?.part_name : "N/A"}
        </Descriptions.Item>

        <Descriptions.Item label="Planned Quantity">
          {productionData?.plannedData?.qtyRequired ? productionData?.plannedData?.qtyRequired : "N/A"}
        </Descriptions.Item>

        <Descriptions.Item label="Next Change Over Planned">
          {currentPartDetails?.next_changeover_planned ? currentPartDetails?.next_changeover_planned : "N/A"}
        </Descriptions.Item>

        <Descriptions.Item label="Number of Cavity">
          {productionData?.partData?.part_id?.no_of_cavity ? productionData?.partData?.part_id?.no_of_cavity : "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label="Actual Quantity">
          {productionData?.partData?.actual_qty ? productionData?.partData?.actual_qty : "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label="Rejected Quantity">
          {currentPartDetails && currentPartDetails?.rejected_qty}
        </Descriptions.Item>
        <Descriptions.Item label="Total Quantity">
          {productionData?.partData?.actual_qty ? (productionData?.partData?.actual_qty -
            currentPartDetails?.rejected_qty) : "N/A"}
        </Descriptions.Item>
      </Descriptions>

      <Divider />
      <LineChartCompare machineId = {props.locationState["machineId"]} department={props.locationState["dept"]}/>
      {/* <LineChart statusData={statusData} /> */}
      <Divider />
    </>
  )
}

export default HardwareDashboard
