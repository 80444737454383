import { Button, Col, Divider, Form, Input, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
// redux
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosApi from "../../../api/axiosApi";
import BackButton from "../../common/BackButton/backButton";

const DailyReportForm = (props) => {
  const [form] = Form.useForm();
  // const { TextArea } = Input;
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [modalType, setModalType] = useState(1);
  const [feedbackType, setFeedbackType] = useState("");
  const [feedback, setFeedback] = useState("");
  const [visible, setVisible] = useState(false);
  let history = useHistory();

  const showModal = () => {
    setVisible(true);
  };

  useEffect(() => {
    setLoading(true);
    console.log(feedbackType);
    axiosApi
      .get(`production/injectionMoulding/getDailyReportForm`)
      .then((response) => {
        if (response.data.err) {
          setError(true);
          return;
        }
        console.log(response.data.data);
        setData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(`ERRORS : ${error}`);
        setError(true);
        setLoading(false);
      });
  }, [feedbackType]);

  const onFinish = async (values) => {
    console.log("Success :", values);
    axiosApi
      .post("production/injectionMoulding/submitDailyReview", {
        dih: values.dih_moulding,
      })
      .then(function (response) {
        console.log(response);
        toast.success("🚀 Form Submitted Successfully", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        setTimeout(() => {
          history.goBack();
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
        alert("Error Occured : ", error.message);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    alert("Error Occured : ", errorInfo);
  };

  const fieldModal = (
    <>
      {/* { console.log(feedbackType, feedback)} */}
      <h2 style={{ textAlign: "center" }}>Send Feedback</h2>
      <ToastContainer />

      <Input
        placeholder="Feedbacks if any"
        value={feedback}
        onChange={(e) => setFeedback(() => e.target.value)}
        style={{ marginTop: "1rem" }}
      ></Input>
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ marginTop: "1rem" }}
          onClick={() => {
            axiosApi
              .post("production/injectionMoulding/submitFeedback", {
                dept: "Production", // update to dynamic dept
                feedback: feedback,
              })
              .then(function (response) {
                console.log(response);
                toast.success("🚀 Form Submitted Successfully", {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  progress: undefined,
                });
                setTimeout(() => {
                  history.goBack();
                }, 3000);
              })
              .catch(function (error) {
                console.log(error);
                alert("Error Occured : ", error.message);
              });
          }}
        >
          Send Feedback
        </Button>
      </div>
    </>
  );

  const handleCancelAlert = () => {
    setVisible(false);
    setFeedback("");
  };

  const myModal = (
    <Modal
      visible={visible}
      onCancel={handleCancelAlert}
      footer={[
        <Button type="danger" onClick={handleCancelAlert}>
          Close
        </Button>,
      ]}
    >
      {modalType === 1 ? fieldModal : <></>}
    </Modal>
  );

  return !loading ? (
    <div className="card-holder">
      <BackButton styleClass="back-btn" />
      <ToastContainer />
      <div className="form-card-prod-manager">
        <h1
          style={{
            paddingBottom: "10px",
            letterSpacing: "0.3rem",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          DAILY REPORT FORM
        </h1>

        <Form
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 16,
          }}
          form={form}
          layout="horizontal"
          colon={false}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Divider />
          <Row className="form-row">
            <Col span={4}>
              <h3>Description</h3>
            </Col>
            <Col span={4}>
              <h3>Unit</h3>
            </Col>
            <Col span={4}>
              <h3>Moulding</h3>
            </Col>
            <Col span={4}>
              <h3>Die Casting</h3>
            </Col>
            <Col span={4}>
              <h3>Paint Shop</h3>
            </Col>
            <Col span={4}>
              <h3>Assy</h3>
            </Col>
          </Row>

          <Row className="form-row">
            <Col span={4}>Alert RED</Col>
            <Col span={2}>Nos</Col>
            <Col span={6}>
              <Input
                type="string"
                disabled
                style={{ width: "70%" }}
                placeholder={data?.managerData?.redAlerts?.length}
              />
              <Button
                type="primary"
                shape="circle"
                onClick={() => {
                  setFeedbackType("red-alert");
                  setModalType(() => 1);
                  showModal();
                }}
              >
                F
              </Button>
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
          </Row>
          {/* <Row className="form-row">
            <Col span={4}>Production Value</Col>
            <Col span={4}>In Lacs</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder={data.sum_prod_value}
                disabled
                style={{ width: "70%" }}
              />
            </Col>

            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
          </Row> */}
          {/* <Row className="form-row">
            <Col span={4}>No. Of Line/Machine</Col>
            <Col span={4}>Nos</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder={data?.Active_Machines.length}
                disabled
                style={{ width: "70%" }}
              />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
          </Row> */}
          {/* <Row className="form-row">
            <Col span={4}>NEE</Col>
            <Col span={4}>%</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder="92.7"
                disabled
                style={{ width: "70%" }}
              />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
          </Row> */}
          <Row className="form-row">
            <Col span={4}>DIH</Col>
            <Col span={2}>Days</Col>
            <Col span={6}>
              <Input
                type="string"
                style={{ width: "70%" }}
                placeholder={data?.managerData?.dih}
                disabled
              />
              <Button
                type="primary"
                shape="circle"
                onClick={() => {
                  setFeedbackType("dih");
                  setModalType(() => 1);
                  showModal();
                }}
              >
                F
              </Button>
            </Col>
            <Col span={4}>
              <Input type="string" style={{ width: "70%" }} disabled />
            </Col>
            <Col span={4}>
              <Input type="string" style={{ width: "70%" }} disabled />
            </Col>
            <Col span={4}>
              <Input type="string" style={{ width: "70%" }} disabled />
            </Col>
          </Row>
          {/* <Row className="form-row">
            <Col span={4}>PPM</Col>
            <Col span={4}>Nos</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder={data.sum_ppm}
                disabled
                style={{ width: "70%" }}
              />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder="NA"
                disabled
                style={{ width: "70%" }}
              />
            </Col>
          </Row> */}
          <Row className="form-row">
            <Col span={4}>NCC Cost Total</Col>
            <Col span={2}>In Rs.</Col>
            <Col span={6}>
              <Input
                type="string"
                placeholder={data?.managerData?.total_ncc}
                disabled
                style={{ width: "70%" }}
              />
              <Button
                type="primary"
                shape="circle"
                onClick={() => {
                  setFeedbackType("total-ncc");
                  setModalType(() => 1);
                  showModal();
                }}
              >
                F
              </Button>
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
          </Row>
          {/* <Row className="form-row">
            <Col span={4}>Scrap</Col>
            <Col span={4}>In Rs.</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder={data.sum_scrap}
                disabled
                style={{ width: "70%" }}
              />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder="NA"
                disabled
                style={{ width: "70%" }}
              />
            </Col>
          </Row> */}
          {/* <Row className="form-row">
            <Col span={4}>Reworking</Col>
            <Col span={4}>In Rs.</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder={data.sum_rework}
                disabled
                style={{ width: "70%" }}
              />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder="NA"
                disabled
                style={{ width: "70%" }}
              />
            </Col>
          </Row> */}
          {/* <Row className="form-row">
            <Col span={4}></Col>
            <Col span={4}></Col>
            <Col span={4} style={{ marginLeft: "3.3%" }}>
              <Input
                type="string"
                disabled
                placeholder="BL-"
                style={{ width: "50%" }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Input
                type="string"
                disabled
                placeholder="RP-"
                style={{ width: "50%" }}
              />
            </Col>
            <Col span={4}></Col>
          </Row> */}
          {/* <Row className="form-row">
            <Col span={4}>RFT</Col>
            <Col span={4}>%</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder={data.rft}
                disabled
                style={{ width: "70%" }}
              />
            </Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder="NA"
                disabled
                style={{ width: "70%" }}
              />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder="NA"
                disabled
                style={{ width: "70%" }}
              />
            </Col>
          </Row> */}
          <Row className="form-row">
            <Col span={4}>Through Put</Col>
            <Col span={2}>%</Col>
            <Col span={6}>
              <Input
                type="string"
                placeholder={data?.managerData?.total_throughput_data}
                disabled
                style={{ width: "70%" }}
              />
              <Button
                type="primary"
                shape="circle"
                onClick={() => {
                  setFeedbackType("total-throughput-data");
                  setModalType(() => 1);
                  showModal();
                }}
              >
                F
              </Button>
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder="NA"
                disabled
                style={{ width: "70%" }}
              />
            </Col>
          </Row>
          <Row className="form-row">
            <Col span={4}>Major Break Down</Col>
            <Col span={2}>NA</Col>
            <Col span={6}>
              <Input
                type="string"
                style={{ width: "70%" }}
                placeholder={data?.majorBreakDownsCount}
                disabled
              />
              <Button
                type="primary"
                shape="circle"
                onClick={() => {
                  setFeedbackType("major-break-down");
                  setModalType(() => 1);
                  showModal();
                }}
              >
                F
              </Button>
            </Col>
            <Col span={4}>
              <Input type="string" style={{ width: "70%" }} disabled />
            </Col>
            <Col span={4}>
              <Input type="string" style={{ width: "70%" }} disabled />
            </Col>
            <Col span={4}>
              <Input type="string" style={{ width: "70%" }} disabled />
            </Col>
          </Row>
          {/* <Row className="form-row">
            <Col span={4}>OT Hours</Col>
            <Col span={4}>Hours</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder={data.sum_rework}
                disabled
                style={{ width: "70%" }}
              />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
          </Row> */}
          {/* <Row className="form-row">
            <Col span={4}>RP/RW Balance</Col>
            <Col span={4}>Nos</Col>
            <Col span={4}>
              <Input
                type="string"
                placeholder={data.sum_rp_rw}
                disabled
                style={{ width: "70%" }}
              />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
            <Col span={4}>
              <Input type="string" disabled style={{ width: "70%" }} />
            </Col>
          </Row> */}

          {/* <div className="submit-btn">
            <Button type="danger" htmlType="submit">
              Submit
            </Button>
          </div> */}
        </Form>
        {myModal}
      </div>
    </div>
  ) : !error ? (
    <div>Loading...</div>
  ) : (
    <div>Error in fetching details</div>
  );
};

export default DailyReportForm;
