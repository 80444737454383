import { Button, Col, Divider, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosApi from "../../../../api/axiosApi";
import BackButton from "../../../common/BackButton/backButton";
import { PartsData } from "../../production_manager/parts_data";
import "./styles.css";

const ProductionManagerPrefilledForm = (props) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [data, setData] = useState();
  const [empty, setEmpty] = useState(false);

  useEffect(() => {
    setEmpty(false);
    axiosApi
      .get(`production/die_casting/getManagerPrefilledForm`)
      .then((response) => {
        console.log("prod : ", response);
        setData(response.data.data);
        setEmpty(true);
      })
      .catch((error) => alert(error));
  }, []);

  return empty ? (
    <>
      <BackButton styleClass="back-btn" />
      <div className="card-holder">
        <ToastContainer />
        <div className="form-card-prod-manager margin-top-set">
          <h1
            style={{
              paddingBottom: "10px",
              letterSpacing: "0.3rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Die-Casting DAILY REPORT
          </h1>
          <div style={{textAlign: "center"}}><strong>Date: </strong> {data?.date}</div>
          <Form
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 16,
            }}
            form={form}
            layout="horizontal"
            colon={false}
          >
            <Divider />
            <div style={{textAlign: 'center'}} marginBottom="10px">
              <PartsData
                name="production"
                production={data?.production}                
              />
            </div>
            <Row>
              <Col span={8}>Downtime:</Col>
              <Col span={8}>
                <Form.Item name="Downtime_actual" labelAlign="left">
                  <Input
                    type="string"
                    placeholder={data?.total_downtime}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>Throughput Data:</Col>
              <Col span={8}>
                <Form.Item name="total_throughput_data" labelAlign="left">
                  <Input
                    type="string"
                    placeholder={data?.total_throughput_data}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>Material used:</Col>
              <Col span={8}>
                <Form.Item name="total_material_used" labelAlign="left">
                  <Input
                    type="string"
                    placeholder={data?.total_material_used}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>Rejection assembly:</Col>
              <Col span={8}>
                <Form.Item name="rejection_assembly_actual" labelAlign="left">
                  <Input
                    type="string"
                    placeholder={data?.total_rejection_assembly}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>Rejection paint shop:</Col>
              <Col span={8}>
                <Form.Item name="rejection_paint_shop_actual" labelAlign="left">
                  <Input
                    type="string"
                    placeholder={data?.total_rejection_paintshop}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Divider className="divider" />
            <Row>
              <Col span={8}>Production Value:</Col>
              <Col span={8}>
                <Form.Item name="production_value" labelAlign="left">
                  <Input
                    type="string"
                    placeholder={data?.total_production_value}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>No. of lines/machines:</Col>
              <Col span={8}>
                <Form.Item name="no_of_lines" labelAlign="left">
                  <Input
                    type="string"
                    placeholder={data?.detailData?.length}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>NEE:</Col>
              <Col span={8}>
                <Form.Item name="nee" labelAlign="left">
                  <Input type="string" placeholder={data?.total_nee} disabled />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>PPM:</Col>
              <Col span={8}>
                <Form.Item name="ppm" labelAlign="left">
                  <Input type="string" placeholder={data?.total_ppm} disabled />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>NCC Total Cost:</Col>
              <Col span={8}>
                <Form.Item name="ncc" labelAlign="left">
                  <Input type="string" placeholder={data?.total_ncc} disabled />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>Scrap:</Col>
              <Col span={8}>
                <Form.Item name="scrap" labelAlign="left">
                  <Input type="string" placeholder={data?.scrap} disabled />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>Reworking:</Col>
              <Col span={8}>
                <Form.Item name="total_reworked_quantity" labelAlign="left">
                  <Input
                    type="string"
                    placeholder={data?.total_reworked_quantity}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>DIH:</Col>
              <Col span={8}>
                <Form.Item name="dih" labelAlign="left">
                  <Input
                    type="Number"
                    disabled
                    placeholder={data?.dih}
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>RFT:</Col>
              <Col span={8}>
                <Form.Item name="rft" labelAlign="left">
                  <Input type="string" placeholder={data?.rft} disabled />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>Headcount:</Col>
              <Col span={8}>
                <Form.Item name="headcount" labelAlign="left">
                  <Input type="string" placeholder={data?.headcount} disabled />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Divider className="divider" />
            <Row>
              <Col span={8}>Alerts:</Col>
              <Col span={16}>
                <Form.Item name="alert" labelAlign="left">
                  <Input type="string" disabled placeholder={data?.redAlerts?.length} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Overtime hours</Col>
              <Col span={16}>
                <Form.Item name="ot_hours" labelAlign="left">
                  <Input type="number" disabled placeholder={data?.ot_hours} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>RP & RW Balance</Col>
              <Col span={16}>
                <Form.Item name="rp_rw_balance" labelAlign="left">
                  <Input type="string" disabled placeholder={data?.rp_rw_balance} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Review with Supervisor:</Col>
              <Col span={16}>
                <Form.Item name="review_supervisor" labelAlign="left">
                  <TextArea type="string" rows={4} disabled placeholder={data?.Review_with_supervisor} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Tool Issue:</Col>
              <Col span={16}>
                <Form.Item name="tool_issue" labelAlign="left">
                  <TextArea type="string" rows={4} disabled placeholder={data?.tool_issue} />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
            <Col span={8}>Rework Status Offline:</Col>
            <Col span={8}>
              <Form.Item name="rework" labelAlign="left">
                <Input type="string" disabled />
              </Form.Item>
            </Col>
          </Row> */}
            <div style={{ textAlign: "center" }}>
              <Button type="primary">
                <Link to={{ pathname: "/dc-production-manager-form/details" }}>
                  View Details
                </Link>
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  ) : (
    <div>Fetching Details!</div>
  );
};

export default ProductionManagerPrefilledForm;
