import React, { useState, useEffect, useCallback } from "react";

import { Card, Row, Col, Button } from "antd";
import { Link } from "react-router-dom";

import axios from "../../api/axiosApi";

// import auth from "../../components/common/LoginComponent/auth";
import "./qa_supervisor_dashboard.css";
import PageFooter from "../../components/common/Footer/footer";

const QASupervisorDashboard = (props) => {
  // console.log("Props : ", props);

  const [machinesList, setMachinesList] = useState();
  // const [machinesListDC, setMachinesListDC] = useState();
  const [data, setData] = useState();

  const [loading, setloading] = useState(true);

  const fetchAllMachines = useCallback(async () => {
    try {
      const machineData = await axios.get("production/injectionMoulding/getMachineInformation");
      const machineListData = machineData.data.data;

      const dcMachines = await axios.get("production/die_casting/getMachineInformation")
      const dcMacData = dcMachines.data.data;
      setMachinesList(machineListData);
      let newData = [];
      for (let i = 0; i < machineListData.length; i++) {
        const res = await axios.get(`production/injectionMoulding/getProductionMouldingPlanning?machineId=${machineListData[i]._id}`);

        let temp = {};

        if (res.data.data.length === 0 || res.data.data === undefined || res.data.data === null) {
          temp = {
            _id: machineListData[i]._id,
            machineName: machineListData[i].name,
            Target: "N/A",
            Actual: "N/A",
            Part_name: "N/A",
            dept: "Injection Moulding",
            status: "No Plans"
          };

        } else {
          // console.log(res.data.data)
          // console.log("res ==",res)
          let stat;

          const runningParts = res.data.data.filter(item => item.status_of_part === "running");

          // console.log("run = ", runningParts )

          if (!runningParts || runningParts === null || runningParts === undefined) {
            if (res.data.data.length > 1) {
              stat = "Multiple Plans but no Running"
            }
            else {
              stat = "One Plan yet to start"
            }
            temp = {
              _id: machineListData[i]._id,
              machineName: machineListData[i].name,
              Target: "N/A",
              Actual: "N/A",
              dept: "Injection Moulding",
              Part_name: "N/A",
              status: stat
            };
          }

          else {
            temp = {
              _id: machineListData[i]._id,
              machineName: machineListData[i].name,
              Target: res.data.data[0].qtyRequired,
              Actual: res.data.data[0].actual_qty,
              dept: "Injection Moulding",
              Part_name: res.data.data[0].part_id.part_name,
              status: "Running"
            };
          }


        }

        // Update data array using functional update
        newData.push(temp);
      }

      for (let i = 0; i < dcMacData.length; i++) {
        const resDC = await axios.get(`production/die_casting/getPlanning?machineId=${dcMacData[i]._id}`);
        // console.log("dcMacData plans", resDC)
        let temp = {};

        if (resDC.data.data.length === 0 || resDC.data.data === null || resDC.data.data === undefined) {
          temp = {
            _id: dcMacData[i]._id,
            machineName: dcMacData[i].name,
            Target: "N/A",
            Actual: "N/A",
            dept: "Die Casting",
            Part_name: "N/A",
            status: "No Plans"
          };

        } else {
          // console.log(res.data.data)
          // console.log("res ==",res)
          let stat;

          const runningParts = resDC.data.data.filter(item => item.status_of_part === "running");

          // console.log("run = ", runningParts )

          if (!runningParts || runningParts === null || runningParts === undefined) {
            if (resDC.data.data.length > 1) {
              stat = "Multiple Plans but no Running"
            }
            else {
              stat = "One Plan yet to start"
            }
            temp = {
              _id: dcMacData[i]._id,
              machineName: dcMacData[i].name,
              Target: "N/A",
              Actual: "N/A",
              dept: "Die Casting",
              Part_name: "N/A",
              status: stat
            };
          }

          else {
            temp = {
              _id: dcMacData[i]._id,
              machineName: dcMacData[i].name,
              Target: resDC.data.data[0].qtyRequired,
              Actual: resDC.data.data[0].actual_qty,
              dept: "Die Casting",
              Part_name: resDC.data.data[0].part_id.part_name,
              status: "Running"
            };
          }


        }

        // Update data array using functional update
        newData.push(temp);
      }



      setData(newData)

      // console.log("data =", data); // This should be inside the loop or another then block
      setloading(false);
    } catch (e) {
      console.log("Error in Fetching Machine Data: ", e);
      setloading(false);
    }
  }, []);

  useEffect(() => {
    
   
      fetchAllMachines();
    
  }, [fetchAllMachines]);
  if (machinesList === null) return "No Machine Data Currently Available";

  if (loading) return "Loading Your Dashboard";
  return (
    <div className="machine-dashboard">
      <div className="site-card-wrapper" style={{ margin: "50px" }}>
        <Card title="NCC Form" bordered={false}>
          <Button type="primary">
            <Link
              to={{
                pathname: "/ncc-form",
              }}
            >
              Injection Moulding  - Fill now
            </Link>
          </Button>
          <Button type="primary">
            <Link
              to={{
                pathname: "/ncc-form",
              }}
            >
              Die Casting -  Fill now
            </Link>
          </Button>
        </Card>
      </div>
      <Row gutter={[32, 32]} className="machine-card-container">
        {data?.map((machine) => (
          <Col
            xs={24}
            sm={12}
            lg={6}
            className="machine-card"
            key={machine._id}
          >
            <Card
              title={`${machine.machineName}`}
              hoverable
              style={{ margin: 15, boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }}
              headStyle={{
                backgroundColor: "#3366ff",
                borderRadius: "10px",
                color: "#ffffff",
              }}
            >
              <p style={{ fontWeight: 'bold' }}>
                Dept Name: {machine.dept}
              </p>
              <p>Status : {machine.status}</p>
              <p>Target Quantity :{machine.Target}</p>
              <p>Actual Quantity :{machine.Actual}</p>
              <p>Part Name : {machine.Part_name}</p>

              <Link
                to={{
                  pathname: `/machines/${machine._id}`,
                  state: {
                    machineId: machine._id,
                    machineName: machine.machineName,
                    dept:machine.dept,
                  },
                }}
              >
                View Forms
              </Link>

              {/* <Row>
                <Col span={11}>
                  <Link
                    to={{
                      pathname: `/machines/${machine._id}`,
                      state: {
                        machineId: machine._id,
                        machineName: machine.name,
                      },
                    }}
                  >
                    View Forms
                  </Link>
                </Col>
                <Col span={11}>
                  <Link
                    to={{
                      pathname: `/hardware/${machine._id}`,
                      state: {
                        machineName: machine.name,
                        machineId: machine._id
                      },
                    }}
                  >
                    View Hardware
                  </Link>
                </Col>
              </Row> */}
            </Card>
          </Col>
        ))}
      </Row>
      <PageFooter />
    </div>
  );
};

export default QASupervisorDashboard;
