import { Table } from 'antd';
import React, { useEffect, useState } from "react";
import { Typography } from 'antd';

const { Text } = Typography;

const columns = [
  {
    title: 'Machine Name',
    dataIndex: 'name',
    key: 'name',
    render: (text,record) => <p>{record.machine}</p>,
  },
  {
    title: 'Target Production',
    dataIndex: 'target',
    key: 'target',
  },
  {
    title: 'Actual Production',
    dataIndex: 'actual',
    key: 'actual',
  },
  {
    title: 'Difference',
    key: 'difference',
    dataIndex: 'difference',
  },
	{
    title: 'Action',
    dataIndex: 'percentage',
    key: 'action',
		render: percentage => {
			
			return (
				<div>
					{percentage >= 30 && <Text level={3} type="danger">Red Alert</Text>}
					{percentage >= 20 && percentage < 30 && <Text level={3} type={"warning"}>Orange Alert</Text>}
					{percentage >= 10 && percentage < 20 && <Text level={3} style={{color:'#ebbd34'}}>Yellow Alert</Text>}
					{percentage < 10 && <Text level={3} type='success'>All Good</Text>}
				</div>
			);
		}
  },
];


const ProductionStatsDetails = ({data}) => {

	const [isLoading, setLoading] = useState(true);
	const [prodValues, setProdValues] = useState([]);

	useEffect(() => {
		if(data){
			setLoading(false);
			setProdValues(data);
		}
	},[data])

	return (
		<Table rowKey="machine" columns={columns} dataSource={prodValues} loading={isLoading}/>
	)
}
export default ProductionStatsDetails;