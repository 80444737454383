import { Button, Col, Divider, Form, Input, Row, Select, Table } from "antd";
import  { useState } from "react";
import axiosApi from "../../../api/axiosApi";
import BackButton from "../../common/BackButton/backButton";
import "./style.css";
import { getUser } from "../../../redux/users/userSlice";
import { useSelector } from "react-redux";

// redux
import { useHistory } from "react-router-dom";

// common components
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

const Supervisor_Handover_Form = (props) => {
	// console.log("Props Data : ", props.location.state);

	const itemData = props.location.state.item;
	console.log(itemData);

	const [data, setData] = useState();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(true);
	// eslint-disable-next-line no-unused-vars
	const [lumpsWeight, setLumpsWeight] = useState();
	const [error, setError] = useState();
	const [partData, setPartData] = useState(null);
	const { id } = props.match.params;
	let history = useHistory();
	const userData = useSelector(getUser);
	// let dept = userData["dept"][0];
	let dept = userData["operation"][0];
    let department;
	let tempDepartment = '';

	if (dept === "Injection Moulding") {
	  department = "injectionMoulding";
	  tempDepartment = 'ProductionMoulding'
	}
	else if (dept === "Die Casting") {
	  department = "die_casting";
	}

	const onFinish = async (values) => {
		console.log("Success :", values);

		axiosApi
			.post(`production/${department}/submitSupervisorform`, {
				operator_id: values.operator_id,
				machine_id: data.machine_id,
				part: data.part,
				operator_name: values.operator_name,
				shift: values.shift,
				output: values.output,
				rejection: values.rejection,
				// lumps_weight: values.lumps_weight,
				// throughput_data: data.throughput_data,
				machine_operators: 1,
				machine_offline_operators: values.machine_offline_operators,
				rejection_assembly: values.rejection_assembly,
				rejection_paint_shop: values.rejection_paint_shop,
				ftr: values.ftr,
				reworked_quantity: values.reworked_quantity,
				reworking_status: values.reworking_status,
				concerns: values.concerns,
				handover_id: data.handover_id,
				cycle_id: data.cycle_id,
				date: data.date,
			})
			.then(function (response) {
				toast.success("🚀 Form Submitted Successfully", {
					position: "bottom-center",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					progress: undefined,
				});
				history.goBack();
			})
			.catch(function (error) {
				console.log(error);
				alert("Error Occured : ", error);
			});
	};

	// eslint-disable-next-line no-unused-vars
	const getThroughput = () => {
		if (!lumpsWeight) {
			toast.error("🚀 Enter lumps weight!!", {
				position: "bottom-center",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				progress: undefined,
			});
			return;
		}
		axiosApi
			.post(`production/${department}/getThroughput`, {
				id,
				lumpwt: lumpsWeight,
				production: data.production,
			})
			.then((res) => {
				setData({ ...data, throughput_data: res.data.data });
				console.log("th", res.data.data);
			})
			.catch((err) => {
				console.log(err);
				toast.error("🚀 Error while fetching throughput!!", {
					position: "bottom-center",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					progress: undefined,
				});
				return;
			});
	};

	useEffect(() => {
		setLoading(true);

		axiosApi
			.get(`production/${department}/getSupervisorForm?id=${id}`)
			.then((res) => {
				console.log("res.data.data", res.data.data);
				// console.log("QA Sup. filled on", res.data.data.qa_filled_date);
				const curr_date = new Date().toISOString().slice(0, 10);
				// console.log("Today's Date", curr_date);
				setData(res.data.data);
				if (res.data.data.qa_filled_date !== curr_date) {
					alert("QA Supervisor has NOT filled NCC Form yet");
					history.goBack();
				}
				axiosApi
					.get(
						`production/${department}/get${tempDepartment}Planning?machineId=${res.data.data.machine_id}`
					)
					.then((response) => {
						// console.log("response", response);
						setPartData(
							response.data.data.map((x) => ({
								key: x._id,
								part_name: x.part_id.part_name,
								qtyRequired: x.qtyRequired,
							}))
						);
						setLoading(false);
					})
					.catch((err) => {
						console.log("err =>", err);
						setError(true);
						setLoading(false);
					});
			})
			.catch((err) => {
				console.log("err =>", err);
				setError(true);
			});
	}, [id, history, department, tempDepartment]);

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
		alert("Error Occured : ", errorInfo);
	};

	return !loading ? (
		<>
			<BackButton />
			<div className="card-holder">
				<ToastContainer />
				<div className="form-card">
					<h1
						style={{
							paddingBottom: "10px",
							letterSpacing: "0.3rem",
							fontWeight: "bold",
						}}
					>
						Supervisor Handover Form
					</h1>

					<Form
						labelCol={{
							span: 10,
						}}
						wrapperCol={{
							span: 8,
						}}
						form={form}
						layout="horizontal"
						colon={false}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						initialValues={{ machine_operators: 1 }}
					>
						<Form.Item
							label="Operator Id"
							name="operator_id"
							labelAlign="left"
							initialValue={data?.operator_id}
						>
							<Input type="string" disabled />
						</Form.Item>
						<Form.Item
							label="Operator Name"
							name="operator_name"
							labelAlign="left"
							initialValue={data?.operator_name}
						>
							<Input type="string" disabled />
						</Form.Item>

						<Form.Item
							label="Shift"
							name="shift"
							labelAlign="left"
							initialValue={data?.shift}
						>
							<Select disabled>
								<Select.Option value="1">I</Select.Option>
								<Select.Option value="2">II</Select.Option>
								<Select.Option value="3">III</Select.Option>
							</Select>
						</Form.Item>

						<Divider className="divider" />
						<span className="sub-headers">
							SHIFT HANDOVER DATA:
						</span>
						{itemData?.production?.map((item) => {
							// console.log("item", item);
							return (
								<div key={item._id}>
									<Row style={{ margin: "1.2em 0" }}>
										<Col
											span={12}
											style={{ textAlign: "left" }}
										>
											Part Name
										</Col>
										<Col
											span={12}
											style={{ textAlign: "left" }}
										>
											<Input
												placeholder={
													item?.part_id.part_name
												}
												disabled
											/>
										</Col>
									</Row>
									<Row style={{ margin: "1.2em 0" }}>
										<Col
											span={12}
											style={{ textAlign: "left" }}
										>
											Accepted Quantity
										</Col>
										<Col
											span={12}
											style={{ textAlign: "left" }}
										>
											<Input
												placeholder={item?.accepted_qty}
												disabled
											/>
										</Col>
									</Row>
									<Row style={{ margin: "1.2em 0" }}>
										<Col
											span={12}
											style={{ textAlign: "left" }}
										>
											Un-Accepted Quantity
										</Col>
										<Col
											span={12}
											style={{ textAlign: "left" }}
										>
											<Input
												placeholder={
													item?.unaccepted_qty
												}
												disabled
											/>
										</Col>
									</Row>
									<Row style={{ margin: "1.2em 0" }}>
										<Col
											span={12}
											style={{ textAlign: "left" }}
										>
											TQW Rejected Quantity
										</Col>
										<Col
											span={12}
											style={{ textAlign: "left" }}
										>
											<Input
												type="number"
												defaultValue={
													item?.tqw_rejected_quantity
												}
												disabled
											/>
										</Col>
									</Row>
								</div>
							);
						})}
						<Divider className="divider" />
						<Form.Item
							label="Down Time"
							name="down_time"
							labelAlign="left"
						>
							<Input type="string" disabled />
						</Form.Item>

						<Divider className="divider" />

						<span className="sub-headers">
							REVIEW AGAINST PLAN:
						</span>
						{partData && (
							<Table
								dataSource={partData}
								pagination={false}
								columns={[
									{
										title: "Part Name",
										dataIndex: "part_name",
										key: "part_name",
									},
									{
										title: "Qty Required",
										dataIndex: "qtyRequired",
										key: "qtyRequired",
									},
								]}
							/>
						)}
						<Divider className="divider" />
						{/* <span className="sub-headers">THROUGHPUT DATA:</span>
          <Form.Item
            label="Lumps Weight"
            name="lumps_weight"
            labelAlign="left"
            rules={[{ required: true, message: "Please enter something!" }]}
            style={{
              marginTop: "20px",
            }}
          >
            <Input type="string" onChange={(e) => setLumpsWeight(e.target.value)}></Input>
          </Form.Item>
          <Button type="primary" onClick={getThroughput}>Save</Button>
          <Form.Item label="Result" name="result" labelAlign="left">
            <Input disabled placeholder={data?.throughput_data}></Input>
          </Form.Item> */}

						<Divider />
						<span className="sub-headers"> HEAD COUNT</span>
						<Form.Item
							label="Machine Operators"
							name="machine_operators"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter something!" }]}
							style={{
								marginTop: "20px",
							}}
						>
							<Input type="number" defaultValue={1} disabled />
						</Form.Item>
						<Form.Item
							label="Machine Offline Operators"
							name="machine_offline_operators"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please enter something!",
								},
							]}
						>
							<Input type="number" />
						</Form.Item>

						<Divider className="divider" />

						<Form.Item
							label="Rejection from Assembly"
							name="rejection_assembly"
							labelAlign="left"
							style={{
								fontWeight: "bold",
								textAlign: "left",
							}}
							initialValue={data?.rejection_assembly}
						>
							<Input type="string" disabled></Input>
						</Form.Item>

						<Divider className="divider" />
						<Form.Item
							label="Rejection from Paint Shop"
							name="rejection_paint_shop"
							labelAlign="left"
							style={{
								fontWeight: "bold",
								textAlign: "left",
							}}
							initialValue={data?.rejection_paint_shop}
						>
							<Input type="string" disabled></Input>
						</Form.Item>
						<Divider className="divider" />
						<span className="sub-headers"> REWORKING STATUS</span>
						<Form.Item
							label="FTR"
							name="ftr"
							labelAlign="left"
							style={{
								marginTop: "20px",
							}}
							rules={[
								{
									required: true,
									message: "Please enter something!",
								},
							]}
						>
							<Input type="string"></Input>
						</Form.Item>
						<Form.Item
							label="Reworked Quantity"
							name="reworked_quantity"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please enter something!",
								},
							]}
						>
							<Input type="string"></Input>
						</Form.Item>
						<Form.Item
							label="Reworking Status"
							name="reworking_status"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please select something!",
								},
							]}
						>
							<Select style={{ textAlign: "left" }}>
								<Select.Option value="Ok">Ok</Select.Option>
								<Select.Option value="Not_Ok">
									Not Ok
								</Select.Option>
								<Select.Option value="pending">
									Pending
								</Select.Option>
							</Select>
						</Form.Item>

						<Divider className="divider" />
						<Form.Item
							label="Concerns"
							name="concerns"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter something!" }]}
							style={{
								fontWeight: "bold",
								textAlign: "left",
							}}
						>
							<Input type="string"></Input>
						</Form.Item>
						<Divider />
						<Button type="danger" htmlType="submit">
							Submit
						</Button>
					</Form>
				</div>
			</div>
		</>
	) : error ? (
		<div>Error while fetching data...</div>
	) : (
		<div>Loading...</div>
	);
};

export default Supervisor_Handover_Form;
