import React from "react";
import { Form, Select, Input, Button, Divider } from "antd";
import { MinusCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { getPartsData } from "../../../../redux/dropDownData/dropDownDataSlice";

export const PartsData = (props) => {
  const partsData = useSelector(getPartsData);
  console.log(props.rejectedQuantity)

  const initialValuesArray = Array.isArray(props.rejectedQuantity)
    ? props.rejectedQuantity
    : [props.rejectedQuantity || {}];
  return (
    <>
      <Form.List name={props.name} initialValue={initialValuesArray}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <>
                <Form.Item
                  label="Part Name"
                  name={[name, "part_id"]}
                  labelAlign="left"
                  rules={[
                    { required: true, message: "Please enter part name!" },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {partsData &&
                      partsData.map((part, index) => (
                        <Select.Option key={index} value={part._id}>
                          {part.part_name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Part Quality"
                  labelAlign="left"
                  name={[name, "part_quality"]}
                  rules={[
                    {
                      required: true,
                      message: "Please select something!",
                    },
                  ]}
                  style={{
                    textAlign: "left",
                  }}
                >
                  <Select style={{ fontWeight: "lighter", width: "100%" }}>
                    <Select.Option value="Good">Good</Select.Option>
                    <Select.Option value="Moderate">Moderate</Select.Option>
                    <Select.Option value="Bad">Bad</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Accepted Quantity"
                  name={[name, "accepted_qty"]}
                  labelAlign="left"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Accepted Quantity!",
                    },
                  ]}
                >
                  <Input type="number" style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                  label="Un-Accepted Quantity"
                  name={[name, "unaccepted_qty"]}
                  labelAlign="left"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Unaccepted Quantity!",
                    },
                  ]}
                  style={{ paddingBottom: "5px" }}
                >
                  <Input type="number" style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                  label="TQW Rejected Quantity"
                  name={[name, "tqw_rejected_quantity"]}
                  labelAlign="left"
                  rules={[
                    {
                      // required: true,
                      message: "Please enter Unaccepted Quantity!",
                    },
                  ]}
                  style={{ paddingBottom: "5px" }}
                >
                  <Input
                    type="number"
                    style={{ width: "100%" }}
                    defaultValue={0}
                  />
                </Form.Item>

                <MinusCircleTwoTone
                  twoToneColor="#eb2f96"
                  style={{ fontSize: "20px" }}
                  onClick={() => remove(name)}
                />
                <Divider />
              </>
            ))}
            <div className="add-button">
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            </div>
          </>
        )}
      </Form.List>
      
    </>
  );
};
