import React from "react"
import { Form, Select, Input, Button, Divider, Row, Col } from "antd"
import { MinusCircleTwoTone, PlusOutlined } from "@ant-design/icons"
import { useSelector } from "react-redux"
import { getPartsData } from "../../../redux/dropDownData/dropDownDataSlice"

export const PartsData = (props) => {
  const partsData = useSelector(getPartsData)
  return (
    <>
      <Form.List name={props.name} initialValue={props?.production}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <>
                <Row>
                  <Col span={8} style={{textAlign: "left" }}>Part Name:</Col>
                  <Col span={8}>
                    <Form.Item
                      name={[name, "part_id"]}
                      labelAlign="left"
                      rules={[
                        { required: true, message: "Please enter part name!" },
                      ]}
                    >
                      <Select>
                        {partsData &&
                          partsData.map((part, index) => (
                            <Select.Option key={index} value={part._id}>
                              {part.part_name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}></Col>
                </Row>
                <Row>
                  <Col span={8} style={{textAlign: "left" }}>Output:</Col>
                  <Col span={8}>
                    <Form.Item
                      name={[name, "accepted_qty"]}
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Accepted Quantity!",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        style={{ width: "100%", paddingBottom: "5px" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}></Col>
                </Row>
                <Row>
                  <Col span={8} style= {{textAlign: "left" }}>Rejection:</Col>
                  <Col span={8}>
                    <Form.Item
                      
                      name={[name, "unaccepted_qty"]}
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Unaccepted Quantity!",
                        },
                      ]}
                    >
                      <Input type="number" style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={8}></Col>
                </Row>
                <MinusCircleTwoTone
                  twoToneColor="#eb2f96"
                  style={{ fontSize: "20px" }}
                  onClick={() => remove(name)}
                />
                <Divider />
              </>
            ))}
            <div className="add-button">
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            </div>
            <Divider />
          </>
        )}
      </Form.List>
    </>
  )
}
