import { React, useState, useEffect } from "react";
import { Col, Row } from "antd";

import MaintenanceHistoryCard from "./maintenanceHistoryCard";
import BackButton from "../../../common/BackButton/backButton";
import axios from "../../../../api/axiosApi";
import "./machineMainetenanceView.css";

const MachineMaintenanceView = (props) => {
	const [maintData, setMaintData] = useState([]);
	const {dept}= props.match.params;
	const machineId = props.location.state["machineId"];
	const month = props.location.state["month"];

	useEffect(() => {
		const maintenanceData = () => {
			axios
				.get(
					`/production/${dept}/getMachineMaintainenceDataByManager?machineID=${machineId}`
				)
				.then(function (response) {
					const resData = response.data.data;
					setMaintData(() => resData[month]);
				})
				.catch(function (error) {
					console.log(error);
					alert(error);
				});
		};
		maintenanceData();
	}, [machineId, month, dept]);

	return (
		<>
			<BackButton styleClass="back-btn" />
			<div className="card-holder">
				<div className="form-card">
					<h1
						style={{
							paddingBottom: "10px",
							letterSpacing: "0.3rem",
							fontWeight: "bold",
							textAlign: "center",
						}}
					>
						MACHINE MAINTENANCE HISTORY
					</h1>
					<div>
						{maintData.length === 0 ? (
							<div>Loading</div>
						) : (
							<div className="site-card-wrapper">
								<Row
									gutter={[
										{ xs: 8, sm: 16, md: 24, lg: 32 },
										{ xs: 8, sm: 16, md: 24, lg: 32 },
									]}
								>
									{maintData?.map(function (item, i) {
										return (
											<Col span={12} key={i}>
												<MaintenanceHistoryCard
													item={item}
													machineId={machineId}
												/>
											</Col>
										);
									})}
								</Row>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default MachineMaintenanceView;
