import { MinusCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Radio } from "antd";
import React from "react";

//D6
const LessonsLearned = () => {
  return (
    <>
      <Form.List name="d8_lessons_learned">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <>
                <Form.Item
                  label="Actions"
                  name={[name, "action"]}
                  fieldKey={[fieldKey, "action"]}
                  labelAlign="left"
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  label="Responsible"
                  name={[name, "responsible"]}
                  fieldKey={[fieldKey, "responsible"]}
                  // rules={[{ required: true, message: 'Please enter data!' }]}
                  labelAlign="left"
                >
                  <Input type="string" />
                </Form.Item>
                <Form.Item
                  label="Deadline."
                  name={[name, "deadline"]}
                  fieldKey={[fieldKey, "deadline"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter data' }]}
                >
                  <Input type="date" />
                </Form.Item>
                <Form.Item
                  label="Done Date"
                  name={[name, "done_date"]}
                  fieldKey={[fieldKey, "done_date"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter data' }]}
                >
                  <Input type="date" />
                </Form.Item>

                <Form.Item
                  label="Check?"
                  name={[name, "check"]}
                  fieldKey={[fieldKey, "check"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter data' }]}
                >
                  <Radio.Group name="check">
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>
                <MinusCircleTwoTone
                  twoToneColor="#eb2f96"
                  style={{ fontSize: "20px" }}
                  onClick={() => remove(name)}
                />
                <Divider />
              </>
            ))}
            <div className="add-button">
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            </div>
          </>
        )}
      </Form.List>
    </>
  );
};

export default LessonsLearned;
