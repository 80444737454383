import { Form, Input, Button, Card, Image } from "antd";
import auth from "./auth";
import { loginController } from "./logic";

import { useDispatch } from "react-redux";
import { addUser } from "../../../redux/users/userSlice";
import "./login-component.styles.css";

import logo from "../../../assets/img/hufLogo.jpg";
import axiosApi from "../../../api/axiosApi";

import { useEffect } from "react";

// redux
import { addDropDownData } from "../../../redux/dropDownData/dropDownDataSlice";
import "react-toastify/dist/ReactToastify.css";

import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

const LoginComponent = (props) => {
  const dispatch = useDispatch();
  
  const getDropDownData = async (dept, operation) => {
    // let response = await axiosApi.get(
    //   "production/injectionMoulding/getDropdownData"
    // );
    // // console.log("DropDownData : ", response);
    // dispatch(addDropDownData(response.data.data));
    // console.log(dept[0])
//     console.log(dept, operation)
    if(dept=== "Production" && (operation==="Injection Moulding" || operation==="All"))
    {
      // console.log("in pm");
      let response = await axiosApi.get(
        "production/injectionMoulding/getDropdownData"
      );
      // console.log("DropDownData : ", response);
      dispatch(addDropDownData(response.data.data));
    }
    else if(operation === "Die Casting")
    {
      // console.log("in dc");
      let response = await axiosApi.get(
        "production/die_casting/getDropDownData"
      );
      // console.log("DropDownData : ", response);
      dispatch(addDropDownData(response.data.data));
    }
  };

  useEffect(() => {
    async function logInCheck() {
      let response = await axiosApi.post("auth/login");
      const resData = response.data;
      // console.log("Response For Logged In User : ", resData);

      if (resData["err"] === false && resData["msg"] === "Already Logged In") {
        // send data to redux store
        dispatch(addUser(resData["data"][0]));
        await getDropDownData(resData.data[0].dept[0], resData.data[0].operation[0] );
        // console.log("Moving to Dashboard",  resData.data[0]);
        auth.login(() => {
          props.history.push("/dashboard");
        });
      } else {
        console.log("Some Error Here While Logging In");
      }
    }

    logInCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values) => {
    const resData = await loginController(values);

    if (
      (resData['data']["err"] === false &&
        resData["data"]["msg"] === "successfully logged in") ||
      resData["data"]["msg"] === "Already Logged In"
    ) {
      // send data to redux store
      dispatch(addUser(resData.data["data"][0]));
      // console.log("Moving to Dashboard Freshly");

      await getDropDownData(resData.data.data[0].dept[0], resData.data.data[0].operation[0]);  

      auth.login(() => {
        props.history.push("/dashboard");
      });
    } else {
      toast.error("Invalid Credentials", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="login">
      <ToastContainer />
      <Card className="card">
        <Form
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <Image className="image" src={logo} />
          <h3> INDUSTRY 4.0 </h3>
          <h2 style={{ fontWeight: "bold" }}> LOGIN </h2>
          <div className="login-form">
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please enter your username!",
                },
              ]}
            >
              <Input className="input" placeholder="Username" />
            </Form.Item>
          </div>

          <div className="login-form">
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password!",
                },
              ]}
            >
              <Input.Password className="input" placeholder="Password" />
            </Form.Item>
          </div>

          <Button
            type="primary"
            htmlType="submit"
            style={{ borderRadius: 5, width: "100%" }}
          >
            Login
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default LoginComponent;
