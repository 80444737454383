import { MinusCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input } from "antd";
import React from "react";

const IsAndIsNotAnalysis = () => {
  // const onFinish = values => {
  //     console.log('Received values of form:', values);
  // };

  return (
    <>
      <Form.List name="is_and_is_not_analysis">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <>
                <Form.Item
                  label="IS (Is the problem, is contributing..)"
                  name={[name, "is_problem_contributing"]}
                  fieldKey={[fieldKey, "is_problem_contributing"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter Data' }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  label="IS NOT (Is not the problem, is not contributing..)"
                  name={[name, "is_problem_not_contributing"]}
                  fieldKey={[fieldKey, "is_problem_not_contributing"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter Data' }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  label="RETAINED FACTORS (Potential technical root cause, validated with data is mandatory!)"
                  name={[name, "retained_factors"]}
                  fieldKey={[fieldKey, "retained_factors"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter Data' }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  label="N^0"
                  name={[name, "n0"]}
                  fieldKey={[fieldKey, "n0"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: "Please enter Data!" }]}
                >
                  <Input.TextArea />
                </Form.Item>

                <MinusCircleTwoTone
                  twoToneColor="#eb2f96"
                  style={{ fontSize: "20px" }}
                  onClick={() => remove(name)}
                />
                <Divider />
              </>
            ))}
            <div className="add-button">
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            </div>
          </>
        )}
      </Form.List>
    </>
  );
};

export default IsAndIsNotAnalysis;
