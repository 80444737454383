import { Button, Divider, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import axiosApi from "../../../../api/axiosApi";

import styles from "../../operator/styleop.module.css";

// redux
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	getAllUsers,
	getMachinesData,
	getPartsData,
	getPpeKit,
	getRawMaterialData,
} from "../../../../redux/dropDownData/dropDownDataSlice";

import { getOperatorData } from "../../../../redux/operators/operatorSlice";
import { getUser } from "../../../../redux/users/userSlice";
import BackButton from "../../../common/BackButton/backButton";
import EmployeeDetail from "../../../common/employeeDetail";

const ShiftStartupForm = (props) => {
	const [form] = Form.useForm();

	const userData = useSelector(getUser);
	const partsData = useSelector(getPartsData);
	const operatorData = useSelector(getOperatorData);
	const ppeKitData = useSelector(getPpeKit);
	const machinesData = useSelector(getMachinesData);
	const rawMaterialData = useSelector(getRawMaterialData);

	const allUsersData = useSelector(getAllUsers);
	let history = useHistory();

    const [planData,setPlanData] = useState();

	useEffect(() => {
		axiosApi
			.get(
				`production/die_casting/getPlanning?machineId=${operatorData.machineId}`
			)
			.then((res) => {
				setPlanData(res.data.data);
				console.log("prod plan : ", res);
			})
			.catch((err) => {
				console.log("err >>", err);
				alert("error while fetching data");
			});
	}, [operatorData.machineId]);

	// console.log("Raw Material Data : ", rawMaterialData);
	const onFinish = async (values) => {
		console.log("Success : ", values);
		console.log("shift_startup_form userData:\n", userData);
		console.log(
			"shift_startup_form userData.username:\n",
			userData.username
		);

		await axiosApi
			.post("/production/die_casting/submitOperatorData", {
				new_day: values["new_day"],
				no_of_emergency_switch: values["no_of_emergency_switch"],
				no_of_limit_switch: values["no_of_limit_switch"],
				pi_card_availability: values["pi_card"],
				no_of_ppe: values["ppe_list"],
				skill_level: values["skill_level"],
				raw_material_no: values["raw_material_no"],
				moisture_content_in_raw_material: values["moisture_content"],
				programme_selection: values["programme_selection"],
				machine_parameter: values["machine_parameter"],
				machine_id: operatorData.machineId,
				shift: operatorData.shift,
				emp_name: `${userData.firstname} ${userData.lastname}`,
				// Operator username
				opr_username: userData.username,
				plan_id: values["plan_name"],
				sup_empid: values["sup_empid"],
				status: "Pending",
				when_created: Date.now(),
				who_created: "BB",
				dep: "die_casting",
				operation: "die_casting",
				reason_for_shift_startup: values["reason_for_shift_startup"],
				part_id: values.part_name,
			})

			.then(async (res) => {
				console.log(res);
			// 	await axiosApi
			// 		.post(
			// 			`production/injectionMoulding/submitActualGraph?machine_id=${operatorData.machineId}`
			// 		)
					// .then((res) => {})
					// .catch((err) => {
					// 	console.log("err >>", err);
					// 	alert("error while resetting count");
					// });
			// 	// check if the shift form is already filled
				if (res.data.msg === "Shift startup-form already exists") {
					form.resetFields();
					alert("Form can be filled only once for every shift!!!");
					history.goBack();
				}
				if (res.data.msg === "Form saved successfully") {
					form.resetFields();
					alert("Form Submitted To Supervisor!!!");
					history.goBack();
				}
			})
			
			.catch((e) => {
				console.log("Error : " + e);
				alert("Error Occured : ", e);
			});
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
		alert("Error Occured : Incomplete Form", errorInfo);
	};



	return (
		<>
			<BackButton styleClass={"back-btn"} />
			<div className="card-holder">
				<div className={styles.form_card}>
					<h1
						style={{
							paddingBottom: "10px",
							letterSpacing: "0.3rem",
							fontWeight: "bold",
							textAlign: "center",
						}}
					>
						SHIFT STARTUP FORM
					</h1>

					<Form
						labelCol={{
							span: 10,
						}}
						wrapperCol={{
							span: 8,
						}}
						form={form}
						layout="horizontal"
						colon={false}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						initialValues={{
							shift: operatorData.shift,
							machine_name: operatorData.machineId,
						}}
					>
						<EmployeeDetail />

						<Form.Item label="Shift" name="shift" labelAlign="left">
							<Select disabled>
								<Select.Option value="1">I</Select.Option>
								<Select.Option value="2">II</Select.Option>
								<Select.Option value="3">III</Select.Option>
							</Select>
						</Form.Item>

						<Form.Item
							label="Reason for shift startup activity: "
							name="reason_for_shift_startup"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please enter something!",
								},
							]}
						>
							<Select style={{width: '100%'}}
								showSearch
								filterOption={(input, option) =>
									option.children
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								dropdownMatchSelectWidth={false} // To prevent the dropdown from taking full width
  								dropdownStyle={{ maxWidth: '100%'}} // Set a maximum width for the dropdown
  								optionLabelProp="value"
								>
								<Select.Option
									value="New shift started"
									default
								>
									New shift started/ Plan Increase
								</Select.Option>
								<Select.Option value="Tool change">
									Tool change
								</Select.Option>
								<Select.Option value="Machine stopped for more than 40 min">
									Machine stopped for more than 40 min
								</Select.Option>
								<Select.Option value="Any quality issue">
									Any quality issue
								</Select.Option>
							</Select>
						</Form.Item>

						<Form.Item
							label="New Day"
							name="new_day"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please enter something!",
								},
							]}
						>
							<Select>
								<Select.Option value="Yes">Yes</Select.Option>
								<Select.Option value="No">No</Select.Option>
							</Select>
						</Form.Item>

						<Divider className={styles.divider} />

						<Form.Item
							label="Machine Name"
							name="machine_name"
							labelAlign="left"
						>
							<Select disabled>
								{machinesData &&
									machinesData.map((machine, index) => (
										<Select.Option
											key={index}
											value={machine._id}
										>
											{machine.name}
										</Select.Option>
									))}
							</Select>
						</Form.Item>

						<Form.Item
							label="Part Name"
							name="part_name"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please enter part name!",
								},
							]}
						>
							<Select 
								style={{width: '100%'}}
								showSearch
								filterOption={(input, option) =>
									option.children
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								dropdownMatchSelectWidth={false} // To prevent the dropdown from taking full width
  								dropdownStyle={{ maxWidth: '100%'}} // Set a maximum width for the dropdown
  								
							>
								{partsData &&
									partsData.map(
										(part, index) =>
											 (
												<Select.Option
													key={index}
													value={part._id}
												>
													{part.part_name}
												</Select.Option>
											)
									)}
							</Select>
						</Form.Item>

                        <Form.Item
							label="Select Plan"
							name="plan_name"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please enter part name!",
								},
							]}
						>
							<Select 
								style={{width: '100%'}}
								showSearch
								filterOption={(input, option) =>
									option.children
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								dropdownMatchSelectWidth={false} // To prevent the dropdown from taking full width
  								dropdownStyle={{ maxWidth: '100%'}} // Set a maximum width for the dropdown
  								
							>
								{planData &&
									planData.map(
										(plan, index) =>
											 (
												<Select.Option
													key={index}
													value={plan._id}
												>
													{plan.part_id.part_name}  = {plan.qtyRequired}
												</Select.Option>
											)
									)}
							</Select>
						</Form.Item>

						<Divider className={styles.divider} />

						<span
							className={styles.sub_headers}
							style={{ marginRight: "93%" }}
						>
							SAFETY:
						</span>

						<Form.Item
							label="No of Emergency Switch"
							name="no_of_emergency_switch"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please select something!",
								},
							]}
						>
							<Select style={{ maxWidth: "500px" }}>
								<Select.Option value="1">1</Select.Option>
								<Select.Option value="2">2</Select.Option>
								<Select.Option value="3">3</Select.Option>
								<Select.Option value="4">4</Select.Option>
								<Select.Option value="5">5</Select.Option>
								<Select.Option value="6">6</Select.Option>
								<Select.Option value="7">7</Select.Option>
								<Select.Option value="8">8</Select.Option>
								<Select.Option value="9">9</Select.Option>
								<Select.Option value="10">10</Select.Option>
							</Select>
						</Form.Item>

						<Form.Item
							label="No of Limit Switch"
							name="no_of_limit_switch"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please select something!",
								},
							]}
						>
							<Select>
								<Select.Option value="1">1</Select.Option>
								<Select.Option value="2">2</Select.Option>
								<Select.Option value="3">3</Select.Option>
								<Select.Option value="4">4</Select.Option>
								<Select.Option value="5">5</Select.Option>
								<Select.Option value="6">6</Select.Option>
								<Select.Option value="7">7</Select.Option>
								<Select.Option value="8">8</Select.Option>
								<Select.Option value="9">9</Select.Option>
								<Select.Option value="10">10</Select.Option>
							</Select>
						</Form.Item>

						<Form.Item
							label="PI Card Availability"
							name="pi_card"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please select something!",
								},
							]}
						>
							<Select>
								<Select.Option value="YES">Yes</Select.Option>
								<Select.Option value="NO">No</Select.Option>
							</Select>
						</Form.Item>

						<Form.Item
							label="PPE List"
							name="ppe_list"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please select something!",
								},
							]}
						>
							<Select mode="multiple" allowClear>
								{ppeKitData &&
									ppeKitData.map((ppe, index) => (
										<Select.Option key={index} value={ppe}>
											{ppe}
										</Select.Option>
									))}
							</Select>
						</Form.Item>

						<Divider />
						<span
							className={styles.sub_header}
							style={{ marginRight: "93%" }}
						>
							MAN:
						</span>

						<Form.Item
							label="Skill Level:"
							labelAlign="left"
							name="skill_level"
							rules={[
								{
									required: true,
									message: "Please select something!",
								},
							]}
						>
							<Select>
								<Select.Option value="1">I</Select.Option>
								<Select.Option value="2">II</Select.Option>
								<Select.Option value="3">III</Select.Option>
								<Select.Option value="4">IV</Select.Option>
							</Select>
						</Form.Item>

						<Divider />
						<span
							className={styles.sub_header}
							style={{ marginRight: "93%" }}
						>
							MATERIAL:
						</span>

						<Form.Item
							label="Raw Material Name:"
							name="raw_material_no"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please select something!",
								},
							]}
						>
							{/* <Select
								style={{width: 500}}
								showSearch
								filterOption={(input, option) =>
									option.children
										.toLowerCase()
										.includes(input.toLowerCase())
								}
							>
								{rawMaterialData &&
									rawMaterialData.map((raw, index) => (
										<Select.Option key={index} value={raw}>
											{raw}
										</Select.Option>
									))}
							</Select> */}
							<Select
							
							style={{width: '100%', overflowX: 'auto'}}
							showSearch
							filterOption={(input, option) =>
								option.children
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							dropdownMatchSelectWidth={false} // To prevent the dropdown from taking full width
							  dropdownStyle={{ maxWidth: '100%'}} // Set a maximum width for the dropdown
							  optionLabelProp="value"
						> {console.log(rawMaterialData)}
							{rawMaterialData &&
								rawMaterialData.map((raw, index) => (
									<Select.Option key={index} value={raw}>
										{raw}
									</Select.Option>
								))}
						</Select>
						</Form.Item>

						<Form.Item
							label="Moisture Content in Raw Material:"
							name="moisture_content"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please select something!",
								},
							]}
						>
							<Input placeholder="Enter" type="number" />
						</Form.Item>

						<Divider />

						<span
							className={styles.sub_header}
							style={{ marginRight: "93%" }}
						>
							METHOD:
						</span>

						<Form.Item
							label="Programme Selection  as Per Mould:"
							name="programme_selection"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please select something!",
								},
							]}
						>
							<Select>
								{partsData &&
									partsData.map(
										(part, index) =>
										 (
												<Select.Option
													key={index}
													value={part.part_method}
												>
													{part.part_method}
												</Select.Option>
											)
									)}
							</Select>
						</Form.Item>

						<Divider />

						<span
							className={styles.sub_header}
							style={{ marginRight: "82%" }}
						>
							MACHINE PARAMETER:
						</span>

						<Form.Item
							label="Machine Parameter as per PPS"
							name="machine_parameter"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please select something!",
								},
							]}
						>
							<Select>
								{partsData &&
									partsData.map(
										(part, index) =>
										(
												<Select.Option
													key={index}
													value={part.pps_no}
												>
													{part.pps_no}
												</Select.Option>
											)
									)}
							</Select>
						</Form.Item>

						<Divider />

						<Form.Item
							label="Send to Supervisor : "
							name="sup_empid"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please select something!",
								},
							]}
						>
							<Select>
								{allUsersData["Quality Assurance"].Supervisor &&
									Object.entries(
										allUsersData["Quality Assurance"]
											.Supervisor
									).map((e, i) => {
										return (
											<Select.Option
												key={i}
												value={e[1]._id}
											>{`${e[1].firstname} ${e[1].lastname}`}</Select.Option>
										);
									})}
							</Select>
						</Form.Item>

						<div className={styles.submit_btn}>
							<Button type="danger" htmlType="submit">
								Submit
							</Button>
						</div>
					</Form>
				</div>
			</div>
		</>
	);
};

export default ShiftStartupForm;
