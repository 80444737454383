import { useState, useEffect } from "react";
import { Form, Select, Input, Divider, Row, Col,Button } from "antd";
import axios from "../../../../api/axiosApi";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getUser } from "../../../../redux/users/userSlice";
import { getOperatorData } from "../../../../redux/operators/operatorSlice";
import {
  getMachinesData,
  getPartsData,
  getAllUsers
} from "../../../../redux/dropDownData/dropDownDataSlice";
// import Button from "antd";
import { PartsData } from "./parts_data";
import axiosApi from "../../../../api/axiosApi";
import styles from "../styleop.module.css";
import BackButton from "../../../common/BackButton/backButton";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ShiftHandoverPrefillForm = () => {
  const allUsersData = useSelector(getAllUsers);
  const machinesData = useSelector(getMachinesData);
  const partsData = useSelector(getPartsData);
  const userData = useSelector(getUser);
  const operatorData = useSelector(getOperatorData);
  let history = useHistory();
  const [data, setData] = useState({});
  const [isEmpty, setIsEmpty] = useState(false);

  const [form] = Form.useForm();
  const onFinish = async (values) => {
    console.log("Success Check :", values);
/* 
{
    "_id": "65a67129c659c3726fa82974",
    "machine_id": "614eba326e3a4a85c8c56236",
    "part_id": "6192083559c32bbaa4e9ef99",
    "plan_id": "65a652bf06322bfbcc132db3",
    "unaccepted_qty": 0,
    "shift": 2,
    "when_created": "2024-01-16T12:06:01.679Z",
    "when_updated": "2024-01-16T12:06:01.679Z",
    "__v": 0,
    "part_quality": "Good",
    "accepted_qty": "22"
}


*/
    let production =[]
    values.parts_data.forEach((item)=>{
      production.push({
          part_id: item.part_id,
          part_quality: item.part_quality ,
          accepted_qty:item.accepted_qty,
          unaccepted_qty: item.unaccepted_qty
      })
    })

    console.log("production data", production);
    axiosApi
      .post("production/injectionMoulding/submitShifthandoverData", {
        machine_id: operatorData.machineId,
        part_no: values.part_name,
        operator_name: `${userData.firstname} ${userData.lastname}`,
        operator_id: userData.empid,
        shift: operatorData.shift,
        sup_id: values.submit_to,

        part_quality: values.part_quality,
        machine_issue: 'No',
          tool_issue: 'No',
          safety: { accident: '0' },

        // safety: {
        //   accident: values.accident,
        //   incidence_near_miss: values.incidence_near_miss,
        //   observation: values.observation,
        //   unsafe_act: values.unsafe_act,
        //   unsafe_condition: values.unsafe_condition,
        // },
        production: production,
      })
      .then(function (response) {
        if (response.data.msg === "Form saved successfully") {
          form.resetFields();
          toast.success("🚀 Form Submitted Successfully", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
          setTimeout(() => {
            history.goBack();
          }, 3000);
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Error Occured : ", error.message);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    let date = new Date().toISOString().toString().slice(0, 10);
    axios
      .get(
        `production/injectionMoulding/getShiftHandoverform?shift=${
          operatorData.shift
        }&machineId=${operatorData.machineId}&datee=${date}`
      )
      .then((response) => {
        if (response.data.data.length === 0) {
          setIsEmpty(true);
        } else {
          console.log("Prefilled Form : ", response.data.data);
          setData({
            ...data,
            prevData: response.data.data[0],
          });
          console.log("data = ", data);
        }
      })
      .catch((error) => console.log(`ERRORS : ${error}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isEmpty)
    return (
      <>
        <BackButton styleClass={"back-btn"} />
        <div>
          <h1 style={{ textAlign: "center" }}>
            No Data for Shift{operatorData.shift}
           
          </h1>
        </div>
      </>
    );
  else if (!data.prevData)
    return (
      <div>
        <h1>Loading....</h1>
      </div>
    );
  else
    return (
      <>
        <BackButton styleClass={"back-btn"} />
        <div className={styles.card_holder}>
          <ToastContainer />

          <div className={styles.form_card}>
            <h1
              style={{
                paddingBottom: "10px",
                letterSpacing: "0.3rem",
                fontWeight: "bold",
              }}
            >
              SHIFT HANDOVER DATA SHEET
            </h1>

            <Form
              labelCol={{
                span: 10,
              }}
              wrapperCol={{
                span: 8,
              }}
              form={form}
              layout="horizontal"
              colon={false}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={{
                prshift: data.prevData.shift,
                shift: operatorData.shift,
                prmachine_name: data.prevData.machine_id,
                machine_name: operatorData.machineId,
                praccident: data.prevData?.safety?.accident,
                princidence_near_miss: data.prevData?.safety?.incidence_near_miss,
                probservation: data.prevData?.safety?.observation,
                prunsafe_act: data.prevData?.safety?.unsafe_act,
                prunsafe_condition: data.prevData?.safety?.unsafe_condition,
                prpart_quality: data.prevData?.part_quality,
                prmachine_issue: data.prevData?.machine_issue,
                prtool_issue: data.prevData?.tool_issue,
                prpart_name: data.prevData?.part_no,
              }}
            >
              <Form.Item
                label="Previous Employee Id"
                name="premp_id"
                labelAlign="left"
              >
                <Input placeholder={data.prevData.operator_id} disabled />
              </Form.Item>
              <Form.Item
                label="Your Employee Id"
                name="emp_id"
                labelAlign="left"
              >
                <Input placeholder={userData.empid} disabled />
              </Form.Item>

              <Form.Item
                label="Previous Employee Name"
                name="premp_name"
                labelAlign="left"
              >
                <Input placeholder={data.prevData.operator_name} disabled />
              </Form.Item>
              <Form.Item
                label="Your Employee Name"
                name="emp_name"
                labelAlign="left"
              >
                <Input
                  placeholder={`${userData.firstname} ${userData.lastname}`}
                  disabled
                />
              </Form.Item>
              <Divider className="divider" />
              <Form.Item
                label="Prefilled Shift"
                name="prshift"
                labelAlign="left"
              >
                <Select disabled>
                  <Select.Option value="1">I</Select.Option>
                  <Select.Option value="2">II</Select.Option>
                  <Select.Option value="3">III</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Shift" name="shift" labelAlign="left">
                <Select disabled>
                  <Select.Option value="1">I</Select.Option>
                  <Select.Option value="2">II</Select.Option>
                  <Select.Option value="3">III</Select.Option>
                </Select>
              </Form.Item>

              <Divider className="divider" />

              <Form.Item
                label="Previous Machine Name"
                name="prmachine_name"
                labelAlign="left"
              >
                <Select disabled>
                  {machinesData &&
                    machinesData.map((machine, index) => (
                      <Select.Option key={index} value={machine._id}>
                        {machine.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Machine Name"
                name="machine_name"
                labelAlign="left"
              >
                <Select disabled>
                  {machinesData &&
                    machinesData.map((machine, index) => (
                      <Select.Option key={index} value={machine._id}>
                        {machine.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <span className="sub-headers">SAFETY</span>
              <Form.Item
                label="Prefilled Accident"
                name="praccident"
                labelAlign="left"
              >
                <Select style={{ textAlign: "left" }} disabled>
                  <Select.Option value="1">Yes</Select.Option>
                  <Select.Option value="0">No</Select.Option>
                </Select>
              </Form.Item>
              <Divider className="divider" />

              {/* <Form.Item
                label="Accident"
                name="accident"
                labelAlign="left"
                rules={[
                  { required: true, message: "Please select something!" },
                ]}
              >
                <Select
                  style={{ textAlign: "left" }}
                  value={data.accident}
                  onChange={(value) => setData({ ...data, accident: value })}
                >
                  <Select.Option value="1">Yes</Select.Option>
                  <Select.Option value="0">No</Select.Option>
                </Select>
              </Form.Item> */}

              {data.accident === "1" ? (
                <>
                  {data.prevData.safety.accident === "1" ? (
                    <Form.Item
                      label="Prefilled Incidence Near Miss:"
                      name="princidence_near_miss"
                      labelAlign="left"
                    >
                      <Input type="string" disabled />
                    </Form.Item>
                  ) : (
                    ""
                  )}

                  <Form.Item
                    label="Incidence Near Miss:"
                    name="incidence_near_miss"
                    labelAlign="left"
                  >
                    <Input type="string" />
                  </Form.Item>

                  {data.prevData.safety.accident === "1" ? (
                    <Form.Item
                      label="Prefilled Observation: "
                      name="probservation"
                      labelAlign="left"
                    >
                      <Input type="string" disabled />
                    </Form.Item>
                  ) : (
                    ""
                  )}

                  <Form.Item
                    label="Observation: "
                    name="observation"
                    labelAlign="left"
                  >
                    <Input type="string" />
                  </Form.Item>

                  {data.prevData.safety.accident === "1" ? (
                    <Form.Item
                      label="Prefilled Unsafe Act: "
                      name="prunsafe_act"
                      labelAlign="left"
                    >
                      <Input type="string" disabled />
                    </Form.Item>
                  ) : (
                    ""
                  )}

                  <Form.Item
                    label="Unsafe Act: "
                    name="unsafe_act"
                    labelAlign="left"
                  >
                    <Input type="string" />
                  </Form.Item>

                  {data.prevData.safety.accident === "1" ? (
                    <Form.Item
                      label="Prefilled Unsafe Condition: "
                      name="prunsafe_condition"
                      labelAlign="left"
                    >
                      <Input type="string" disabled />
                    </Form.Item>
                  ) : (
                    ""
                  )}

                  <Form.Item
                    label="Unsafe Condition: "
                    name="unsafe_condition"
                    labelAlign="left"
                  >
                    <Input type="string" />
                  </Form.Item>
                </>
              ) : (
                ""
              )}
              <Divider className="divider" />

              {/* <Form.Item
                label="PREVIOUS PART QUALITY"
                name="prpart_quality"
                labelAlign="left"
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  // paddingBottom: "12px",
                }}
              >
                <Select
                  style={{ fontWeight: "lighter", width: "100%" }}
                  disabled
                >
                  <Select.Option value="1">Good</Select.Option>
                  <Select.Option value="2">Moderate</Select.Option>
                  <Select.Option value="0">Bad</Select.Option>
                </Select>
              </Form.Item> */}

              {/* <Form.Item
                label="PART QUALITY"
                name="part_quality"
                labelAlign="left"
                rules={[
                  { required: true, message: "Please select something!" },
                ]}
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  // paddingBottom: "12px",
                }}
              >
                <Select style={{ fontWeight: "lighter", width: "100%" }}>
                  <Select.Option value="1">Good</Select.Option>
                  <Select.Option value="2">Moderate</Select.Option>
                  <Select.Option value="0">Bad</Select.Option>
                </Select>
              </Form.Item> */}

              <Form.Item
                label="Previous Machine Issue"
                name="prmachine_issue"
                labelAlign="left"
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                <Select
                  style={{ fontWeight: "lighter", width: "100%" }}
                  disabled
                >
                  <Select.Option value="1">Good</Select.Option>
                  <Select.Option value="2">Moderate</Select.Option>
                  <Select.Option value="0">Bad</Select.Option>
                </Select>
              </Form.Item>

              {/* <Form.Item
                label="MACHINE ISSUE"
                name="machine_issue"
                labelAlign="left"
                rules={[
                  { required: true, message: "Please select something!" },
                ]}
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                <Select style={{ fontWeight: "lighter", width: "100%" }}>
                  <Select.Option value="1">Yes</Select.Option>
                  <Select.Option value="0">No</Select.Option>
                </Select>
              </Form.Item> */}

              <Divider className="divider" />

              <Form.Item
                label="Previous Tool Issue"
                name="prtool_issue"
                labelAlign="left"
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                <Select
                  style={{ fontWeight: "lighter", width: "100%" }}
                  disabled
                >
                  <Select.Option value="1">Good</Select.Option>
                  <Select.Option value="2">Moderate</Select.Option>
                  <Select.Option value="0">Bad</Select.Option>
                </Select>
              </Form.Item>

              {/* <Form.Item
                label="TOOL ISSUE"
                name="tool_issue"
                labelAlign="left"
                rules={[
                  { required: true, message: "Please select something!" },
                ]}
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                <Select style={{ fontWeight: "lighter", width: "100%" }}>
                  <Select.Option value="1">Yes</Select.Option>
                  <Select.Option value="0">No</Select.Option>
                </Select>
              </Form.Item> */}

              <Divider className="divider" />

              <div className="sub-headers" style={{ textAlign: "center" }}>
                STARTUP REJECTION ENTRY
              </div>

              <div className="sub-headers">Previous Employee:</div>
              {data.prevData?.production?.map((item, index) => (
                <div key={index}>                  
                  <Row>{index + 1}</Row>
                  <Row>
                    <Col span={12}>Part Name </Col>
                    <Col span={12}>{item.part_id.part_name}</Col>
                  </Row>
                  <Row>
                    <Col span={12}>Part Quality</Col>
                    <Col span={12}>{item.part_quality}</Col>
                  </Row>
                  <Row>
                    <Col span={12}>Accepted Quantity </Col>
                    <Col span={12}>{item.accepted_qty}</Col>
                  </Row>
                  <Row>
                    <Col span={12}>Unaccepted Quantity </Col>
                    <Col span={12}>{item.unaccepted_qty}</Col>
                  </Row>
                  <Row>
                    <Col span={12}>TQW Rejected Quantity</Col>
                    <Col span={12}>{item.tqw_rejected_quantity}</Col>
                  </Row>
                </div>
              ))}

              <div className="sub-headers">Current Employee</div>

              <PartsData
                name="parts_data"
                parts_name={partsData}
                rejectedQuantity={data.prevData.rejected_part_data}
              />

              <Divider className="divider" />

              <Form.Item
                label="SUBMIT TO"
                name="submit_to"
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingBottom: "5px",
                }}
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Please Select Handover to!",
                  },
                ]}
              >
                <Select style={{ fontWeight: "lighter", width: "100%" }}>
                  {allUsersData["Production"].Supervisor &&
                    Object.entries(allUsersData["Production"].Supervisor).map(
                      (e, i) => {
                        return (
                          <Select.Option
                            key={i}
                            value={e[1]._id}
                          >{`${e[1].firstname} ${e[1].lastname}`}</Select.Option>
                        );
                      }
                    )}
                </Select>
              </Form.Item>
              <Divider />
              <div className="submit-btn">
                <Button type="danger" htmlType="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </>
    );
};

export default ShiftHandoverPrefillForm;
