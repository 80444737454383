import  { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Layout,
  Table,
  Modal,
  Select,
  Descriptions,
  Popconfirm,
  message,
} from "antd";
import axiosApi from "../../api/axiosApi";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";

const { Content } = Layout;

const TraineeDetails = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addFormLevel, setAddFormLevel] = useState();
  const { id } = props.match.params;
  const [loading, setLoading] = useState(false);
  const [trainee, setTrainee] = useState();
  const [error, setError] = useState(false);
  let history = useHistory();

  const tableColumns = [
    {
      title: "Srno.",
      key: "_id",
      align: "center",
      render: (text, record, index) => <p>{index + 1}</p>,
    },
    {
      title: "Training Started At",
      key: "_id",
      dataIndex: "when_created",
      align: "center",
      render: (text) => <p>{text.substr(0, 10)}</p>,
    },
    {
      title: "Level",
      key: "_id",
      dataIndex: "level",
      align: "center",
      render: (text) => <p>{`Level ${text}`}</p>,
    },
    {
      title: "Overall Rating",
      key: "_id",
      dataIndex: "overall",
      align: "center",
      render: (text) => <p>{text === "" ? "Ongoing" : text}</p>,
    },
    {
      title: "Action",
      key: "_id",
      align: "center",
      render: (record) => (
        <span style={{ display: "inline-flex", gap: "5px" }}>
          <Button
            type="primary"
            onClick={() =>
              history.push(
                `/production-supervisor-ojt/form/level/${record.level}`,
                { _id: record._id }
              )
            }
          >
            View
          </Button>
          <Popconfirm
            title="Are you sure to delete this form?"
            onConfirm={() => onDelete(record._id)}
            onCancel={() => message.error("Cancelled")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    axiosApi
      .get(`/training/all/getTrainee?_id=${id}`)
      .then((res) => {
        setTrainee(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
      });
  }, [id]);

  const showAddTraineeModal = () => {
    setIsModalVisible(true);
  };

  const handleAddTraineeModalOk = () => {
    setIsModalVisible(false);
  };

  const handleAddTraineeModalCancel = () => {
    setIsModalVisible(false);
  };

  const onDelete = (_id) => {
    axiosApi
      .post("/training/all/deleteTraineeForm", {
        _id,
        trainee: trainee?.details._id,
      })
      .then((res) => {
        toast.success("🚀 Form deleted successfully!!", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        console.log(res);
        setTrainee(res.data.data);
      })
      .catch((err) => {
        toast.error("🚀 Error while deleting form", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        return;
      });
  };

  const onAddOJTForm = () => {
    if (!addFormLevel) {
      toast.error("🚀 Enter form level", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    axiosApi
      .post("/training/all/createTrainingForm", {
        _id: id,
        level: addFormLevel,
      })
      .then((res) => {
        toast.success("🚀 Form added successfully!!", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        setTrainee(res.data.data);
        setIsModalVisible(false);
      })
      .catch((err) => {
        toast.error("🚀 Error while creating form", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        setIsModalVisible(false);
        return;
      });
  };

  console.log(trainee);

  return !loading ? (
    <div className="">
      <ToastContainer />
      <Layout className="layout">
        <Content style={{ padding: "50px 50px" }}>
          <div className="site-layout-content">
            <h2>Trainee Detail page of {trainee?.details?.name}</h2>
            <Divider />
            <Descriptions
              bordered
              column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label="Trainee Name:" span={1.5}>
                {trainee?.details?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Training Status:" span={1.5}>
                {trainee?.details?.status}
              </Descriptions.Item>
              <Descriptions.Item label="Trainee Number:" span={1.5}>
                {trainee?.details?.employee_number}
              </Descriptions.Item>
              <Descriptions.Item label="Department:" span={1.5}>
                {trainee?.details?.dept}
              </Descriptions.Item>
              <Descriptions.Item label="Designation:" span={1.5}>
                {trainee?.details?.designation}
              </Descriptions.Item>
              <Descriptions.Item label="Line:" span={1.5}>
                {trainee?.details?.line}
              </Descriptions.Item>
            </Descriptions>

            <Divider />
            <Table
              bordered
              dataSource={trainee?.forms}
              columns={tableColumns}
              pagination={true}
            />
            <Modal
              title="Add New OJT Form"
              visible={isModalVisible}
              footer={null}
              onOk={handleAddTraineeModalOk}
              onCancel={handleAddTraineeModalCancel}
              okText="Add Form"
            >
              <div>
                Select form level:
                <Select
                  onChange={(value) => setAddFormLevel(value)}
                  style={{ width: 100, padding: "0 5px" }}
                >
                  <Select.Option value="1">Level 1</Select.Option>
                  <Select.Option value="2">Level 2</Select.Option>
                  <Select.Option value="3">Level 3</Select.Option>
                  <Select.Option value="4">Level 4</Select.Option>
                </Select>
              </div>
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <Button danger onClick={handleAddTraineeModalCancel}>
                  Cancel
                </Button>
                <Button
                  style={{ borderColor: "#1890ff", color: "#1890ff" }}
                  onClick={onAddOJTForm}
                >
                  Add OJT Form
                </Button>
              </div>
            </Modal>
            <Button type="primary" onClick={showAddTraineeModal}>
              Add OJT Form
            </Button>
          </div>
        </Content>
      </Layout>
    </div>
  ) : error ? (
    <p>Error while fetching data...</p>
  ) : (
    <p>Loading....</p>
  );
};

export default TraineeDetails;
