import { React } from "react";


const AlertsSummary = (props) => {
  let maintenanceDept = { "RedAlerts": [], "OrangeAlerts": [], "YellowAlerts": [] };
  let logisticsDept = { "RedAlerts": [], "OrangeAlerts": [], "YellowAlerts": [] };
  let qualityDept = { "RedAlerts": [], "OrangeAlerts": [], "YellowAlerts": [] };

  for (let i = 0; i < props.machineStoppageAlerts.length; i++) {
    if (props.machineStoppageAlerts[i].data.msg === "Breakdown") {
      // console.log(props.machineStoppageAlerts[i])
      if (props.machineStoppageAlerts[i].color === "Red") {
        maintenanceDept['RedAlerts'].push(props.machineStoppageAlerts[i]);
      }
      else if (props.machineStoppageAlerts[i].color === "Orange") {
        maintenanceDept['OrangeAlerts'].push(props.machineStoppageAlerts[i]);
      }
      else if (props.machineStoppageAlerts[i].color === "Yellow") {
        maintenanceDept['YellowAlerts'].push(props.machineStoppageAlerts[i]);
      }
    }
    else if (props.machineStoppageAlerts[i].data.msg === "Material Shortage") {
      if (props.machineStoppageAlerts[i].color === "Red") {
        logisticsDept['RedAlerts'].push(props.machineStoppageAlerts[i]);
      }
      else if (props.machineStoppageAlerts[i].color === "Orange") {
        logisticsDept['OrangeAlerts'].push(props.machineStoppageAlerts[i]);
      }
      else if (props.machineStoppageAlerts[i].color === "Yellow") {
        logisticsDept['YellowAlerts'].push(props.machineStoppageAlerts[i]);
      }
    }
    else if (props.machineStoppageAlerts[i].data.msg === "Quality Issue") {
      if (props.machineStoppageAlerts[i].color === "Red") {
        qualityDept['RedAlerts'].push(props.machineStoppageAlerts[i]);
      }
      else if (props.machineStoppageAlerts[i].color === "Orange") {
        qualityDept['OrangeAlerts'].push(props.machineStoppageAlerts[i]);
      }
      else if (props.machineStoppageAlerts[i].color === "Yellow") {
        qualityDept['YellowAlerts'].push(props.machineStoppageAlerts[i]);
      }
    }
  }
  // console.log(maintenanceDept);
  // console.log(logisticsDept);
  // console.log(qualityDept);


  return (
    <>
      <div className="alert-summary">
        <table>
          <thead>
            <tr>
              <th colSpan="4">Alert Summary</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Department</th>
              <th>Red</th>
              <th>Orange</th>
              <th>Yellow</th>
            </tr>
            <tr>
              <td id="l-title">Maintenance</td>
              <td>
                <button onClick={() => { props.makeStopAlertsVisible(maintenanceDept["RedAlerts"]) }}> {maintenanceDept["RedAlerts"].length}</button>
              </td>
              <td>
                <button onClick={() => { props.makeStopAlertsVisible(maintenanceDept["OrangeAlerts"]) }}> {maintenanceDept["OrangeAlerts"].length}</button>
              </td>
              <td>
                <button onClick={() => { props.makeStopAlertsVisible(maintenanceDept["YellowAlerts"]) }}> {maintenanceDept["YellowAlerts"].length}</button>
              </td>
            </tr>
            <tr>
              <td id="l-title">Logistics</td>
              <td>
                <button onClick={() => { props.makeStopAlertsVisible(logisticsDept["RedAlerts"]) }}> {logisticsDept["RedAlerts"].length}</button>
              </td>
              <td>
                <button onClick={() => { props.makeStopAlertsVisible(logisticsDept["OrangeAlerts"]) }}> {logisticsDept["OrangeAlerts"].length}</button>
              </td>
              <td>
                <button onClick={() => { props.makeStopAlertsVisible(logisticsDept["YellowAlerts"]) }}> {logisticsDept["YellowAlerts"].length}</button>
              </td>
            </tr>
            <tr>
              <td id="l-title">Quality</td>
              <td>
                <button onClick={() => { props.makeStopAlertsVisible(qualityDept["RedAlerts"]) }}> {qualityDept["RedAlerts"].length}</button>
              </td>
              <td>
                <button onClick={() => { props.makeStopAlertsVisible(qualityDept["OrangeAlerts"]) }}> {qualityDept["OrangeAlerts"].length}</button>
              </td>
              <td>
                <button onClick={() => { props.makeStopAlertsVisible(qualityDept["YellowAlerts"]) }}> {qualityDept["YellowAlerts"].length}</button>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </>
  )
}

export default AlertsSummary;






