import { Button, Col, Divider, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// redux
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosApi from "../../../api/axiosApi";
import BackButton from "../../common/BackButton/backButton";
import "./prod_manager.css";
import { PartsData } from "./parts_data";

const ProductionSupervisorForm = (props) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [data, setData] = useState();
  const [empty, setEmpty] = useState(false);

  let history = useHistory();

  const onFinish = async (values) => {
    console.log("Success :", values);
    axiosApi
      .post("production/injectionMoulding/submitProductionManager", {
        Review_with_supervisor: values.review_supervisor,
        rp_rw_balance: values.rp_rw_balance,
        detailData: data.detailData,
        // issues: data.machineIssues,
        total_downtime: data.total_downtime,
        total_material_used: values.total_material_used,
        production: values.production,
        total_rejection_assembly: data.total_rejection_assembly,
        total_rejection_paintshop: data.total_rejection_paintshop,
        total_throughput_data: values.total_throughput_data,
        total_production_value: data.total_production_value,
        total_nee: data.total_nee,
        total_ppm: data.total_ppm,
        total_ncc: data.total_ncc,
        scrap: values.scrap,
        total_reworked_quantity: data.total_reworked_quantity,
        rft: values.rft,
        headcount: values.headcount,
        date: data.date,
        tool_issue: values.tool_issue,
        ot_hours: values.ot_hours,
        redAlerts: data.redAlerts,
        dih: values.dih,
      })
      .then(function (response) {
        console.log(response);
        if (response.data.err) {
          toast.error("🚀 Fill all fields correctly", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
          return;
        }
        toast.success("🚀 Form Submitted Successfully", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        setTimeout(() => {
          history.goBack();
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
        alert("Error Occured : ", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    alert("Error Occured : ", errorInfo);
  };

  useEffect(() => {
    setEmpty(false);
    axiosApi
      .get(`production/injectionMoulding/getProductionManagerForm`)
      .then((response) => {
        console.log("prod manager : ", response);
        setData(response.data.data);
        if (response.data.data) {
          setEmpty(true);
        }
      })
      .catch((error) => console.log(`ERRORS : ${error}`));
  }, []);

  return empty ? (
    <>
      <BackButton styleClass="back-btn" />
      <div className="card-holder">
        <ToastContainer />
        <div className="form-card-prod-manager margin-top-set">
          <h1
            style={{
              paddingBottom: "10px",
              letterSpacing: "0.3rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            PRODUCTION MANAGER FORM
          </h1>

          <Form
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 16,
            }}
            form={form}
            layout="horizontal"
            colon={false}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Divider />

            <div style={{ textAlign: "center" }} marginBottom="10px">
              <PartsData name="production" />
            </div>

            {/* <Row>
              <Col span={8}>Rejection:</Col>
              <Col span={8}>
                <Form.Item name="rejection_actual" labelAlign="left">
                  <Input
                    type="string"
                    placeholder={data.total_rejection}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row> */}
            <Row>
              <Col span={8}>Downtime:</Col>
              <Col span={8}>
                <Form.Item name="Downtime_actual" labelAlign="left">
                  <Input
                    type="string"
                    placeholder={data.total_downtime}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>Throughput Data:</Col>
              <Col span={8}>
                <Form.Item name="total_throughput_data" labelAlign="left">
                  <Input type="string" />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>Material used:</Col>
              <Col span={8}>
                <Form.Item name="total_material_used" labelAlign="left">
                  <Input type="string" />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>Rejection assembly:</Col>
              <Col span={8}>
                <Form.Item name="rejection_assembly_actual" labelAlign="left">
                  <Input
                    type="string"
                    placeholder={data.total_rejection_assembly}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>Rejection paint shop:</Col>
              <Col span={8}>
                <Form.Item name="rejection_paint_shop_actual" labelAlign="left">
                  <Input
                    type="string"
                    placeholder={data.total_rejection_paintshop}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Divider className="divider" />
            <Row>
              <Col span={8}>Production Value:</Col>
              <Col span={8}>
                <Form.Item name="production_value" labelAlign="left">
                  <Input
                    type="string"
                    placeholder={data?.total_production_value}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>No. of lines/machines:</Col>
              <Col span={8}>
                <Form.Item name="no_of_lines" labelAlign="left">
                  <Input
                    type="string"
                    placeholder={data?.detailData?.length}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>NEE:</Col>
              <Col span={8}>
                <Form.Item name="nee" labelAlign="left">
                  <Input type="string" placeholder={data?.total_nee} disabled />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>PPM:</Col>
              <Col span={8}>
                <Form.Item name="ppm" labelAlign="left">
                  <Input type="string" placeholder={data?.total_ppm} disabled />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>NCC Total Cost:</Col>
              <Col span={8}>
                <Form.Item name="ncc" labelAlign="left">
                  <Input type="string" placeholder={data?.total_ncc} disabled />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>Scrap:</Col>
              <Col span={8}>
                <Form.Item name="scrap" labelAlign="left">
                  <Input type="string" />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>Reworking:</Col>
              <Col span={8}>
                <Form.Item name="total_reworked_quantity" labelAlign="left">
                  <Input
                    type="string"
                    placeholder={data?.total_reworked_quantity}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>DIH:</Col>
              <Col span={8}>
                <Form.Item name="dih" labelAlign="left">
                  <Input type="Number" />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>RFT:</Col>
              <Col span={8}>
                <Form.Item name="rft" labelAlign="left">
                  <Input type="string" />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={8}>Headcount:</Col>
              <Col span={8}>
                <Form.Item name="headcount" labelAlign="left">
                  <Input type="string" defaultValue={data?.headcount} />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Divider className="divider" />
            <Row>
              <Col span={8}>Alerts:</Col>
              <Col span={16}>
                <Form.Item name="redAlerts" labelAlign="left">
                  <Input
                    type="string"
                    disabled
                    placeholder={data?.redAlerts?.length}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Overtime hours</Col>
              <Col span={16}>
                <Form.Item name="ot_hours" labelAlign="left">
                  <Input type="number" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>RP & RW Balance</Col>
              <Col span={16}>
                <Form.Item name="rp_rw_balance" labelAlign="left">
                  <Input type="string" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Review with Supervisor:</Col>
              <Col span={16}>
                <Form.Item name="review_supervisor" labelAlign="left">
                  <TextArea type="string" rows={4} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Tool Issue:</Col>
              <Col span={16}>
                <Form.Item name="tool_issue" labelAlign="left">
                  <TextArea type="string" rows={4} />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
            <Col span={8}>Rework Status Offline:</Col>
            <Col span={8}>
              <Form.Item name="rework" labelAlign="left">
                <Input type="string" disabled />
              </Form.Item>
            </Col>
          </Row> */}
            <div style={{ textAlign: "center" }}>
              <Button type="primary">
                <Link to={{ pathname: "/production-manager-form/details" }}>
                  View Details
                </Link>
              </Button>
            </div>

            <div className="submit-btn" textAlign="left">
              <Button type="danger" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  ) : (
    <div>Error in fetching details</div>
  );
};

export default ProductionSupervisorForm;
