import { React, useState } from "react";
import { Select, Input, Button, Col, Row, Radio, Typography, Card } from "antd";
import { toast } from 'react-toastify';
import { CheckOutlined } from "@ant-design/icons";
import axios from "../../../../api/axiosApi";

const MaintenanceCard = (props) => {
	console.log("props maintcard", props)
	const [isApproved, setIsApproved] = useState(props.item.status);
	const [remark, setRemark] = useState("");
	const machineId = props.machineId;
	const dept= props.dept;
	const submit = (recordId) => {
		if(!remark){
            return toast.error("Enter Remark", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
            });
        }
		setIsApproved(true);
		const data = {
			machineId,
			status: true,
			recordId,
			managerRemark: remark,
		};

		console.log("Request Data", data);
		axios
			.post(
				`/production/${dept}/submitMaintainenceDataByManager`,
				data
			)
			.then(function (response) {
				console.log("response", response);
				alert(
					"Maintenance Checkpoint Data Submitted Successfully!"
				);
			})
			.catch(function (error) {
				console.log("Error", error);
				alert(error);
			});
	};
	return (
		
		<Card
			title={props.item.maint_info}
			style={{ boxShadow: "5px 5px 5px 5px grey" }}
			headStyle={{
				backgroundColor:
					props.item.isMaintained === true ? "#29a329" : "#cc0000",
				borderRadius: "10px",
				color: "#ffffff",
			}}
			raised="true"
		>
			<Row
				gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
				style={{ marginBottom: "4%" }}
			>
				<Col className="gutter-row" span={12}>
					Maintenance Type
				</Col>
				<Col className="gutter-row" span={12}>
					<Select
						defaultValue={props.item.maintType}
						style={{ width: 120 }}
						disabled
					>
						<Select.Option value={props.item.maintType}>
							{props.item.maintType}
						</Select.Option>
					</Select>
				</Col>
			</Row>
			<Row
				gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
				style={{ marginBottom: "4%" }}
			>
				<Col className="gutter-row" span={12}>
					Maintenance Frequency
				</Col>
				<Col className="gutter-row" span={12}>
					{props.item.recurrence && (
						<Select
							defaultValue={props.item.recurrence}
							style={{ width: 120 }}
							disabled
						>
							<Select.Option value={props.item.recurrence}>
							{props.item.recurrence}
							</Select.Option>
						</Select>
					) }
				</Col>
			</Row>
			<Row
				gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
				style={{ marginBottom: "4%" }}
			>
				<Col className="gutter-row" span={24}>
					<Typography.Text>Completed</Typography.Text>

					<Radio.Group
						initialValues="Yes"
						defaultValue={props.item.isMaintained ? "1" : "0"}
						disabled
					>
						<Radio value="1" style={{ margin: "0px 12px" }}>
							Yes
						</Radio>
						<Radio value="0">No</Radio>
					</Radio.Group>
				</Col>
			</Row>
			<Row
				gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
				style={{ marginBottom: "4%" }}
			>
				<Col className="gutter-row" span={24}>
					<Input
						addonBefore="Operator Remark"
						value={props.item.remark}
						disabled
					/>
				</Col>
			</Row>

			<Row
				gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
				style={{ marginBottom: "4%" }}
			>
				<Col className="gutter-row" span={24}>
					<Input
						name="managerRemark"
						addonBefore="Add Remark"
						placeholder="Remark"
						value={props.item.managerRemark}
						disabled={isApproved}
						onChange={(e) => {
							setRemark(e.target.value);
						}}
					/>
				</Col>
			</Row>

			<Row>
				<Col span={11}>
					<Button
						type="primary"
						shape="round"
						disabled={isApproved}
						icon={<CheckOutlined />}
						className="action-button approve-btn"
						onClick={() => {							
							submit(props.item._id);
							
						}}
					>
						Approve
					</Button>
				</Col>
			</Row>
		</Card>
	);
};

export default MaintenanceCard;
