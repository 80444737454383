import { useState, useEffect } from "react";
import { Col, Row } from "antd";

import MaintenanceCard from "./DCmaintenanceCard";
import BackButton from "../../../common/BackButton/backButton";
import axios from "../../../../api/axiosApi";

import "./toolMaintenanceReview.css";

const DCToolMaintenanceReview = (props) => {
  const [maintData, setMaintData] = useState([]);

  const month = props.location.state["month"];
  useEffect(() => {
    const maintenanceData = () => {
      axios
        .get(`/production/die_casting/getToolMaintDataManager`)
        .then(function (response) {
          const resData = response.data.data;
          setMaintData(() => resData[month]);
        })
        .catch(function (error) {
          console.log(error);
          alert(error);
        });
    };
    maintenanceData();
  }, [month]);

  return (
    <>
      <BackButton styleClass="back-btn" />
      <div className="card-holder">
        <div className="form-card">
          <h1
            style={{
              paddingBottom: "10px",
              letterSpacing: "0.3rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            TOOL MAINTENANCE REVIEW
          </h1>
          <div>
            {maintData.length === 0 ? (
              <div>Loading</div>
            ) : (
              <div className="site-card-wrapper">
                <Row
                  gutter={[
                    { xs: 8, sm: 16, md: 24, lg: 32 },
                    { xs: 8, sm: 16, md: 24, lg: 32 },
                  ]}
                >
                  {maintData.map(function (item, i) {
                    console.log(item);
                    return (
                      <Col span={12} key={i}>
                        <MaintenanceCard item={item} />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DCToolMaintenanceReview;
