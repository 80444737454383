import React, { useEffect, useState } from "react";
import { Form, Input, Button, Divider, Row, Col } from "antd";

import { useSelector } from "react-redux";
import { getUser } from "../../../../redux/users/userSlice";
import "../shift_startup/style.css";
// import {
//   getPartsData,
// } from "../../../../redux/dropDownData/dropDownDataSlice";

import axios from "../../../../api/axiosApi";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router";
import BackButton from "../../../common/BackButton/backButton";
import axiosApi from "../../../../api/axiosApi";
import PartsQuantityForm from "./partsQuantityForm";

const NCCForm = (props) => {
	const [form] = Form.useForm();
	const userData = useSelector(getUser);
	const [loading, setLoading] = useState();
	const [error, setError] = useState();
	const [partQuantity, setPartQuantity] = useState();
	const [internalNCC, setInternalNCC] = useState();
	const [update, setUpdate] = useState(true);
	// const partsData = useSelector(getPartsData);
	let history = useHistory();
	// const [op_recordId] = useState("");
	// const [op_submit_date] = useState("");
	useEffect(() => {
		setLoading(true);
		axiosApi
			.get(`production/injectionMoulding/getNccData`)
			.then((res) => {
				console.log("NCC Data : ", res.data);
				setPartQuantity(res.data.data);
				setUpdate(!update);
				setLoading(false);
			})
			.catch((err) => {
				console.log("err =>", err);
				setError(true);
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onFinish = (values) => {
		axios
			.post("/production/injectionMoulding/submitQASupervisorFeedback", {
				sup_name: userData["username"],
				moulding_ppm: values["moulding_ppm"],
				internal_customer_ppm_paint_shop:
					values["internal_customer_ppm_paint_shop"],
				internal_customer_ppm_assembly_shop:
					values["internal_customer_ppm_assembly_shop"],
				senior: "Production Manager",
				production: partQuantity,
				total_internal_ncc: internalNCC,
			})
			.then(function (response) {
				if (!response.data.err) {
					toast.success("🚀 Form Submitted Successfully", {
						position: "bottom-center",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						progress: undefined,
					});
					setTimeout(() => {
						history.goBack();
					}, 3000);
				}
			})
			.catch(function (error) {
				console.log(error);
				alert(error);
			});

		form.resetFields();
	};
	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	useEffect(() => {
		let ncc = 0;
		partQuantity?.forEach((element) => {
			let machine_ncc = 0;
			element?.parts_production?.forEach((item) => {
				let cost = item.part_id?.cost_of_part;
				if (!cost) {
					cost = 0;
				}
				machine_ncc +=
					parseInt(cost) *
					(parseInt(item.unaccepted_qty) +
						parseInt(item.tqw_rejected_quantity));
				item.ncc =
					parseInt(cost) *
					(parseInt(item.unaccepted_qty) +
						parseInt(item.tqw_rejected_quantity));
				ncc +=
					parseInt(cost) *
					(parseInt(item.unaccepted_qty) +
						parseInt(item.tqw_rejected_quantity));
				console.log("ncc", ncc);
			});
			element.machine_ncc = machine_ncc;
		});
		setInternalNCC(ncc);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [update]);

	const onTQWChange = (index1, index2, e) => {
		if (!e.target.value) {
			let dif =
				0 -
				partQuantity[index1].parts_production[index2]
					.tqw_rejected_quantity;
			let data = partQuantity;
			data[index1].parts_production[index2].tqw_rejected_quantity = 0;
			data[index1].parts_production[index2].accepted_qty -= dif;
			setPartQuantity(data);
			setUpdate(!update);
			return;
		}
		let dif =
			parseInt(e.target.value) -
			partQuantity[index1].parts_production[index2].tqw_rejected_quantity;
		let data = partQuantity;
		data[index1].parts_production[index2].tqw_rejected_quantity = parseInt(
			e.target.value
		);
		data[index1].parts_production[index2].accepted_qty -= dif;
		setPartQuantity(data);
		setUpdate(!update);
	};
	return !loading ? (
		<>
			<BackButton styleClass={"back-btn"} />
			<div className="card-holder">
				<ToastContainer />
				<div className="form-card">
					<h1
						style={{
							paddingBottom: "10px",
							letterSpacing: "0.3rem",
							fontWeight: "bold",
							textAlign: "center",
						}}
					>
						NCC Form
					</h1>
					<br />
					<Form
						labelCol={{
							span: 8,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						wrapperCol={{
							span: 9,
						}}
						layout="horizontal"
						form={form}
					>
						{partQuantity &&
							partQuantity.map((item, index1) => {
								return (
									<div key={index1}>
										{item?.parts_production.length !==
											0 && (
											<div
												style={{
													fontWeight: "500",
													fontSize: 20,
												}}
											>
												{item?.name}
											</div>
										)}
										<PartsQuantityForm
											partQuantity={item.parts_production}
											onTQWChange={onTQWChange}
											index={index1}
										/>
									</div>
								);
							})}
						<Divider className="divider" />
						<Row style={{ margin: "1.2em 0" }}>
							<Col
								span={12}
								style={{
									textAlign: "left",
									fontWeight: "500",
									fontSize: 20,
								}}
							>
								Total NCC Cost
							</Col>
							<Col span={12} style={{ textAlign: "left" }}>
								<Input placeholder={internalNCC} disabled />
							</Col>
						</Row>

						<Divider className="divider" />

						<Form.Item
							label="Moulding PPM"
							name="moulding_ppm"
							rules={[
								{
									required: true,
									message: "Please input Moulding PPM!",
								},
							]}
							labelAlign="left"
						>
							<Input
								style={{ maxWidth: "500px" }}
								type="number"
							/>
						</Form.Item>

						<Form.Item
							labelAlign="left"
							label="Internal Customer PPM (Paint Shop)"
							rules={[
								{
									required: true,
									message:
										"Please input Internal Customer PPM (Paint Shop)!",
								},
							]}
							name="internal_customer_ppm_paint_shop"
						>
							<Input
								style={{ maxWidth: "500px" }}
								type="number"
							/>
						</Form.Item>
						<Form.Item
							label="Internal Customer PPM (Assy Shop)"
							name="internal_customer_ppm_assembly_shop"
							rules={[
								{
									required: true,
									message:
										"Please input Internal Customer PPM (Assy Shop)!",
								},
							]}
							labelAlign="left"
						>
							<Input
								style={{ maxWidth: "500px" }}
								type="number"
							/>
						</Form.Item>

						<div style={{ textAlign: "center", marginTop: "20px" }}>
							<Button type="primary" danger htmlType="submit">
								Submit
							</Button>
						</div>
					</Form>
				</div>
			</div>
		</>
	) : error ? (
		<div>Error while fetching details...</div>
	) : (
		<div>Loading...</div>
	);
};

export default NCCForm;
