import { React, useState } from "react";

import { Select, Button, Col, Row, Typography, Card } from "antd";

import axios from "../../../../api/axiosApi";
import { CheckOutlined } from "@ant-design/icons";

const MaintenanceCard = (props) => {
  const [isMaintained, setIsMaintained] = useState(false);
  const submit = (partId, partNo, partName) => {
    const data = {
      partId,
      partNo,
      partName,
      isMaint: true,
    };

    console.log("Data", data);
    axios
      .post("/production/injectionMoulding/submitToolMaintData", data)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
        alert(error);
      });
  };

  return (
    <Card
      title={props.item.partName}
      style={{ boxShadow: "5px 5px 5px 5px grey" }}
      headStyle={{
        backgroundColor: isMaintained === true ? "#29a329" : "#cc0000",
        borderRadius: "10px",
        color: "#ffffff",
      }}
      raised="true"
    >
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        style={{ marginBottom: "4%" }}
      >
        <Col className="gutter-row" span={12}>
          <Typography.Text strong>Part No.</Typography.Text>
        </Col>
        <Col className="gutter-row" span={12}>
          <Select
            defaultValue={props.item.partNo}
            style={{ width: 120 }}
            disabled
          >
            <Select.Option value={props.item.partNo}>
              {props.item.partNo}
            </Select.Option>
          </Select>
        </Col>
      </Row>

      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        style={{ marginBottom: "4%" }}
      >
        <Col className="gutter-row" span={12}>
          <Typography.Text strong>Tool Life</Typography.Text>
        </Col>
        <Col className="gutter-row" span={12}>
          <Select
            defaultValue={props.item.toolLife}
            style={{ width: 120 }}
            disabled
          >
            <Select.Option value={props.item.toolLife}>
              {props.item.toolLife}
            </Select.Option>
          </Select>
        </Col>
      </Row>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        style={{ marginBottom: "4%" }}
      >
        <Col className="gutter-row" span={12}>
          <Typography.Text strong>Balance Tool Life</Typography.Text>
        </Col>
        <Col className="gutter-row" span={12}>
          <Select
            defaultValue={props.item.balanceToolLife}
            style={{ width: 120 }}
            disabled
          >
            <Select.Option value={props.item.balanceToolLife}>
              {props.item.balanceToolLife}
            </Select.Option>
          </Select>
        </Col>
      </Row>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        style={{ marginBottom: "4%" }}
      >
        <Col className="gutter-row" span={12}>
          <Typography.Text strong>Produced Shot Qty</Typography.Text>
        </Col>
        <Col className="gutter-row" span={12}>
          <Select
            defaultValue={props.item.producedShotQt}
            style={{ width: 120 }}
            disabled
          >
            <Select.Option value={props.item.producedShotQt}>
              {props.item.producedShotQt}
            </Select.Option>
          </Select>
        </Col>
      </Row>

      <Row>
        <Col span={11}>
          <Button
            type="primary"
            shape="round"
            icon={<CheckOutlined />}
            disabled={isMaintained}
            className="action-button approve-btn"
            onClick={() => {
              setIsMaintained(true);
              submit(props.item.partId, props.item.partNo, props.item.partName);
            }}
          >
            COMPLETE
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default MaintenanceCard;
