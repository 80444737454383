import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  machinesData: [],
  partsData: [],
  ppeKit: [],
  rawMaterial: [],
  dept: [],
  allUsers: {},
};

const dropDownDataSlice = createSlice({
  name: "dropDownData",
  initialState: initialState,
  reducers: {
    addDropDownData: (state, { payload }) => {
      // console.log("Payload", payload);

      if (payload.machine_data) {
        state.machinesData = payload.machine_data;
      }
      if (payload.part_data) {
        state.partsData = payload.part_data;
      }
      if (payload.ppe_kit) {
        state.ppeKit = payload.ppe_kit;
      }
      if (payload.raw_material) {
        state.rawMaterial = payload.raw_material;
      }

      if (payload.dept) {
        state.dept = payload.dept;
      }

      if (payload.users) {
        state.allUsers = payload.users;
      }
    },
    removeDropDownData: (state) => {
      state = initialState;
    },
  },
});

export const { addDropDownData, removeDropDownData } =
  dropDownDataSlice.actions;

export const getMachinesData = (state) => state.dropDownData.machinesData;
export const getPartsData = (state) => state.dropDownData.partsData;
export const getPpeKit = (state) => state.dropDownData.ppeKit;
export const getRawMaterialData = (state) => state.dropDownData.rawMaterial;
export const getDepartments = (state) => state.dropDownData.dept;
export const getAllUsers = (state) => state.dropDownData.allUsers;

export default dropDownDataSlice.reducer;
