import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  operator: {},
  isStored: false,
};

const operatorSlice = createSlice({
  name: "operator",
  initialState: initialState,
  reducers: {
    addOperatorData: (state, { payload }) => {
      state.operator = payload;
      state.isStored = true;
    },
    removeOperatorData: (state) => {
      state.operator = {};
      state.isStored = false;
    },
  },
});

export const { addOperatorData, removeOperatorData } = operatorSlice.actions;

export const getCurrOperatorData = (state) => state.operator.isStored;

export const getOperatorData = (state) => state.operator.operator;

export default operatorSlice.reducer;
