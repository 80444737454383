import {
	Button,
	Card,
	Col,
	Divider,
	Input,
	Layout,
	Modal,
	Row,
	Select,
} from "antd";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
// import PageFooter from "../../../components/common/Footer/footer";
import PageFooter from "../../../components/common/Footer/footer";
import styles from "./dc_operator_dashboard.module.css";

import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
// import axiosApi from "../../api/axiosApi";
import axiosApi from "../../../api/axiosApi";
import {
	getAllUsers,
	// getMachinesData,
} from "../../../redux/dropDownData/dropDownDataSlice";
import { getOperatorData } from "../../../redux/operators/operatorSlice";
import { getUser } from "../../../redux/users/userSlice";
//import { socketSendAlerts } from "../../api/socket";
// import moment from "moment";

const { Content } = Layout;

const DCOperatorDashboard = (props) => {
	//logged userData
	const userData = useSelector(getUser);
	const operatorData = useSelector(getOperatorData);
	// const machinesData = useSelector(getMachinesData);

	const allUsersData = useSelector(getAllUsers);
	// console.log(allUsersData);

	// modal functions
	const [visible, setVisible] = useState(false);
	const [stoppageReason, setStoppageReason] = useState("Breakdown");
	const [stoppageComments, setStoppageComments] = useState("");
	const [alerts, setAlerts] = useState([]);
	const [productionPlan, setProductionPlan] = useState();

	const [modalType, setModalType] = useState(3);

	const showModal = () => {
		setVisible(true);
	};

	const handleSendAlert = () => {
	
		let receiverIds = [[], [], []];
	
		var isPresent = false;
		if (
			allUsersData["Die Casting"].Manager &&
			allUsersData["Die Casting"].Supervisor &&
			allUsersData.Maintenance.Manager &&
			allUsersData["Tool Room"].Manager &&
			allUsersData["Quality Assurance"].Manager &&
			allUsersData["Quality Assurance"].Supervisor
		) {
			isPresent = true;
		}
		if (isPresent) {
			// ALL Production Supervisors in receiverIds[0]
			Object.entries(allUsersData["Die Casting"].Supervisor).forEach((e) => {
				receiverIds[0].push({ rId: e[1]._id, isRead: false });
			});
			// ALL Production Managers in receiverIds[1]
			Object.entries(allUsersData["Die Casting"].Manager).forEach((e) => {
				receiverIds[1].push({ rId: e[1]._id, isRead: false });
			});

			// Stoppage due to Breakdown
			if (stoppageReason === "Breakdown") {
				// Maintenance Supervisors in receiverIds[0]
				Object.entries(allUsersData["Maintenance"].Supervisor).forEach(
					(e) => {
						receiverIds[0].push({ rId: e[1]._id, isRead: false });
					}
				);
				// Maintenance Manager in receiverIds[1]
				Object.entries(allUsersData["Maintenance"].Manager).forEach(
					(e) => {
						receiverIds[1].push({ rId: e[1]._id, isRead: false });
					}
				);
				// ALL GM in receiverIds[2]
				Object.entries(
					allUsersData["Production"].GeneralManager
				).forEach((e) => {
					receiverIds[2].push({ rId: e[1]._id, isRead: false });
				});
			}
			// Stoppage due to Quality Issue
			if (stoppageReason === "Quality Issue") {
				// Quality Supervisors in receiverIds[0]
				Object.entries(
					allUsersData["Quality Assurance"].Supervisor
				).forEach((e) => {
					receiverIds[0].push({ rId: e[1]._id, isRead: false });
				});
				// Quality Managers in receiverIds[1]
				Object.entries(
					allUsersData["Quality Assurance"].Manager
				).forEach((e) => {
					receiverIds[1].push({ rId: e[1]._id, isRead: false });
				});
				// Quality Managers in receiverIds[2]
				Object.entries(
					allUsersData["Quality Assurance"].Manager
				).forEach((e) => {
					receiverIds[2].push({ rId: e[1]._id, isRead: false });
				});
			}

			// Stoppage due to Tool Issue
			if (stoppageReason === "Tool Issue") {
				// Quality Supervisors in receiverIds[0]
				Object.entries(allUsersData["Tool Room"].Manager).forEach(
					(e) => {
						receiverIds[0].push({ rId: e[1]._id, isRead: false });
					}
				);
				// Quality Managers in receiverIds[1]
				Object.entries(allUsersData["Tool Room"].Manager).forEach(
					(e) => {
						receiverIds[1].push({ rId: e[1]._id, isRead: false });
					}
				);
				// Quality Managers in receiverIds[2]
				Object.entries(allUsersData["Tool Room"].Manager).forEach(
					(e) => {
						receiverIds[2].push({ rId: e[1]._id, isRead: false });
					}
				);
			}

			

			axiosApi.post("production/die_casting/createQrciForms", {
				senderId: userData._id,
				receiverIds: receiverIds,
				machineId: operatorData.machineId,
				shift: operatorData.shift,
				// color: "Orange",
				type: "qrci",
				data: {
					msg: stoppageReason,
					comments: stoppageComments,
				},
				externally_created: false,
				should_escalate: true,
			});

			alert(`${stoppageReason} Alert Sent`);
			setStoppageReason(() => "");
			setStoppageComments(() => "");
			setVisible(false);
			console.log("ReceiverIds", receiverIds);
		} else {
			alert(
				`Alert Not Sent !! Some roles are not registered or not active yet`
			);
			setStoppageReason(() => "");
			setStoppageComments(() => "");
			setVisible(false);
		}
	};

	const handleCancelAlert = () => {
		setVisible(false);
		setStoppageReason(() => "");
		setStoppageComments(() => "");
	};

	const fetchResolveAlerts = (userId) => {
		// Fetches Stoppage Alerts (of System QRCI) created (i.e. sent) BY THAT Operator
		axiosApi
			.get(
				`production/injectionMoulding/getAlerts?senderId=${userId}&isResolved=false&type=instant`
			)
			.then((res) => {
				const alertData = res.data.data;
				setAlerts(() => alertData);
			})
			.catch((err) => {
				console.log(err.message);
			});
	};

	const resolveAlert = (alertId) => {
		console.log("Clicked : ", alertId);
		axiosApi
			.post(`/production/injectionMoulding/updateAlerts`, {
				alertId: alertId,
				isResolved: true,
			})
			.then((res) => {
				console.log(res);
				fetchResolveAlerts(userData._id);
			})
			.catch((err) => {
				console.log(err.message);
			});
	};

	const onReduceCount = () => {
		axiosApi
			.post(`production/die_casting/submitRejectedParts`, {
				machine_id: operatorData.machineId,
				shift:operatorData.shift
			})
			.then((res) => {
				toast.success("🚀 Part rejected successfully!!", {
					position: "bottom-center",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					progress: undefined,
				});
			})
			.catch((err) => {
				console.log("err >>", err);
				alert("error while updating count");
			});
	};


	useEffect(() => {
		fetchResolveAlerts(userData._id);
	}, [userData._id]);

	useEffect(() => {
		axiosApi
			.get(
				`production/die_casting/getPlanning?machineId=${operatorData.machineId}`
			)
			.then((res) => {
				setProductionPlan(res.data.data);
				console.log("prod plan : ", res);
			})
			.catch((err) => {
				console.log("err >>", err);
				alert("error while fetching data");
			});
	}, [operatorData.machineId]);

	useEffect(() => {
		console.log(productionPlan);
	}, [productionPlan]);

	const sendAlertsModal = (
		<>
			<h2 style={{ textAlign: "center" }}>Send Alerts</h2>
			<ToastContainer />
			<Select
				clearIcon
				placeholder="Select A Reason"
				onChange={(value) => {
					setStoppageReason(() => value);
				}}
				style={{ marginTop: "1rem" }}
			>
				<Select.Option value="Breakdown">Breakdown</Select.Option>
				<Select.Option value="Quality Issue">
					Quality Issue
				</Select.Option>
				<Select.Option value="Tool Issue">Tool Issue</Select.Option>
				<Select.Option value="Material Shortage">
					Material Shortage
				</Select.Option>
				<Select.Option value="Others">Others</Select.Option>
			</Select>

			<Input
				placeholder="Other Comments if any"
				value={stoppageComments}
				onChange={(e) => setStoppageComments(() => e.target.value)}
				style={{ marginTop: "1rem" }}
			></Input>
			<div style={{ textAlign: "center" }}>
				<Button
					style={{ marginTop: "1rem" }}
					onClick={() => handleSendAlert()}
				>
					Send Alerts
				</Button>
			</div>
		</>
	);

	const resolveAlertsModal = (
		<>
			<h2 style={{ textAlign: "center" }}>Resolve Alerts</h2>
			{alerts?.length === 0
				? "All Alerts Resolved"
				: alerts?.map((a) => {
						return (
							<Card key={a.alerts._id} className={styles.alert_card}>
								<div>
									<p>Alert Reason : {a.alerts.data.msg}</p>
									<p>
										Alert Comments :{" "}
										{a.alerts.data.comments}
									</p>
									<p>Alert Type : {a.alerts.color}</p>
									<p>
										Is Resolved :{" "}
										{a.alerts.isResolved ? "True" : "False"}
									</p>
									<div style={{ textAlign: "center" }}>
										<Button
											onClick={() =>
												resolveAlert(a.alerts._id)
											}
										>
											Mark As Resolved
										</Button>
									</div>
								</div>
							</Card>
						);
				  })}
		</>
	);

	const reduceCountModal = (
		<div style={{ textAlign: "center" }}>
			<h2>Reduce Count</h2>
			<Button onClick={onReduceCount}> Reduce Count</Button>
		</div>
	);

	// const resetCountModal = (
	// 	<div style={{ textAlign: "center" }}>
	// 		<h2>Are You Sure You want to reset part count?</h2>
	// 		<Button onClick={resetCount}>Yes</Button>
	// 		<Button onClick={handleCancelAlert}>No</Button>
	// 	</div>
	// );

	const showProductionPlan = (
		<div>
			<h2 style={{ textAlign: "center" }}>Production Plan</h2>
			<Row>
				<Col span={10}>
					<h3>Part Name</h3>
				</Col>
				<Col span={6}>
					<h3>Planned Qty</h3>
				</Col>
				<Col span={6}>
					<h3>Date</h3>
				</Col>
			</Row>
			<div>
				{productionPlan?.map((item, index) => {
					console.log(item);
					return (
						<div key={index}>
							<Row>
								<Col span={10}>{item.part_id.part_name}</Col>
								<Col span={6}>{item.qtyRequired}</Col>
								<Col span={6}>
									{new Date(item.date).toLocaleDateString(
										"en-GB"
									)}
								</Col>
							</Row>
						</div>
					);
				})}
			</div>
		</div>
	);

	const myModal = (
		<Modal
			visible={visible}
			onCancel={handleCancelAlert}
			footer={[
				<Button type="danger" onClick={handleCancelAlert}>
					Close
				</Button>,
			]}
		>
			{/* {modalType === 1 ? (
				reduceCountModal
			) : modalType === 2 ? (
				sendAlertsModal
			) : modalType === 3 ? (
				resolveAlertsModal
			) : modalType === 4 ? (
				showProductionPlan
			) : modalType === 5 ? (
				resetCountModal
			) : (
				<></>
			)} */}
            {modalType === 1 ? (
				reduceCountModal
			) : modalType === 2 ? (
				sendAlertsModal
			) : modalType === 3 ? (
				resolveAlertsModal
			) : modalType === 4 ? (
				showProductionPlan
			) : (
				<></>
			)}
		</Modal>
	);

	return (
		<div className={styles.operator_dashboard} >
			<Layout className={styles.layout} >
				<Content  >
					<div className={styles.site_layout_content} >
						<h1 >Forms</h1>
						<Divider />
						<div className={styles.site_card_wrapper}>
							<Card title="Shift Startup form" bordered={false}>
								<Button type="primary">
									<Link to="/dc-shift-startup">Fill now</Link>
								</Button>
							</Card>
							<Card
								title="Shift Handover Form"
								bordered={false}
								className={styles.shift_handover_form}
							>
								<Button type="primary">
									<Link to="/dc-new-shift-handover-form">
										New Form
									</Link>
								</Button>
								<Button>
									<Link to="/dc-prefill-shift-handover-form">
										Prefilled Form
									</Link>
								</Button>
							</Card>
							{/* <Card title="Hourly Report Form" bordered={false}>
                <Button type="primary">
                  <Link to="/hourly-report">Fill now</Link>
                </Button>
              </Card> */}
						</div>
						<Divider />
						<Button
							type="primary"
							onClick={() => {
								setModalType(() => 1);
								showModal();
							}}
						>
							Update Quantity
						</Button>
						<Button
							type="primary"
							onClick={() => {
								setModalType(() => 2);
								showModal();
							}}
						>
							Machine Stoppage
						</Button>
						<Button
							type="primary"
							onClick={() => {
								fetchResolveAlerts(userData._id);
								setModalType(() => 3);
								showModal();
							}}
						>
							Resolve Alerts
						</Button>
						<Button
							type="primary"
							onClick={() => {
								setModalType(() => 4);
								showModal();
							}}
						>
							Show planned quantity
						</Button>
						{/* <Button
							type="primary"
							disabled={true}
							onClick={() => {
								setModalType(() => 5);
								showModal();
							}}
						>
							Reset Part Count
						</Button> */}
						{myModal}
					</div>
				</Content>
			</Layout>
			<PageFooter />
		</div>
	);
};

export default DCOperatorDashboard;
