import React, { useState, useEffect } from "react";

import { Card, Row, Col } from "antd";
import { Link } from "react-router-dom";

import axios from "../../../api/axiosApi";

import auth from "../../../components/common/LoginComponent/auth";
import "./maintenance_manager.css";
import BackButton from "../../../components/common/BackButton/backButton";

const MachineMaintenanceDashboard = (props) => {
  const [machinesList, setMachinesList] = useState();
  const { dept } = props.match.params;
  const [loading, setloading] = useState(true);

  // useEffect(() => {
  // 	if (auth.isAuthenticated()) {
  // 		setloading(() => true);
  // 		const fetchAllMachines = async () => {

  // 			await axios
  // 				.get(`production/${dept}/getMachineInformation`)
  // 				.then((machineData) => {
  // 					setMachinesList(() => machineData.data.data);
  // 					setloading(() => false);
  // 				})
  // 				.catch((e) => {
  // 					console.log("Error in Fetching Operator Data : ", e);
  // 				});
  // 		};
  // 		fetchAllMachines();
  // 	}
  // }, []);
  useEffect(() => {
    const isAuthenticated = auth.isAuthenticated();

    const fetchData = async () => {
      if (isAuthenticated) {
        setloading(() => true);

        try {
          const machineData = await axios.get(
            `production/${dept}/getMachineInformation`
          );
          setMachinesList(() => machineData.data.data);
          setloading(() => false);
        } catch (e) {
          console.log("Error in Fetching Operator Data: ", e);
        }
      }
    };

    fetchData();
  }, [dept]); // Remove 'auth' from the dependency array

  if (machinesList === null || machinesList === undefined || !machinesList)
    return "No Machine Data Currently Available";

  if (loading) return "Loading Your Dashboard";
  return (
    <div className="machine-dashboard">
      <BackButton styleClass={"back-btn"} />
      <Row gutter={[32, 32]} className="machine-card-container">
        {machinesList?.map((machine) => (
          <Col
            xs={24}
            sm={12}
            lg={6}
            className="machine-card"
            key={machine._id}
          >
            <Card
              title={`${machine.name}`}
              hoverable
              style={{
                margin: 15,
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                borderRadius: "10px",
              }}
              headStyle={{
                backgroundColor: "#3366ff",
                borderRadius: "10px",
                color: "#ffffff",
              }}
            >
              <Link
                to={{
                  pathname: `/machine-maintenance-review-forms/${dept}`,
                  state: {
                    machineId: machine._id,
                    machineName: machine.name,
                  },
                }}
              >
                Review Maintenance
              </Link>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default MachineMaintenanceDashboard;
