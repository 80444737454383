import { Col, Row } from 'antd'
import  { useEffect, useState } from 'react'
import axiosApi from '../../../api/axiosApi';
import ProductionStats from './ProductionStats';

const AllDepartmentBoard = () => {

    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [prodValues, setProdValues] = useState({});
    const [prodValuesDC, setProdValuesDC] = useState({});
    const [showDetails, setShowDetails] = useState(false);
    const [dept, setDept] = useState("")

    const modData = (data, func) => {
        if (data.data.data.err) {
            setError(true);
            return;
        }
        let yellow = false, orange = false, red = false;
        data?.data?.data?.details.forEach(element => {
            yellow = yellow || (element.percentage >= 10 && element.percentage < 20);
            orange = orange || (element.percentage >= 20 && element.percentage < 30);
            red = red || (element.percentage >= 30);
        });

        yellow = yellow || (data?.data?.data?.percentage >= 10 && data?.data?.data?.percentage < 20);
        orange = orange || (data?.data?.data?.percentage >= 20 && data?.data?.data?.percentage < 30);
        red = red || (data?.data?.data?.percentage >= 30);

        func({
            target: data.data.data.total_target,
            actual: data.data.data.total_actual,
            percentage: data.data.data.percentage,
            details: data.data.data.details,
            boardColor: red ? "red" : orange ? "orange" : yellow ? "yellow" : "green"
        })
    }

    const fetchData = () => {

        axiosApi.get(`production/injectionMoulding/getHourlyTargetActualPage`)
            .then(res => {
                modData(res,setProdValues)               
                if (isLoading) {
                    setLoading(false)
                }
            })
            .catch(err => {
                console.log('err => ', err);
                setLoading(false);
                setError(true);
            })

        axiosApi.get(`production/die_casting/getHourlyTargetActualPage`)
            .then(res => {
                // console.log("dc data",  res.data)
                modData(res,setProdValuesDC)               
                if (isLoading) {
                    setLoading(false)
                }
            })
            .catch(err => {
                console.log('err => ', err);
                setLoading(false);
                setError(true);
            })

    }


    useEffect(() => {
        fetchData();
        setInterval(() => {
            fetchData();
        }, 1000 * 60);
        // eslint-disable-next-line react-hooks/exhaustive-deps	
    }, []);

    const alertStyle = {
        textDecoration: "underline",
        fontSize: "18px",
    };

    const handleAlertClick = (boardColor,dept) => {
        setShowDetails((prevShowDetails) => !prevShowDetails);
        setDept(dept);
    };

    return !isLoading ? (
        <div>
            <Row gutter={16} style={{ marginTop: "2%", marginBottom: "2%" }}>
                <Col span={6}>
                    <div style={{ fontSize: "20px" }}>
                        Injection Moulding
                    </div>
                    {prodValues?.boardColor && (
                        <div
                            style={{ ...alertStyle, color: prodValues.boardColor }}
                            onClick={() => handleAlertClick(prodValues.boardColor, "injectionMoulding")}
                        >
                            {prodValues.boardColor === "green" ? "All Good" : `${prodValues.boardColor} Alert`}
                        </div>)}
                </Col>
                <Col span={6}>
                    <div style={{ fontSize: "20px" }}>
                        Die Casting
                    </div>
                    {prodValuesDC?.boardColor && (
                        <div
                            style={{ ...alertStyle, color: prodValuesDC.boardColor }}
                            onClick={() => handleAlertClick(prodValuesDC.boardColor, "die_casting")}
                        >
                            {prodValuesDC.boardColor === "green" ? "All Good" : `${prodValuesDC.boardColor} Alert`}
                        </div>)}
                </Col>
                <Col span={6}>
                    <div style={{ fontSize: "20px" }}>
                        Paint Shop
                    </div>
                    <div style={{ textDecoration: "underline", fontSize: "18px" }}>All Good</div>
                </Col>
                <Col span={6}>
                    <div style={{ fontSize: "20px" }}>
                        Assembly
                    </div>
                    <div style={{ textDecoration: "underline", fontSize: "18px" }}>All Good</div>
                </Col>
            </Row>
            {showDetails && <ProductionStats dept={dept} />}
        </div>
    ) : !error ? <div>Loading...</div> : <div>Error while fetching...</div>
}

export default AllDepartmentBoard