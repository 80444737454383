import { Button, Divider, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import axiosApi from "../../../../api/axiosApi";
import styles from "../../operator/styleop.module.css";
// redux
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	getAllUsers,
	getMachinesData,
	getPartsData,
} from "../../../../redux/dropDownData/dropDownDataSlice";
import { getOperatorData } from "../../../../redux/operators/operatorSlice";
import { getUser } from "../../../../redux/users/userSlice";

//common components
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackButton from "../../../common/BackButton/backButton";
import EmployeeDetail from "../../../common/employeeDetail";
import { PartsData } from "../../operator/shift_handover/parts_data";

const ShiftHandoverForm = (props) => {
	const [data, setData] = useState({
		accident: "0",
	});
	const [form] = Form.useForm();
	const [rejectedQuantity, setRejectedQuantity] = useState();
	const [loading, setLoading] = useState();
	const [error, setError] = useState();
	const userData = useSelector(getUser);
	const operatorData = useSelector(getOperatorData);
	const allUsersData = useSelector(getAllUsers);
	const machinesData = useSelector(getMachinesData);
	const partsData = useSelector(getPartsData);


	let history = useHistory();

	const onFinish = async (values) => {
		console.log("Values : \n", values);
		console.log(values.production);

		// Machine one fixed and "count" fixed
		// For each part in form:
		//     (accepted_qty + unaccepted_qty) :  (count * cavity)

		const currentMachineId = operatorData.machineId;
		const currentMachine = machinesData.filter(
			(machine) => machine._id === currentMachineId
		);
		// console.log("currentMachine", currentMachine);
		const currentMachineCount = currentMachine[0].count;
		console.log("currentMachineCount", currentMachineCount);

		// Add "no_of_cavity" field in all objects of values.production
		let alertParts = [];

		// for (let currPart of values.production) {
		// 	// console.log("currPart", currPart);
		// 	// let alertPartObj;
		// 	const partId = currPart.part_id;
		// 	const acceptedQty = currPart.accepted_qty;
		// 	const unacceptedQty = currPart.unaccepted_qty;

		// 	let partCavity, partName;
		// 	for (let p of partsData) {
		// 		if (p._id === partId) {
		// 			partName = p.part_name;
		// 			partCavity = p.no_of_cavity;
		// 			break;
		// 		}
		// 	}
		// 	// console.table({partId, partName, partCavity, acceptedQty, unacceptedQty});
		// 	// if (
		// 	// 	Number(acceptedQty) + Number(unacceptedQty) >
		// 	// 	Number(currentMachineCount) * Number(partCavity)
		// 	// ) {
		// 	// 	console.log("Exceeed Condition entered");
		// 	// 	alertPartObj = {
		// 	// 		partId,
		// 	// 		acceptedQty,
		// 	// 		unacceptedQty,
		// 	// 		partCavity,
		// 	// 		partName,
		// 	// 	};
		// 	// 	alertParts.push(alertPartObj);
		// 	// }
		// }
		// console.log("alertParts", alertParts);

		if (alertParts.length !== 0) {
			let alertString =
				"Input Values Exceed Maximum Possible Production:\n";
			alertString += `Machine Count: ${currentMachineCount}\n`;
			for (let part of alertParts) {
				alertString += `\nPart Name: ${part.partName}\n`;
				const lhs = Number(
					Number(part.acceptedQty) + Number(part.unacceptedQty)
				).toFixed();
				const rhs =
					Number(part.partCavity).toFixed() *
					Number(currentMachineCount).toFixed();
				alertString += `Accepted + Rejected = ${lhs}\n`;
				alertString += `Cavity * Count = ${rhs}\n`;
			}
			console.log(alertString);
			alert(alertString);
			return;
		}

		axiosApi
			.post("production/die_casting/submitShiftHandoverData", {
				machine_id: operatorData.machineId,
				part_id: values.part_name,
				operator_name: `${userData.firstname} ${userData.lastname}`,
				operator_id: userData.empid,
				shift: operatorData.shift,
				sup_id: values.submit_to,

				part_quality: values.part_quality,
				machine_issue: values.machine_issue,
				tool_issue: values.tool_issue,

				safety: {
					accident: values.accident,
					incidence_near_miss: values.incidence_near_miss,
					observation: values.observation,
					unsafe_act: values.unsafe_act,
					unsafe_condition: values.unsafe_condition,
				},
				production: values.production,
			})
			.then(function (response) {
				console.log("Response.data\n", response.data);
				if (response.data.msg === "Form saved successfully") {
					toast.success("🚀 Form Submitted Successfully", {
						position: "bottom-center",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						progress: undefined,
					});
					form.resetFields();
					setTimeout(() => {
						history.goBack();
					}, 3000);
				}
			})
			.catch(function (error) {
				console.log(error);
				alert("Error Occured : ", error.message);
			});
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
		alert("Error Occured : Incomplete", errorInfo);
	};

	useEffect(() => {
		setLoading(true);
		// console.log("operatorData", operatorData);
		axiosApi
			.get(
				`production/die_casting/getOperatorShiftHandoverData?machine_id=${operatorData.machineId}`
			)
			.then((res) => {
				setRejectedQuantity(res.data.data);

				axiosApi
					.get(
						`production/die_casting/getDuplicateHandover?machine_id=${operatorData.machineId}&shift=${operatorData.shift}`
					)
					.then((resp) => {
						console.log("resp.data.msg", resp.data.msg);
						if (resp.data.msg === "Duplicate Found") {
							alert(
								"Cannot fill another Handover Form for same machine and shift today"
							);
							history.goBack();
						}
						setLoading(false);
					})
					.catch((err) => {
						console.log("err >>", err);
						setLoading(false);
						setError(true);
					});
				setLoading(false);
			})
			.catch((err) => {
				console.log("err >>", err);
				setLoading(false);
				setError(true);
			});
	}, [operatorData.machineId, operatorData.shift, history]);

	return !loading ? (
		<>
			<BackButton styleClass={"back-btn"} />
			<div className={styles.card_holder}>
				<ToastContainer />
				<div></div>
				<div className={styles.form_card}>
					<h1
						style={{
							paddingBottom: "10px",
							letterSpacing: "0.3rem",
							fontWeight: "bold",
							textAlign: "center",
						}}
					>
						SHIFT HANDOVER DATA SHEET
					</h1>

					<Form
						labelCol={{
							span: 10,
						}}
						wrapperCol={{
							span: 8,
						}}
						form={form}
						layout="horizontal"
						colon={false}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						initialValues={{
							shift: operatorData.shift,
							machine_name: operatorData.machineId,
						}}
					>
						<EmployeeDetail />

						<Form.Item label="Shift" name="shift" labelAlign="left">
							<Select disabled>
								<Select.Option value="1">I</Select.Option>
								<Select.Option value="2">II</Select.Option>
								<Select.Option value="3">III</Select.Option>
							</Select>
						</Form.Item>

						<Divider className="divider" />

						<Form.Item
							label="Machine Name"
							name="machine_name"
							labelAlign="left"
						>
							<Select disabled>
								{machinesData &&
									machinesData.map((machine, index) => (
										<Select.Option
											key={index}
											value={machine._id}
										>
											{machine.name}
										</Select.Option>
									))}
							</Select>
						</Form.Item>

						<Divider className="divider" />

						<span className="sub-headers">SAFETY:</span>
						<Form.Item
							label="Accident"
							name="accident"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please select something!",
								},
							]}
						>
							<Select
								style={{ textAlign: "left" }}
								value={data.accident}
								onChange={(value) =>
									setData({ ...data, accident: value })
								}
							>
								<Select.Option value="1">Yes</Select.Option>
								<Select.Option value="0">No</Select.Option>
							</Select>
						</Form.Item>
						{data.accident === "1" ? (
							<>
								<Form.Item
									label="Incidence Near Miss:"
									name="incidence_near_miss"
									labelAlign="left"
								>
									<Input type="string" />
								</Form.Item>

								<Form.Item
									label="Observation: "
									name="observation"
									labelAlign="left"
								>
									<Input type="string" />
								</Form.Item>

								<Form.Item
									label="Unsafe Act: "
									name="unsafe_act"
									labelAlign="left"
								>
									<Input type="string" />
								</Form.Item>

								<Form.Item
									label="Unsafe Condition: "
									name="unsafe_condition"
									labelAlign="left"
								>
									<Input type="string" />
								</Form.Item>
							</>
						) : (
							""
						)}
						<Divider className="divider" />
						{/* <Form.Item
              label="PART QUALITY"
              name="part_quality"
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "Please select something!",
                },
              ]}
              style={{
                fontWeight: "bold",
                textAlign: "left",
                // paddingBottom: "12px",
              }}
            >
              <Select style={{ fontWeight: "lighter", width: "100%" }}>
                <Select.Option value="1">Good</Select.Option>
                <Select.Option value="2">Moderate</Select.Option>
                <Select.Option value="0">Bad</Select.Option>
              </Select>
            </Form.Item>

            <Divider /> */}

						<Form.Item
							label="Machine Issue"
							name="machine_issue"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please select something!",
								},
							]}
							style={{
								fontWeight: "bold",
								textAlign: "left",
							}}
						>
							<Select
								style={{ fontWeight: "lighter", width: "100%" }}
							>
								<Select.Option value="Yes">Yes</Select.Option>
								<Select.Option value="No">No</Select.Option>
							</Select>
						</Form.Item>

						<Divider className="divider" />

						<Form.Item
							label="Tool Issue"
							name="tool_issue"
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please select something!",
								},
							]}
							style={{
								fontWeight: "bold",
								textAlign: "left",
							}}
						>
							<Select
								style={{ fontWeight: "lighter", width: "100%" }}
							>
								<Select.Option value="Yes">Yes</Select.Option>
								<Select.Option value="No">No</Select.Option>
							</Select>
						</Form.Item>

						<Divider className="divider" />

						<span className="sub-headers">PARTS QUANTITY:</span>

						<PartsData
							name="production"
							partsData={partsData}
							rejectedQuantity={rejectedQuantity}
						/>

						{/* <Form.Item
            label="Cost of Non Conforming Qty."
            name="cost_of_nonconforming_qty"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: "Please enter Cost of Non Conforming Quantity!",
              },
            ]}
            style={{ paddingBottom: "5px" }}
          >
            <Input type="string" style={{ width: "100%" }} />
          </Form.Item> */}
						<Divider className="divider" />

						<Form.Item
							label="SUBMIT TO"
							name="submit_to"
							style={{
								fontWeight: "bold",
								textAlign: "left",
								paddingBottom: "5px",
							}}
							labelAlign="left"
							rules={[
								{
									required: true,
									message: "Please Select A Supervisor!",
								},
							]}
						>
							<Select
								style={{ fontWeight: "lighter", width: "100%" }}
							>
								{allUsersData["Die Casting"].Supervisor &&
									Object.entries(
										allUsersData["Die Casting"].Supervisor
									).map((e, i) => {
										return (
											<Select.Option
												key={i}
												value={e[1]._id}
											>{`${e[1].firstname} ${e[1].lastname}`}</Select.Option>
										);
									})}
							</Select>
						</Form.Item>
						<Divider />
						<div className="submit-btn">
							<Button type="danger" htmlType="submit">
								Submit
							</Button>
						</div>
					</Form>
				</div>
			</div>
		</>
	) : error ? (
		<div>error while fetching details...</div>
	) : (
		<div>Loading...</div>
	);
};

export default ShiftHandoverForm;
