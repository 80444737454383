import { FormOutlined } from "@ant-design/icons";
import { Button, Tabs, Row, Col, Card } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosApi from "../../api/axiosApi";
import Feedback from "../../components/forms/general_manager/feedback";
// import GetToolMaintenanceForms from "../../components/forms/maintenanceManager/toolMaintenance/getToolMaintenanceForms";
import DelayedLineQrci from "../../components/forms/QRCI/delayed_line_qrci/delayed_line_qrci";
import SupervisorOJTDashboard from "../supervisor/supervisor_ojt_dashboard";
import "./gm_dashboard.css";

import GmDbProductionStatsDetails from "../../components/common/ProductionStats/GmDbProductionStatsDetails";
import ColorBars from "../../components/common/ColorLamps/colorBars";
import AlertsSummary from "../../components/common/SummaryTables/AlertsSummary";
import AlertsStats from "../../components/common/ProductionStats/AlertsStats";
import QrciSummary from "../../components/common/SummaryTables/QrciSummary";
import QrciStatsTable from "../../components/common/ProductionStats/QrciStatsTable";
import PreventiveMaintenance from "../../components/common/SummaryTables/PreventiveMaintenance";
import PreventiveMaintenanceTable from "../../components/common/ProductionStats/PreventiveMaintenanceTable";

const { TabPane } = Tabs;

const GeneralManagerDashboard = (props) => {
  const [status, getStatus] = useState([]);
  const [statusDC, getStatusDC] = useState([]);
  // const status = [];
  const [dispData, setdispData] = useState([]);

  const [machineStoppageAlerts, setMachineStoppageAlerts] = useState([]);
  const [machineStoppageAlert, setMachineStoppageAlert] = useState([]);
  const [machineInfo, setMachineInfo] = useState([]);

  const [totalInternalNcc, setTotalInternalNcc] = useState(0);
  const [totalThroughput, setTotalThroughput] = useState(0);
  const [totalDIH, setTotalDIH] = useState(0);

  const [plantQrci, setPlantQrci] = useState([]);
  const [lineQrci, setLineQrci] = useState([]);
  const [QrciDept, setQrciDept] = useState(0);

  const [preventiveMaint, setPreventiveMaint] = useState([]);

  const [QtyAlertsVisibility, setQtyAlertsVisibility] = useState(false);
  const [StopAlertsVisibility, setStopAlertsVisibility] = useState(false);
  const [QrciVisibility, setQrciVisibility] = useState(false);
  const [PrvMaintVisibility, setPrvMaintVisibility] = useState(false);

  var redAlertsDC = 0;
  const [totalDCDIH, setTotalDCDIH] = useState(0);
  const [totalInternalNccDC, setTotalInternalNccDC] = useState(0);
  const [totalThroughputDC, setTotalThroughputDC] = useState(0);
  let majorBreakdownDC = [];

  var redAlerts = 0;
  let majorBreakdown = [];

  for (let i = 0; i < machineStoppageAlerts.length; i++) {
    // console.log(machineStoppageAlerts)
    if (
      machineStoppageAlerts[i].data.msg === "Breakdown" &&
      (machineStoppageAlerts[i].department === "injectionMoulding" ||
        machineStoppageAlerts[i].department === "Production")
    ) {
      majorBreakdown.push(machineStoppageAlerts[i]);
    }
  }
  for (let i = 0; i < machineStoppageAlerts.length; i++) {
    // console.log(machineStoppageAlerts)
    if (
      machineStoppageAlerts[i].data.msg === "Breakdown" &&
      machineStoppageAlerts[i].department === "Die Casting"
    ) {
      majorBreakdownDC.push(machineStoppageAlerts[i]);
    }
  }

  const changeDataFunc = () => {
    setQtyAlertsVisibility(!QtyAlertsVisibility);
    setStopAlertsVisibility(false);
    setQrciVisibility(false);
    setPrvMaintVisibility(false);
  };

  const makeStopAlertsVisible = (data) => {
    setStopAlertsVisibility(true);
    setQtyAlertsVisibility(false);
    setQrciVisibility(false);
    setPrvMaintVisibility(false);

    const modData = data.map((item) => {
      let alert = item.color;
      let machine_details = machineInfo.filter((machineItem) => {
        return machineItem._id === item.machineId;
      })[0];
      let machine_name;
      if (item.type === "external_qrci") {
        machine_name = "External Qrci Alerts";
      } else {
        if (machine_details !== undefined) {
          machine_name = machine_details.name;
        } else {
          machine_name = "Name Not Found in DB";
        }
      }
      let machine_msg = item.data.msg;
      let id = item.id;
      return { machine_name, machine_msg, alert, id };
    });

    setMachineStoppageAlert(modData);
    // console.log(data);
  };

  const makeQrciVisible = (dept) => {
    setQrciDept(dept);
    setStopAlertsVisibility(false);
    setQtyAlertsVisibility(false);
    setQrciVisibility(true);
    setPrvMaintVisibility(false);
  };

  const makePmVisible = (dept) => {
    setQrciDept(dept);
    setStopAlertsVisibility(false);
    setQtyAlertsVisibility(false);
    setQrciVisibility(false);
    setPrvMaintVisibility(true);
  };

  const [isLoading, setLoading] = useState(true);
  // const [error, setError] = useState(false);
  // const [prodValues, setProdValues] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch machine info and wait for it to complete
        await getMachineInfoRequest();

        // Once machine info is available, fetch other data
        await qtyData();
        await getMachineStoppageAlerts();
        await getQASupervisorFb();
        await getPlantQrciData();

        // Now that machine info is available, fetch LineQrciData
        await getLineQrciData();

        // Fetch other data that doesn't depend on machine info
        getPrivativeMaint();
        getMaintenanceStatus();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMaintenanceStatus = async () => {
    await axiosApi
      .get(`/production/injectionMoulding/getMachineMaintScheduleDetails`)
      .then((response) => {
        const data = response.data.data;
        // console.log("maint schedule data",data)
        getStatus(() => data);
      })
      .catch((error) => {
        console.log(`ERRORS : ${error}`);
        alert(`Error:"${error}`);
      });

    await axiosApi
      .get(`/production/die_casting/getMachineMaintScheduleDetails`)
      .then((response) => {
        const data = response.data.data;
        // console.log("maint schedule data",data)
        getStatusDC(() => data);
      })
      .catch((error) => {
        console.log(`ERRORS : ${error}`);
        alert(`Error:"${error}`);
      });
  };

  const qtyData = async () => {
    try {
      const dieCastingRes = await axiosApi.get(
        `production/die_casting/getProductionQtyAlerts`
      );
      const injectionMouldingRes = await axiosApi.get(
        `production/injectionMoulding/getProductionQtyAlerts`
      );
      //console.log("get diecasting data: ", dieCastingRes)
      const dieCastingData = processData(
        dieCastingRes.data.data,
        "Die Casting"
      );
      const injectionMouldingData = processData(
        injectionMouldingRes.data.data,
        "Injection Moulding"
      );
      //console.log("Die-casting data: ", dieCastingData)
      //console.log("Injection moulding data: ", injectionMouldingData)

      const finalAlertsData = [injectionMouldingData, dieCastingData];

      setdispData(finalAlertsData);
      //console.log("alerts: ", finalAlertsData);

      if (isLoading) {
        setLoading(false);
      }
    } catch (err) {
      console.error(`ERROR: ${err}`);
      setLoading(false);
      // setError(true);
    }
  };

  const processData = (data, machineType) => {
    return data.map((item, id) => {
      const actual_qty = item.actual_qty;
      const planned_qty = item.planned_qty;
      const name = item.machine_id.name;

      const difference = item.planned_qty - item.actual_qty;

      let percentage;
      if (difference > 150) {
        percentage = "Red";
      } else if (90 < difference && difference < 150) {
        percentage = "Orange";
      } else if (60 < difference && difference < 90) {
        percentage = "Yellow";
      } else {
        percentage = "Green";
      }

      return {
        id,
        name,
        actual_qty,
        planned_qty,
        difference,
        percentage,
        machineType,
      };
    });
  };

  // const getMachineStoppageAlerts = async () => {
  //   await axiosApi
  //     .get(`production/injectionMoulding/getAllAlerts`)
  //     .then((response) => {
  //       const alertsData = response.data.data;
  //       const modAlertsData = alertsData.map((item, idx) => {
  //         let color = item.color;
  //         let data = item.data;
  //         let id = idx;
  //         let department = item.department;
  //         let machineId;
  //         if (item.machineId) {
  //           machineId = item.machineId;
  //         }

  //         return { data, color, id, machineId, department };
  //       });
  //       setMachineStoppageAlerts(modAlertsData);
  //       // console.log(modAlertsData);
  //     })
  //     .catch((error) => {
  //       console.log(`ERRORS : ${error}`);
  //       alert(`Error:"${error}`);
  //     });

  //   await axiosApi
  //     .get(`production/die_casting/getAllAlerts`)
  //     .then((response) => {
  //       const alertsData = response.data.data;
  //       //console.log(response.data)
  //       const modAlertsData = alertsData.map((item, idx) => {
  //         let color = item.color;
  //         let data = item.data;
  //         let id = idx;
  //         let department = item.department;
  //         let machineId;
  //         if (item.machineId) {
  //           machineId = item.machineId;
  //         }

  //         return { data, color, id, machineId, department };
  //       });
  //       modAlertsData.map((item) => {
  //         setMachineStoppageAlerts((prevAlerts) => [...prevAlerts, item]);
  //       });
  //       // console.log(modAlertsData);
  //     })
  //     .catch((error) => {
  //       console.log(`ERRORS : ${error}`);
  //       alert(`Error:"${error}`);
  //     });
  // };
  const getMachineStoppageAlerts = async () => {
    await axiosApi
      .get(`production/injectionMoulding/getAllAlerts`)
      .then((response) => {
        const alertsData = response.data.data;
        const modAlertsData = alertsData.map((item, idx) => {
          let color = item.color;
          let data = item.data;
          let id = idx;
          let department = item.department;
          let machineId;
          if (item.machineId) {
            machineId = item.machineId;
          }

          return { data, color, id, machineId, department };
        });
        setMachineStoppageAlerts(modAlertsData);
      })
      .catch((error) => {
        console.log(`ERRORS : ${error}`);
        alert(`Error:"${error}`);
      });

    await axiosApi
      .get(`production/die_casting/getAllAlerts`)
      .then((response) => {
        const alertsData = response.data.data;
        // console.log(response.data)
        const modAlertsData = alertsData.map((item, idx) => {
          let color = item.color;
          let data = item.data;
          let id = idx;
          let department = item.department;
          let machineId;
          if (item.machineId) {
            machineId = item.machineId;
          }

          return { data, color, id, machineId, department };
        });

        // Use spread operator to append items to the array
        setMachineStoppageAlerts((prevAlerts) => [
          ...prevAlerts,
          ...modAlertsData,
        ]);
      })
      .catch((error) => {
        console.log(`ERRORS : ${error}`);
        alert(`Error:"${error}`);
      });
  };

  const getMachineInfoRequest = async () => {
    await axiosApi
      .get(`production/injectionMoulding/getMachineInformation`)
      .then((response) => {
        const machineInf = response.data.data;
        //console.log()
        setMachineInfo(machineInf);
      })
      .catch((error) => {
        console.log(`ERRORS : ${error}`);
        alert(`Error:"${error}`);
      });

    await axiosApi
      .get(`production/die_casting/getMachineInformation`)
      .then((response) => {
        const machineInf = response.data.data;
        machineInf.forEach((item) => {
          setMachineInfo((prevMachines) => [...prevMachines, item]);
        });
      })
      .catch((error) => {
        console.log(`ERRORS : ${error}`);
        alert(`Error:"${error}`);
      });

    //console.log("machine data is = ", machineInfo)
  };

  const getPlantQrciData = async () => {
    await axiosApi
      .get(`production/injectionMoulding/getPlantQrciForm`)
      .then((response) => {
        const plantQrciData = response.data.data;
        //console.log("mac info = ",machineInfo)
        const modPlantQrciData = plantQrciData.map((item, index) => {
          let problem_as_per_operator = item.problem_as_per_operator;
          let id = index;
          //console.log("item = ", item.machineId)
          let machine_details = machineInfo.filter((machineItem) => {
            return machineItem._id === item.machineId;
          })[0];
          let machine_name;
          if (item.externally_created === true) {
            machine_name = "External QRCI";
          } else {
            if (machine_details !== undefined) {
              machine_name = machine_details.name;
            } else {
              // console.log(machineInfo)
              machine_name = "Name Not Found in DB";
            }
          }
          let comment_from_operator = item.comment_from_operator;
          return {
            id,
            machine_name,
            problem_as_per_operator,
            comment_from_operator,
          };
        });

        setPlantQrci((prevData) => [...prevData, modPlantQrciData]);
      })
      .catch((error) => {
        console.log(`ERRORS : ${error}`);
        alert(`Error:"${error}`);
      });

    await axiosApi
      .get(`production/die_casting/getPlantQrciForm`)
      .then((response) => {
        const plantQrciData = response.data.data;
        //console.log("mac info = ",machineInfo)
        const modPlantQrciData = plantQrciData.map((item, index) => {
          let problem_as_per_operator = item.problem_as_per_operator;
          let id = index;
          //console.log("item = ", item.machineId)
          let machine_details = machineInfo.filter((machineItem) => {
            return machineItem._id === item.machineId;
          })[0];
          let machine_name;
          if (item.externally_created === true) {
            machine_name = "External QRCI";
          } else {
            if (machine_details !== undefined) {
              machine_name = machine_details.name;
            } else {
              // console.log(machineInfo)
              machine_name = "Name Not Found in DB";
            }
          }
          let comment_from_operator = item.comment_from_operator;
          return {
            id,
            machine_name,
            problem_as_per_operator,
            comment_from_operator,
          };
        });

        setPlantQrci((prevData) => [...prevData, modPlantQrciData]);
      })
      .catch((error) => {
        console.log(`ERRORS : ${error}`);
        alert(`Error:"${error}`);
      });
  };

  const getLineQrciData = async () => {
    await axiosApi
      .get(`production/injectionMoulding/getLineQrciForm`)
      .then((response) => {
        const lineQrciData = response.data.data;
        //console.log("mac info = ",machineInfo)
        const modLineQrciData = lineQrciData.map((item, index) => {
          let problem_as_per_operator = item.problem_as_per_operator;
          let id = index;
          //console.log("item = ", item.machineId)
          let current_status = item.current_status;
          let completed_7_days = item.completed_7_days;
          let date_time = item.date_time;
          let machine_details = machineInfo.filter((machineItem) => {
            return machineItem._id === item.machineId;
          })[0];
          let machine_name;

          if (item.externally_created === true) {
            machine_name = "External QRCI";
          } else {
            if (machine_details !== undefined) {
              machine_name = machine_details.name;
            } else {
              machine_name = "Name Not Found in DB";
            }
          }
          let comment_from_operator = item.comment_from_operator;
          return {
            id,
            machine_name,
            problem_as_per_operator,
            comment_from_operator,
            current_status,
            completed_7_days,
            date_time
          };
        });

        setLineQrci((prevData) => [...prevData, modLineQrciData]);
      })
      .catch((error) => {
        console.log(`ERRORS : ${error}`);
        alert(`Error:"${error}`);
      });

    await axiosApi
      .get(`production/die_casting/getLineQrciForm`)
      .then((response) => {
        const lineQrciData = response.data.data;
        //console.log("mac info = ",machineInfo)
        const modLineQrciData = lineQrciData.map((item, index) => {
          let problem_as_per_operator = item.problem_as_per_operator;
          let id = index;
          //console.log("item = ", item.machineId)
          let current_status = item.current_status;
          let completed_7_days = item.completed_7_days;
          let date_time = item.date_time;
          let machine_details = machineInfo.filter((machineItem) => {
            return machineItem._id === item.machineId;
          })[0];
          let machine_name;

          if (item.externally_created === true) {
            machine_name = "External QRCI";
          } else {
            if (machine_details !== undefined) {
              machine_name = machine_details.name;
            } else {
              machine_name = "Name Not Found in DB";
            }
          }
          let comment_from_operator = item.comment_from_operator;
          return {
            id,
            machine_name,
            problem_as_per_operator,
            comment_from_operator,
            current_status,
            completed_7_days,
            date_time
          };
        });

        setLineQrci((prevData) => [...prevData, modLineQrciData]);
      })
      .catch((error) => {
        console.log(`ERRORS : ${error}`);
        alert(`Error:"${error}`);
      });
  };

  redAlerts = machineStoppageAlerts.filter((item) => {
    return (
      item.color === "Red" &&
      (item.department === "injectionMoulding" ||
        item.department === "Production")
    );
  });
  redAlertsDC = machineStoppageAlerts.filter((item) => {
    return item.color === "Red" && item.department === "Die Casting";
  });

  // console.log(redAlerts)

  const getQASupervisorFb = async () => {
    await axiosApi
      .get(`production/injectionMoulding/getQASupervisorFeedback`)
      .then((response) => {
        const QASupervisorFbData = response.data.data.qaSupervisorFbs;
        const ProductionManagerData = response.data.data.ProductionManagerData;

        // console.log(QASupervisorFbData);
        let local_total_ncc = 0;
        for (let i = 0; i < QASupervisorFbData.length; i++) {
          local_total_ncc += QASupervisorFbData[i].total_internal_ncc;
        }
        setTotalInternalNcc(local_total_ncc);

        let local_total_throughput = 0;
        let local_total_dih = 0;
        for (let i = 0; i < ProductionManagerData.length; i++) {
          local_total_throughput +=
            ProductionManagerData[i].total_throughput_data;
          local_total_dih += ProductionManagerData[i].dih;
        }
        setTotalThroughput(local_total_throughput);
        setTotalDIH(local_total_dih);

        // console.log(totalInternalNcc);
      })
      .catch((error) => {
        console.log(`ERRORS : ${error}`);
        alert(`Error:"${error}`);
      });

    await axiosApi
      .get(`production/die_casting/getQASupervisorFeedback`)
      .then((response) => {
        const QASupervisorFbData = response.data.data.qaSupervisorFbs;
        const ProductionManagerData = response.data.data.ProductionManagerData;

        // console.log(QASupervisorFbData);
        let local_total_ncc = 0;
        for (let i = 0; i < QASupervisorFbData.length; i++) {
          local_total_ncc += QASupervisorFbData[i].total_internal_ncc;
        }
        setTotalInternalNccDC(local_total_ncc);

        let local_total_throughput = 0;
        let local_total_dih = 0;
        for (let i = 0; i < ProductionManagerData.length; i++) {
          local_total_throughput +=
            ProductionManagerData[i].total_throughput_data;
          local_total_dih += ProductionManagerData[i].dih;
        }
        setTotalThroughputDC(local_total_throughput);
        setTotalDCDIH(local_total_dih);

        // console.log(totalInternalNcc);
      })
      .catch((error) => {
        console.log(`ERRORS : ${error}`);
        alert(`Error:"${error}`);
      });
  };

  const getPrivativeMaint = async () => {
    await axiosApi
      .get(
        `production/injectionMoulding/getAllMachineMaintainenceDataByManager`
      )
      .then((response) => {
        const PmData = response.data.data;
        const modPmData = PmData.map((item) => {
          let maintData_0 = item.maintData;
          let maintData = maintData_0.map((item, idx2) => {
            let checkPoint = item.checkPoint;
            let maintainedAt = item.maintainedAt;
            let maintType = item.maintType;
            let idx = idx2;
            return { checkPoint, maintainedAt, maintType, idx };
          });
          let machineName = item.machineName;
          return { machineName, maintData };
        });
        setPreventiveMaint((prevData) => [...prevData, modPmData]);
        //console.log(modPmData)
        // console.log(PmData);
      })
      .catch((error) => {
        console.log(`ERRORS : ${error}`);
        alert(`Error:"${error}`);
      });

    await axiosApi
      .get(`production/die_casting/getAllMachineMaintainenceDataByManager`)
      .then((response) => {
        const PmData = response.data.data;
        const modPmData = PmData?.map((item) => {
          let maintData_0 = item.maintData;
          let maintData = maintData_0.map((item, idx2) => {
            let checkPoint = item.maint_info;
            let maintainedAt = item.maintDate;
            let maintType = item.maintType;
            let idx = idx2;
            return { checkPoint, maintainedAt, maintType, idx };
          });
          let machineName = item.machineName;
          return { machineName, maintData };
        });
        setPreventiveMaint((prevData) => [...prevData, modPmData]);
        //console.log(modPmData)
        //console.log(modData);
      })
      .catch((error) => {
        console.log(`ERRORS : ${error}`);
        alert(`Error:"${error}`);
      });
  };

  return (
    <>
      <h1>General Manager Dashboard</h1>
      <Tabs
        defaultActiveKey="1"
        tabPosition="left"
        size="large"
        style={{ marginTop: "5%" }}
      >
        <TabPane
          tab={
            <span>
              <FormOutlined />
              Stats
            </span>
          }
          key="1"
        >
          <ColorBars
            changeDataFunc={changeDataFunc}
            alertsData={dispData}
            type="prodQtyAlerts"
          />

          <div className="gm-dashboard">
            <div className="left-side">
              <div className="summary-tables">
                <AlertsSummary
                  makeStopAlertsVisible={makeStopAlertsVisible}
                  machineStoppageAlerts={machineStoppageAlerts}
                  machineInfo={machineInfo}
                />
                <div className="operations-kpi">
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="6">Operations KPI</th>
                      </tr>
                      <tr>
                        <th>Sr.no</th>
                        <th>KPI</th>
                        <th>Moulding</th>
                        <th>Die Casting</th>
                        <th>Paint</th>
                        <th>Assembly</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td id="l-title">Red Alert</td>
                        <td>
                          <button
                            onClick={() => {
                              makeStopAlertsVisible(redAlerts);
                            }}
                          >
                            {redAlerts.length}{" "}
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              makeStopAlertsVisible(redAlertsDC);
                            }}
                          >
                            {redAlertsDC.length}{" "}
                          </button>
                        </td>
                        <td>
                          <button></button>
                        </td>
                        <td>
                          <button></button>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td id="l-title">DIH</td>
                        <td>
                          <p>{totalDIH}</p>
                        </td>
                        <td>
                          <p>{totalDCDIH}</p>
                        </td>
                        <td>
                          <button></button>
                        </td>
                        <td>
                          <button></button>
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td id="l-title">NCC cost total</td>
                        <td>
                          <p>{totalInternalNcc}</p>
                        </td>
                        <td>
                          <p>{totalInternalNccDC}</p>
                        </td>
                        <td>
                          <p></p>
                        </td>
                        <td>
                          <button></button>
                        </td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td id="l-title">Through put</td>
                        <td>
                          <p>{totalThroughput}</p>
                        </td>
                        <td>
                          <p>{totalThroughputDC}</p>
                        </td>
                        <td>
                          <button></button>
                        </td>
                        <td>
                          <button></button>
                        </td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td id="l-title">Major break down</td>
                        <td>
                          <button
                            onClick={() => {
                              makeStopAlertsVisible(majorBreakdown);
                            }}
                          >
                            {majorBreakdown.length}
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              makeStopAlertsVisible(majorBreakdownDC);
                            }}
                          >
                            {" "}
                            {majorBreakdownDC.length}{" "}
                          </button>
                        </td>
                        <td>
                          <button></button>
                        </td>
                        <td>
                          <button></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="right-side">
              <QrciSummary
                makeQrciVisible={makeQrciVisible}
                plantQrciData={plantQrci}
                lineQrciData={lineQrci}
              />
              <PreventiveMaintenance
                makePmVisible={makePmVisible}
                PmData={preventiveMaint}
              />
            </div>
          </div>

          <GmDbProductionStatsDetails
            data={dispData}
            isVisible={QtyAlertsVisibility}
            dept={QrciDept}
          />
          <AlertsStats
            data={machineStoppageAlert}
            isVisible={StopAlertsVisibility}
          />
          <QrciStatsTable
            plantQrciData={plantQrci}
            lineQrciData={lineQrci}
            isVisible={QrciVisibility}
            dept={QrciDept}
          />

          <PreventiveMaintenanceTable
            PmData={preventiveMaint}
            isVisible={PrvMaintVisibility}
            dept={QrciDept}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <FormOutlined />
              Injection Moulding Data
            </span>
          }
          key="2"
        >
          <Button type="primary">
            <Link
              to={{
                pathname: "/general-manager/production-manager-form",
              }}
            >
              View Form
            </Link>
          </Button>
        </TabPane>

        <TabPane
          tab={
            <span>
              <FormOutlined />
              Die-Casting Data
            </span>
          }
          key="8"
        >
          <Button type="primary">
            <Link
              to={{
                pathname: "/general-manager/dc-production-manager-form",
              }}
            >
              View Form
            </Link>
          </Button>
        </TabPane>

        <TabPane
          tab={
            <span>
              <FormOutlined />
              Line & Plant Qrci
            </span>
          }
          key="3"
        >
          <Button type="primary">
            <Link to={{ pathname: "/lineqrci/all" }}>Line QRCI</Link>
          </Button>
          <Button type="primary" style={{ marginLeft: 50 }}>
            <Link to={{ pathname: "/plantqrci/all" }}>Plant QRCI</Link>
          </Button>
          <div>
            <DelayedLineQrci />
          </div>
        </TabPane>
        <TabPane
          tab={
            <span>
              <FormOutlined />
              Training
            </span>
          }
          key="4"
        >
          <SupervisorOJTDashboard />
        </TabPane>
        <TabPane
          tab={
            <span>
              <FormOutlined />
              Feedback
            </span>
          }
          key="5"
        >
          <Feedback />
        </TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <FormOutlined />
              Machine Maintenance
            </span>
          }
          key="6"
        >
          <div className="site-card-wrapper">
            <Row gutter={16}>
              <Col span={6}>
                <Card
                  title="INJECTION MOULDING"
                  bordered={false}
                  style={{ borderRadius: "10px" }}
                  headStyle={{
                    backgroundColor: "#FFA500",
                    borderRadius: "10px",
                    color: "#ffffff",
                  }}
                  raised="true"
                >
                  <Row>
                    <Col span={12}>
                      <p>Machines Pending</p>
                    </Col>
                    <Col span={12}>
                      <p>{status.upcomingMaint}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <p>Machines Done</p>
                    </Col>
                    <Col span={12}>
                      <p>{status.completedMaint}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      {" "}
                      <Link
                        to={{
                          pathname: `/machine-maintenance-manager/injectionMoulding`,
                          state: {},
                        }}
                      >
                        View Machines
                      </Link>
                    </Col>
                    <Col span={12}>
                      {" "}
                      <Link
                        to={{
                          pathname: `/maintenance-schedule/injectionMoulding`,
                          state: {},
                        }}
                      >
                        View Status
                      </Link>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  title="DIE CASTING"
                  bordered={false}
                  style={{ borderRadius: "10px" }}
                  headStyle={{
                    backgroundColor: "#FFA500",
                    borderRadius: "10px",
                    color: "#ffffff",
                  }}
                  raised="true"
                >
                  <Row>
                    <Col span={12}>
                      <p>Machines Pending</p>
                    </Col>
                    <Col span={12}>
                      <p>{statusDC.upcomingMaint}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <p>Machines Done</p>
                    </Col>
                    <Col span={12}>
                      <p>{statusDC.completedMaint}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      {" "}
                      <Link
                        to={{
                          pathname: `/machine-maintenance-manager/die_casting`,
                          state: {},
                        }}
                      >
                        View Machines
                      </Link>
                    </Col>
                    <Col span={12}>
                      {" "}
                      <Link
                        to={{
                          pathname: `/maintenance-schedule/die_casting`,
                          state: {},
                        }}
                      >
                        View Status
                      </Link>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  title="PAINT SHOP"
                  bordered={false}
                  style={{ borderRadius: "10px" }}
                  headStyle={{
                    backgroundColor: "#FFA500",
                    borderRadius: "10px",
                    color: "#ffffff",
                  }}
                  raised="true"
                >
                  <Link
                    to={{
                      pathname: `/machines/`,
                      state: {},
                    }}
                  >
                    View Machines
                  </Link>
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  title="ASSEMBLY"
                  bordered={false}
                  style={{ borderRadius: "10px" }}
                  headStyle={{
                    backgroundColor: "#FFA500",
                    borderRadius: "10px",
                    color: "#ffffff",
                  }}
                  raised="true"
                >
                  <Link
                    to={{
                      pathname: `/machines/`,
                      state: {},
                    }}
                  >
                    View Machines
                  </Link>
                </Card>
              </Col>
            </Row>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <FormOutlined />
              Tool Maintenance
            </span>
          }
          key="7"
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div className="site-card-wrapper">
              <Row gutter={16}>
                <Col span={6}>
                  <Card
                    title="INJECTION MOULDING"
                    bordered={false}
                    style={{ borderRadius: "10px" }}
                    headStyle={{
                      backgroundColor: "#FFA500",
                      borderRadius: "10px",
                      color: "#ffffff",
                    }}
                    raised="true"
                  >
                    <Link
                      to={{
                        pathname: `/general-manager-tool-maintenance-forms/injectionMoulding`,

                        state: {},
                      }}
                    >
                      Tool Maintenance
                    </Link>
                  </Card>
                </Col>
                <Col span={6}>
                  <Card
                    title="DIE CASTING"
                    bordered={false}
                    style={{ borderRadius: "10px" }}
                    headStyle={{
                      backgroundColor: "#FFA500",
                      borderRadius: "10px",
                      color: "#ffffff",
                    }}
                    raised="true"
                  >
                    <Link
                      to={{
                        pathname: `/general-manager-tool-maintenance-forms/die_casting`,
                        state: {},
                      }}
                    >
                      Tool Maintenance
                    </Link>
                  </Card>
                </Col>
                <Col span={6}>
                  <Card
                    title="PAINT SHOP"
                    bordered={false}
                    style={{ borderRadius: "10px" }}
                    headStyle={{
                      backgroundColor: "#FFA500",
                      borderRadius: "10px",
                      color: "#ffffff",
                    }}
                    raised="true"
                  >
                    <Link
                      to={{
                        //pathname: `/machine-maintenance-manager/injectionMoulding`,
                        state: {},
                      }}
                    >
                      Tool Maintenance
                    </Link>
                  </Card>
                </Col>
                <Col span={6}>
                  <Card
                    title="ASSEMBLY"
                    bordered={false}
                    style={{ borderRadius: "10px" }}
                    headStyle={{
                      backgroundColor: "#FFA500",
                      borderRadius: "10px",
                      color: "#ffffff",
                    }}
                    raised="true"
                  >
                    <Link
                      to={{
                        //pathname: `/machine-maintenance-manager/injectionMoulding`,
                        state: {},
                      }}
                    >
                      Tool Maintenance
                    </Link>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default GeneralManagerDashboard;
