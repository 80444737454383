import { Button, Col, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import axiosApi from '../../../api/axiosApi';
import { getDepartments } from '../../../redux/dropDownData/dropDownDataSlice';

const Feedback = () => {

    const [dept,setDept] = useState();
    const [feedback,setFeedBack] = useState('');
    const deptData = useSelector(getDepartments);

    const onSubmit = () => {
        if(!dept){
            return toast.error("🚀 Select a department first!!", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
            });
        }
        if(!feedback){
            return toast.error("Please Enter Feedback", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
            });
        }
        console.log('fsd')
        axiosApi.post('production/injectionMoulding/submitFeedback', {
                dept: dept,
                feedback: feedback
            })
            .then(res => {
                if(res.data.err){
                    toast.error("🚀 Error while submitting feedback!!", {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        progress: undefined,
                    });  
                }else{
                    toast.success("🚀 Feedback Submitted Successfully", {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        progress: undefined,
                    });
                    setDept();
                    setFeedBack('');
                }
            })
            .catch(err => {
                toast.error("🚀 Error while submitting feedback!!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                });  
                console.log('err',err);
            })   
     };

    return (
        <div>
            <ToastContainer />
            <h2>Give your Feedback</h2>
            <Row style={{marginTop: '2%'}}>
                <Col span={4}>Select a department: </Col>
                <Col span={6}>
                    <Select onChange={(value) => setDept(value)} style={{width: 170}} value={dept}>
                        {deptData && deptData.map((item,index) => {
                            return (
                                <Select.Option value={item[1]}>{item[1]}</Select.Option>
                            )
                        })}
                    </Select>
                </Col>
            </Row>
            
            <Row style={{marginTop: "3%",marginLeft: '2%'}}>
                <TextArea 
                    onChange={(e) => setFeedBack(e.target.value)}
                    style={{width: "90%"}}
                    rows={6}
                    placeholder="Enter your feedback here"
                    value={feedback}
                    
                />
            </Row>
            <Row style={{marginTop: "3%"}}>
                <Col span={22}>
                    <Button type="primary" onClick={onSubmit}>Submit</Button>
                </Col>
            </Row>
            
        </div>
    )
}

export default Feedback;