import { React, useState, useEffect } from "react";

import { Table, Space } from "antd";

import axios from "../../../../api/axiosApi";
import { Link } from "react-router-dom";

const AllShiftHandoverForms = () => {
  const [allHandOverForms, getallHandOverForms] = useState([]);
  useEffect(() => {
    const fetchAllHandoverForms = async () => {
      await axios
        .get("production/injectionMoulding/getShiftHandoverform")
        .then((response) => {
          console.log("Response:", response);
          const allforms = response.data.data;
          console.log("Form data", allforms.length);
          getallHandOverForms(() => allforms);
        })
        .catch((error) => console.log(`ERRORS : ${error}`));
    };
    fetchAllHandoverForms();
  }, []);

  const columns = [
    {
      title: "Machine Name",
      dataIndex: "machineName",
      key: "machineName",
    },

    {
      title: "Part Name",
      dataIndex: "partName",
      key: "partName",
    },
    {
      title: "Operator Name",
      dataIndex: "operatorName",
      key: "operatorName",
    },
    {
      title: "Shift",
      dataIndex: "shift",
      key: "shift",
    },

    {
      title: "Take Up ",
      key: "handOverFormId",
      render: (text, record) => (
        <Space size="middle">
          <Link
            to={{
              pathname: "/prefill-shift-handover-form",
              state: {
                handOverFormId: record.action,
              },
            }}
          >
            View
          </Link>
        </Space>
      ),
    },
  ];

  const handOverForms = [];

  allHandOverForms.forEach((form) => {
    handOverForms.push({
      key: form._id,
      machineName: form.machine_no,
      operatorName: form.operator_name,
      shift: form.shift,
      partName: form.part_no,
      handOverFormId: form._id,
    });
  });

  return (
    <div>
      <h2>All Shift Handover Forms</h2>

      <div style={{ margin: "25px", padding: "25px", border: "2px solid red" }}>
        <Table columns={columns} dataSource={handOverForms} />
      </div>
    </div>
  );
};

export default AllShiftHandoverForms;
