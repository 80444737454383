import { Table } from 'antd';
import React, { useEffect, useState } from "react";
import { Typography } from 'antd';

const { Text } = Typography;

const columns = [
  {
    title: 'Machine Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Target Production',
    dataIndex: 'planned_qty',
    key: 'target',
  },
  {
    title: 'Actual Production',
    dataIndex: 'actual_qty',
    key: 'actual',
  },
  {
    title: 'Difference',
    key: 'difference',
    dataIndex: 'difference',
  },
	{
    title: 'Action',
    dataIndex: 'percentage',
    key: 'action',
		render: percentage => {
			
			return (
				<div>
					{percentage ==='Red' && <Text level={3} type="danger">Red Alert</Text>}
					{percentage ==='Orange' && <Text level={3} type={"warning"}>Orange Alert</Text>}
					{percentage ==='Yellow' && <Text level={3} style={{color:'#ebbd34'}}>Yellow Alert</Text>}
					{percentage ==='Green' && <Text level={3} type='success'>All Good</Text>}
				</div>
			);
		}
  },
  // {
  //   title: 'MachineType',
  //   key: 'machineType',
  //   dataIndex: 'MachineType',
  // },
];


const ProductionStatsDetails = ({data,isVisible, dept}) => {

  
	const [isLoading, setLoading] = useState(true);
	const [prodValues, setProdValues] = useState([]);

	useEffect(() => {
    if(data){
      //console.log("foughewiougfhwe",data)
			setLoading(false);
      
			setProdValues(data[dept]);
		}
	},[data, dept])

  if(data && isVisible){
    console.log("hethyendfejhgfuge",prodValues)
  
    if(data[dept].length){
      return (
        <Table rowKey="id" columns={columns} dataSource={data[dept]} loading={isLoading}/>
      )
    }
    else{
      return(
        <p>No Data Found</p>
      )
    }
  }
  else{
    return <></>
  }
}
export default ProductionStatsDetails;