import {  Table } from 'antd';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import axiosApi from '../../../api/axiosApi';

const FeedbackTable = ({title,from}) => {

  const [feedbacks,setFeedbacks] = useState([]);
  const [loading,setLoading] = useState();
  const [error,setError] = useState();

  const tableColumns = [
    {
      title: "Sr No.",
      key: "_id",
      align: "center",
      fixed: "left",
      render: (text, record, index) => <p>{index + 1}</p>,
    },
    {
      title: "From",
      dataIndex: "firstname",
      align: "center",
      width: "10%",
      render: (text,record) => <p>{`${record?.from?.firstname} ${record?.from?.lastname}`}</p>,
    },
    {
      title: "Date & time",
      dataIndex: "when_created",
      align: "center",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Department",
      dataIndex: "dept",
      align: "center",
    },
    {
      title: "Feedback",
      dataIndex: "feedback",
    }
  ];

  useEffect(() => {
    const getFeedbacks = async () => {
      setLoading(true);
      await axiosApi.get(`production/injectionMoulding/getFeedbacks?from=${from}`)
        .then((res) => {
          console.log('obj',res);
          if(res.data.err){
            setLoading(false);
            setError(true);
          }else{
            let tempData=res.data.data;
            tempData.forEach((item)=>{
              let newDate=new Date(item.when_created);
              newDate.setHours(newDate.getHours()+5);
              newDate.setMinutes(newDate.getMinutes()+30);
              item.when_created=newDate.toISOString().slice(0,16);
            })
            setFeedbacks(res.data.data);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
          console.log('err',err);
        })
    };
    getFeedbacks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !loading ? (
    <div>
      <h2>{`${title} Feedback`}</h2>
      <Table 
        bordered
        dataSource={feedbacks}
        columns={tableColumns}
        pagination={true}
        style={{marginTop:"5%"}}
        scroll={{ x: "max-content" }}
      />
    </div>
  ) : !error ? <div>Loading...</div> : <div>Error while fetching details </div>
}

export default FeedbackTable;