import axios from 'axios';
import { useEffect, useState } from 'react';
import axiosApi from '../api/axiosApi';
import CanvasJSReact from '../canvasjs-3.6.3/canvasjs.react';
//var CanvasJSReact = require('./canvasjs.react');
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function LineChartCompare(props) {
  const [plannedData, setPlannedData] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {   
    graphDataUpdate()
    setInterval(() => {graphDataUpdate()},1000*20)
    // eslint-disable-next-line react-hooks/exhaustive-deps	
  },[])
 
  const graphDataUpdate = async () =>{
    setLoading(true)
    const request1 = axiosApi
        .get(
          `production/${props.department}/getPlannedGraph?machine_id=${props.machineId}`
        )     
    const request2 = axiosApi
        .get(
          `production/${props.department}/getActualGraph?machine_id=${props.machineId}`
        )
        console.log('Response from request 1:', request1);
        console.log('Response from request 2---:', request2);
        await axios.all([request1, request2]).then(axios.spread(function(res1, res2) {
  
          console.log('Response from request 1:', res1.data);
          console.log('Response from request 2:', res2.data);

          let obj = res1.data.data
          // console.log("Obj 1 = ", obj)
         
          let actualQty = 0,plannedQty = 0;

          obj.forEach((item)=>{
            item.dataPoints.forEach((item2)=>{
              item2.x = new Date(new Date(item2.x).toLocaleString('en',{  hourCycle: 'h23' }))
            })
          })
          let dataObj=obj;
          
          let obj2 = res2.data.data;
          console.log("Obj2 = ", obj2)
          if(obj2)
          {
            obj2.forEach((arr)=>{
              if(arr.lengendText!=="A-Running")
              {
                arr.forEach((item)=>{
                  item.dataPoints.forEach((item2)=>{
                    item2.x = new Date(new Date(item2.x).toLocaleString('en',{  hourCycle: 'h23' }))
                  })
                })
              }
              else
              {
                arr.forEach((item)=>{
                  item.dataPoints.forEach((item2)=>{
                    item2.x = new Date(new Date(item2.x).toLocaleString('en',{  hourCycle: 'h23' }))
                  })
                }) 
                arr.forEach(item => {
                  item.dataPoints.forEach(ele => {
                    plannedQty += ele.y;
                  })
                })
              }
              dataObj=dataObj.concat(arr)
            })
          }
          // if(obj2 !== undefined){
          // obj2.forEach((item)=>{
          //   item.dataPoints.forEach((item2)=>{
          //     item2.x = new Date(new Date(item2.x).toLocaleString('en',{  hourCycle: 'h23' }))
          //   })
          // })}
          
          //console.log(obj2)
          let colorArray = [];
          for(let i=0;i<obj.length;i++){
            colorArray.push("#00ccff");
          }
          
          // obj.forEach(item => {
          //   item.dataPoints.forEach(ele => {
          //     plannedQty += ele.y;
          //   })
          // })
          
          // if(obj2 !== undefined){
          // obj2.forEach(item => {
          //   item.dataPoints.forEach(ele => {
          //     actualQty += ele.y;
          //   })
          // })}
          // else
          // {
          //   actualQty=0;
          // }
          let percentage = (actualQty / plannedQty) * 100,color = "";
          if(percentage < 70){
            color="red"
          }else if(percentage < 80){
            color="orange"
          }else if( percentage < 90){
            color="yellow"
          }else{
            color="green"
          }
          for(let i=0;i<obj2.length;i++){
            colorArray.push(color);
          }
          CanvasJS.addColorSet("greenShades",colorArray);
          // dataObj = obj.concat(obj2)

          let conditional_date = new Date(Date.now());
  
        //console.log("conditional_date",conditional_date);
        // >=00 <= 07
        //console.log("conditional_date",conditional_date);
        if (conditional_date.getHours() >= 0 && conditional_date.getHours() <= 7) {
          conditional_date.setDate(conditional_date.getDate() - 1);
        }
          let lower_limit_time=new Date(conditional_date);
          lower_limit_time.setHours(7);
          lower_limit_time.setMinutes(0);
          lower_limit_time.setSeconds(0);
      
          let upper_limit_time=new Date();
          upper_limit_time.setDate(conditional_date.getDate()+1);
          upper_limit_time.setHours(7);
          upper_limit_time.setMinutes(0);
          upper_limit_time.setSeconds(0);

          console.log("lower_limit_time",lower_limit_time);
          console.log("lower_limit_time",upper_limit_time);

          const options = {
           // animationEnabled: true,
            exportEnabled: true,
            theme: "light1", // "light1", "dark1", "dark2",
            colorSet: "greenShades",
            title:{
              text: "Planned Vs Actual"
            },
            axisY: {
              title: "Quantity",
              suffix: "",
            },
            axisX: {
              title: "Time",
              prefix: "",
              minimum:lower_limit_time,
              maximum:upper_limit_time,
              
            },
            toolTip: {
              enabled: true,
            },
            data: dataObj
          }
          //console.log("opyion",options)
          // setPlannedData(...plannedData,options)
          setPlannedData(options)
          setLoading(false)       
        }));
  }
  return (
  <div>
   {!loading && <CanvasJSChart options = {plannedData}
      /* onRef={ref => this.chart = ref} */
    />}
    {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
  </div>
  );

}

export default LineChartCompare