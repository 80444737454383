import { Select, Input, Col, Row, Radio, Typography, Card } from "antd";

const MaintenanceHistoryCard = (props) => {
	// const [isApproved, setIsApproved] = useState(props.item.status);
	// const [remark, setRemark] = useState("");
	// const machineId = props.machineId;

	// const submit = (recordId) => {
	// 	const data = {
	// 		machineId,
	// 		status: true,
	// 		recordId,
	// 		managerRemark: remark,
	// 	};

	// 	console.log("Request Data", data);
	// 	axios
	// 		.post(
	// 			"/production/injectionMoulding/submitMaintainenceDataByManager",
	// 			data
	// 		)
	// 		.then(function (response) {
	// 			console.log("response", response);
	// 		})
	// 		.catch(function (error) {
	// 			console.log("Error", error);
	// 			alert(error);
	// 		});
	// };
	return (
		<Card
			title={props.item.checkPoint}
			style={{ boxShadow: "5px 5px 5px 5px grey" }}
			headStyle={{
				backgroundColor:
					props.item.isMaintained === true ? "#29a329" : "#cc0000",
				borderRadius: "10px",
				color: "#ffffff",
			}}
			raised="true"
		>
			<Row
				gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
				style={{ marginBottom: "4%" }}
			>
				<Col className="gutter-row" span={12}>
					Maintenance Type
				</Col>
				<Col className="gutter-row" span={12}>
					<Select
						defaultValue={props.item.maintType}
						style={{ width: 120 }}
						disabled
					>
						<Select.Option value={props.item.maintType}>
							{props.item.maintType}
						</Select.Option>
					</Select>
				</Col>
			</Row>
			<Row
				gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
				style={{ marginBottom: "4%" }}
			>
				<Col className="gutter-row" span={12}>
					Maintenance Frequency
				</Col>
				<Col className="gutter-row" span={12}>
					{props.item.monthly ? (
						<Select
							defaultValue={props.item.monthly}
							style={{ width: 120 }}
							disabled
						>
							<Select.Option value={props.item.monthly}>
								Monthly
							</Select.Option>
						</Select>
					) : props.item.quaterly ? (
						<Select
							defaultValue={props.item.quaterly}
							style={{ width: 120 }}
							disabled
						>
							<Select.Option value={props.item.quaterly}>
								Quarterly
							</Select.Option>
						</Select>
					) : (
						<Select
							defaultValue={props.item.sixMonthly}
							style={{ width: 120 }}
							disabled
						>
							<Select.Option value={props.item.sixMonthly}>
								Half Yearly
							</Select.Option>
						</Select>
					)}
				</Col>
			</Row>
			<Row
				gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
				style={{ marginBottom: "4%" }}
			>
				<Col className="gutter-row" span={24}>
					<Typography.Text>Completed</Typography.Text>

					<Radio.Group
						initialValues="Yes"
						defaultValue={props.item.isMaintained ? "1" : "0"}
						disabled
					>
						<Radio value="1" style={{ margin: "0px 12px" }}>
							Yes
						</Radio>
						<Radio value="0">No</Radio>
					</Radio.Group>
				</Col>
			</Row>
			<Row
				gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
				style={{ marginBottom: "4%" }}
			>
				<Col className="gutter-row" span={24}>
					<Input
						addonBefore="Operator Remark"
						value={props.item.remark}
						disabled
					/>
				</Col>
			</Row>

			<Row
				gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
				style={{ marginBottom: "4%" }}
			>
				<Col className="gutter-row" span={24}>
					<Input
						name="managerRemark"
						addonBefore="Manager Remark"
						value={props.item.managerRemark}
						disabled
					/>
				</Col>
			</Row>

			{/* <Row>
				<Col span={11}>
					<Button
						type="primary"
						shape="round"
						icon={<CheckOutlined />}
						className="action-button approve-btn"
						onClick={() => {
							setIsApproved(true);
							// submit(props.item.id);
						}}
						disabled
					>
						Approve
					</Button>
				</Col>
			</Row> */}
		</Card>
	);
};

export default MaintenanceHistoryCard;
