import { Button, Popconfirm, Table, Tag } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axiosApi from "../../../../api/axiosApi";
import { getAllUsers } from "../../../../redux/dropDownData/dropDownDataSlice";
import { getUser } from "../../../../redux/users/userSlice";

const DelayedLineQrci = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState();
	const userData = useSelector(getUser);
	const allUsersData = useSelector(getAllUsers);

	useEffect(() => {
		setLoading(true);
		axiosApi
			.get("production/injectionMoulding/getDelayedLineQrciForm")
			.then((res) => {
				console.log(res.data.data);
				setData(res.data.data);
				setLoading(false);
			})
			.catch((err) => {
				alert(err);
				setLoading(false);
			});
	}, []);

	const onClickYes = (index) => {
		let receiverIds = [];

		// receiverIds.push({ rId: "6150694d5dc6d35ad8cd0958", isRead: false }); // ruturaj
		// receiverIds.push({ rId: "61d2e8244f1a9afb96c1224c", isRead: false }); // akash1
		// receiverIds.push({ rId: "6150692f5dc6d35ad8cd0953", isRead: false }); //akash

		// dynamic users
		var isPresent = false;
		if (
			allUsersData.Production.Manager &&
			allUsersData.Production.Supervisor &&
			allUsersData.Maintenance.Manager &&
			allUsersData["Tool Room"].Manager
		) {
			isPresent = true;
		}
		if (isPresent) {
			// production managers
			Object.entries(allUsersData.Production.Manager).forEach((e) => {
				receiverIds.push({ rId: e[1]._id, isRead: false });
			});

			// production supervisors
			Object.entries(allUsersData.Production.Supervisor).forEach((e) => {
				receiverIds.push({ rId: e[1]._id, isRead: false });
			});

			//maintenance manager
			Object.entries(allUsersData.Maintenance.Manager).forEach((e) => {
				receiverIds.push({ rId: e[1]._id, isRead: false });
			});

			// tools room manager
			Object.entries(allUsersData["Tool Room"].Manager).forEach((e) => {
				receiverIds.push({ rId: e[1]._id, isRead: false });
			});
		}

		axiosApi
			.post("production/injectionMoulding/generatePlantQrciForm", {
				qrci_ref_no: data[index].qrci_ref_no,
				_id: data[index]._id,
				current_status: data[index].current_status,
				date_time: data[index].date_time,
				what_is_the_problem: data[index].what_is_the_problem,
				dept: data[index].dept,
				machineId: data[index].machineId,
				alert_id: data[index].alert_id,
				senderId: userData._id,
				receiverIds: receiverIds,
			})
			.then((res) => {
				if (res.data.err) {
					toast.error("🚀 Error while updating", {
						position: "bottom-center",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						progress: undefined,
					});
				} else {
					toast.success("🚀 Plant Qrci generated!!", {
						position: "bottom-center",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						progress: undefined,
					});
					setData(res.data.data);
				}
			})
			.catch((err) => {
				toast.error("🚀 Error while updating", {
					position: "bottom-center",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					progress: undefined,
				});
				console.log("err", err);
			});
	};

	const onClickNo = (index) => {
		console.log("eee", index);
		axiosApi
			.post("production/injectionMoulding/cancelPlantQrciForm", {
				_id: data[index]._id,
			})
			.then((res) => {
				if (res.data.err) {
					toast.error("🚀 Error while cancelling", {
						position: "bottom-center",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						progress: undefined,
					});
				} else {
					toast.success("🚀 Plant Qrci generated!!", {
						position: "bottom-center",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						progress: undefined,
					});
					setData(res.data.data);
				}
			})
			.catch((err) => {
				toast.error("🚀 Error while cancelling", {
					position: "bottom-center",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					progress: undefined,
				});
				console.log("err", err);
			});
	};

	const columns = [
		{
			title: "Ref. No.",
			dataIndex: "qrci_ref_no",
			key: "qrci_ref_no",
			fixed: "left",
		},
		{
			title: "Status",
			dataIndex: "current_status",
			key: "current_status",
			render: (current_status) => {
				let color;
				if (current_status === "ongoing") {
					color = "red";
				} else if (current_status === "completed") {
					color = "green";
				} else {
					color = "geekblue";
				}
				return (
					<Tag color={color} key={current_status}>
						{current_status.toUpperCase()}
					</Tag>
				);
			},
		},
		{
			title: "Date & Time",
			dataIndex: "date_time",
			key: "date_time",
			render: (date_time) => {
				return (
					<div>
						{new Date(date_time).toLocaleDateString()}
						<br />
						{new Date(date_time).toLocaleTimeString()}
					</div>
				);
			},
		},
		{
			title: "Problem",
			dataIndex: "what_is_the_problem",
			key: "what_is_the_problem",
		},
		{
			title: "Department",
			dataIndex: "dept",
			key: "dept",
		},
		{
			title: "Generate Plant Qrci?",
			render: (text, record, index) => {
				return (
					<div>
						<Popconfirm
							title="Are you sure you want to generate Plant Qrci form?"
							onConfirm={() => onClickYes(index)}
							okText="Yes"
							cancelText="No"
						>
							<Button
								style={{
									backgroundColor: "green",
									opacity: 0.7,
									color: "white",
									borderColor: "white",
								}}
							>
								Yes
							</Button>
						</Popconfirm>

						<Popconfirm
							title="Are you sure you dont want to generate PLant Qrci form?"
							onConfirm={() => onClickNo(index)}
							okText="Yes"
							cancelText="No"
						>
							<Button type="danger">No</Button>
						</Popconfirm>
					</div>
				);
			},
		},
		{
			title: "View",
			key: "link",
			fixed: "right",
			dataIndex: "qrci_ref_no",
			render: (qrci_ref_no) => (
				<Link to={{ pathname: `/lineqrci/${qrci_ref_no}` }}>View</Link>
			),
		},
	];
	return !loading ? (
		data.length !== 0 ? (
			<div>
				<ToastContainer />
				<h2 style={{ marginTop: 50 }}>
					Line Qrci Forms generated 7 days ago
				</h2>
				<Table
					columns={columns}
					dataSource={data}
					scroll={{ x: "max-content" }}
					style={{ marginTop: 50 }}
				/>
			</div>
		) : (
			<div></div>
		)
	) : (
		<div>Loading...</div>
	);
};

export default DelayedLineQrci;
