import { React, useState, useEffect } from "react";
import { Col, Row } from "antd";
// import { useHistory } from "react-router-dom";
// import { Button } from "antd";
import MaintenanceCard from "./maintenanceCard.js";
import BackButton from "../../../common/BackButton/backButton";
// import EmployeeDetail from "../../common/employeeDetail";
import MaintenanceCardDC from "./maintenanceCardDC.js";

import axios from "../../../../api/axiosApi";

import "../maintenance.css";

const MachineMaintenance = (props) => {
	const [maintData, setMaintData] = useState([]);
	// const history = useHistory();
	const {dept}= props.match.params;
	useEffect(() => {
		const maintenanceData = () => {
			axios
				.get(
					`/production/${dept}/getMachineMaintMetadata?machineID=${props.location.state.machineId}`
				)
				.then(function (response) {
					const resData = response.data.data;
					console.log("Machine Maint. Metadata:", resData);
					setMaintData(() => resData);
				})
				.catch(function (error) {
					console.log(error);
					alert(error);
				});
		};
		maintenanceData();
	}, [props.location.state.machineId, dept]);

	// const onFinish = async (values) => {
	// 	const data = {
	// 		machineName: props.location.state.machineName,
	// 		machineId: props.location.state.machineId,
	// 		maintData,
	// 	};

	// 	console.log("Submitting data:", data);

	// 	axios
	// 		.post(
	// 			"/production/injectionMoulding/submitMachineMaintainenceDataOperator",
	// 			data
	// 		)
	// 		.then(function (res) {
	// 			if (res.data.msg === "Form saved successfully") {
	// 				alert("Form Submitted To Supervisor!!!");
	// 				history.goBack();
	// 			}
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 			alert(error);
	// 		});
	// };

	return (
		<>
			<BackButton styleClass={"back-btn"} />
			<div className="card-holder">
				<div className="form-card">
					<h1
						style={{
							paddingBottom: "10px",
							letterSpacing: "0.3rem",
							fontWeight: "bold",
							textAlign: "center",
						}}
					>
						MACHINE MAINTENANCE
					</h1>

					<div className="site-card-wrapper">
						<Row
							gutter={[
								{ xs: 8, sm: 16, md: 24, lg: 32 },
								{ xs: 8, sm: 16, md: 24, lg: 32 },
							]}
						>
							{maintData?.map(function (item, i) {
								return (
									<Col span={12} key={i}>
										{dept==="injectionMoulding" && <MaintenanceCard
											item={item}
											machineName={
												props.location.state.machineName
											}
											machineId={
												props.location.state.machineId
											}
											dept={dept}
										/>}
										{dept==="die_casting" && <MaintenanceCardDC
											item={item}
											machineName={
												props.location.state.machineName
											}
											machineId={
												props.location.state.machineId
											}
											dept={dept}
										/>}
									</Col>
								);
							})}
						</Row>
					</div>

					{/* <div className="submit-btn">
						<Button
							type="danger"
							htmlType="submit"
							onClick={onFinish}
						>
							Submit
						</Button>
					</div> */}
					{/* </Form> */}
				</div>
			</div>
		</>
	);
};

export default MachineMaintenance;
