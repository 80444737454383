import { React, useState, useEffect } from "react";
import axios from "../../../api/axiosApi";
import { Divider, Radio, Space } from "antd";
import auth from "../../common/LoginComponent/auth";
// import BackButton from "../../common/BackButton/backButton";
import ShiftHandOverCard from "./ShiftHandOverCard";
import { getUser } from "../../../redux/users/userSlice";
import { useSelector } from "react-redux";

const AllHandoverForms = (props) => {
	const [Allforms, setAllforms] = useState();
	const [currentShift, setCurrentShift] = useState("1");
	const userData = useSelector(getUser);
	// let dept = userData["dept"][0];
	let dept = userData["operation"][0];
    let department="injectionMoulding";
	
	if(dept=== "Injection Moulding")
	{
	  department="injectionMoulding";
	}
	else if(dept === "Die Casting")
	{
	  department="die_casting";
	}

	const changeShift = (e) => {
		setCurrentShift(e.target.value);
	};

	useEffect(() => {
		if (auth.isAuthenticated()) {
			const fetchAllForms = async () => {
				await axios
					.get(
						`/production/${department}/getAllHandoverOverForms?isRead=false&shift=${currentShift}`
					)
					.then((response) => {
						console.log("All Forms : ", response.data.data);
						setAllforms(response.data.data);
					})
					.catch((error) => {
						console.log(`ERRORS : ${error}`);
						alert(`Error:"${error}`);
					});
			};
			fetchAllForms();
		}
	}, [currentShift, department]);

	// console.log("Allforms", Allforms);

	const prevDateCheck = (item) => {
		const curr_date = new Date();
		let prev_date = new Date(curr_date.getTime());
		prev_date.setDate(curr_date.getDate() );
		prev_date = prev_date.toISOString().toString().slice(0, 10);
		// console.log("prev_date ", prev_date);

		const creation_date = new Date(item.when_created);
		const date = new Date();
		const when_created = new Date(
			creation_date.getTime() - date.getTimezoneOffset() * 60000
		)
			.toISOString()
			.toString()
			.slice(0, 10);

		if (when_created === prev_date) return true;
		return false;
	};

	const formsToShow =
		Allforms && Allforms.filter((item) => prevDateCheck(item));

	// console.log("formsToShow", formsToShow);
	const tablebody =
		formsToShow &&
		formsToShow.map((item) => (
			<ShiftHandOverCard item={item} key={item?._id} dept={department}/>
		));

	return (
		<div>
			{/* <BackButton styleClass="back-btn" /> */}
			<div
				style={{
					display: "flex",
					justifyContent: "start",
					margin: "5%",
				}}
			>
				<Space style={{ marginBottom: 24 }}>
					<Radio.Group
						value={currentShift}
						onChange={changeShift}
						style={{ width: "200%" }}
					>
						<Radio.Button value="1">Shift 1</Radio.Button>
						<Radio.Button value="2">Shift 2</Radio.Button>
						<Radio.Button value="3">Shift 3</Radio.Button>
					</Radio.Group>
				</Space>
			</div>
			<Divider />
			{tablebody && tablebody.length === 0 ? (
				<h3>No forms to be displayed</h3>
			) : (
				<div className="form-grid">{tablebody}</div>
			)}
		</div>
	);
};

export default AllHandoverForms;
