import React from "react";
import "./footer.css";
import {
  FacebookFilled,
  TwitterCircleFilled,
  InstagramFilled,
  LinkedinFilled,
  CopyrightCircleOutlined,
} from "@ant-design/icons";

const PageFooter = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="footer-col">
            <h4>company</h4>
            <ul>
              <li>
                <a href="/#">about us</a>
              </li>
              <li>
                <a href="/#">our services</a>
              </li>
              <li>
                <a href="/#">privacy policy</a>
              </li>
              <li>
                <a href="/#">affiliate program</a>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Contact</h4>
            <ul>
              <li>
                <a href="/#">FAQ</a>
              </li>
              <li>
                <a href="/#">Q.A supervisor</a>
              </li>
              <li>
                <a href="/#">production supervisor</a>
              </li>
              <li>
                <a href="/#">production manager</a>
              </li>
            </ul>
          </div>
          <div className="footer-col"></div>
          <div className="footer-col">
            <h4>Follow us</h4>
            <div className="social-links">
              <a href="/#">
                <FacebookFilled
                  style={{ fontSize: "20px", marginTop: "10px" }}
                />
              </a>
              <a href="/#">
                <TwitterCircleFilled
                  style={{ fontSize: "20px", marginTop: "10px" }}
                />
              </a>
              <a href="/#">
                <InstagramFilled
                  style={{ fontSize: "20px", marginTop: "10px" }}
                />
              </a>
              <a href="/#">
                <LinkedinFilled
                  style={{ fontSize: "20px", marginTop: "10px" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        Copyright <CopyrightCircleOutlined />
        2021 HUF India, By PICT
      </div>
    </footer>
  );
};

export default PageFooter;
