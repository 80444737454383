import {
  Button,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  Select,
  Table,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import  { useEffect, useState } from "react";
//all imports to get Navbar workin!
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosApi from "../../api/axiosApi";
import { getDepartments } from "../../redux/dropDownData/dropDownDataSlice";
import "./supervisor_ojt_dashboard.css";

const { Content } = Layout;

const SupervisorOJTDashboard = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const dept = useSelector(getDepartments);
  const [trainees, setTrainees] = useState([]);
  const [searchedTrainees ,setSearchedTrainees] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { Search } = Input;

  const showAddTraineeModal = () => {
    setIsModalVisible(true);
  };

  const handleAddTraineeModalOk = () => {
    setIsModalVisible(false);
  };

  const handleAddTraineeModalCancel = () => {
    setIsModalVisible(false);
  };

  const tableColumns = [
    {
      title: "Sr. No.",
      width: 100,
      align: "center",
      key: "_id",
      render: (text, record, index) => <p>{index + 1}</p>,
    },
    {
      title: "Trainee  Name",
      dataIndex: "name",
      key: "_id",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      key: "_id",
    },
    {
      title: "View",
      dataIndex: "view",
      key: "view",
      width: 100,
      align: "center",
      render: (text, record) => (
        <Button style={{ borderWidth: 0, color: "blue" }}>
          <Link
            to={{
              pathname: `/production-supervisor-ojt/${record._id}`,
              state: {
                trainee: record._id,
              },
            }}
          >
            View
          </Link>
        </Button>
      ),
    },
  ];

  const onFinish = (values) => {
    axiosApi
      .post("/training/all/addTrainee", values)
      .then((res) => {
        setSearchedTrainees(res.data.data);
        setTrainees(res.data.data);
        toast.success("🚀 Trainee added successfully!!", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        setIsModalVisible(false);
      })
      .catch((err) => {
        toast.error("🚀 Error while adding trainee", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        setIsModalVisible(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    alert("Error Occured : ", errorInfo);
  };

  const onSearch = (value) => {
    const newTrainees = trainees?.filter(item => item.name.toString().toLowerCase().includes(value.toString().toLowerCase()));
    setSearchedTrainees(newTrainees);
  }

  useEffect(() => {
    setLoading(true);
    axiosApi
      .get("training/all/getTrainee")
      .then((res) => {
        setTrainees(res.data.data);
        setSearchedTrainees(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  return !loading ? (
    <div>
      <ToastContainer />

      <div className="">
        <Layout className="layout">
          <Content style={{ padding: "50px 50px" }}>
            <div className="site-layout-content">
              <h2>Trainees Dashboard</h2>             
              <Search
                placeholder="Search Trainee Name"
                allowClear
                enterButton="Search"
                size="large"
                onSearch={onSearch}
              />
              <Divider />

              <Table
                bordered
                dataSource={searchedTrainees}
                columns={tableColumns}
                pagination={true}
              />

              {/* Add Trainee Modal Section */}
              <Button type="primary" onClick={showAddTraineeModal}>
                Add Trainee
              </Button>
              <Modal
                title="Add New Trainee"
                visible={isModalVisible}
                footer={null}
                onOk={handleAddTraineeModalOk}
                onCancel={handleAddTraineeModalCancel}
                okText="Add Trainee"
              >
                <Form
                  labelCol={{
                    span: 10,
                  }}
                  wrapperCol={{
                    span: 8,
                  }}
                  form={form}
                  layout="horizontal"
                  colon={false}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  className="modal-input-form"
                >
                  <FormItem
                    label="Trainee Name"
                    name="name"
                    rules={[
                      { required: true, message: "Please enter something!" },
                    ]}
                    labelAlign="left"
                  >
                    <Input placeholder="Trainee name" />
                  </FormItem>

                  <FormItem
                    label="Department"
                    name="dept"
                    rules={[
                      { required: true, message: "Please enter something!" },
                    ]}
                  >
                    <Select>
                      {dept &&
                        dept.map((dept, index) => (
                          <Select.Option key={index} value={dept[1]}>
                            {dept[1]}
                          </Select.Option>
                        ))}
                    </Select>
                  </FormItem>

                  <FormItem
                    label="Trainee Number"
                    name="number"
                    rules={[
                      { required: true, message: "Please enter something!" },
                    ]}
                  >
                    <Input placeholder="Trainee Number" type="number" />
                  </FormItem>
                  <FormItem
                    label="Designation"
                    name="designation"
                    rules={[
                      { required: true, message: "Please enter something!" },
                    ]}
                  >
                    <Input placeholder="Designation" />
                  </FormItem>
                  <FormItem
                    label="Line"
                    name="line"
                    rules={[
                      { required: true, message: "Please enter something!" },
                    ]}
                  >
                    <Input placeholder="Line" />
                  </FormItem>
                  <div style={{ textAlign: "center" }}>
                    <Button type="danger" onClick={handleAddTraineeModalCancel}>
                      Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                      Add Trainee
                    </Button>
                  </div>
                </Form>
              </Modal>
            </div>
          </Content>
        </Layout>
      </div>
    </div>
  ) : error ? (
    <p>Error while fetching data...</p>
  ) : (
    <p>Loading...</p>
  );
};

export default SupervisorOJTDashboard;
