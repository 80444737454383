import { React, useState } from "react";
import { Input, Button, Col, Row, Radio, Typography, Card } from "antd";

import { CheckOutlined } from "@ant-design/icons";
import axios from "../../../../api/axiosApi";

const MaintenanceCard = (props) => {
  const [isApproved, setIsApproved] = useState(props.item.status);

  const submit = (recordId, partId, partNo) => {
    const data = {
      partId,
      partNo,
      status: true,
      recordId,
    };

    console.log("Data", data);
    axios
      .post("/production/injectionMoulding/submitToolMaintDataManager", data)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
        alert(error);
      });
  };

  return (
    <Card
      title={props.item.partName}
      style={{ boxShadow: "5px 5px 5px 5px grey" }}
      headStyle={{
        backgroundColor: props.item.isMaint === true ? "#29a329" : "#cc0000",
        borderRadius: "10px",
        color: "#ffffff",
      }}
      raised="true"
    >
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        style={{ marginBottom: "4%" }}
      >
        <Col className="gutter-row" span={12}>
          <Typography.Text>PART ID</Typography.Text>
        </Col>
        <Col className="gutter-row" span={12}>
          <Input value={props.item.partId} disabled />
        </Col>
      </Row>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        style={{ marginBottom: "4%" }}
      >
        <Col className="gutter-row" span={12}>
          <Typography.Text>PART NAME</Typography.Text>
        </Col>
        <Col className="gutter-row" span={12}>
          <Input value={props.item.partName} disabled />
        </Col>
      </Row>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        style={{ marginBottom: "4%" }}
      >
        <Col className="gutter-row" span={12}>
          <Typography.Text>PART No.</Typography.Text>
        </Col>
        <Col className="gutter-row" span={12}>
          <Input value={props.item.partNo} disabled />
        </Col>
      </Row>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        style={{ marginBottom: "4%" }}
      >
        <Col className="gutter-row" span={12}>
          <Typography.Text>NEXT PM SHOT</Typography.Text>
        </Col>
        <Col className="gutter-row" span={12}>
          <Input value={props.item.nextPMShot} disabled />
        </Col>
      </Row>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        style={{ marginBottom: "4%" }}
      >
        <Col className="gutter-row" span={24}>
          <Typography.Text>Completed</Typography.Text>

          <Radio.Group
            initialValues="Yes"
            defaultValue={props.item.isMaint ? "1" : "0"}
            disabled
          >
            <Radio value="1" style={{ margin: "0px 12px" }}>
              Yes
            </Radio>
            <Radio value="0">No</Radio>
          </Radio.Group>
        </Col>
      </Row>

      <Row>
        <Col span={11}>
          <Button
            type="primary"
            shape="round"
            disabled={isApproved}
            icon={<CheckOutlined />}
            className="action-button approve-btn"
            onClick={() => {
              setIsApproved(true);
              submit(props.item.id, props.item.partId, props.item.partNo);
            }}
          >
            Approve
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default MaintenanceCard;
