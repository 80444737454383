import { React, useState, useEffect } from "react";
import { Col, Row } from "antd";

import BackButton from "../../../common/BackButton/backButton";
import MaintenanceCard from "./maintenanceCard.js";

import axios from "../../../../api/axiosApi";
import "../maintenance.css";

const MachineMaintenance = () => {
  const [maintData, setMaintData] = useState([]);

  useEffect(() => {
    const maintenanceData = () => {
      axios
        .get(`/production/injectionMoulding/getToolMaintData`)
        .then(function (response) {
          const resData = response.data.data;
          setMaintData(() => resData);
        })
        .catch(function (error) {
          console.log(error);
          alert(error);
        });
    };
    maintenanceData();
  }, []);

  return (
    <>
    <BackButton styleClass="back-btn" />
    <div className="card-holder">
      
      <div className="form-card">
        <h1
          style={{
            paddingBottom: "10px",
            letterSpacing: "0.3rem",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          TOOL MAINTENANCE
        </h1>

        <div className="site-card-wrapper">
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24, lg: 32 },
              { xs: 8, sm: 16, md: 24, lg: 32 },
            ]}
          >
            {maintData.length === 0 ? (
              <div>No Maintenance Data</div>
            ) : (
              maintData.map(function (item, i) {
                return item.isMaintained !== true ? (
                  <Col span={12} key={i}>
                    <MaintenanceCard item={item} />
                  </Col>
                ) : (
                  <div></div>
                );
              })
            )}
          </Row>
        </div>
      </div>
    </div>
    </>
  );
};

export default MachineMaintenance;
