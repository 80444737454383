import { React, useState, useEffect } from "react";
import axios from "../../../../api/axiosApi";
import "./getAllOperators.css";

import { Card, Divider, Row, Col, Radio, Space, DatePicker } from "antd";
import auth from "../../../common/LoginComponent/auth";
import { Link } from "react-router-dom";
import BackButton from "../../../common/BackButton/backButton";

const QASupervisorAllOperators = (props) => {
  const [allforms, getallforms] = useState([]);
  const [currentShift, setCurrentShift] = useState("1");
  const [dateFilter, setDateFilter] = useState();
  let tempDept="injectionMoulding";
  
  if(props.location.state["dept"]==="Die Casting" || props.location.state["dept"]==="die_casting")
  {
    tempDept="die_casting"
  }

  const changeShift = (e) => {
    setCurrentShift(e.target.value);
  };

  const changeDate = (e) => {
    const date = new Date();
    const offsetDate = new Date(e.getTime() - date.getTimezoneOffset() * 60000);
    setDateFilter(offsetDate);
  };

  const machineId = props.location.state["machineId"];

  // console.log("MACHINE ID", machineId);

  useEffect(() => {
    if (auth.isAuthenticated() && dateFilter) {
     
      let datee = dateFilter.toISOString().toString().slice(0, 10);
      const fetchAllForms = async () => {
        await axios
          .get(
            `/production/${tempDept}/getOperatorData?machineId=${machineId}&shift=${currentShift}&datee=${datee}`
          )
          .then((response) => {
            const allforms = response.data.data;
            getallforms(() => allforms);
          })
          .catch((error) => {
            console.log(`ERRORS : ${error}`);
            alert(`Error:"${error}`);
          });
      };
      fetchAllForms();
    }
  }, [machineId, currentShift, dateFilter]);

  const tablebody =
    allforms &&
    allforms.map((item) => {
      return (
        <Card
          className="card"
          key={item._id}
          title={item.emp_name}
          style={{ width: 300, margin: 15 }}
          headStyle={{
            backgroundColor: item.flag === true ? "#29a329" : "#cc0000",
            borderRadius: "10px",
            color: "#ffffff",
          }}
          raised="true"
        >
          <Row>
            <Col span={12}>
              <p>Submitted On </p>
            </Col>
            <Col span={12}>
              <p>{item.when_created.substring(0, 10)}</p>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <p>Submitted At</p>
            </Col>
            <Col span={12}>
              <p>{item.when_created.substring(11, 19)}</p>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <p>Shift </p>
            </Col>
            <Col span={12}>
              <p>{item.shift}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <p>Department</p>
            </Col>
            <Col span={12}>
              <p>{item.dept}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <p>Operation</p>
            </Col>
            <Col span={12}>
              <p>{item.operation}</p>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <p>Approval Status</p>
            </Col>
            <Col span={12}>
              <p>{item.status}</p>
            </Col>
          </Row>

          <Link
            to={{
              pathname: `/shift_start_up/${item._id}`,
              state: {
                machineId: machineId,
                dept:tempDept,
              },
            }}
          >
            {/* {item.status === "Pending" ? "View" : "Review"} */}
            Review
          </Link>
        </Card>
      );
    });

  return (
    <div>
      <BackButton styleClass="back-btn" />
      <h1 style={{ textAlign: "center" }}> QUALITY ASSURANCE SUPERVISOR</h1>
      <Divider />
      <div style={{ display: "flex", justifyContent: "start", margin: "5%" }}>
        <Space style={{ marginBottom: 24 }}>
          <Radio.Group
            value={currentShift}
            onChange={changeShift}
            style={{ width: "200%" }}
          >
            <Radio.Button value="1">Shift 1</Radio.Button>
            <Radio.Button value="2">Shift 2</Radio.Button>
            <Radio.Button value="3">Shift 3</Radio.Button>
          </Radio.Group>
        </Space>
        <DatePicker
          style={{ marginLeft: "30%" }}
          onChange={(e) => {
            changeDate(e?._d);
          }}
        />
      </div>

      <div>
        {allforms.length === 0 ? (
          <div>No forms to be displayed</div>
        ) : (
          <div className="form-grid">{tablebody}</div>
        )}
      </div>
    </div>
  );
};

export default QASupervisorAllOperators;
