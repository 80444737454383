import React, { useState, useEffect, useCallback } from "react";

import { Link } from "react-router-dom";
import axios from "../api/axiosApi";
import { Card, Row, Col } from "antd";
import auth from "../components/common/LoginComponent/auth";
import { useSelector } from "react-redux";
import { getUser } from "../redux/users/userSlice";

import PageFooter from "../components/common/Footer/footer";
import BackButton from "../components/common/BackButton/backButton";
// import "./qa_supervisor_dashboard.css";

const HardwareAllDashboard = () => {
  const [machinesList, setMachinesList] = useState();
  const [data, setData] = useState();

  const userData = useSelector(getUser);
  // let dept = userData["dept"][0];
  let dept = userData["operation"][0];
  let role = userData["roles"][0];
  console.log("userData",userData["operation"][0])

  let displayAllMachine = false;

  let department='';
  let tempDepartment = '';
  if (dept === "Injection Moulding") {
    department = "injectionMoulding";
    tempDepartment = 'ProductionMoulding'
  }
  else if (dept === "Die Casting") {
    department = "die_casting";
  }
  else{
    department = "injectionMoulding";
    tempDepartment = 'ProductionMoulding'
  }

  if (dept==="All"||role === "ManagingDirector" || role === "GeneralManager" || dept === "Maintenance" || dept==="Quality Assurance" ) {
    displayAllMachine = true;
  }

  const [loading, setloading] = useState(true);

  const fetchAllMachines = useCallback(async () => {
    try {
      let newData = [];
      if (!displayAllMachine) {
        const machineData = await axios.get(`production/${department}/getMachineInformation`);
        let machineListData = machineData.data.data;
        setMachinesList(machineListData);

        // console.log("Machine data = ", machineData);
        // console.log("Machine List = ", machineListData);

     
        for (let i = 0; i < machineListData.length; i++) {
          const res = await axios.get(`production/${department}/get${tempDepartment}Planning?machineId=${machineListData[i]._id}`);



          let temp = {};

          if (res.data.data.length === 0 || res.data.data === undefined || res.data.data === null) {
            temp = {
              _id: machineListData[i]._id,
              machineName: machineListData[i].name,
              department:department,
              Target: "N/A",
              Actual: "N/A",
              Part_name: "N/A",
              status: "No Plans"
            };

          } else {
            // console.log(res.data.data)
            // console.log("res ==",res)
            let stat;

            const runningParts = res.data.data.filter(item => item.status_of_part === "Running");

            // console.log("run = ", runningParts )

            if (runningParts === null || runningParts === undefined || runningParts.length === 0) {
              if (res.data.data.length > 1) {
                stat = "Multiple Plans but no Running"
              }
              else {
                stat = "One Plan yet to start"
              }
              temp = {
                _id: machineListData[i]._id,
                machineName: machineListData[i].name,
                department:department,
                Target: "N/A",
                Actual: "N/A",
                Part_name: "N/A",
                status: stat
              };
            }

            else {
              temp = {
                _id: machineListData[i]._id,
                machineName: machineListData[i].name,
                department:department,
                Target: runningParts[0].qtyRequired,
                Actual: runningParts[0].actual_qty,
                Part_name: runningParts[0].part_id.part_name,
                status: "Running"
              };
            }


          }

          // Update data array using functional update
          newData.push(temp);
        }
        // console.log("data =", data)  
      }
      else {
        let arr = ["injectionMoulding", "die_casting"]
        let arr2 = ["ProductionMoulding", ""]

        
        for (let j = 0; j < 2; j++) {
          const machineData = await axios.get(`production/${arr[j]}/getMachineInformation`);
          let machineListData = machineData.data.data;
          // console.log(machineListData)
          setMachinesList(machineListData);
          for (let i = 0; i < machineListData.length; i++) {
            const res = await axios.get(`production/${arr[j]}/get${arr2[j]}Planning?machineId=${machineListData[i]._id}`);

            let temp = {};

            if (res.data.data.length === 0 || res.data.data === undefined || res.data.data === null) {
              temp = {
                _id: machineListData[i]._id,
                machineName: machineListData[i].name,
                department:arr[j],
                Target: "N/A",
                Actual: "N/A",
                Part_name: "N/A",
                status: "No Plans"
              };

            } else {
              // console.log(res.data.data)
              // console.log("res ==",res)
              let stat;

              const runningParts = res.data.data.filter(item => item.status_of_part === "Running");

              // console.log("run = ", runningParts )

              if (runningParts === null || runningParts === undefined || runningParts.length === 0) {
                if (res.data.data.length > 1) {
                  stat = "Multiple Plans but no Running"
                }
                else {
                  stat = "One Plan yet to start"
                }
                temp = {
                  _id: machineListData[i]._id,
                  machineName: machineListData[i].name,
                  department:arr[j],
                  Target: "N/A",
                  Actual: "N/A",
                  Part_name: "N/A",
                  status: stat
                };
              }

              else {
                temp = {
                  _id: machineListData[i]._id,
                  machineName: machineListData[i].name,
                  department:arr[j],
                  Target: runningParts[0].qtyRequired,
                  Actual: runningParts[0].actual_qty,
                  Part_name: runningParts[0].part_id.part_name,
                  status: "Running"
                };
              }


            }

            // Update data array using functional update
            newData.push(temp);
          }
        }
      }

      setData(newData)

      // console.log("final data =", data); // This should be inside the loop or another then block
      setloading(false);
    } catch (e) {
      console.log("Error in Fetching Machine Data: ", e);
      setloading(false);
    }
  }, [department, displayAllMachine, tempDepartment]);

  useEffect(() => {
    if (auth.isAuthenticated()) {
      setloading(true);
      fetchAllMachines();
    }
  }, [fetchAllMachines]);

  if (machinesList === null || machinesList === undefined) return "No Machine Data Currently Available";

  if (loading) return "Loading Your Dashboard";
  return (
    <div className="machine-dashboard">
      <BackButton styleClass="back-btn" />
      <Row gutter={[32, 32]} className="machine-card-container">
        {data?.map((machine) => (
          <Col
            xs={24}
            sm={12}
            lg={6}
            className="machine-card"
            key={machine._id}
          >
            <Card
              title={`${machine.machineName}`}
              hoverable
              style={{ margin: 15, boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }}
              headStyle={{
                backgroundColor: "#3366ff",
                borderRadius: "10px",
                color: "#ffffff",
              }}
            >
              {displayAllMachine && <p style={{ fontWeight: 'bold' }}>
                Dept Name: {machine.department==="injectionMoulding"? "Injection Moulding": "Die Casting"}
              </p>}
              <p>Status : {machine.status}</p>
              <p>Target Quantity :{machine.Target}</p>
              <p>Actual Quantity :{machine.Actual}</p>
              <p>Part Name : {machine.Part_name}</p>
            
              <Link
                to={{
                  pathname: `/hardware/${machine._id}`,
                  state: {
                    machineName: machine.machineName,
                    machineId: machine._id,
                    dept:  machine.department 
                  },
                }}
              >
                View Machine Graph
              </Link>
            </Card>
          </Col>
        ))}
      </Row>
      <PageFooter />
    </div>
  );
};

export default HardwareAllDashboard;
