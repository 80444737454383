import { Button, Divider, Form, Input, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
//common components
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosApi from "../../api/axiosApi";
import BackButton from "../../components/common/BackButton/backButton";
import FollowUpTable from "./followuptable";
import ProblemCreated from "./problem_created";
import ProblemDetected from "./problem_detected";
import "./style.css";

const LineQrciForm = (props) => {
	const initdata = {
		dataSource: [
			{
				key: "0",
				shift: "I",
				D1: 0,
				D2: 0,
				D3: 0,
				D4: 0,
				D5: 0,
			},
			{
				key: "1",
				shift: "II",
				D1: 0,
				D2: 0,
				D3: 0,
				D4: 0,
				D5: 0,
			},
			{
				key: "2",
				shift: "III",
				D1: 0,
				D2: 0,
				D3: 0,
				D4: 0,
				D5: 0,
			},
		],
		count: 2,
	};
	const { id,dept } = props.match.params;
	const [data, setData] = useState(initdata);
	const [form] = Form.useForm();
	const [initialData, setInitialData] = useState();
	const [loading, setLoading] = useState();
	const [error, setError] = useState();
	const onFinish = (values) => {
		values["follow_up"] = data.dataSource;
		console.log(values);
		axiosApi
			.post(`/production/${dept}/submitLineQrci`, values)
			.then(function (response) {
				setData(initdata);
				alert("QRCI Form submitted !!");
			})
			.catch(function (error) {
				console.log(error);
				alert("Error : ", error);
			});
		console.log("Success :", values);
	};
	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
		alert("Error Occured : ", errorInfo);
	};

	useEffect(() => {
		setLoading(true);
		axiosApi
			.get(`production/${dept}/getLineQrciForm?qrciRefNo=${id}`)
			.then((response) => {
				setInitialData(response.data.data);
				setLoading(false);
				console.log(response.data.data.date_time);
			})
			.catch((error) => {
				console.log(`ERRORS : ${error}`);
				toast.error("🚀 Error while fetching data!!", {
					position: "bottom-center",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					progress: undefined,
				});
				setLoading(false);
				setError(true);
			});
	}, [id, dept]);

	return !loading ? (
		<div className="card-holder">
			<ToastContainer />
			<div>
				<BackButton styleClass={"back-btn"} />
			</div>
			<div className="form-card">
				<h1
					style={{
						paddingBottom: "10px",
						letterSpacing: "0.3rem",
						fontWeight: "bold",
						textAlign: "center",
					}}
				>
					Line & Departmental QRCI
				</h1>

				<Form
					labelCol={{
						span: 16,
					}}
					wrapperCol={{
						span: 8,
					}}
					form={form}
					layout="horizontal"
					colon={false}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					initialValues={initialData}
				>
					{/* <EmployeeDetail />

          <Form.Item label="Shift" name="shift" labelAlign="left">
            <Select disabled>
              <Select.Option value="1">I</Select.Option>
              <Select.Option value="2">II</Select.Option>
              <Select.Option value="3">III</Select.Option>
            </Select>
          </Form.Item> */}
					<Divider className="divider" />
					<Form.Item
						label="Problem"
						name="problem_as_per_operator"
						labelAlign="left"
						// rules={[{ required: true, message: "Please enter team!" }]}
					>
						<Input type="string" disabled />
					</Form.Item>
					<Form.Item
						label="Operator comment"
						name="comment_from_operator"
						labelAlign="left"
						// rules={[{ required: true, message: "Please enter team!" }]}
					>
						<Input type="string" disabled />
					</Form.Item>
					<Divider className="divider" />
					<span className="sub-headers">DATE AND TEAM:</span>
					<Form.Item
						label="Date/Time "
						name="date_time"
						labelAlign="left"
						placeholder={initialData?.date_time}
						// rules={[{ required: true, message: "Please enter date and time!" }]}
					>
						<Input type="datetime-local" />
					</Form.Item>
					<Form.Item
						label="Qrci Reference No."
						name="qrci_ref_no"
						labelAlign="left"
						// // rules={[{ required: true, message: "Please enter date and time!" }]}
					>
						<Input type="number" disabled />
					</Form.Item>
					<Form.Item
						label="Team"
						name="team"
						labelAlign="left"
						// rules={[{ required: true, message: "Please enter team!" }]}
					>
						<Input type="string" />
					</Form.Item>
					<Divider className="divider" />
					<span className="sub-headers">PROBLEM DESCRIPTION:</span>
					<Form.Item
						label="Product"
						name="product"
						labelAlign="left"
						// rules={[{ required: true, message: "Please enter something!" }]}
					>
						<Input type="string" />
					</Form.Item>
					<Form.Item
						label="Situation"
						name="situation"
						labelAlign="left"
						// rules={[{ required: true, message: "Please enter something!" }]}
					>
						<Input.TextArea />
					</Form.Item>
					<Form.Item
						label="What is the problem?"
						name="what_is_the_problem"
						labelAlign="left"
						// rules={[{ required: true, message: "Please enter the problem!" }]}
					>
						<Input.TextArea />
					</Form.Item>
					<Form.Item
						label="Problem Detection (Where?)"
						name="problem_detection_where"
						labelAlign="left"
						// rules={[{ required: true, message: "Please enter the problem!" }]}
					>
						<Input.TextArea />
					</Form.Item>
					<Form.Item
						label="Problem Detection (Who?)"
						name="problem_detection_who"
						labelAlign="left"
						// rules={[{ required: true, message: "Please enter the problem!" }]}
					>
						<Input.TextArea />
					</Form.Item>
					<Form.Item
						label="Why is it a problem?"
						name="why_is_it_a_problem"
						labelAlign="left"
						// rules={[{ required: true, message: "Please enter the problem!" }]}
					>
						<Input.TextArea />
					</Form.Item>
					<Form.Item
						label="How many parts?"
						name="how_many_parts"
						labelAlign="left"
						// rules={[{ required: true, message: "Please enter the problem!" }]}
					>
						<Input type="number" />
					</Form.Item>
					<Form.Item
						label="Other references / situations at risk (HSE)"
						name="other_references"
						labelAlign="left"
						// rules={[{ required: true, message: "Please enter the problem!" }]}
					>
						<Input.TextArea />
					</Form.Item>
					<Divider className="divider" />
					<span className="sub-headers">
						CHECK YOUR STANDARD FIRST
					</span>
					<Form.Item
						label="SW"
						name="sw"
						labelAlign="left"
						// rules={[
						//     {
						//         required: true,
						//         message: "Please select Ok/Not Ok/Not Applicable!",
						//     },
						// ]}
					>
						<Radio.Group name="sw">
							<Radio value={1}>Ok</Radio>
							<Radio value={2}>Not Ok</Radio>
							<Radio value={3}>Not Applicable</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label="Self Inspection"
						name="self_inspection"
						labelAlign="left"
						// rules={[
						//     {
						//         required: true,
						//         message: "Please select Ok/Not Ok/Not Applicable!",
						//     },
						// ]}
					>
						<Radio.Group name="self_inspection">
							<Radio value={1}>Ok</Radio>
							<Radio value={2}>Not Ok</Radio>
							<Radio value={3}>Not Applicable</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label="Polyvalence"
						name="polyvalence"
						labelAlign="left"
						// rules={[
						//     {
						//         required: true,
						//         message: "Please select Ok/Not Ok/Not Applicable!",
						//     },
						// ]}
					>
						<Radio.Group name="polyvalence">
							<Radio value={1}>Ok</Radio>
							<Radio value={2}>Not Ok</Radio>
							<Radio value={3}>Not Applicable</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label="Ok First Part"
						name="ok_first_part"
						labelAlign="left"
						// rules={[
						//     {
						//         required: true,
						//         message: "Please select Ok/Not Ok/Not Applicable!",
						//     },
						// ]}
					>
						<Radio.Group name="ok_first_part">
							<Radio value={1}>Ok</Radio>
							<Radio value={2}>Not Ok</Radio>
							<Radio value={3}>Not Applicable</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label="Poka Yoke"
						name="poka_yoke"
						labelAlign="left"
						// rules={[
						//     {
						//         required: true,
						//         message: "Please select Ok/Not Ok/Not Applicable!",
						//     },
						// ]}
					>
						<Radio.Group name="poka_yoke">
							<Radio value={1}>Ok</Radio>
							<Radio value={2}>Not Ok</Radio>
							<Radio value={3}>Not Applicable</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label="Final Inspection"
						name="final_inspection"
						labelAlign="left"
						// rules={[
						//     {
						//         required: true,
						//         message: "Please select Ok/Not Ok/Not Applicable!",
						//     },
						// ]}
					>
						<Radio.Group name="final_inspection">
							<Radio value={1}>Ok</Radio>
							<Radio value={2}>Not Ok</Radio>
							<Radio value={3}>Not Applicable</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label="Safety Instruction"
						name="safety_instruction"
						labelAlign="left"
						// rules={[
						//     {
						//         required: true,
						//         message: "Please select Ok/Not Ok/Not Applicable!",
						//     },
						// ]}
					>
						<Radio.Group name="final_inspection">
							<Radio value={1}>Ok</Radio>
							<Radio value={2}>Not Ok</Radio>
							<Radio value={3}>Not Applicable</Radio>
						</Radio.Group>
					</Form.Item>
					<Divider />
					<span className="sub-headers">PROBLEM DETECTION</span>
					<ProblemDetected />
					<Divider />
					<span className="sub-headers">PROBLEM CREATION</span>
					<ProblemCreated />
					<Divider />
					<span className="sub-headers">
						FOLLOW UP (5 DAYS BY SHIFT):
					</span>
					<FollowUpTable data={data} setData={setData} />
					[NOK Quantity]
					<Divider className="divider" />
					<span className="sub-headers">VERIFICATION:</span>
					<Form.Item
						label="Verification by Supervisor"
						name="verification_by_supervisor"
						labelAlign="left"
						// rules={[{ required: true, message: "Please select something!" }]}
					>
						<Input type="string" />
					</Form.Item>
					<Form.Item
						label="Verification by UAP Manager"
						name="verification_by_uap"
						labelAlign="left"
						// rules={[{ required: true, message: "Please select something!" }]}
					>
						<Input type="string" />
					</Form.Item>
					<Form.Item
						label="Escalation to UAP QRCI ?"
						name="escalation_to_uap"
						labelAlign="left"
						// rules={[{ required: true, message: "Please select something!" }]}
					>
						<Radio.Group name="escalation_to_uap">
							<Radio value={1}>Yes</Radio>
							<Radio value={2}>No</Radio>
						</Radio.Group>
					</Form.Item>
					<Divider className="divider" />
					<span className="sub-headers">STANDARD UPDATE</span>
					<Form.Item
						label="Work Instruction (SW)"
						name="work_instruction"
						labelAlign="left"
						// rules={[
						//     {
						//         required: true,
						//         message: "Please select Yes/No!",
						//     },
						// ]}
					>
						<Radio.Group name="work_instruction">
							<Radio value={1}>Yes</Radio>
							<Radio value={2}>No</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label="Safety Instruction"
						name="safety_instruction"
						labelAlign="left"
						// rules={[
						//     {
						//         required: true,
						//         message: "Please select Yes/No!",
						//     },
						// ]}
					>
						<Radio.Group name="safety_instruction">
							<Radio value={1}>Yes</Radio>
							<Radio value={2}>No</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label="Final Inspection Standard"
						name="final_inspection_standard"
						labelAlign="left"
						// rules={[
						//     {
						//         required: true,
						//         message: "Please select Yes/No!",
						//     },
						// ]}
					>
						<Radio.Group name="final_inspection_standard">
							<Radio value={1}>Yes</Radio>
							<Radio value={2}>No</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label="Maintenance Plan"
						name="maintenance_plan"
						labelAlign="left"
						// rules={[
						//     {
						//         required: true,
						//         message: "Please select Yes/No!",
						//     },
						// ]}
					>
						<Radio.Group name="maintenance_plan">
							<Radio value={1}>Yes</Radio>
							<Radio value={2}>No</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label="Control Plan"
						name="control_plan"
						labelAlign="left"
						// rules={[
						//     {
						//         required: true,
						//         message: "Please select Yes/No!",
						//     },
						// ]}
					>
						<Radio.Group name="control_plan">
							<Radio value={1}>Yes</Radio>
							<Radio value={2}>No</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label="FMEA/Risk Assesment"
						name="risk_assesment"
						labelAlign="left"
						// rules={[
						//     {
						//         required: true,
						//         message: "Please select Yes/No!",
						//     },
						// ]}
					>
						<Radio.Group name="risk_assesment">
							<Radio value={1}>Yes</Radio>
							<Radio value={2}>No</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label={
							<Input
								type="string"
								placeholder="Other"
								name="other"
							/>
						}
						name="otheryesno"
						labelAlign="left"
					>
						{/* <Input type="string" /> */}
						<Radio.Group name="otheryesno">
							<Radio value={1}>Yes</Radio>
							<Radio value={2}>No</Radio>
						</Radio.Group>
					</Form.Item>
					<Divider />
					<Form.Item
						label="Current Status"
						name="current_status"
						labelAlign="left"
					>
						<Select>
							<Select.Option key="1" value="completed">
								Completed
							</Select.Option>
							<Select.Option key="2" value="dumped">
								Abort
							</Select.Option>
							<Select.Option key="3" value="ongoing">
								Ongoing
							</Select.Option>
						</Select>
					</Form.Item>
					<Divider />
					<div className="submit-btn">
						<Button type="danger" htmlType="submit">
							Submit
						</Button>
					</div>
				</Form>
			</div>
		</div>
	) : error ? (
		<div>Error while fetching data...</div>
	) : (
		<div>Loading...</div>
	);
};

export default LineQrciForm;
