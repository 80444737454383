/* App.js */
// import React, { Component } from 'react';
import CanvasJSReact from './canvasjs.react';
// var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const MajBreakdownkpi = (props) => {
		const options = {
			title: {
				text: "MAJOR BREAKDOWN"
			},
			axisX: {
				title: "Month"
			},
			axisY: {
                title: "No. of Major Breakdowns"
            },
			data: [
			{
				type: "column",
				dataPoints: [
					{ label: "Jan",  y: 0 },
					{ label: "Feb",  y: 0  },
					{ label: "Mar",  y: 0  },
					{ label: "Apr",  y: 0  },
					{ label: "May",  y: 0  },
					{ label: "Jun",  y: 0  },
					{ label: "Jul",  y: 0  },
					{ label: "Aug",  y: 0  },
					{ label: "Sep",  y: 0  },
					{ label: "Oct",  y: 0  },
					{ label: "Nov",  y: 0  },
					{ label: "Dec",  y: 0  }
				]
			}
			]
		}
		let MB = props.MajBreakdown;
		// console.log("option data", options.data[0].dataPoints[1].label); 
		for(let i = 0; i < MB.length; i++){
			
            let z = MB[i].date.substr(5,2);
            if(z === "01"){
                options.data[0].dataPoints[0].y++;
            }else if(z === "02"){
                options.data[0].dataPoints[1].y++;
            }else if(z === "03"){
                options.data[0].dataPoints[2].y++;
            }else if(z === "04"){
                options.data[0].dataPoints[3].y++;
            }else if(z === "05"){
                options.data[0].dataPoints[4].y++;
            }else if(z === "06"){
                options.data[0].dataPoints[5].y++;
            }else if(z === "07"){
                options.data[0].dataPoints[6].y++;
            }else if(z === "08"){
                options.data[0].dataPoints[7].y++;
            }else if(z === "09"){
                options.data[0].dataPoints[8].y++;
            }else if(z === "10"){
                options.data[0].dataPoints[9].y++;
            }else if(z === "11"){
                options.data[0].dataPoints[10].y++;
            }else if(z === "12"){
                options.data[0].dataPoints[11].y++;
            }
        }

		return (
		<div>
			<CanvasJSChart options = {options}
				/* onRef={ref => this.chart = ref} */
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		);
	}

    export default MajBreakdownkpi;