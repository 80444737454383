import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./users/userSlice";
import dropDownDataReducer from "./dropDownData/dropDownDataSlice";
import operatorReducer from "./operators/operatorSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    dropDownData: dropDownDataReducer,
    operator: operatorReducer,
  },
});
