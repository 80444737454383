import { Button, Card, Col, Radio, Row, Space } from "antd";
import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "../../api/axiosApi";
import BackButton from "../../components/common/BackButton/backButton";
import auth from "../../components/common/LoginComponent/auth";
import { getUser } from "../../redux/users/userSlice";
import "./current_shift_form.css";

const QASupervisorCurrentShift = (props) => {
	const [Allforms, getallforms] = useState([]);
	const [currentShift, setCurrentShift] = useState("1");

	const userData = useSelector(getUser);
	const machineId = props.machineId;
	let dept;
	if(!props.dept)
	{
		dept="injectionMoulding"
	}
	else if(props.dept==="Injection Moulding")
	{
		dept="injectionMoulding"
	}
	else if(props.dept==="Die Casting")
	{
		dept="die_casting"
	}

	// let history = useHistory();
	const changeShift = (e) => {
		setCurrentShift(e.target.value);
	};

	const fetchForms = async () => {
		await axios
			.get(
				`/production/${dept}/getOperatorData?machineId=${machineId}&shift=${currentShift}`
			)
			.then((response) => {
				const allforms = response.data.data;
				getallforms(() => allforms);
			})
			.catch((error) => {
				console.log(`ERRORS : ${error}`);
				alert(error);
			});
	};

	// useEffect(() => {
	// 	if (auth.isAuthenticated()) {
	// 		const fetchAllForms = async () => {
	// 			await axios
	// 				.get(
	// 					`/production/${dept}/getOperatorData?machineId=${machineId}&shift=${currentShift}`
	// 				)
	// 				.then((response) => {
	// 					getallforms(response.data.data);
	// 				})
	// 				.catch((error) => {
	// 					console.log(`ERRORS : ${error}`);
	// 					alert(`Error:"${error}`);
	// 				});
	// 		};
	// 		fetchAllForms();
	// 	}
	// }, [machineId, currentShift]);

	useEffect(() => {
		const fetchData = async () => {
		  try {
			if (auth.isAuthenticated()) {
			  const response = await axios.get(
				`/production/${dept}/getOperatorData?machineId=${machineId}&shift=${currentShift}`
			  );
			  getallforms(response.data.data);
			}
		  } catch (error) {
			console.error(`Error: ${error}`);
			alert(`Error: ${error}`);
		  }
		};
	  
		fetchData();
	  
		// Cleanup function
		return () => {
		  // Any cleanup code if needed
		};
	  }, [machineId, currentShift, dept]);
	  

	const tablebody =
		Allforms &&
		Allforms.map((item) => {
			console.log(item);
			const date = new Date();
			var creation_date = new Date(item.when_created);
			const when_created = new Date(
				creation_date.getTime() - date.getTimezoneOffset() * 60000
			)
				.toISOString()
				.toString()
				.slice(0, 10);

			let today_date = new Date();
			const curr_date = new Date(
				today_date.getTime() - date.getTimezoneOffset() * 60000
			)
				.toISOString()
				.toString()
				.slice(0, 10);

			if (when_created === curr_date) {
				return (
					<Card
						className="card"
						key={item._id}
						title="SHIFT STARTUP"
						style={{ width: 300, margin: 15 }}
						headStyle={{
							backgroundColor:
								item.flag === true ? "#29a329" : "#cc0000",
							borderRadius: "10px",
							color: "#ffffff",
						}}
						raised="true"
					>
						<Row>
							<Col span={12}>
								<p>Submitted On </p>
							</Col>
							<Col span={12}>
								<p>{curr_date}</p>
							</Col>
						</Row>

						<Row>
							<Col span={12}>
								<p>Submitted At</p>
							</Col>
							<Col span={12}>
								<p>
									{new Date(
										item.when_created
									).toLocaleTimeString()}
								</p>
								{/* <p>{item?.when_created.substring(11,19)}</p> */}
							</Col>
						</Row>

						<Row>
							<Col span={12}>
								<p>Submitted By </p>
							</Col>
							<Col span={12}>
								<p>{item?.opr_username}</p>
							</Col>
						</Row>

						<Row>
							<Col span={12}>
								<p>Shift </p>
							</Col>
							<Col span={12}>
								<p>{item?.shift}</p>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<p>Department</p>
							</Col>
							<Col span={12}>
								<p>{item?.dept}</p>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<p>Operation</p>
							</Col>
							<Col span={12}>
								<p>{item?.operation}</p>
							</Col>
						</Row>

						<Row>
							<Col span={12}>
								<p>Approval Status</p>
							</Col>
							<Col span={12}>
								<p>{item?.status}</p>
							</Col>
						</Row>

						<Row>
							<Col span={12}>
								<Link
									to={{
										pathname: `/shift_start_up/${item._id}`,
										state: {
											machineId: props.machineId,
											dept:dept,
										},
									}}
									disabled={item.status !== "Pending"}
								>
									{item.status === "Pending"
										? "View"
										: "Review"}
								</Link>
							</Col>

							<Col span={12}>
								<Button
									onClick={() => {
										const data = {
											data: {
												sup_empid: userData["empid"],
												status: "Approved",
											},
											formId: item._id,
										};
										axios
											.post(
												`/production/${dept}/submitQASupervisor`,
												data
											)
											.then(function (response) {
												fetchForms();
												alert("Status Approved!!!");
											})
											.catch(function (error) {
												console.log(error);
												alert(error);
											});

										fetchForms();
									}}
									color="#cc0000"
									disabled={
										item.status !== "Pending"
											? true
											: item.flag === false
											? true
											: false
									}
								>
									Accept
								</Button>
							</Col>
						</Row>
					</Card>
				);
			}
			return false;
		});

	return (
		<div>
			<BackButton styleClass="back-btn" />
			<div
				style={{
					display: "flex",
					justifyContent: "start",
					margin: "5%",
				}}
			>
				<Space style={{ marginBottom: 24 }}>
					<Radio.Group
						value={currentShift}
						onChange={changeShift}
						style={{ width: "200%" }}
					>
						<Radio.Button value="1">Shift 1</Radio.Button>
						<Radio.Button value="2">Shift 2</Radio.Button>
						<Radio.Button value="3">Shift 3</Radio.Button>
					</Radio.Group>
				</Space>
			</div>
			{/* <Divider /> */}
			{Allforms.length === 0 ? (
				<div>No forms to be displayed</div>
			) : (
				<div className="form-grid">{tablebody}</div>
			)}
		</div>
	);
};
export default QASupervisorCurrentShift;
