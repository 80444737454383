import axiosApi from "../../../api/axiosApi";

export const loginController = async (values) => {
  try {
    let response = await axiosApi.post("auth/login", {
      username: values["username"],
      password: values["password"],
    });

    return response;
  } catch (err) {
    console.log(err);
  }
};
