import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import "./backButton.css";

const BackButton = (props) => {
  useEffect(() => {
    // console.log(props.styleClass);
  });
  let history = useHistory();
  return (
    <div>
      <Button
        onClick={() => history.goBack()}
        className="back-btn"
        danger
        shape="circle"
        type="primary"
      >
        <ArrowLeftOutlined />
      </Button>
    </div>
  );
};

export default BackButton;
