import { React, useState, useEffect } from "react";
import axios from "../../../api/axiosApi";

import { Card, Col, Row, Tabs } from "antd";
import { Link } from "react-router-dom";

import "./maintenance_manager.css";
// import ColorBars from "../../../components/common/ColorLamps/colorBars"
// import AlertsStats from "../../../components/common/ProductionStats/AlertsStats";


// import QrciSummary from "../../../components/common/SummaryTables/QrciSummary";
// import QrciStatsTable from "../../../components/common/ProductionStats/QrciStatsTable";

const MaintenanceManager = () => {
  const [status, getStatus] = useState([]);
  const [statusDC, getStatusDC] = useState([]);
  // const [alertsData, getAlerts] = useState([]);
  // const [machineInfo, getMachineInfo] = useState([]);
  // const [passingData, setPassingData] = useState([]);

  // const[plantQrci,setPlantQrci]=useState([]);
  // const[lineQrci,setLineQrci]=useState([]);
  // const [QrciVisibility,setQrciVisibility]=useState(false);

  // const [StopAlertsVisibility,setStopAlertsVisibility]=useState(false);

  // const makeQrciVisible=()=>{
  //   setStopAlertsVisibility(false);
  //   setQrciVisibility(true);

  // }
  useEffect(() => {
    const getMaintenanceStatus = async () => {
      await axios
        .get(`/production/injectionMoulding/getMachineMaintScheduleDetails`)
        .then((response) => {
          const stat = response.data.data;
          console.log(stat);
          getStatus(() => stat);
        })
        .catch((error) => {
          console.log(`ERRORS : ${error}`);
          alert(`Error:"${error}`);
        });
    };
    const getMaintenanceStatusDC = async () => {
      await axios
        .get(`/production/die_casting/getMachineMaintScheduleDetails`)
        .then((response) => {
          const stat = response.data.data;
          console.log(stat);
          getStatusDC(() => stat);
        })
        .catch((error) => {
          console.log(`ERRORS : ${error}`);
          alert(`Error:"${error}`);
        });
    };

    // const getAlertsData = async () => {
    //   await axios
    //     .get(`/production/injectionMoulding/getAllAlerts`)
    //     .then((response) => {
    //       const alertsData = response.data.data;
    //       getAlerts(() => alertsData);
    //     })
    //     .catch((error) => {
    //       console.log(`ERRORS : ${error}`);
    //       alert(`Error:"${error}`);
    //     });
    // };

    // const getMachineInfoRequest = async () => {
    //   await axios
    //     .get(`/production/injectionMoulding/getMachineInformation`)
    //     .then((response) => {
    //       const machineInfo = response.data.data;
    //       getMachineInfo(() => machineInfo);
    //     })
    //     .catch((error) => {
    //       console.log(`ERRORS : ${error}`);
    //       alert(`Error:"${error}`);
    //     });
    // };

    // const getPlantQrciData = async () =>{
    //   await axios
    //   .get(`production/injectionMoulding/getPlantQrciForm`)
    //   .then((response) => {
    //     const plantQrciData = response.data.data;
    //     const modPlantQrciData=plantQrciData.map(item=>{
    //       let problem_as_per_operator=item.problem_as_per_operator;
    //       let machine_details = machineInfo.filter(machineItem=> { return machineItem._id===item.machineId})[0];
    //       let machine_name;
    //       if(item.externally_created===true){
    //         machine_name="External QRCI"
    //       }
    //       else{
    //         if(machine_details !== undefined){
    //           machine_name=machine_details.name;
    //         }
    //         else{
    //           console.log(machineInfo)
    //             machine_name="Name Not Found in DB"
    //         }
    //       }
    //       let comment_from_operator=item.comment_from_operator;
    //       return ({machine_name,problem_as_per_operator,comment_from_operator})
    //     });

    //     setPlantQrci(() => modPlantQrciData);
    //   })
    //   .catch((error) => {
    //     console.log(`ERRORS : ${error}`);
    //     alert(`Error:"${error}`);
    //   });
    // }

    // const getLineQrciData = async () =>{
    //   await axios
    //   .get(`production/injectionMoulding/getLineQrciForm`)
    //   .then((response) => {
    //     const lineQrciData = response.data.data;
    //     const modLineQrciData=lineQrciData.map(item=>{
    //       let problem_as_per_operator=item.problem_as_per_operator;
    //       let machine_details = machineInfo.filter(machineItem=> { return machineItem._id===item.machineId})[0];
    //       let machine_name;

    //       if(item.externally_created===true){
    //         machine_name="External QRCI"
    //       }
    //       else{
    //         if(machine_details !== undefined){
    //           machine_name=machine_details.name;
    //         }
    //         else{
    //             machine_name="Name Not Found in DB"
    //         }
    //       }
    //       let comment_from_operator=item.comment_from_operator;
    //       return ({machine_name,problem_as_per_operator,comment_from_operator})
    //     });

    //     setLineQrci(() => modLineQrciData);
    //   })
    //   .catch((error) => {
    //     console.log(`ERRORS : ${error}`);
    //     alert(`Error:"${error}`);
    //   });
    // }

    getMaintenanceStatus();
    getMaintenanceStatusDC();
    // getAlertsData();
    // getMachineInfoRequest();
    // getPlantQrciData();
    // getLineQrciData();
  }, []);
  // const [dispData, setdispData] = useState();

  // const changeDataFunc=(alertsData,machineInfo)=>{

  //   const modData=alertsData.map(item=>{
  //     let alert=item.color;
  //     let machine_details = machineInfo.filter(machineItem=> { return machineItem._id===item.machineId})[0];
  //     let machine_name
  //     if(item.type==="external_qrci"){
  //       machine_name="External Qrci Alerts"
  //     }
  //     else{
  //       if(machine_details !== undefined){
  //         machine_name=machine_details.name;
  //       }
  //       else{
  //         machine_name="Name Not Found in DB"
  //       }
  //     }
  //     let machine_msg=item.data.msg;
  //     return ({machine_name,machine_msg,alert})
  //   });

  //   setPassingData(modData);
  //   console.log(passingData)
  //   setdispData(modData);
  //   setStopAlertsVisibility(true);
  //   setQrciVisibility(false);
  // }

  return (
    <div>
      {/* <AlertsStats data={dispData} isVisible={StopAlertsVisibility}/>
      <QrciStatsTable plantQrciData={plantQrci} lineQrciData={lineQrci} isVisible={QrciVisibility}/>

      
      <h1>Hello Maintenance Manager</h1>
      <ColorBars changeDataFunc={changeDataFunc} alertsData={alertsData} machineInfo={machineInfo} type="machineStopAlerts"/>

      <div className="right-side">
        <QrciSummary makeQrciVisible={makeQrciVisible} plantQrciData={plantQrci} lineQrciData={lineQrci} QrciVisibility={QrciVisibility}/>
      </div> */}

      <Tabs defaultActiveKey="1" centered style={{ marginTop: "5%" }}>
        <Tabs.TabPane tab="Machine Maintenance" key="1">
          <div className="site-card-wrapper">
            <Row gutter={16}>
              <Col span={6}>
                <Card
                  title="INJECTION MOULDING"
                  bordered={false}
                  style={{ borderRadius: "10px" }}
                  headStyle={{
                    backgroundColor:
                      status.upcomingMaint > status.completedMaint
                        ? "#cc0000"
                        : "#FFA500",
                    borderRadius: "10px",
                    color: "#ffffff",
                  }}
                  raised="true"
                >
                  <Row>
                    <Col span={12}>
                      <p>Machines Pending</p>
                    </Col>
                    <Col span={12}>
                      <p>{status.upcomingMaint}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <p>Machines Done</p>
                    </Col>
                    <Col span={12}>
                      <p>{status.completedMaint}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      {" "}
                      <Link
                        to={{
                          pathname: `/machine-maintenance-manager/injectionMoulding`,
                          state: {},
                        }}
                      >
                        View Machines
                      </Link>
                    </Col>
                    <Col span={12}>
                      {" "}
                      <Link
                        to={{
                          pathname: `/maintenance-schedule/injectionMoulding`,
                          state: {},
                        }}
                      >
                        View Status
                      </Link>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  title="DIE CASTING"
                  bordered={false}
                  style={{ borderRadius: "10px" }}
                  headStyle={{
                    backgroundColor: "#FFA500",
                    borderRadius: "10px",
                    color: "#ffffff",
                  }}
                  raised="true"
                >
                  <Row>
                    <Col span={12}>
                      <p>Machines Pending</p>
                    </Col>
                    <Col span={12}>
                      <p>{statusDC.upcomingMaint}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <p>Machines Done</p>
                    </Col>
                    <Col span={12}>
                      <p>{statusDC.completedMaint}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      {" "}
                      <Link
                        to={{
                          pathname: `/machine-maintenance-manager/die_casting`,
                          state: {},
                        }}
                      >
                        View Machines
                      </Link>
                    </Col>
                    <Col span={12}>
                      {" "}
                      <Link
                        to={{
                          pathname: `/maintenance-schedule/die_casting`,
                          state: {},
                        }}
                      >
                        View Status
                      </Link>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  title="PAINT SHOP"
                  bordered={false}
                  style={{ borderRadius: "10px" }}
                  headStyle={{
                    backgroundColor: "#FFA500",
                    borderRadius: "10px",
                    color: "#ffffff",
                  }}
                  raised="true"
                >
                  <Link
                    to={{
                      pathname: `/machines/`,
                      state: {},
                    }}
                  >
                    View Machines
                  </Link>
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  title="ASSEMBLY"
                  bordered={false}
                  style={{ borderRadius: "10px" }}
                  headStyle={{
                    backgroundColor: "#FFA500",
                    borderRadius: "10px",
                    color: "#ffffff",
                  }}
                  raised="true"
                >
                  <Link
                    to={{
                      pathname: `/machines/`,
                      state: {},
                    }}
                  >
                    View Machines
                  </Link>
                </Card>
              </Col>
            </Row>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Tool Maintenance" key="2">
          
          <div className="site-card-wrapper">
            <Row gutter={16}>
              <Col span={6}>
                <Card
                  title="INJECTION MOULDING"
                  bordered={false}
                  style={{ borderRadius: "10px" }}
                  headStyle={{
                    backgroundColor:
                      status.upcomingMaint > status.completedMaint
                        ? "#cc0000"
                        : "#FFA500",
                    borderRadius: "10px",
                    color: "#ffffff",
                  }}
                  raised="true"
                >
                  {" "}
                  <Link
                    to={{
                      pathname: `/general-manager-tool-maintenance-forms/injectionMoulding`,
                      state: {},
                    }}
                  >
                    Tool Maintenance
                  </Link>
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  title="DIE CASTING"
                  bordered={false}
                  style={{ borderRadius: "10px" }}
                  headStyle={{
                    backgroundColor: "#FFA500",
                    borderRadius: "10px",
                    color: "#ffffff",
                  }}
                  raised="true"
                >
                  <Link
                    to={{
                      pathname: `/general-manager-tool-maintenance-forms/die_casting`,
                      state: {},
                    }}
                  >
                    Tool Maintenance
                  </Link>
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  title="PAINT SHOP"
                  bordered={false}
                  style={{ borderRadius: "10px" }}
                  headStyle={{
                    backgroundColor: "#FFA500",
                    borderRadius: "10px",
                    color: "#ffffff",
                  }}
                  raised="true"
                >
                  <Link
                    to={{
                      //pathname: `/machines/`,
                      state: {},
                    }}
                  >
                    Tool Maintenance
                  </Link>
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  title="ASSEMBLY"
                  bordered={false}
                  style={{ borderRadius: "10px" }}
                  headStyle={{
                    backgroundColor: "#FFA500",
                    borderRadius: "10px",
                    color: "#ffffff",
                  }}
                  raised="true"
                >
                  <Link
                    to={{
                      //pathname: `/machines/`,
                      state: {},
                    }}
                  >
                    Tool Maintenance
                  </Link>
                </Card>
              </Col>
            </Row>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default MaintenanceManager;
