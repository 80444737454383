import { Table, Button, DatePicker, Select, Input } from "antd";
import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SaveOutlined, DeleteOutlined,EditOutlined } from "@ant-design/icons";
import BackButton from "../../../../components/common/BackButton/backButton";
import {
  getMachinesData,
  getPartsData,
} from "../../../../redux/dropDownData/dropDownDataSlice";
import { toast, ToastContainer } from "react-toastify";
import moment from 'moment';
import axiosApi from "../../../../api/axiosApi";

const ViewProductionPlan = (props) => {
  const machinesData = useSelector(getMachinesData);
  const partsData = useSelector(getPartsData);
  const [loading,setLoading] = useState();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setLoading(true);
    axiosApi
      .get(`production/injectionMoulding/getProductionMouldingPlanning`)
      .then((response) => {
        console.log('res',response) 
        setTableData(response.data.data);
        setLoading(false);
      })
      .catch((error) => console.log(`ERRORS : ${error}`));
  }, []);

  function secondsToTime(secs) {
    var hours = Math.floor(secs / (60 * 60));

    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    // var divisor_for_seconds = divisor_for_minutes % 60;
    // var seconds = Math.ceil(divisor_for_seconds);

    return `${hours}h:${minutes}m`;
  }

  console.log('tabel',tableData); 

  const onSave = (index) => {
    if (
      tableData[index].part_id &&
      tableData[index].qtyRequired
    ) {
      console.log('data',tableData[index].part_id,tableData[index].qtyRequired)
      axiosApi
        .get(
          `production/injectionMoulding/getPlannedTime?part_id=${
            tableData[index].part_id._id
          }&quantity=${tableData[index].qtyRequired}`
        )
        .then((response) => {
          console.log("prod", response);

          let items = [...tableData];
          let item = { ...items[index] };
          item.plannedTime = response.data.data.planned_time;
          item.partNo = response.data.data.part_number;
          items[index] = item;
          setTableData(items);
        })
        .catch((error) => console.log(`ERRORS : ${error}`));
    } else {
      toast.error("🚀 Enter entire Data", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };

  // const onDelete = (originalIndex) => {
  //   axiosApi.get(`production/injectionMoulding/deleteProductionPlan?_id=${tableData[originalIndex]._id}`)
  //         .then(res => {
  //           let filteredTableData = [];
  //           for(let index = 0;index<tableData.length;index++)
  //           {
  //             if(index!==originalIndex)
  //             {
  //               filteredTableData.push(tableData[index]);
  //             }
  //           }
  //           console.log('fi',filteredTableData);
  //           setTableData(filteredTableData);
  //           toast.success("🚀 Plan deleted successfully!!", {
  //             position: "bottom-center",
  //             autoClose: 3000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             progress: undefined,
  //           });
  //         })
  //         .catch(err => {
  //           console.log('err',err);
  //           toast.error("🚀 Enter entire Data", {
  //             position: "bottom-center",
  //             autoClose: 3000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             progress: undefined,
  //           });
  //         })
  // };

  // function refreshPage(){
  //   window.location.reload();
  
  // } 
  const onDelete = (originalIndex) => {
    // Send a request to delete the production plan from the backend
    axiosApi
      .get(`production/injectionMoulding/deleteProductionPlan?_id=${tableData[originalIndex]._id}`)
      .then(() => {
        // Clone the current table data array
        const updatedTableData = [...tableData];
        
        // Use splice to directly modify the array, removing the deleted item
        updatedTableData.splice(originalIndex, 1);
  
        // Update the state with the modified array
        setTableData(updatedTableData);
        // refreshPage();
        // Show a success toast message
        toast.success("🚀 Plan deleted successfully!!", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        // Show an error toast message
        toast.error("🚀 Enter entire Data", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      });
  };

  const onUpdate = (index) => {
    axiosApi.post(`production/injectionMoulding/updateProductionPlan`,{obj: tableData[index]})
      .then((res) => {
        toast.success("🚀 Plan updated successfully!!", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      })
      .catch(err => {
        console.log('err',err);
        toast.error("🚀 Enter entire Data", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      })
  }

  const columns = [
    {
      title: "Sr No",
      dataIndex: "SrNo",
      key: "SrNo",
      render: (text,record,index) => (
        <div>{index+1}</div>
      )
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record,index) => (
        <DatePicker
          style={{ width: "100%" }}
          defaultValue={moment(text)}
          onChange={(e) => {
            let items = [...tableData];
            let item = { ...items[index] };
            item.date = e?._d;
            items[index] = item;
            setTableData(items);
          }}
        />
      ),
        },
    {
      title: "Part Name",
      dataIndex: "part_id",
      key: "part_id",
      render: (text, record,index) => {
        console.log(text);
        return (
        <Select
          style={{ width: "180px" }}
          onChange={(value) => {
            let items = [...tableData];
            let item = { ...items[index] };
            item.part_id = value;
            items[index] = item;
            setTableData(items);
          }}
          defaultValue={text.part_name}
        >
          {partsData &&
            partsData.map((part, index) => (
              <Select.Option key={part._id} value={part._id} >
                {part.part_name}
              </Select.Option>
            ))}
        </Select>
      )},
            },
    {
      title: "Part No",
      dataIndex: "partNo",
      key: "partNo",
    },
    {
      title: "Machine Name",
      dataIndex: "machine_id",
      key: "machine_id",
      render: (text, record,index) => (
        <Select
          style={{ width: "180px" }}
          onChange={(value) => {
            let items = [...tableData];
            let item = { ...items[index] };
            item.machine_id = value;
            items[index] = item;
            setTableData(items);
          }}
          defaultValue={text._id}
        >
          {machinesData &&
            machinesData.map((machine) => (
              <Select.Option key={machine._id} value={machine._id}>
                {machine.name}
              </Select.Option>
            ))}
        </Select>
      ),
    },
    {
      title: "Qty Required",
      dataIndex: "qtyRequired",
      key: "qtyRequired",
      render: (text, record,index) => (
        <Input
          type="number"
          defaultValue={text}
          onChange={(e) => {
            let items = [...tableData];
            let item = { ...items[index] };
            item.qtyRequired = parseInt(e.target.value);
            items[index] = item;
            setTableData(items);
          }}
        />
      ),
    },
    {
      title: "Plannned Time",
      dataIndex: "plannedTime",
      key: "plannedTime",
      render: (text, record) => {
        if (text) {
          let time = secondsToTime(parseInt(text));
          return <p>{time}</p>;
        }
      },
    },
    {
      title: "Review",
      dataIndex: "review",
      key: "review",
    },
    {
      title: "Actual Time",
      dataIndex: "actual_time",
      key: "actual_time",
      render: (text, record) => {
        if (text) {
          // let time = secondsToTime(parseInt(text));
          return <p>{text}</p>;
        }
      }
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      render: (text, record,index) => (
        <span style={{ display: "inline-flex", gap: "5px" }}>
          <Button type="primary" onClick={() => onSave(index)}>
            <SaveOutlined />
          </Button>
          <Button type="default" style={{backgroundColor:"green",color:"white"}} onClick={() => onUpdate(index)}>
            <EditOutlined />
          </Button>
          <Button type="danger" onClick={() => onDelete(index)}>
            <DeleteOutlined />
          </Button>
        </span>
      ),
    }
  ];

  return !loading ? (
    <div>
      <BackButton styleClass="back-btn" />
      <ToastContainer />
      <h1
        style={{
          paddingBottom: "10px",
          letterSpacing: "0.3rem",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        PRODUCTION MOULDING PLANNING
      </h1>
      {tableData.length === 0 ? (
        <div>
          <h2>No Plan Set</h2>
          <Button type="primary">
            <Link to={{ pathname: "/new_production_plan" }}>New Plan</Link>
          </Button>
        </div>
      ) : (
        <Table dataSource={tableData} columns={columns} />
      )}
    </div>
  ): <div style={{fontSize: "300%"}}>Loading...</div>;
};

export default ViewProductionPlan;
