import { MinusCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Radio } from "antd";
import React from "react";

//D5
const CauseOfOccurence = () => {
  return (
    <>
      <Form.List name="d5_cause_of_occurence">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <>
                <span className="sub-headers">Retained Factor D5</span>
                <Form.Item
                  label="Retained Factor D5: N^0"
                  name={[name, "n0"]}
                  fieldKey={[fieldKey, "n0"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter Data' }]}
                >
                  <Input type="string" />
                </Form.Item>
                <Form.Item
                  label="Retained Factor D5: Is/Is Not Retained factor of Non-Detection"
                  name={[name, "retained_factor_of_non_detection"]}
                  fieldKey={[fieldKey, "retained_factor_of_non_detection"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter Data' }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <span className="sub-headers">Control Point</span>
                <Form.Item
                  label="Control Point: How to measure/characterize the retained factor"
                  name={[name, "control_point_retained_factors"]}
                  fieldKey={[fieldKey, "control_point_retained_factors"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter Data' }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  label="(1)Std. Exists?"
                  name={[name, "std_exists1"]}
                  fieldKey={[fieldKey, "std_exists1"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter Data' }]}
                >
                  <Radio.Group name="std_exists1">
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>

                <span className="sub-headers">Standard</span>
                <Form.Item
                  label="Standard: What is OK criteria in the existing Std.?"
                  name={[name, "standard_what_is_ok"]}
                  fieldKey={[fieldKey, "standard_what_is_ok"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter Data' }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <span className="sub-headers">Real Situation</span>
                <Form.Item
                  label="Real Situation: NOK(Data)"
                  name={[name, "real_situation_no_ok"]}
                  fieldKey={[fieldKey, "real_situation_no_ok"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter Data' }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  label="Real Situation: OK(Data)"
                  name={[name, "real_situation_ok"]}
                  fieldKey={[fieldKey, "real_situation_ok"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter Data' }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  label="(2)Std. Exists?"
                  name={[name, "std_exists2"]}
                  fieldKey={[fieldKey, "std_exists2"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter Data' }]}
                >
                  <Radio.Group name="std_exists2">
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="(3) Real OK vs Std.?"
                  name={[name, "real_ok_vs_std"]}
                  fieldKey={[fieldKey, "real_ok_vs_std"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter Data' }]}
                >
                  <Radio.Group name="real_ok_vs_std">
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>
                <span className="sub-headers">
                  Investigation plan to validate/eleminate a Reatined Factor
                  (Reproduce the Concern)
                </span>
                <br></br>
                <span className="sub-headers">(TECHNICAL POINT OF VIEW)</span>

                <Form.Item
                  label="Investigation Actions"
                  name={[name, "investigation_actions"]}
                  fieldKey={[fieldKey, "investigation_actions"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter Action' }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  label="Resp."
                  name={[name, "resp"]}
                  fieldKey={[fieldKey, "resp"]}
                  // rules={[{ required: true, message: 'Please enter data!' }]}
                  labelAlign="left"
                >
                  <Input type="string" />
                </Form.Item>
                <Form.Item
                  label="Deadline."
                  name={[name, "deadline"]}
                  fieldKey={[fieldKey, "deadline"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter data' }]}
                >
                  <Input type="date" />
                </Form.Item>
                <Form.Item
                  label="Done Date"
                  name={[name, "done_date"]}
                  fieldKey={[fieldKey, "done_date"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter data' }]}
                >
                  <Input type="date" />
                </Form.Item>

                <Form.Item
                  label="Results: Problem reproduced?"
                  name={[name, "results"]}
                  fieldKey={[fieldKey, "results"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter Data' }]}
                >
                  <Radio.Group name="results">
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="Retained Factor Validated?"
                  name={[name, "retained_factor_validated"]}
                  fieldKey={[fieldKey, "retained_factor_validated"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter data' }]}
                >
                  <Radio.Group name="retained_factor_validated">
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>
                <MinusCircleTwoTone
                  twoToneColor="#eb2f96"
                  style={{ fontSize: "20px" }}
                  onClick={() => remove(name)}
                />
                <Divider />
              </>
            ))}
            <div className="add-button">
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            </div>
          </>
        )}
      </Form.List>
    </>
  );
};

export default CauseOfOccurence;
