import { Card, Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const ShiftHandOverCard = ({ item, hist,dept }) => {
  console.log("Card item", item);
  // let link;
  // if(dept==="injectionMoulding")
  // {
  //   link="production-supervisor"
  // }
  // else
  // {
  //   link="dc-production-supervisor"
  // }

  return (
    <Card
      className="card"
      key={item._id}
      title="SHIFT HANDOVER"
      style={{ width: 300, margin: 15, textAlign: "center" }}
      headStyle={{
        backgroundColor: "#29a329",
        borderRadius: "10px",
        color: "#ffffff",
      }}
      raised="true"
    >
      <Row>
        <Col span={12}>
          <p>Submitted On </p>
        </Col>
        <Col span={12}>
          <p>{item?.date}</p>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <p>Submitted At</p>
        </Col>
        <Col span={12}>
          <p>{new Date(item.when_created).toLocaleTimeString()}</p>
          {/* <p>{item?.when_created?.substring(11, 19)}</p> */}
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <p>Submitted By </p>
        </Col>
        <Col span={12}>
          <p>{item?.operator_name}</p>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <p>Shift </p>
        </Col>
        <Col span={12}>
          <p>{item?.shift}</p>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <p>Machine Name</p>
        </Col>
        <Col span={12}>
          <p>{item?.machine_id?.name}</p>
        </Col>
      </Row>

      {hist !== true ? (
        <Row>
          <Col span={24}>
            <Link
              to={{
                pathname: `/production-supervisor/${item?._id}`,
                state: {
                  item: item,
                },
              }}
            >
              View Form
            </Link>
          </Col>
        </Row>
      ) : (
        <div></div>
      )}
    </Card>
  );
};

export default ShiftHandOverCard;
