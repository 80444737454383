import { Card, Col, Row, Tabs } from "antd";
import { Link } from "react-router-dom";

import "./maintenance_operator.css";

const MaintenanceOperator = () => {
	return (
		<div>
			<h1>Hello Maintenance Operator</h1>
			<Tabs defaultActiveKey="1" centered style={{ marginTop: "5%" }}>
				<Tabs.TabPane tab="Machine Maintenance" key="1">
					<div className="site-card-wrapper">
						<Row gutter={16}>
							<Col span={6}>
								<Card
									title="INJECTION MOULDING"
									bordered={false}
									style={{ borderRadius: "10px" }}
									headStyle={{
										backgroundColor: "#FFA500",
										borderRadius: "10px",
										color: "#ffffff",
									}}
									raised="true"
								>
									<Link
										to={{
											pathname: `/machine-maintenance/injectionMoulding`,
											state: {},
										}}
									>
										View Machines
									</Link>
								</Card>
							</Col>
							<Col span={6}>
								<Card
									title="DIE CASTING"
									bordered={false}
									style={{ borderRadius: "10px" }}
									headStyle={{
										backgroundColor: "#FFA500",
										borderRadius: "10px",
										color: "#ffffff",
									}}
									raised="true"
								>
									<Link
										to={{
											pathname: `/machine-maintenance/die_casting`,
											state: {},
										}}
									>
										View Machines
									</Link>
								</Card>
							</Col>
							<Col span={6}>
								<Card
									title="PAINT SHOP"
									bordered={false}
									style={{ borderRadius: "10px" }}
									headStyle={{
										backgroundColor: "#FFA500",
										borderRadius: "10px",
										color: "#ffffff",
									}}
									raised="true"
								>
									<Link
										to={{
											pathname: `/machines/`,
											state: {},
										}}
									>
										View Machines
									</Link>
								</Card>
							</Col>
							<Col span={6}>
								<Card
									title="ASSEMBLY"
									bordered={false}
									style={{ borderRadius: "10px" }}
									headStyle={{
										backgroundColor: "#FFA500",
										borderRadius: "10px",
										color: "#ffffff",
									}}
									raised="true"
								>
									<Link
										to={{
											pathname: `/machines/`,
											state: {},
										}}
									>
										View Machines
									</Link>
								</Card>
							</Col>
						</Row>
					</div>
				</Tabs.TabPane>
				<Tabs.TabPane tab="Tool Maintenance" key="2">
				<div className="site-card-wrapper">
						<Row gutter={16}>
							<Col span={6}>
								<Card
									title="INJECTION MOULDING"
									bordered={false}
									style={{ borderRadius: "10px" }}
									headStyle={{
										backgroundColor: "#FFA500",
										borderRadius: "10px",
										color: "#ffffff",
									}}
									raised="true"
								>
									<Link
										to={{
											pathname: `/tool-maintenance-form`,
											state: {},
										}}
									>
										Tool Maintenance
									</Link>
								</Card>
							</Col>
							<Col span={6}>
								<Card
									title="DIE CASTING"
									bordered={false}
									style={{ borderRadius: "10px" }}
									headStyle={{
										backgroundColor: "#FFA500",
										borderRadius: "10px",
										color: "#ffffff",
									}}
									raised="true"
								>
									<Link
										to={{
											pathname: `/dc-tool-maintenance-form`,
											state: {},
										}}
									>
										Tool Maintenance
									</Link>
								</Card>
							</Col>
							<Col span={6}>
								<Card
									title="PAINT SHOP"
									bordered={false}
									style={{ borderRadius: "10px" }}
									headStyle={{
										backgroundColor: "#FFA500",
										borderRadius: "10px",
										color: "#ffffff",
									}}
									raised="true"
								>
									<Link
										to={{
											pathname: `/machines/`,
											state: {},
										}}
									>
										Tool Maintenance
									</Link>
								</Card>
							</Col>
							<Col span={6}>
								<Card
									title="ASSEMBLY"
									bordered={false}
									style={{ borderRadius: "10px" }}
									headStyle={{
										backgroundColor: "#FFA500",
										borderRadius: "10px",
										color: "#ffffff",
									}}
									raised="true"
								>
									<Link
										to={{
											pathname: `/machines/`,
											state: {},
										}}
									>
										Tool Maintenance
									</Link>
								</Card>
							</Col>
						</Row>
					</div>
				</Tabs.TabPane>
			</Tabs>
		</div>
	);
};

export default MaintenanceOperator;
