const dummyData=[
      {
        "_id": {
          "$oid": "614d702b2659cdb82dad27cd"
        },
        "name": "470 T HAITIAN ",
        "status": "ON",
        "createdAt": {
          "$date": {
            "$numberLong": "1632464939613"
          }
        },
        "updatedAt": {
          "$date": {
            "$numberLong": "1674569936992"
          }
        },
        "__v": 0,
        "department": "Production",
        "parameter": {
          "no_of_emergency_switch": 2,
          "no_of_limit_switch": 6,
          "no_of_ppe": "Hand Gloves"
        },
        "count": 34631,
        "count_read_api_number": "1706164",
        "count_read_key": "GHX9ZJ3CCZCGQTB9",
        "count_write_key": "SVRBBRKGLA1PU9JL",
        "status_read_key": "",
        "status_write_key": "IAGZ6KNXT816XAHX",
        "actual_qty": 1808,
        "planned_quantity": 6350
      },
      {
        "_id": {
          "$oid": "614d701b2659cdb82dad27cb"
        },
        "name": "HT 160 T HAITIAN ",
        "status": "ON",
        "createdAt": {
          "$date": {
            "$numberLong": "1632464923970"
          }
        },
        "updatedAt": {
          "$date": {
            "$numberLong": "1674561767230"
          }
        },
        "__v": 0,
        "department": "Production",
        "parameter": {
          "no_of_emergency_switch": 4,
          "no_of_limit_switch": 6,
          "no_of_ppe": "Safety shoes"
        },
        "count_read_api_number": "1707080",
        "count_read_key": "A724PVWIBZCF63WM",
        "count_write_key": "O9OQI0OJQN8UDQ9P",
        "status_read_key": "",
        "status_write_key": "PPT7T2AXAL80XK1Q",
        "count": 57564,
        "actual_qty": 0,
        "planned_quantity": 0
      },
      {
        "_id": {
          "$oid": "614eba326e3a4a85c8c56236"
        },
        "name": "80 T MILACRON VERTICAL MOLDING",
        "parameter": {
          "no_of_emergency_switch": 2,
          "no_of_limit_switch": 2,
          "no_of_ppe": "Safety shoes"
        },
        "createdAt": {
          "$date": {
            "$numberLong": "1632464955821"
          }
        },
        "updatedAt": {
          "$date": {
            "$numberLong": "1674567017650"
          }
        },
        "__v": 40,
        "count": 453875,
        "status": [
          {
            "value": 1,
            "when_updated": {
              "$date": {
                "$numberLong": "1633884621710"
              }
            },
            "_id": {
              "$oid": "61631c667da65221f307e9b6"
            }
          },
          {
            "value": 0,
            "when_updated": {
              "$date": {
                "$numberLong": "1633884621710"
              }
            },
            "_id": {
              "$oid": "61631daa7da65221f307e9f7"
            }
          },
          {
            "value": 1,
            "when_updated": {
              "$date": {
                "$numberLong": "1633929516389"
              }
            },
            "_id": {
              "$oid": "6163c977e30cacae3f6ac571"
            }
          },
          {
            "value": 0,
            "when_updated": {
              "$date": {
                "$numberLong": "1633929516389"
              }
            },
            "_id": {
              "$oid": "6163c990e30cacae3f6ac57b"
            }
          },
          {
            "value": 1,
            "_id": {
              "$oid": "6163c9dca5b04e65928d1277"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633929692549"
              }
            }
          },
          {
            "value": 0,
            "_id": {
              "$oid": "6163c9eca5b04e65928d1285"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633929708342"
              }
            }
          },
          {
            "value": 1,
            "_id": {
              "$oid": "6163c9fda5b04e65928d1295"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633929725200"
              }
            }
          },
          {
            "value": 0,
            "_id": {
              "$oid": "6163ca03a5b04e65928d129f"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633929731178"
              }
            }
          },
          {
            "value": 1,
            "_id": {
              "$oid": "6163cacaa5b04e65928d12ce"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633929930256"
              }
            }
          },
          {
            "value": 1,
            "_id": {
              "$oid": "6163d4ee7b5283071c42c96e"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633932526700"
              }
            }
          },
          {
            "value": 0,
            "_id": {
              "$oid": "6163d4fe7b5283071c42c99f"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633932542316"
              }
            }
          },
          {
            "value": 0,
            "_id": {
              "$oid": "6163d50e7b5283071c42c9d4"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633932558133"
              }
            }
          },
          {
            "value": 1,
            "_id": {
              "$oid": "6163d51c7b5283071c42ca0d"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633932572074"
              }
            }
          },
          {
            "value": 1,
            "_id": {
              "$oid": "6163d5257b5283071c42ca3b"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633932581540"
              }
            }
          },
          {
            "value": 0,
            "_id": {
              "$oid": "6163d7e97b5283071c42d1f9"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633933289337"
              }
            }
          },
          {
            "value": 1,
            "_id": {
              "$oid": "6163d86b7b5283071c42d29e"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633933419680"
              }
            }
          },
          {
            "value": 0,
            "_id": {
              "$oid": "6163d8aa7b5283071c42d350"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633933482650"
              }
            }
          },
          {
            "value": 0,
            "_id": {
              "$oid": "6163e4997b5283071c43fbde"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633936537526"
              }
            }
          },
          {
            "value": 1,
            "_id": {
              "$oid": "6163e4a27b5283071c43fc1b"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633936546653"
              }
            }
          },
          {
            "value": 1,
            "_id": {
              "$oid": "6163e57d7b5283071c4438c9"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633936765829"
              }
            }
          },
          {
            "value": 0,
            "_id": {
              "$oid": "6163e5807b5283071c4438f6"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633936768905"
              }
            }
          },
          {
            "value": 0,
            "_id": {
              "$oid": "6163e6c77b5283071c443f22"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633937095785"
              }
            }
          },
          {
            "value": 0,
            "_id": {
              "$oid": "6163e6c87b5283071c443f3b"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633937096711"
              }
            }
          },
          {
            "value": 1,
            "_id": {
              "$oid": "6163e96d7b5283071c44596d"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633937773977"
              }
            }
          },
          {
            "value": 1,
            "_id": {
              "$oid": "6163eb0a7b5283071c44a13b"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633938186973"
              }
            }
          },
          {
            "value": 0,
            "_id": {
              "$oid": "6163f3a9a6e64f57eab348d7"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1633940393108"
              }
            }
          }
        ],
        "department": "Maintenance",
        "count_read_api_number": "1532536",
        "count_read_key": "MJXNTSD9HRLEXCDN",
        "count_write_key": "OPP6O2WRU2FHL8ZT",
        "status_read_key": "S6WVG7WQNOQQJZM6",
        "status_write_key": "",
        "actual_qty": 0,
        "planned_quantity": 25320
      },
      {
        "_id": {
          "$oid": "62678d12af7fac41f23cf362"
        },
        "name": "KM 160 T B",
        "status": [
          {
            "value": 0,
            "_id": {
              "$oid": "62678d12af7fac41f23cf363"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1650953490597"
              }
            }
          }
        ],
        "count": 34008,
        "department": "Production",
        "createdAt": {
          "$date": {
            "$numberLong": "1650953490604"
          }
        },
        "updatedAt": {
          "$date": {
            "$numberLong": "1670895000453"
          }
        },
        "__v": 0,
        "parameter": {
          "no_of_emergency_switch": 2,
          "no_of_limit_switch": 7,
          "no_of_ppe": "Hand Gloves"
        },
        "count_read_api_number": "1696535",
        "count_read_key": "XP4EWBQ6I56576GC",
        "count_write_key": "HZ24VM2SXMOBYIWW",
        "status_read_key": "",
        "status_write_key": "W7N6IOSOU751LA38",
        "actual_qty": 0,
        "planned_quantity": 0
      },
      {
        "_id": {
          "$oid": "614d6f6a2659cdb82dad27bd"
        },
        "name": "325 T DEMAG",
        "status": "OFF",
        "createdAt": {
          "$date": {
            "$numberLong": "1632464746219"
          }
        },
        "updatedAt": {
          "$date": {
            "$numberLong": "1674569909100"
          }
        },
        "__v": 0,
        "parameter": {
          "no_of_emergency_switch": 3,
          "no_of_limit_switch": 4,
          "no_of_ppe": "Safety shoes"
        },
        "count_read_api_number": "1707070",
        "count_read_key": "45TJ3DK1O0N08UED",
        "count_write_key": "WPY8VIGA03HA4867",
        "status_read_key": "",
        "status_write_key": "SS7I602U2O0O4Z3D",
        "count": 23158,
        "actual_qty": 908,
        "planned_quantity": 5850
      },
      {
        "_id": {
          "$oid": "614d6fa52659cdb82dad27bf"
        },
        "name": "420 T KRAUSS MAFFEI",
        "status": "ON",
        "createdAt": {
          "$date": {
            "$numberLong": "1632464805944"
          }
        },
        "updatedAt": {
          "$date": {
            "$numberLong": "1670895000453"
          }
        },
        "__v": 0,
        "parameter": {
          "no_of_emergency_switch": 5,
          "no_of_limit_switch": 6,
          "no_of_ppe": "Safety shoes"
        },
        "count_read_api_number": "1707083",
        "count_read_key": "C3DQXK1WT7DGZ25I",
        "count_write_key": "QNU138IKQ9PWYO3K",
        "status_read_key": "",
        "status_write_key": "NDAYOBJO7O7T8HSL",
        "count": 22079,
        "actual_qty": 0,
        "planned_quantity": 0
      },
      {
        "_id": {
          "$oid": "614d6fc02659cdb82dad27c1"
        },
        "name": " 350 T KRAUSS MAFFEI",
        "status": "ON",
        "createdAt": {
          "$date": {
            "$numberLong": "1632464832363"
          }
        },
        "updatedAt": {
          "$date": {
            "$numberLong": "1674539928589"
          }
        },
        "__v": 0,
        "department": "Production",
        "parameter": {
          "no_of_emergency_switch": 5,
          "no_of_limit_switch": 6,
          "no_of_ppe": "Safety shoes"
        },
        "count_read_api_number": "1707051",
        "count_read_key": "6HHF9787F5NK86GO",
        "count_write_key": "E5RYXLJS1WSMRT17",
        "status_read_key": "",
        "status_write_key": "6TZJHMTH9VTNOKPT",
        "count": 36593,
        "actual_qty": 0,
        "planned_quantity": 0
      },
      {
        "_id": {
          "$oid": "6233031e44e958716a08700c"
        },
        "name": "KM 160 T A",
        "status": [
          {
            "value": 0,
            "_id": {
              "$oid": "6233031e44e958716a08700d"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1647510302289"
              }
            }
          }
        ],
        "parameter": {
          "no_of_emergency_switch": 2,
          "no_of_limit_switch": 7,
          "no_of_ppe": "Hand Gloves"
        },
        "count": 29318,
        "department": "Production",
        "createdAt": {
          "$date": {
            "$numberLong": "1647510302290"
          }
        },
        "updatedAt": {
          "$date": {
            "$numberLong": "1674539810308"
          }
        },
        "__v": 0,
        "count_read_api_number": "1707066",
        "count_read_key": "5MP678PRRBLDHZ95",
        "count_write_key": "9R16UPM1L4XOF4DL",
        "status_read_key": "",
        "status_write_key": "3ZO5P3REG0TOQ33I",
        "actual_qty": 0,
        "planned_quantity": 0
      },
      {
        "_id": {
          "$oid": "6268c348af7fac41f23cfa05"
        },
        "name": "150 T DEMAG",
        "status": [
          {
            "value": 0,
            "_id": {
              "$oid": "6268c348af7fac41f23cfa06"
            },
            "when_updated": {
              "$date": {
                "$numberLong": "1651032904101"
              }
            }
          }
        ],
        "count": 52266,
        "department": "Production",
        "createdAt": {
          "$date": {
            "$numberLong": "1651032904107"
          }
        },
        "updatedAt": {
          "$date": {
            "$numberLong": "1675081688918"
          }
        },
        "__v": 0,
        "parameter": {
          "no_of_emergency_switch": 2,
          "no_of_limit_switch": 4,
          "no_of_ppe": "Safety shoes"
        },
        "count_read_api_number": "1707094",
        "count_read_key": "2M642TCCA3R1PO83",
        "count_write_key": "WRGGUHDC9PMOYBO0",
        "status_read_key": "",
        "status_write_key": "SK0CXPRFZP65H7C7",
        "actual_qty": 224,
        "planned_quantity": 980
      },
      {
        "_id": {
          "$oid": "614d6fd22659cdb82dad27c3"
        },
        "name": "KM 500 T KRAUSS MAFFEI",
        "status": "ON",
        "createdAt": {
          "$date": {
            "$numberLong": "1632464850919"
          }
        },
        "updatedAt": {
          "$date": {
            "$numberLong": "1674567018623"
          }
        },
        "__v": 0,
        "department": "Production",
        "parameter": {
          "no_of_emergency_switch": 4,
          "no_of_limit_switch": 7,
          "no_of_ppe": "Safety shoes"
        },
        "count_read_api_number": "1696535",
        "count_read_key": "XP4EWBQ6I56576GC",
        "count_write_key": "HZ24VM2SXMOBYIWW",
        "status_read_key": "",
        "status_write_key": "W7N6IOSOU751LA38",
        "count": 36367,
        "actual_qty": 144,
        "planned_quantity": 6370
      },
      
]

export default dummyData;