import { React } from "react";
import dummyData from "../../../pages/general_manager/dummy_data";

// const ColorBars = (props) => {
//     var alert_flag_list = [];
//     if (props.type === "machineStopAlerts") {

//         for (let i = 0; i < props.alertsData.length; i++) {
//             if (props.alertsData[i].color === "Red" && !alert_flag_list.includes("Red")) {
//                 alert_flag_list.push("Red");
//             }
//             else if (props.alertsData[i].color === "Orange" && !alert_flag_list.includes("Orange")) {
//                 alert_flag_list.push("Orange");
//             }
//             else if (props.alertsData[i].color === "Yellow" && !alert_flag_list.includes("Yellow")) {
//                 alert_flag_list.push("Yellow");
//             }
//         }
//     }
//     else if (props.type === "prodQtyAlerts") {
//         for (let i = 0; i < props.alertsData.length; i++) {
//             if (props.alertsData[i].percentage === "Red" && !alert_flag_list.includes("Red")) {
//                 alert_flag_list.push("Red");
//             }
//             else if (props.alertsData[i].percentage === "Orange" && !alert_flag_list.includes("Orange")) {
//                 alert_flag_list.push("Orange");
//             }
//             else if (props.alertsData[i].percentage === "Yellow" && !alert_flag_list.includes("Yellow")) {
//                 alert_flag_list.push("Yellow");
//             }
//         }
//     }
//     let alert_flag = "#34EB34";
//     if (alert_flag_list.includes("Red")) {
//         alert_flag = "Red";
//     }
//     else if (alert_flag_list.includes("Orange")) {
//         alert_flag = "Orange";
//     }
//     else if (alert_flag_list.includes("Yellow")) {
//         alert_flag = "Yellow";
//     }

//     return (
//         <>
//             <div className="color-bars">
//                 <div className="bar" id="mould-bar">
//                     <div className="bar-label"
//                         style={{ backgroundColor: alert_flag }}>

//                     </div>
//                     <button onClick={() => props.changeDataFunc(props.alertsData, props.machineInfo)}>
//                         <label
//                             htmlFor="table-bar"
//                         >
//                             Moulding
//                         </label>
//                     </button>
//                 </div>

//                 <div className="bar" id="dieCast-bar">
//                     <div className="bar-label"
//                         style={{ backgroundColor: alert_flag }}>

//                     </div>
//                     <button onClick={() => props.changeDataFunc(dummyData)}>
//                         <label
//                             htmlFor="table-bar"
//                         >
//                             Die Cast
//                         </label>
//                     </button>
//                 </div>

//                 <div className="bar" id="paint-bar">
//                     <div className="bar-label"
//                         /*style={{ backgroundColor: alert_flag }}*/>

//                     </div>
//                     <button onClick={() => props.changeDataFunc(dummyData)}>
//                         <label
//                             htmlFor="table-bar"
//                         >
//                             Paint shop
//                         </label>
//                     </button>
//                 </div>

//                 <div className="bar" id="assembly-bar">
//                     <div className="bar-label"
//                         /*style={{ backgroundColor: alert_flag }}*/>

//                     </div>
//                     <button onClick={() => props.changeDataFunc(dummyData)}>
//                         <label
//                             htmlFor="table-bar"
//                         >
//                             Assembly
//                         </label>
//                     </button>
//                 </div>

//                 <div className="bar" id="keyEquipment-bar">
//                     <div className="bar-label"
//                         /*style={{ backgroundColor: alert_flag }}*/>

//                     </div>
//                     <button onClick={() => props.changeDataFunc(dummyData)}>
//                         <label
//                             htmlFor="table-bar"
//                         >
//                             Key Equipment
//                         </label>
//                     </button>
//                 </div>
//             </div>
//         </>
//     )
// }

const ColorBars = (props) => {
  const getAlertFlag = (type) => {
    let alertFlagList = [];
    props.alertsData
            .forEach((dept)=>{
                dept.filter((item) => item.machineType === type)
                .map((item) => alertFlagList.push(item.percentage))
            })
            //console.log("Alert FL: ",alertFlagList)

    let alertFlag = "#34EB34";
    if (alertFlagList.includes("Red")) {
      alertFlag = "Red";
    } else if (alertFlagList.includes("Orange")) {
      alertFlag = "Orange";
    } else if (alertFlagList.includes("Yellow")) {
      alertFlag = "Yellow";
    }
    return alertFlag;
  };

  return (
    <>
      <div className="color-bars">
        <div className="bar" id="mould-bar">
          <div
            className="bar-label"
            style={{ backgroundColor: getAlertFlag("Injection Moulding") }}
          ></div>
          <button onClick={() => props.changeDataFunc(0)}>
            <label htmlFor="table-bar">Moulding</label>
          </button>
        </div>

        <div className="bar" id="dieCast-bar">
          <div
            className="bar-label"
            style={{ backgroundColor: getAlertFlag("Die Casting") }}
          ></div>
          <button onClick={() => props.changeDataFunc(1)}>
            <label htmlFor="table-bar">Die Cast</label>
          </button>
        </div>

        <div className="bar" id="paint-bar">
          <div
            className="bar-label"
            /*style={{ backgroundColor: alert_flag }}*/
          ></div>
          <button onClick={() => props.changeDataFunc(dummyData)}>
            <label htmlFor="table-bar"> Paint shop </label>
          </button>
        </div>

        <div className="bar" id="assembly-bar">
          <div
            className="bar-label"
            /*style={{ backgroundColor: alert_flag }}*/
          ></div>
          <button onClick={() => props.changeDataFunc(dummyData)}>
            <label htmlFor="table-bar">Assembly</label>
          </button>
        </div>

        <div className="bar" id="keyEquipment-bar">
          <div
            className="bar-label"
            /*style={{ backgroundColor: alert_flag }}*/
          ></div>
          <button onClick={() => props.changeDataFunc(dummyData)}>
            <label htmlFor="table-bar">Key Equipment</label>
          </button>
        </div>
      </div>
    </>
  );
};

export default ColorBars;
