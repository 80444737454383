import { React } from "react";



const QrciSummary = (props) => {

  let pm = [];
  let dc = [];
  // console.log(props)
  if (props.lineQrciData[0] && pm.push(...props.lineQrciData[0])) { }
  if (props.plantQrciData[0] && pm.push(...props.plantQrciData[0])) { }
  if (props.lineQrciData[1] && dc.push(...props.lineQrciData[1])) { }
  if (props.plantQrciData[1] && dc.push(...props.plantQrciData[1])) { }
  
  
  let flagColor = "green";
  let flagColorDC = "green";

  if (props.lineQrciData[0]) {
    console.log("prod qrxi data", props.lineQrciData[0]);
    let ongoing = props?.lineQrciData[0].filter((item) => item.current_status === "ongoing")
    
    for (let i = 0; i < ongoing.length; i++) {
      
      let qrciDate = new Date(ongoing[i].date_time)
      let todayDate = new Date(Date.now())
      let dif=(todayDate.getTime()-qrciDate.getTime()) / (1000 * 60 * 60 * 24);
      console.log("dif==", dif);
      if (ongoing[i].current_status === "ongoing") {       
        if(dif >7)
        {
          flagColor="red"
          break;
        }
        else if(dif>3)
        {
          flagColor="orange";
        }
        else if(dif>1 && flagColor!=="orange")
        {
          flagColor="yellow"
        }
      }
    }
  }
  if (props.lineQrciData[1]) {
    console.log("prod qrxi data", props.lineQrciData[1]);
    let ongoing = props?.lineQrciData[1].filter((item) => item.current_status === "ongoing")
    
    for (let i = 0; i < ongoing.length; i++) {
      
      let qrciDate = new Date(ongoing[i].date_time)
      let todayDate = new Date(Date.now())
      let dif=(todayDate.getTime()-qrciDate.getTime()) / (1000 * 60 * 60 * 24);
      console.log("dif==", dif);
      if (ongoing[i].current_status === "ongoing") {       
        if(dif >7)
        {
          flagColorDC="red"
          break;
        }
        else if(dif>3)
        {
          flagColorDC="orange";
        }
        else if(dif>1 && flagColorDC!=="orange")
        {
          flagColorDC="yellow"
        }
      }
    }
  }
  // console.log("ongoing === ",ongoing)


  
  return (
    <>
      <div className="r-table" id="qrci">
        <div className="qrci-bar">
          <table id="qrci-table-bar">
            <thead>
              <tr>
                <th colSpan="2"> QRCI</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <div id="lt">PM</div>
                </th>
                <td>
                <div className={`${flagColor}-bar`}>
                    <button onClick={() => { props.makeQrciVisible(0) }}>{pm.length}</button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <div id="lt">DC</div>
                </th>
                <td>
                <div className={`${flagColorDC}-bar`}>
                    <button onClick={() => { props.makeQrciVisible(1) }}>{dc.length}</button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <div id="lt">Assy</div>
                </th>
                <td>
                  <div className="green-bar">
                    <a href="/">x</a>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <div id="lt">Paint</div>
                </th>
                <td>
                  <div className="green-bar">
                    <a href="/">x</a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </>
  )
}

export default QrciSummary;






