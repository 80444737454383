import { Button, Form, Input, Select } from "antd";
// import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getUser } from "../../../../redux/users/userSlice";
import { getAllUsers } from "../../../../redux/dropDownData/dropDownDataSlice";
import { DatePicker, TimePicker } from "antd";
// import FormItem from "antd/lib/form/FormItem";
import moment from "moment";

import axiosApi from "../../../../api/axiosApi";
import BackButton from "../../../common/BackButton/backButton";
import "../style.css";

const CreateAlert = (props) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState();
	const userData = useSelector(getUser);
	const allUsersData = useSelector(getAllUsers);
	let history = useHistory();

	const onChangeDateGenerated = (date, dateString) => {
		form.setFieldsValue({
			dateGenerated: moment(dateString),
		});
	};

	const onChangeTimeGenerated = (time, timeString) => {
		form.setFieldsValue({
			timeGenerated: moment(timeString, "HH:mm:ss"),
		});
	};

	const onChangeDateTargeted = (date, dateString) => {
		form.setFieldsValue({
			dateTargeted: moment(dateString),
		});
	};

	const onChangeTimeTargeted = (time, timeString) => {
		form.setFieldsValue({
			timeTargeted: moment(timeString, "HH:mm:ss"),
		});
	};

	const onFinish = async (values) => {
		// console.log("values.should_escalate", values.should_escalate);
		if (values.should_escalate && values.should_escalate === "yes") {
			values.should_escalate = true;
		} else {
			values.should_escalate = false;
		}
		console.log("values", values);

		/* 
            receiverIds[0] - Below 90
            receiverIds[1] - 90-150
            receiverIds[2] - Above 150
        */
		let receiverIds = [[], [], []];

		var isPresent = false;
		if (
			allUsersData.Production.Manager &&
			allUsersData.Production.Supervisor &&
			// allUsersData.Maintenance.Manager &&
			// allUsersData["Tool Room"].Manager &&
			allUsersData["Quality Assurance"].Manager &&
			allUsersData["Quality Assurance"].Supervisor &&
			//
			allUsersData.Safety.Supervisor &&
			allUsersData.Safety.Manager &&
			allUsersData.Logistics.Supervisor &&
			allUsersData.Logistics.Manager
		) {
			isPresent = true;
		}

		if (isPresent) {
			// Issue field is "Production"
			if (values.field === "Production") {
				// Production Supervisors in receiverIds[0]
				Object.entries(allUsersData.Production.Supervisor).forEach(
					(e) => {
						receiverIds[0].push({ rId: e[1]._id, isRead: false });
					}
				);
				// Production Managers in receiverIds[1]
				Object.entries(allUsersData.Production.Manager).forEach((e) => {
					receiverIds[1].push({ rId: e[1]._id, isRead: false });
				});
				// ALL GM in receiverIds[2]
				Object.entries(allUsersData.Production.GeneralManager).forEach(
					(e) => {
						receiverIds[2].push({ rId: e[1]._id, isRead: false });
					}
				);
			}
			// Issue field is "Quality" or "Customer Complaint"
			if (
				values.field === "Quality" ||
				values.field === "Customer Complaint"
			) {
				// Quality Supervisors in receiverIds[0]
				Object.entries(
					allUsersData["Quality Assurance"].Supervisor
				).forEach((e) => {
					receiverIds[0].push({ rId: e[1]._id, isRead: false });
				});
				// Quality Managers in receiverIds[1]
				Object.entries(
					allUsersData["Quality Assurance"].Manager
				).forEach((e) => {
					receiverIds[1].push({ rId: e[1]._id, isRead: false });
				});
				// Quality Managers in receiverIds[2]
				Object.entries(
					allUsersData["Quality Assurance"].Manager
				).forEach((e) => {
					receiverIds[2].push({ rId: e[1]._id, isRead: false });
				});
			}

			// Issue field is "HSE"
			if (values.field === "HSE") {
				// Safety Supervisors in receiverIds[0]
				Object.entries(allUsersData.Safety.Supervisor).forEach((e) => {
					receiverIds[0].push({ rId: e[1]._id, isRead: false });
				});
				// Safety Managers in receiverIds[1]
				Object.entries(allUsersData.Safety.Manager).forEach((e) => {
					receiverIds[1].push({ rId: e[1]._id, isRead: false });
				});
				// Safety Managers in receiverIds[2]
				Object.entries(allUsersData.Safety.Manager).forEach((e) => {
					receiverIds[2].push({ rId: e[1]._id, isRead: false });
				});
			}

			// Issue field is "Logistics"
			if (values.field === "Logistics") {
				// Logistics Supervisors in receiverIds[0]
				Object.entries(allUsersData.Logistics.Supervisor).forEach(
					(e) => {
						receiverIds[0].push({ rId: e[1]._id, isRead: false });
					}
				);
				// Logistics Managers in receiverIds[1]
				Object.entries(allUsersData.Logistics.Manager).forEach((e) => {
					receiverIds[1].push({ rId: e[1]._id, isRead: false });
				});
				// Logistics Managers in receiverIds[2]
				Object.entries(allUsersData.Logistics.Manager).forEach((e) => {
					receiverIds[2].push({ rId: e[1]._id, isRead: false });
				});
			}

			console.log("ReceiverIds", receiverIds);

			axiosApi
				.post("production/injectionMoulding/createExternalQrciForms", {
					externally_created: true,
					type: "external_qrci",
					...values,
					senderId: userData._id,
					// current receivers are testdev2(Prod sup.) and testdev3(Prod. Mgr)
					receiverIds: receiverIds,
				})
				.then(function (response) {
					toast.success("Form Created Successfully", {
						position: "bottom-center",
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						progress: undefined,
					});
					form.resetFields();
					setTimeout(() => {
						history.goBack();
					}, 2000);
				})
				.catch(function (error) {
					console.log(error);
					alert("Error Occured : ", error.message);
				});
		} else {
			toast.error("Some roles are not created/active yet", {
				position: "bottom-center",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				progress: undefined,
			});
			setTimeout(() => {
				history.goBack();
			}, 3000);
		}
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
		alert("Error Occured : ", errorInfo);
	};

	useEffect(() => {
		setLoading(true);
		axiosApi
			.get(`production/injectionMoulding/getNextQrciNumber`)
			.then(async (response) => {
				form.setFieldsValue({
					nextQrciNumber: response.data.data.next_qrci_no,
				});
				console.log(
					"nextQrciNumber",
					form.getFieldValue("nextQrciNumber")
				);
				setLoading(false);
			})
			.catch((error) => console.log(`ERRORS : ${error}`));
	}, [form]);

	return !loading ? (
		<>
			<BackButton styleClass={"back-btn"} />
			<div className="card-holder">
				<ToastContainer />
				<div></div>
				<div className="form-card">
					<h1
						style={{
							paddingBottom: "10px",
							letterSpacing: "0.3rem",
							fontWeight: "bold",
							textAlign: "center",
						}}
					>
						ALERT CREATION FORM
					</h1>

					<Form
						labelCol={{ span: 10 }}
						wrapperCol={{ span: 8 }}
						form={form}
						layout="horizontal"
						colon={false}
						initialValues={{
							nextQrciNumber: -1,
							dateGenerated: moment(new Date()),
							timeGenerated: moment(new Date(), "HH:mm:ss"),
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
					>
						<Form.Item
							label="Field:"
							name="field"
							labelAlign="left"
						>
							<Select>
								<Select.Option value="HSE">HSE</Select.Option>
								<Select.Option value="Customer Complaint">
									Customer Complaint
								</Select.Option>
								<Select.Option value="Production">
									Production
								</Select.Option>
								<Select.Option value="Logistics">
									Logistics
								</Select.Option>
								<Select.Option value="Quality">
									Quality
								</Select.Option>
							</Select>
						</Form.Item>

						<Form.Item
							label="Type of Issue:"
							name="issue_type"
							labelAlign="left"
						>
							<Select>
								<Select.Option value="minor">
									Minor
								</Select.Option>
								<Select.Option value="major">
									Major
								</Select.Option>
								<Select.Option value="hospitalization">
									Hospitalization
								</Select.Option>
							</Select>
						</Form.Item>

						<Form.Item
							noStyle
							shouldUpdate={(prevValues, currentValues) =>
								prevValues.issue_type !==
								currentValues.issue_type
							}
							labelAlign="left"
						>
							{({ getFieldValue }) =>
								getFieldValue("issue_type") === "minor" ? (
									<Form.Item
										name="should_escalate"
										label="Escalate Line QRCI to Plant QRCI:"
										labelAlign="left"
										rules={[
											{
												required: true,
												message: "Please Select Yes/No",
											},
										]}
									>
										<Select>
											<Select.Option value="yes">
												Yes
											</Select.Option>
											<Select.Option value="no">
												No
											</Select.Option>
										</Select>
									</Form.Item>
								) : null
							}
						</Form.Item>

						<Form.Item
							label="Description of Issue: "
							name="issue_desc"
							labelAlign="left"
							rules={[
								{
									required: true,
									message:
										"Please enter Description of Issue!",
								},
							]}
						>
							<Input.TextArea
								style={{ width: 300 }}
								placeholder="Enter"
								type="text"
							/>
						</Form.Item>

						<Form.Item
							label="QRCI Number:"
							name="nextQrciNumber"
							labelAlign="left"
						>
							<Input type="number" disabled />
						</Form.Item>

						<Form.Item
							label="Pilot:"
							name="pilot"
							labelAlign="left"
						>
							<Input />
						</Form.Item>

						<Form.Item
							label="Date Generated:"
							labelAlign="left"
							name="dateGenerated"
							rules={[
								{
									required: true,
									message: "Please select Date Generated!",
								},
							]}
						>
							<DatePicker onChange={onChangeDateGenerated} />
						</Form.Item>

						<Form.Item
							label="Time Generated:"
							labelAlign="left"
							name="timeGenerated"
							rules={[
								{
									required: true,
									message: "Please select Time Generated!",
								},
							]}
						>
							<TimePicker onChange={onChangeTimeGenerated} />
						</Form.Item>

						<Form.Item
							label="Date Targeted:"
							labelAlign="left"
							name="dateTargeted"
							rules={[
								{
									required: true,
									message: "Please select Date Targeted!",
								},
							]}
						>
							<DatePicker onChange={onChangeDateTargeted} />
						</Form.Item>

						<Form.Item
							label="Time Targeted:"
							labelAlign="left"
							name="timeTargeted"
							rules={[
								{
									required: true,
									message: "Please select Time Targeted!",
								},
							]}
						>
							<TimePicker onChange={onChangeTimeTargeted} />
						</Form.Item>

						<div className="submit-btn">
							<Button type="danger" htmlType="submit">
								Submit
							</Button>
						</div>
					</Form>
				</div>
			</div>
		</>
	) : (
		<div style={{ fontSize: "300%" }}>Loading...</div>
	);
};

export default CreateAlert;
