import React from "react";

import { Form, Input } from "antd";
import { useSelector } from "react-redux";

import { getUser } from "../../redux/users/userSlice";

const EmployeeDetail = () => {
  const userData = useSelector(getUser);
  return (
    <div>
      <Form.Item label={<span style={{ fontSize: '26px' }}>Employee ID</span>} name="emp_id" labelAlign="left">
        <Input placeholder={userData.empid} disabled />
      </Form.Item>

      <Form.Item label={<span style={{ fontSize: '26px' }}>Employee Name</span>} name="emp_name" labelAlign="left">
        <Input
          placeholder={`${userData.firstname} ${userData.lastname}`}
          disabled
        />
      </Form.Item>
    </div>
  );
};

export default EmployeeDetail;
