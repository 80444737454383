import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Card } from "antd";

import axios from "../../../../api/axiosApi";
import "./getMaintenanceForms.css";
import BackButton from "../../../common/BackButton/backButton";

const MaintenanceOperatorForms = (props) => {
	const [allforms, getallforms] = useState([]);
	const {dept}=props.match.params;

	useEffect(() => {
		const fetchAllForms = async () => {
			await axios
				.get(
					`/production/${dept}/getMachineMaintainenceDataByManager?machineID=${props.location.state.machineId}`
				)
				.then((response) => {
					const allforms = response.data.data;
					getallforms(() => allforms);
					console.log("allforms - ",allforms)
				})
				.catch((error) => {
					console.log("error", error);
					// alert(error);
				});
		};
		fetchAllForms();
	}, [props.location.state.machineId, dept]);

	const tableBody = Object.keys(allforms).map(function (key, index) {
		return (
			
			<div key={index}>
				<BackButton styleClass={"back-btn"} />
				<Card
					title={key}
					style={{ width: 300, margin: 15 }}
					headStyle={{
						backgroundColor: "#FFA500",
						borderRadius: "10px",
						color: "#ffffff",
					}}
					raised="true"
				>
					<Link
						to={{
							pathname: `/machine-maintenance-review/${dept}`,
							state: {
								machineId: props.location.state.machineId,
								month: key,
							},
						}}
					>
						Review Maintenance
					</Link>
				</Card>
			</div>
		);
	});

	return (
		<div>
			{tableBody.length === 0 ? (
				<div>No forms present</div>
			) : (
				<div className="maintenance-forms">{tableBody}</div>
			)}
		</div>
	);
};

export default MaintenanceOperatorForms;
