// import React from "react";

import { Card } from "antd";
import { Link } from "react-router-dom";

const QASupervisorHistory = (props) => {
  // let history = useHistory();
  return (
    <div>
    
      <Link
        to={{
          pathname: `/shift_start_up`,
          state: {
            machineId: props.machineId,
            dept:props.dept,
          },
        }}
      >
        <Card
          className="card"
          key={1}
          style={{ margin: 15 }}
          headStyle={{
            backgroundColor: "#3366ff",
            borderRadius: "10px",
            color: "#ffffff",
          }}
          title={"Shift Startup Forms"}
          hoverable
        >
          <p>Click To View All Forms </p>
        </Card>
      </Link>

      <Link to={`/other_forms`}>
        <Card
          className="card"
          key={1}
          style={{ margin: 15 }}
          headStyle={{
            backgroundColor: "#3366ff",
            borderRadius: "10px",
            color: "#ffffff",
          }}
          title={"Other Forms"}
          hoverable
        >
          <p>Click To View All Forms </p>
        </Card>
      </Link>
    </div>
  );
};

export default QASupervisorHistory;
