import { MinusCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Radio } from "antd";
import React from "react";
import "./style.css";

const ActionToProtectCustomer = (props) => {
  // const onFinish = values => {
  //     console.log('Received values of form:', values);
  // };

  return (
    <>
      <Form.List name={props.name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <>
                <Form.Item
                  label="Action"
                  name={[name, "action"]}
                  fieldKey={[fieldKey, "action"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter Action' }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  label="Resp."
                  name={[name, "resp"]}
                  fieldKey={[fieldKey, "resp"]}
                  // rules={[{ required: true, message: 'Please enter data!' }]}
                  labelAlign="left"
                >
                  <Input type="string" />
                </Form.Item>
                <Form.Item
                  label="Deadline."
                  name={[name, "deadline"]}
                  fieldKey={[fieldKey, "deadline"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter data' }]}
                >
                  <Input type="date" />
                </Form.Item>
                <Form.Item
                  label="Done Date"
                  name={[name, "done_date"]}
                  fieldKey={[fieldKey, "done_date"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter data' }]}
                >
                  <Input type="date" />
                </Form.Item>
                <Form.Item
                  label="Effective"
                  name={[name, "effective"]}
                  fieldKey={[fieldKey, "effective"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter data' }]}
                >
                  <Radio.Group name="effective">
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label="New Risk Detected?"
                  name={[name, "new_risk_detected"]}
                  fieldKey={[fieldKey, "new_risk_detected"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter data' }]}
                >
                  <Radio.Group name="new_risk_detected">
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="Comments/Conclusions/Results"
                  name={[name, "comments"]}
                  fieldKey={[fieldKey, "comments"]}
                  labelAlign="left"
                  // rules={[{ required: true, message: 'Please enter Data' }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <MinusCircleTwoTone
                  twoToneColor="#eb2f96"
                  style={{ fontSize: "20px" }}
                  onClick={() => remove(name)}
                />
                <Divider />
              </>
            ))}
            <div className="add-button">
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            </div>
          </>
        )}
      </Form.List>
    </>
  );
};

export default ActionToProtectCustomer;
