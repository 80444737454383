import { Col, Input, Row } from "antd";
import React, { useState } from "react";

const PartsQuantityForm = ({ partQuantity, onTQWChange, index }) => {
  const [renderState, setRenderState] = useState(false);

  return (
    <div>
      {partQuantity &&
        partQuantity.map((item, index2) => {
          return (
            <div key={index2}>
              <Row style={{ margin: "1.2em 0" }}>
                <Col span={12} style={{ textAlign: "left" }}>
                  Part Name
                </Col>
                <Col span={12} style={{ textAlign: "left" }}>
                  <Input placeholder={item.part_id?.part_name} disabled />
                </Col>
              </Row>
              <Row style={{ margin: "1.2em 0" }}>
                <Col span={12} style={{ textAlign: "left" }}>
                  Accepted Quantity
                </Col>
                <Col span={12} style={{ textAlign: "left" }}>
                  <Input placeholder={item?.accepted_qty} disabled />
                </Col>
              </Row>
              <Row style={{ margin: "1.2em 0" }}>
                <Col span={12} style={{ textAlign: "left" }}>
                  Un-Accepted Quantity
                </Col>
                <Col span={12} style={{ textAlign: "left" }}>
                  <Input placeholder={item.unaccepted_qty} disabled />
                </Col>
              </Row>
              <Row style={{ margin: "1.2em 0" }}>
                <Col span={12} style={{ textAlign: "left" }}>
                  TQW Rejected Quantity
                </Col>
                <Col span={12} style={{ textAlign: "left" }}>
                  <Input
                    type="number"
                    defaultValue={item.tqw_rejected_quantity}
                    onChange={(e) => {
                      onTQWChange(index, index2, e);
                      setRenderState(!renderState);
                    }}
                  />
                </Col>
              </Row>
            </div>
          );
        })}
    </div>
  );
};

export default PartsQuantityForm;
