import { Button, Divider, Form, Input, Radio, Checkbox, Select } from "antd";
import React, { useEffect, useState } from "react";
//common components
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackButton from "../../components/common/BackButton/backButton";
import ActionToProtectCustomer from "./action_to_protect_customer";
import IsAndIsNotAnalysis from "./is_and_is_not_analysis";
import CauseNonDetection from "./cause_non_detection";
import CauseOfOccurence from "./cause_of_occurence";
import CorrectiveActions from "./corrective_actions";
import FiveWhysAnalysis from "./5_whys_analysis";
import ManagementActions from "./management_action";
import EffectivenessTracking from "./effectiveness_tracking";
import "./style.css";
import LessonsLearned from "./lessons_learned";
import axiosApi from "../../api/axiosApi";

const Qrci8DForm = (props) => {
	const { id } = props.match.params;
	const [form] = Form.useForm();
	const [initialData, setInitialData] = useState();
	const [loading, setLoading] = useState();
	const [error, setError] = useState();
	const [d7Data, setD7Data] = useState({
		incident_data: {
			previous_months: [""],
			previous_values: [0],
			next_months: [""],
			next_values: [0],
		},
		factor_1: {
			previous_months: [""],
			previous_values: [0],
			next_months: [""],
			next_values: [0],
		},
		factor_2: {
			previous_months: [""],
			previous_values: [0],
			next_months: [""],
			next_values: [0],
		},
		factor_3: {
			previous_months: [""],
			previous_values: [0],
			next_months: [""],
			next_values: [0],
		},
	});

	useEffect(() => {
		setLoading(true);
		axiosApi
			.get(
				`production/injectionMoulding/getPlantQrciForm?qrciRefNo=${id}`
			)
			.then((response) => {
				setInitialData(response.data.data);
				console.log(response.data.data);
				setD7Data(response.data.data.d7_effectiveness_tracking);
				setLoading(false);
			})
			.catch((error) => {
				console.log(`ERRORS : ${error}`);
				toast.error("🚀 Error while fetching data!!", {
					position: "bottom-center",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					progress: undefined,
				});
				setLoading(false);
				setError(true);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onFinish = (values) => {
		axiosApi
			.post(`production/injectionMoulding/submitPlantQrci`, {
				...values,
				d7_effectiveness_tracking: d7Data,
			})
			.then((response) => {
				toast.success("🚀 Form Updated Successfully", {
					position: "bottom-center",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					progress: undefined,
				});
			})
			.catch((error) => {
				console.log("Error", error);
				toast.error(`🚀 Error while updating data`, {
					position: "bottom-center",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					progress: undefined,
				});
			});
	};
	const onFinishFailed = (errorInfo) => {
		toast.error(`🚀 ${errorInfo}`, {
			position: "bottom-center",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			progress: undefined,
		});
	};
	const classificationOptions = [
		"Quality/HSE",
		"Logistic",
		"Program",
		"Others",
	];

	return !loading ? (
		<>
			<BackButton styleClass={"back-btn"} />
			<div className="card-holder">
				<ToastContainer />

				<div className="form-card qrci-form">
					<h1
						style={{
							paddingBottom: "10px",
							letterSpacing: "0.3rem",
							fontWeight: "bold",
							textAlign: "center",
						}}
					>
						QRCI 8D Form
					</h1>

					<Form
						labelCol={{
							span: 16,
						}}
						wrapperCol={{
							span: 8,
						}}
						form={form}
						layout="horizontal"
						colon={false}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						initialValues={initialData}
					>
						<Divider className="divider" />
						<Form.Item
							label="Problem"
							name="problem_as_per_operator"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter team!" }]}
						>
							<Input type="string" disabled />
						</Form.Item>
						<Form.Item
							label="Operator comment"
							name="comment_from_operator"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter team!" }]}
						>
							<Input type="string" disabled />
						</Form.Item>
						<Divider className="divider" />

						<span className="sub-headers">DATE AND TEAM:</span>
						<Form.Item
							label="Date/Time "
							name="date"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter date and time!" }]}
						>
							<Input type="datetime-local" />
						</Form.Item>

						<Form.Item
							label="QRCI Reference Number"
							name="qrci_ref_no"
							labelAlign="left"
							// // rules={[{ required: true, message: "Please QRCI Reference Number!" }]}
						>
							<Input type="number" disabled />
						</Form.Item>

						<Form.Item
							label="Pilot"
							name="pilot"
							labelAlign="left"
							// // rules={[{ required: true, message: "Please enter pilot!" }]}
						>
							<Input type="string" />
						</Form.Item>
						<Form.Item
							label="Team"
							name="team"
							labelAlign="left"
							// // rules={[{ required: true, message: "Please enter team!" }]}
						>
							<Input type="string" />
						</Form.Item>
						<Form.Item
							label="Opened"
							name="opened"
							labelAlign="left"
							// // rules={[{ required: true, message: "Please the the required details!!" }]}
						>
							<Input type="string" />
						</Form.Item>

						<Divider className="divider" />

						<span className="sub-headers">
							C1 (To be filled within 24 hours)
						</span>
						<Form.Item
							label="Deadline"
							name="c1_deadline"
							labelAlign="left"
							// // rules={[{ required: true, message: "Please enter date and time!" }]}
						>
							<Input type="datetime-local" />
						</Form.Item>
						<Form.Item
							label="Done Date"
							name="c1_done"
							labelAlign="left"
							// // rules={[{ required: true, message: "Please enter date and time!" }]}
						>
							<Input type="datetime-local" />
						</Form.Item>
						<Divider />

						<span className="sub-headers">
							Responsible Manager Validation
						</span>
						<Form.Item
							label="C1 Validation Manager Name"
							name="c1_validation_manager_name"
							labelAlign="left"
							// // rules={[{ required: true, message: "Please enter data!" }]}
						>
							<Input type="string" />
						</Form.Item>
						<Form.Item
							label="Huf View Validation Manager Name"
							name="huf_view_validation_manager_name"
							labelAlign="left"
							// // rules={[{ required: true, message: "Please enter data!" }]}
						>
							<Input type="string" />
						</Form.Item>
						<Divider />

						<span className="sub-headers">
							C1 to D6 (To be filled within 10 workdays)
						</span>
						<Form.Item
							label="C1 to D6 Deadline"
							name="c1_to_d6_deadline"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter date and time!" }]}
						>
							<Input type="datetime-local" />
						</Form.Item>
						<Form.Item
							label="C1 to D6 Done Date"
							name="c1_to_d6_done"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter date and time!" }]}
						>
							<Input type="datetime-local" />
						</Form.Item>
						<Divider />

						<Form.Item
							label="Pilot 2"
							name="pilot2"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter pilot!" }]}
						>
							<Input type="string" />
						</Form.Item>
						<Divider />
						<span className="sub-headers">
							C1 to D8 (To be filled within 60 workdays)
						</span>
						<Form.Item
							label="C1 to D8 Deadline"
							name="c1_to_d8_deadline"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter date and time!" }]}
						>
							<Input type="datetime-local" />
						</Form.Item>
						<Form.Item
							label="C1 to D8 Done Date"
							name="c1_to_d8_done"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter date and time!" }]}
						>
							<Input type="datetime-local" />
						</Form.Item>
						<Divider />

						<span className="sub-headers">
							Responsible Manager{" "}
						</span>
						<Form.Item
							label="Plant/Program Manager Name"
							name="plant_manager_name"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter data!" }]}
						>
							<Input type="string" />
						</Form.Item>
						<Divider />

						<span className="sub-headers">Classification</span>
						<Form.Item
							label="Classification"
							name="classification"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter data!" }]}
						>
							<Checkbox.Group options={classificationOptions} />
						</Form.Item>
						<Divider />

						{/* FORM STARTS HERE*/}
						<span className="sub-headers">CONTAINMENT-C1</span>
						<Form.Item
							label="What is the Problem? (CUSTOMER VIEW)"
							name="containment_c1_customer_view_problem"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter something!" }]}
						>
							<Input.TextArea />
						</Form.Item>

						<Divider className="divider" />

						<span className="sub-headers">
							IMMEDIATE ACTIONS TO PROTECT THE CUSTOMER
						</span>
						<br></br>
						<span className="sub-headers">
							(Customer satisfied within 24 hours)
						</span>
						<br></br>
						<span className="sub-headers">
							Please use 5W/2H analysis if necessary
						</span>
						<ActionToProtectCustomer name="immediate_action_to_protect_customer" />

						<Divider />
						<span className="sub-headers">
							IS AND IS NOT ANALYSIS
						</span>
						<IsAndIsNotAnalysis />

						<Divider />
						<span className="sub-headers">
							D1-PROBLEM DEFINITION 5W/2H (Initial HUF Review)
						</span>

						<Form.Item
							label="What is the problem?  Clear definition: OK/NOT-OK (Include Facts and Data)"
							name="what_is_the_problem"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter the problem!" }]}
						>
							<Input.TextArea />
						</Form.Item>
						<Form.Item
							label="Why is it a problem?"
							name="why_is_it_a_problem"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter the problem!" }]}
						>
							<Input.TextArea />
						</Form.Item>
						<Form.Item
							label="When was the Problem Created?"
							name="when_problem_created"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter the problem!" }]}
						>
							<Input.TextArea />
						</Form.Item>
						<Form.Item
							label="Who found the problem?"
							name="who_found_problem"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter the problem!" }]}
						>
							<Input.TextArea />
						</Form.Item>
						<Form.Item
							label="Where was the problem found?"
							name="where_problem_detected"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter the problem!" }]}
						>
							<Input.TextArea />
						</Form.Item>
						<Form.Item
							label="How was the problem found?"
							name="how_was_problem_detected"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter the problem!" }]}
						>
							<Input.TextArea />
						</Form.Item>
						<Form.Item
							label="How many? (HSE: Accident, Environment, Fire, Other)"
							name="how_many"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter the problem!" }]}
						>
							<Input type="number" />
						</Form.Item>
						<Form.Item
							label="Re-occurrence"
							name="reoccurence"
							labelAlign="left"
							// rules={[
							//     {
							//         required: true,
							//         message: "Please select Yes/No",
							//     },
							// ]}
						>
							<Radio.Group name="reoccurrence">
								<Radio value="Yes">Yes</Radio>
								<Radio value="No">No</Radio>
							</Radio.Group>
						</Form.Item>
						<Form.Item
							label="Need to inform other plants/R&D centers?"
							name="need_to_inform_others"
							labelAlign="left"
							// rules={[
							//     {
							//         required: true,
							//         message: "Please select Yes/No",
							//     },
							// ]}
						>
							<Radio.Group name="need_to_inform_others">
								<Radio value="Yes">Yes</Radio>
								<Radio value="No">No</Radio>
							</Radio.Group>
						</Form.Item>
						<Divider className="divider" />

						<span className="sub-headers">D2: SIMILAR RISKS</span>
						<Form.Item
							label="Are the any similar risks (compare with above section)?"
							name="d2_similar_risks"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter the problem!" }]}
						>
							<Input.TextArea />
						</Form.Item>

						<Divider className="divider" />

						<span className="sub-headers">
							D3: IMMEDIATE ACTIONS TO PROTECT HUF -
							CONTAINMENT-C2
						</span>
						<br></br>
						<span className="sub-headers">
							(Containment actions based on Huf View)
						</span>
						{/* Here we need create new immediate action(HUF) form same as the immediate action(Customer) 
                        Now Just including the old one*/}
						<ActionToProtectCustomer name="d3_immediate_actions_to_protect_huf_containment_c2" />
						<Divider className="divider" />
						<Form.Item
							label="What did you learn from containment?"
							name="what_did_u_learn_from_containment"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter what you learned!" }]}
						>
							<Input.TextArea />
						</Form.Item>
						<Form.Item
							label="Total No. of Defects found (At Customer) "
							name="total_no_of_defect_found_customer"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter counts of defects!" }]}
						>
							<Input type="number" />
						</Form.Item>
						<Form.Item
							label="Total No. of Defects found (Internally) "
							name="total_no_of_defect_found_internally"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter counts of defects!" }]}
						>
							<Input type="number" />
						</Form.Item>

						<Divider />

						<span className="sub-headers">
							D4: CAUSE OF NON-DETECTION
						</span>
						<br></br>
						<span className="sub-headers">
							In case ext. customer does not exist, D4 comes after
							D5 to show why couldn't we detect the cause of
							occurrence
						</span>
						<br></br>
						<CauseNonDetection />
						<Divider />

						<span className="sub-headers">
							D5: CAUSE OF OCCURENCE
						</span>
						<br></br>
						<span className="sub-headers">
							Why happends this time and not other time?
						</span>
						<br></br>
						<span className="sub-headers">
							In case ext. customer does not exist, D5 comes
							before D4
						</span>
						<br></br>
						<CauseOfOccurence />
						<Divider />

						<span className="sub-headers">
							D6: CORRECTIVE ACTIONS
						</span>
						<br></br>
						<span className="sub-headers">
							(Process, Machines, Standards, Hazard
							Identification, Risk assessments and Determining
							Controls )
						</span>
						<br></br>
						<CorrectiveActions />
						<Divider />

						<span className="sub-headers">
							D7: Effectiveness Tracking
						</span>
						<br></br>
						<EffectivenessTracking
							factor={"INCIDENT DATA"}
							name="incident_data"
							d7Data={d7Data}
							setD7Data={setD7Data}
						/>
						<Divider />
						<EffectivenessTracking
							factor={"FACTOR 1"}
							name="factor_1"
							d7Data={d7Data}
							setD7Data={setD7Data}
						/>
						<Divider />
						<EffectivenessTracking
							factor={"FACTOR 2"}
							name="factor_2"
							d7Data={d7Data}
							setD7Data={setD7Data}
						/>
						<Divider />
						<EffectivenessTracking
							factor={"FACTOR 3"}
							name="factor_3"
							d7Data={d7Data}
							setD7Data={setD7Data}
						/>
						<Divider />

						<span className="sub-headers">D8: LESSONS LEARNED</span>
						<br></br>
						<LessonsLearned />
						<Divider />

						<span className="sub-headers">5 WHYs ANALYSIS</span>
						<br></br>
						<span className="sub-headers">
							(Management Point of View)
						</span>
						<br></br>

						<FiveWhysAnalysis />
						<br></br>
						<Form.Item
							label="Management Issue"
							name="why_analysis_management_issue"
							labelAlign="left"
							// rules={[{ required: true, message: "Please enter the issue!" }]}
						>
							<Input.TextArea />
						</Form.Item>
						<Divider />

						<span className="sub-headers">
							MANAGEMENT ACTIONS LIST
						</span>
						<ManagementActions />
						<br></br>
						<Divider />
						<Form.Item
							label="Current Status"
							name="current_status"
							labelAlign="left"
						>
							<Select>
								<Select.Option key="1" value="completed">
									Completed
								</Select.Option>
								<Select.Option key="2" value="dumped">
									Abort
								</Select.Option>
								<Select.Option key="3" value="ongoing">
									Ongoing
								</Select.Option>
							</Select>
						</Form.Item>

						<Divider />
						<div className="submit-btn">
							<Button type="danger" htmlType="submit">
								Submit
							</Button>
						</div>
					</Form>
				</div>
			</div>
		</>
	) : error ? (
		<div>Error while fetching/updating data...</div>
	) : (
		<div>Loading...</div>
	);
};

export default Qrci8DForm;
