import React from 'react';
import { Modal } from 'antd';
import { Tabs,Table } from 'antd';

const { TabPane } = Tabs;

const DetailsModal = ({isModalVisible,handleCancel,handleOk,data}) => {

  const partColumns = [
    {
      title: 'SrNo',
      dataIndex: 'SrNo',
      key: 'name',
      render: (text,record,index) => (
        <p>{index+1}</p>
      )
    },
    {
      title: 'Part Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: data?.title,
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const machineColumns = [
    {
      title: 'SrNo',
      dataIndex: 'SrNo',
      key: 'name',
      render: (text,record,index) => (
        <p>{index+1}</p>
      )
    },
    {
      title: 'Machine Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title:  data?.title,
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const activeColumns = [
    {
      title: 'SrNo',
      dataIndex: 'SrNo',
      key: '_id',
      render: (text,record,index) => (
        <p>{index+1}</p>
      )
    },
    {
      title: 'Machine Name',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  return !data?.Active_Machines ? (
    <>
      <Modal title="Details" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={1000}>
        <Tabs defaultActiveKey="1" >
            <TabPane tab="Machine Details" key="1">
              <Table dataSource={data?.data_by_machines} columns={machineColumns} />
            </TabPane>
            <TabPane tab="Part Details" key="2">
              <Table dataSource={data?.data_by_parts} columns={partColumns} />
            </TabPane>
        </Tabs>
      </Modal>
    </>
  ) : (
    <>
      <Modal title="Active Machines" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={1000}>
        <Table dataSource={data?.Active_Machines} columns={activeColumns} />
      </Modal>
    </>
  );
};

export default DetailsModal;