import { Row, Col, Divider, Card } from "antd";
import { useEffect, useState } from "react";
import axiosApi from "../../api/axiosApi";
import { useSelector } from "react-redux";
import { getMachinesData } from "../../redux/dropDownData/dropDownDataSlice";

const sectionContainerStyle = {
	border: "black solid",
	borderRadius: "8px",
	margin: "1rem",
	padding: "0.5rem",
};

const sectionTitleStyle = {
	fontWeight: "500",
	fontSize: "1.5rem",
	marginTop: "1rem",
	marginBottom: "1rem",
};

function MaintCommonScreen() {
	let [screenData, setScreenData] = useState([]);
	const machines = useSelector(getMachinesData);

	useEffect(() => {
		const assignColorLevel = (color) => {
			if (color === "Yellow") {
				return 1;
			}
			if (color === "Orange") {
				return 2;
			}
			if (color === "Red") {
				return 3;
			}
			return 0;
		};

		// Sort Alerts by their Color
		const sortAlerts = (alerts) => {
			// Place the higher Color level
			// (i.e. worse) alert first when comparing two alerts
			const alertComparator = (a1, a2) => {
				if (a1.colorLevel === a2.colorLevel) {
					const date1 = new Date(a1.when_created);
					const date2 = new Date(a2.when_created);
					if (date1 < date2) {
						return -1;
					}
					if (date1 > date2) {
						return 1;
					}
					return 0;
				}
				return a1.colorLevel < a2.colorLevel ? 1 : -1;
			};
			alerts.sort(alertComparator);
		};

		// Sort Machines by their Worst Status
		const sortMachines = (allMachinesData) => {
			const machineComparator = (m1, m2) => {
				if (
					m1.machineAlerts.length === 0 &&
					m2.machineAlerts.length === 0
				) {
					return 2;
				}
				if (
					m1.machineAlerts.length === 0 ||
					m2.machineAlerts.length === 0
				) {
					return m1.machineAlerts.length < m2.machineAlerts.length
						? 1
						: -1;
				}
				if (
					m1.machineAlerts[0].colorLevel ===
					m2.machineAlerts[0].colorLevel
				) {
					if (m1.machineAlerts.length === m2.machineAlerts.length) {
						return 0;
					}
					return m1.machineAlerts.length < m2.machineAlerts.length
						? 1
						: -1;
				}
				return m1.machineAlerts[0].colorLevel <
					m2.machineAlerts[0].colorLevel
					? 1
					: -1;
			};
			allMachinesData.sort(machineComparator);
		};

		const fetchScreenData = async () => {
			await axiosApi
				.get("production/injectionMoulding/getMaintenanceScreenData")
				.then((resp) => {
					const fetchedAlerts = resp.data.data;
					// console.log("fetchedAlerts", fetchedAlerts);
					const allMachinesData = [];
					for (let m of machines) {
						let machineAlerts = [];
						for (let alert of fetchedAlerts) {
							if (alert.machineId === m._id) {
								alert.colorLevel = assignColorLevel(
									alert.color
								);
								machineAlerts.push(alert);
							}
						}
						sortAlerts(machineAlerts);
						allMachinesData.push({
							machineName: m.name,
							machineId: m._id,
							machineAlerts,
						});
					}
					sortMachines(allMachinesData);
					console.log(allMachinesData);
					setScreenData(allMachinesData);
				});
		};
		fetchScreenData();
		setInterval(() => {
			fetchScreenData();
		}, 60 * 1000);
	}, [machines]);

	return (
		<>
			<div
				className="title"
				style={{
					color: "navy",
					margin: "1rem",
					marginBottom: "2rem",
					padding: "1rem",
					fontSize: "2.5rem",
					fontWeight: "700",
				}}
			>
				MAINTENANCE TOWER LAMP
			</div>

			<Row style={{ margin: "2rem" }}>
				<Col span={9}>
					<div className="main" style={sectionContainerStyle}>
						<h1 style={sectionTitleStyle}>INJECTION MOULDING</h1>
						<Divider />

						{screenData.map((machineData) => {
							let bgColor;
							if (machineData.machineAlerts.length > 0) {
								bgColor = machineData.machineAlerts[0].color;
							} else {
								bgColor = "Green";
							}
							let textColor = "white";
							if (bgColor === "Yellow") {
								textColor = "black";
							}
							return (
								<Card
									key={machineData.machineId}
									title={machineData.machineName}
									size="small"
									style={{
										border: "1px solid grey",
										margin: "1.5rem",
									}}
									headStyle={{
										backgroundColor: bgColor.toLowerCase(),
										color: textColor,
										fontWeight: "700",
										fontSize: "1rem",
									}}
								>
									{/* {machineData.machineAlerts?.length > 0 && } */}
									<div style={{ color: "black" }}>
										{machineData.machineAlerts.length > 0 &&
											machineData.machineAlerts[0].data
												.comments}
									</div>
								</Card>
							);
						})}
					</div>
				</Col>
				<Col span={5}>
					<div className="main" style={sectionContainerStyle}>
						<h1 style={sectionTitleStyle}>DIE-CASTING</h1>
						<Divider />
					</div>
				</Col>
				<Col span={5}>
					<div className="main" style={sectionContainerStyle}>
						<h1 style={sectionTitleStyle}>PAINT-SHOP</h1>
						<Divider />
					</div>
				</Col>
				<Col span={5}>
					<div className="main" style={sectionContainerStyle}>
						<h1 style={sectionTitleStyle}>ASSEMBLY</h1>
						<Divider />
					</div>
				</Col>
			</Row>

			<Row style={{ margin: "2rem" }}></Row>
		</>
	);
}

export default MaintCommonScreen;
