import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosApi from "../../../api/axiosApi";
import BackButton from "../../common/BackButton/backButton";
import "./prod_manager.css";

const ProductionSupervisorDetailsPage = () => {
  const [data, setData] = useState();
  const [empty, setEmpty] = useState(false);
  // const [tableData,setTableData] = useState(
  //   [
  //   {
  //     key: '1',
  //     SrNo: 1,
  //     date: '20-11-21',
  //     partName : 'mould A',
  //     partNo: 101,
  //     machineName: 'Moulding Machine',
  //     qtyRequired: 500,
  //     plannedTime: 20,
  //     review:'Delayed',
  //     actualTime : 'NULL',
  //   },
  //   {
  //     key: '2',
  //     SrNo: 2,
  //     date: '22-11-21',
  //     partName : 'mould B',
  //     partNo: 101,
  //     machineName: 'Moulding Machine',
  //     qtyRequired: 190,
  //     plannedTime: 50,
  //     review:'On Time',
  //     actualTime : 'NULL',
  //   },
  // ]
  // );

  useEffect(() => {
    setEmpty(false);
    axiosApi
      .get(
        `production/injectionMoulding/getProductionManagerForm`
      )
      .then((response) => {
        console.log(response);
        setData(response.data.data.detailData);
        setEmpty(true);
      })
      .catch((error) => console.log(`ERRORS : ${error}`));
  }, []);
  // const tableData = [
  //   {
  //     key: '1',
  //     SrNo: 1,
  //     date: '20-11-21',
  //     partName : 'mould A',
  //     partNo: 101,
  //     machineName: 'Moulding Machine',
  //     qtyRequired: 500,
  //     plannedTime: 20,
  //     review:'Delayed',
  //     actualTime : 'NULL',
  //   },
  //   {
  //     key: '2',
  //     SrNo: 2,
  //     date: '22-11-21',
  //     partName : 'mould B',
  //     partNo: 101,
  //     machineName: 'Moulding Machine',
  //     qtyRequired: 190,
  //     plannedTime: 50,
  //     review:'On Time',
  //     actualTime : 'NULL',
  //   },
  // ];
  console.log(data);
  const tableColumns = [
    {
      title: "Machine Name",
      dataIndex: "machine_name",
      key: "machine_name",
    },
    {
      title: "Downtime",
      dataIndex: "downtime",
      key: "downtime",
    },
    // {
    //   title: "Material Used",
    //   dataIndex: "material_used",
    //   key: "material_used",
    // },
    {
      title: "Output",
      dataIndex: "output",
      key: "output",
    },
    {
      title: "Rejection",
      dataIndex: "rejection",
      key: "rejection",
    },
    // {
    //   title: "Rejection Assembly",
    //   dataIndex: "rejection_assembly",
    //   key: "rejection_assembly",
    // },
    // {
    //   title: "Rejection Paint Shop",
    //   dataIndex: "rejection_paintshop",
    //   key: "rejection_paintshop",
    // },
    {
      title: "Rework Status Offline",
      dataIndex: "rework",
      key: "rework",
    },
    // {
    //   title: "Throughput Data",
    //   dataIndex: "throughput_data",
    //   key: "throughput_data",
    // },
  ];
  return empty ? (
    <div className="card-holder">
      <BackButton styleClass="back-btn" />
      <ToastContainer />
      <div className="form-card-prod-manager margin-top-set">
        <h1
          style={{
            paddingBottom: "10px",
            letterSpacing: "0.3rem",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          ALL MACHINE DETAILS
        </h1>
        <Table columns={tableColumns} dataSource={data} scroll={{ x: 1200 }} />
      </div>
    </div>
  ) : (
    <div>Fetching Details......</div>
  );
};

export default ProductionSupervisorDetailsPage;
