// import React from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getCurrOperatorData } from "../redux/operators/operatorSlice";
import { getUser } from "../redux/users/userSlice";

// dashboards

import OperatorDashboard from "../pages/operator/operator_dashboard";
import OpSelectMachinePage from "../pages/operator/op_dashboard";
import ProductionManagerDashboard from "../pages/production_manager/prod_manager_dashboard";
import QASupervisorDashboard from "../pages/quality_supervisor/qa_supervisor_dashboard";
import SupervisorDashboard from "../pages/supervisor/supervisor_dashboard";
import MaintenanceOperator from "../pages/maintenance/maintenance_operator/maintenance_operator_dashboard";
import MaintenanceManager from "../pages/maintenance/maintenance_manager/maintenance_manager_dashboard";

import GeneralManagerDashboard from "../pages/general_manager/general_manager_dashboard";
import ManagingDirectorDashboard from "../pages/managing_director/managing_director_dashboard";
import ProductionStats from "../components/common/ProductionStats/ProductionStats";
import AllDepartmentBoard from "../components/common/ProductionStats/AllDepartmentBoard";
import MaintCommonScreen from "../pages/maintenance/maint_common_screen";
import LogCommonScreen from "../pages/logistics/log_common_screen";

import DCOpSelectMachinePage from "../pages/DC/Operator/dc_op_dashboard"
import DCOperatorDashboard from "../pages/DC/Operator/dc_operator_dashboard"
import DCManager from "../pages/DC/production_manager/dc_prod_manager_dashboard"

import DCSupervisorDashboard from "../pages/DC/supervisor/dc_supervisor_dashboard"


const Dashboards = (props) => {
  const userData = useSelector(getUser);
  const operatorData = useSelector(getCurrOperatorData);
  // console.log("userdata = ", userData)
  let role = userData["roles"][0];
  let dept = userData["dept"][0];
  let operation = userData["operation"][0];

  let renderDashboard = "Loading Your Dashboard";

  function switchRole() {
    switch (role) {
      case "Operator":
        if ( operation==="Injection Moulding") {
          return operatorData === true ? (
            <OperatorDashboard />
          ) : (
            <OpSelectMachinePage />
          );
        } else if (dept === "Maintenance") {
          return <MaintenanceOperator />;
        }
        else if(operation==="Die Casting")
        {
          return operatorData === true ? (
            <DCOperatorDashboard />
          ) : (
            <DCOpSelectMachinePage />
          );
        }
        break;

      case "Supervisor":
        if (operation==="Injection Moulding") {
          return <SupervisorDashboard />;
        }
        else if(operation==="Die Casting"){
          return <DCSupervisorDashboard />
        }
        if (dept === "Quality Assurance") {
          return <QASupervisorDashboard />;
        }
        break;

      case "Manager":
        if (dept === "Production" && operation==="Injection Moulding") {
          return <ProductionManagerDashboard />;
        } else if (dept === "Maintenance") {
          return <MaintenanceManager />;
        }
        else if(operation==="Die Casting")
        {
          return  <DCManager/>;
         
        }
        break;

      case "GeneralManager":
        return <GeneralManagerDashboard />;

      case "ManagingDirector":
        return <ManagingDirectorDashboard />;

      case "CommonScreen":
        if (dept === "Maintenance") {
          return <MaintCommonScreen />;
        }
        if (dept === "Logistics") {
          return <LogCommonScreen />;
        }
        break;

      default:
        <h2>Your are lost Somewhere </h2>;
    }
  }
  renderDashboard = switchRole();
  return (
    <div>
      <ToastContainer />
      {role !== "Operator" &&
        role !== "GeneralManager" &&
        role !== "ManagingDirector" &&
        role !== "CommonScreen" && <ProductionStats dept ={dept} role={role}/>}
      {(role === "GeneralManager" || role === "ManagingDirector") && (
        <AllDepartmentBoard />
      )}
      {renderDashboard}
    </div>
  );
};

export default Dashboards;
