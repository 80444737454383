import { MinusCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input } from "antd";
import React from "react";

const FiveWhysAnalysis = () => {
  return (
    <>
      <Form.List name="_5_whys_analysis">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <>
                <Form.Item
                  label="N^0"
                  name={[name, "n_0"]}
                  fieldKey={[fieldKey, "n_0"]}
                  // rules={[{ required: true, message: 'Please enter data!' }]}
                  labelAlign="left"
                >
                  <Input type="string" />
                </Form.Item>
                <Form.Item
                  label="Why? (1)"
                  name={[name, "why1"]}
                  fieldKey={[fieldKey, "why1"]}
                  // rules={[{ required: true, message: 'Please enter data!' }]}
                  labelAlign="left"
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  label="Validated Factors (V) 1"
                  name={[name, "v1"]}
                  fieldKey={[fieldKey, "v1"]}
                  // rules={[{ required: true, message: 'Please enter data!' }]}
                  labelAlign="left"
                >
                  <Input type="string" />
                </Form.Item>

                <Form.Item
                  label="Why? (2)"
                  name={[name, "why2"]}
                  fieldKey={[fieldKey, "why2"]}
                  // rules={[{ required: true, message: 'Please enter data!' }]}
                  labelAlign="left"
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  label="Validated Factors (V) 2"
                  name={[name, "v2"]}
                  fieldKey={[fieldKey, "v2"]}
                  // rules={[{ required: true, message: 'Please enter data!' }]}
                  labelAlign="left"
                >
                  <Input type="string" />
                </Form.Item>

                <Form.Item
                  label="Why? (3)"
                  name={[name, "why3"]}
                  fieldKey={[fieldKey, "why3"]}
                  // rules={[{ required: true, message: 'Please enter data!' }]}
                  labelAlign="left"
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  label="Validated Factors (V3) 1"
                  name={[name, "v3"]}
                  fieldKey={[fieldKey, "v3"]}
                  // rules={[{ required: true, message: 'Please enter data!' }]}
                  labelAlign="left"
                >
                  <Input type="string" />
                </Form.Item>

                <Form.Item
                  label="Why? (4)"
                  name={[name, "why4"]}
                  fieldKey={[fieldKey, "why4"]}
                  // rules={[{ required: true, message: 'Please enter data!' }]}
                  labelAlign="left"
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  label="Validated Factors (V) 4"
                  name={[name, "v4"]}
                  fieldKey={[fieldKey, "v4"]}
                  // rules={[{ required: true, message: 'Please enter data!' }]}
                  labelAlign="left"
                >
                  <Input type="string" />
                </Form.Item>

                <Form.Item
                  label="Why? (5)"
                  name={[name, "why5"]}
                  fieldKey={[fieldKey, "why5"]}
                  // rules={[{ required: true, message: 'Please enter data!' }]}
                  labelAlign="left"
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  label="Validated Factors (V) 5"
                  name={[name, "v5"]}
                  fieldKey={[fieldKey, "v5"]}
                  // rules={[{ required: true, message: 'Please enter data!' }]}
                  labelAlign="left"
                >
                  <Input type="string" />
                </Form.Item>

                <MinusCircleTwoTone
                  twoToneColor="#eb2f96"
                  style={{ fontSize: "20px" }}
                  onClick={() => remove(name)}
                />
                <Divider />
              </>
            ))}
            <div className="add-button">
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            </div>
          </>
        )}
      </Form.List>
    </>
  );
};

export default FiveWhysAnalysis;
