import {
  EditOutlined,
  LogoutOutlined,
  UserOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import { notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosApi from "../../../api/axiosApi";
import auth from "../LoginComponent/auth";
import { removeDropDownData } from "../../../redux/dropDownData/dropDownDataSlice";
import { removeOperatorData } from "../../../redux/operators/operatorSlice";
import { getUser, removeUser } from "../../../redux/users/userSlice";
import HufLogo from "../HufLogo/index";
import NotificationImg from "../Notifications/notification.svg";
import "./navbar.css";

import { removeSocketClientLocally } from "../../../api/socket";

const NavBar = (props) => {
  const userData = useSelector(getUser);
  const [showUser, setShowUser] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const ref = useRef();
  const dispatch = useDispatch();

  const logout = () => {
    axiosApi
      .get("/auth/logout")
      .then((res) => {
        console.log(res)
        toast.success(`✔ ${res.data.msg}`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        if (res.data["err"] === false && res.data["msg"] === "Logged Out Successfully") {
          auth.logout(() => {
            setTimeout(() => {
              window.location.href = "/";
              dispatch(removeUser());
              dispatch(removeDropDownData());
              dispatch(removeOperatorData());
              removeSocketClientLocally();
            }, 3000);
          });
        }
      })
      .catch((e) => {
        console.log("error", e);
        alert("Error while Logging Out");
      });
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showUser && ref.current && !ref.current.contains(e.target)) {
        setShowUser(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showUser]);

  // useEffect(() => {
  //   const checkIfClickedOutside = (e) => {
  //     if (showNotification && ref.current && !ref.current.contains(e.target)) {
  //       setShowNotification(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", checkIfClickedOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", checkIfClickedOutside);
  //   };
  // }, [showNotification]);

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const getNotifs = () => {
      props.socket.on("getNotification", (data) => {
        setNotifications((prev) => [...prev, data]);

        const title = `${
          data.alerts.type === "system"
            ? `System Generated Alert - ${data.alerts.color}`
            : ` ${data.senderdata.senderRole} ${data.senderdata.senderName} from ${data.senderdata.senderDept} has sent Color - ${data.alerts.color}`
        }`;

        const desc = `Reason : ${data.alerts.data.msg}\n\n ${
          data.alerts.data.comments
            ? `Comments : ${data.alerts.data.comments}`
            : ""
        }`;

        openNotification(title, desc, "warning");
      });
    };
    if (props.socket !== null) {
      getNotifs();
    }
  }, [props.socket]);

  useEffect(() => {
    const fetchNotifications = () => {
      axiosApi
        .get(
          `production/injectionMoulding/getAlerts?receiverId=${userData._id}&isRead=false`
        )
        .then((res) => {
          const alertData = res.data.data;
          setNotifications(() => alertData);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };

    if (userData._id) {
      fetchNotifications();
    }
  }, [userData._id]);

  const openNotification = (title, desc, color) => {
    notification[color]({
      message: title,
      description: desc,
      duration: 0,
    });
  };

  let history = useHistory();

  return (
    <nav className="huf-nav-bar">
      <ToastContainer />
      <div className="logo">
        <HufLogo />
      </div>
      <div className="right-content on-hover">
        <div
          className="icon"
          onClick={() => setShowNotification(!showNotification)}
        >
          <img
            src={NotificationImg}
            onClick={() => {
              history.push("/notifications", {
                userId: userData._id,
              });
            }}
            className="iconImg"
            alt=""
          />
          {notifications?.length > 0 && (
            <div className="counter">{notifications?.length}</div>
          )}
        </div>

        <div
          ref={ref}
          onClick={() => setShowNotification(false)}
          className="on-hover"
        >
          <LineChartOutlined
            style={{
              fontSize: "1.5rem",
              color: "#FFFFFF",
              marginRight: "1.5rem",
            }}
            onClick={() => {
              history.push("/hardware");
            }}
          />

          <UserOutlined
            style={{ fontSize: "1.5rem", color: "#FFFFFF" }}
            onClick={() => setShowUser((prevState) => !prevState)}
          />

          {showUser && (
            <ul className="nav-ul hide">
              <li className="nav-li">
                <EditOutlined /> {`${userData.firstname} ${userData.lastname}`}
              </li>

              <li className="nav-li logout">
                <p onClick={logout}>
                  <LogoutOutlined /> Logout
                </p>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
