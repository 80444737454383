import { Button, Layout, Tabs } from "antd";
import { FormOutlined, HistoryOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import axiosApi from "../../api/axiosApi";
import { Link } from "react-router-dom";
import "./prod_manager_dash.css";
import StoppageAlert from "./StoppageAlert";
import Redkpi from "./KPI/red_alert_KPI";
import DIHkpi from "./KPI/dih_KPI";
import NCCkpi from "./KPI/ncc_cost_KPI";
import Throughputkpi from "./KPI/throughput_KPI";
import MajBreakdownkpi from "./KPI/maj_breakdown_KPI";

import FeedbackTable from "../../components/forms/production_manager/feedbacks_table";
import AlertStat from "./alertStat";
const { TabPane } = Tabs;

const { Content } = Layout;

const ProductionManagerDashboard = () => {
	const [machineStoppageAlerts, setMachineStoppageAlerts] = useState([]);
	const [machineStoppageAlert, setMachineStoppageAlert] = useState([]);
	const [redAlert, setRedAlert] = useState([]);
	const [machineInfo, getMachineInfo] = useState([]);
	const [StopAlertsVisibility, setStopAlertsVisibility] = useState(false);
	const [DIH, setDIH] = useState([]);
	const [NCC, setNCC] = useState([]);
	const [ThroughPut, setThroughPut] = useState([]);
	const [MajBreakdown, setMajorBreakdown] = useState([]);

	const makeStopAlertsVisible = (data) => {
		setStopAlertsVisibility(true);
		console.log("data = ", data)
		console.log("machine info =", machineInfo)
		const modData = data.map((item) => {
			let alert = item.color;
			let machine_details = machineInfo.filter((machineItem) => {
				return machineItem._id === item.machineId;
			})[0];
			let machine_name;
			if (machine_details !== undefined) {
				machine_name = machine_details.name;
			} else {
				machine_name = "Name Not Found in DB";
			}
			let machine_msg = item.data.msg;
			return { machine_name, machine_msg, alert };
		});

		setMachineStoppageAlert(modData);
		console.log("makestopalertvisible", modData);
	};

	useEffect(() => {
		getMachineStoppageAlerts();
		getMachineInfoRequest();
		getRedAlert();
		getDIH();
		getNCC();
		getThroughPut();
		getMajBreakdown();
	}, []);

	const getMachineStoppageAlerts = async () => {
		await axiosApi
			.get(`production/injectionMoulding/getAlertSummery`)
			.then((response) => {
				const alertsData = response.data.data;
				setMachineStoppageAlerts(alertsData);
				console.log("DataAlert", alertsData);
			})
			.catch((error) => {
				console.log(`ERRORS : ${error}`);
				alert(`Error:"${error}`);
			});
		setStopAlertsVisibility(false);
	};

	const getRedAlert = async () => {
		await axiosApi
			.get(`production/injectionMoulding/getAlertSummery`)
			.then((response) => {
				const alertsData = response.data.data;
				setRedAlert(alertsData);
			})
			.catch((error) => {
				console.log(`ERRORS : ${error}`);
				alert(`Error:"${error}`);
			});
	};

	const getDIH = async () => {
		await axiosApi
			.get(`production/injectionMoulding/getQASupervisorFeedback`)
			.then((response) => {
				const dihData = response.data.data.ProductionManagerData;
				console.log("DIH", dihData);
				setDIH(dihData);
			})
			.catch((error) => {
				console.log(`ERROR : ${error}`);
				alert(`Error:"${error}`);
			});
	};

	const getNCC = async () => {
		await axiosApi
			.get(`production/injectionMoulding/getQASupervisorFeedback`)
			.then((response) => {
				const nccData = response.data.data.ProductionManagerData;
				console.log("NCC", nccData);
				setNCC(nccData);
			})
			.catch((error) => {
				console.log(`ERROR : ${error}`);
				alert(`Error:"${error}`);
			});
	};

	const getThroughPut = async () => {
		await axiosApi
			.get(`production/injectionMoulding/getQASupervisorFeedback`)
			.then((response) => {
				const throughputData = response.data.data.ProductionManagerData;
				console.log("Throughput", throughputData);
				setThroughPut(throughputData);
			})
			.catch((error) => {
				console.log(`ERROR : ${error}`);
				alert(`Error:"${error}`);
			});
	};

	const getMajBreakdown = async () => {
		await axiosApi
			.get(`production/injectionMoulding/getAlertSummery`)
			.then((response) => {
				const alertsData = response.data.data;
				setMajorBreakdown(alertsData);
			})
			.catch((error) => {
				console.log(`ERRORS : ${error}`);
				alert(`Error:"${error}`);
			});
	};

	const getMachineInfoRequest = async () => {
		await axiosApi
			.get(`production/injectionMoulding/getMachineInformation`)
			.then((response) => {
				const machineInfo = response.data.data;
				getMachineInfo(() => machineInfo);
			})
			.catch((error) => {
				console.log(`ERRORS : ${error}`);
				alert(`Error:"${error}`);
			});
	};

	return (
		<div className="production-supervisor-dashboard">
			<br />
			<h1>Production Manager Dashboard</h1>
			<AlertStat
				data={machineStoppageAlert}
				isVisible={StopAlertsVisibility}
			/>
			<div
				className="summary-tables"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<StoppageAlert
					makeStopAlertsVisible={makeStopAlertsVisible}
					machineStoppageAlerts={machineStoppageAlerts}
					machineInfo={machineInfo}
				/>
			</div>
			<br></br>
			<br></br>
			<br></br>
			<h2> KPI Graph </h2>
			{/* <div style={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '50px',
      }}> */}
			<div className="container-graph text-center">
				<div className="row">
					<div className="col">
						<Redkpi redAlert={redAlert} />
					</div>
					<div className="col">
						<DIHkpi DIH={DIH} />
					</div>
				</div>
				<div className="row">
					<div className="col">
						<NCCkpi NCC={NCC} />
					</div>
					<div className="col">
						<Throughputkpi ThroughPut={ThroughPut} />
					</div>
				</div>
				<div className="row">
					<div className="col">
						<MajBreakdownkpi MajBreakdown={MajBreakdown} />
					</div>
					<div className="col"></div>
				</div>
			</div>

			{/* </div> */}
			<Layout className="layout">
				<Content style={{ padding: "50px 50px" }}>
					<div className="site-layout-content">
						<Tabs
							defaultActiveKey="1"
							tabPosition="left"
							size="large"
							style={{ marginTop: "5%" }}
						>
							<TabPane
								tab={
									<span>
										<FormOutlined />
										Production Manager Form
									</span>
								}
								key="1"
							>
								{/*<QASupervisorCurrentShift
                  machineId={props.location.state["machineId"]}
                />*/}

								<Button type="primary">
									<Link
										to={{
											pathname:
												"/production-manager-form",
										}}
									>
										Fill now
									</Link>
								</Button>
								<Button
									type="primary"
									style={{ marginLeft: 50 }}
								>
									<Link
										to={{
											pathname:
												"/production-manager-form/prefilled",
										}}
									>
										Forms History
									</Link>
								</Button>
							</TabPane>
							<TabPane
								tab={
									<span>
										<FormOutlined />
										Production Planning
									</span>
								}
								key="2"
							>
								<Button type="primary">
									<Link
										to={{
											pathname: "/new_production_plan",
										}}
									>
										New Plan
									</Link>
								</Button>
								<Button
									type="primary"
									style={{ marginLeft: 50 }}
								>
									<Link
										to={{
											pathname: "/view_production_plan",
										}}
									>
										View Plan
									</Link>
								</Button>
							</TabPane>
							<TabPane
								tab={
									<span>
										<HistoryOutlined />
										History
									</span>
								}
								key="3"
							>
								<Button type="primary">
									<Link to={{ pathname: "/dashboard" }}>
										Show From History
									</Link>
								</Button>
							</TabPane>
							<TabPane
								tab={
									<span>
										<FormOutlined />
										Line & Departmental Qrci
									</span>
								}
								key="4"
							>
								<Button type="primary">
									<Link to={{ pathname: "/lineqrci/all" }}>
										Line QRCI
									</Link>
								</Button>
								<Button
									type="primary"
									style={{ marginLeft: 50 }}
								>
									<Link to={{ pathname: "/plantqrci/all" }}>
										Plant QRCI
									</Link>
								</Button>
							</TabPane>
							<TabPane
								tab={
									<span>
										<FormOutlined />
										General Manager Feedbacks
									</span>
								}
								key="5"
							>
								<FeedbackTable
									title="General Manager"
									from="GeneralManager"
								/>
							</TabPane>
							<TabPane
								tab={
									<span>
										<FormOutlined />
										Managing Director Feedbacks
									</span>
								}
								key="6"
							>
								<FeedbackTable
									title="Managing Director"
									from="ManagingDirector"
								/>
							</TabPane>

							<TabPane
								tab={
									<span>
										<FormOutlined />
										External Alerts Dashboard
									</span>
								}
								key="7"
							>
								<Button type="primary">
									<Link to={{ pathname: "/create_alerts" }}>
										Create New Alert
									</Link>
								</Button>
								<Button
									type="primary"
									style={{ marginLeft: 50 }}
								>
									<Link to={{ pathname: "/alert_history" }}>
										Alert History
									</Link>
								</Button>
							</TabPane>
						</Tabs>
						,{/*<Divider />*/}
						{/*<div className="site-card-wrapper">
              
              <Card title="Production Manager Form" bordered={false}> 
                <Button type="primary">
                  <Link to= {{pathname: '/production-manager-form'}} >Fill now</Link>
                </Button>
                <Button type="primary" style={{marginLeft: 50}}>
                  <Link to= {{pathname: '/production-manager-form/prefilled'}} >Prefilled Form</Link>
                </Button>
              </Card>        
              <Card title="Daily Operation Review" bordered={false}> 
                <Button type="primary">
                  <Link to= {{pathname: '/daily-operation-review'}}>Fill now</Link>
                </Button>
              </Card>        
              </div>*/}
					</div>
				</Content>
			</Layout>
		</div>
	);
};

export default ProductionManagerDashboard;
