import { Statistic, Row, Col, Button } from "antd";
import { Typography } from "antd";
import { useEffect, useState, useRef } from "react";

import "react-toastify/dist/ReactToastify.css";
import axiosApi from "../../../api/axiosApi";
import ProductionStatsDetails from "./ProductionStatsDetails";

const { Text, Title } = Typography;

const ProductionStats = (props) => {
  const [showDetails, setShowDetails] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [prodValues, setProdValues] = useState({});
  let tempDept = useRef(null);
  let showAllDept = useRef(false);

  useEffect(() => {
    if (props.dept) {
      console.log("dept= ", props.dept);
      console.log("role = ", props.role);

      if (props.dept === "Die Casting" || props.dept === "die_casting") {
        tempDept.current = "die_casting";
      }
      else if(props.dept==="Production" || props.dept==="injectionMoulding"){
        tempDept.current="injectionMoulding"
      }
      

      if (props.dept === "Maintenance" || props.dept === "Quality Assurance") {
        showAllDept.current = true;
      }
    }
  }, [props.dept, props.role]);

  const fetchData = () => {
    console.log("depth", showAllDept.current)
    if (showAllDept.current) {
      let arr = ["injectionMoulding", "die_casting"];

      Promise.all(
        arr.map((item) =>
          axiosApi
            .get(`production/${item}/getHourlyTargetActualPage`)
            .then((res) => {
              if (res.data.data.err) {
                setError(true);
                return [];
              }

              return res.data.data.details || [];
            })
            .catch((err) => {
              console.log("err => ", err);
              setError(true);
              return [];
            })
        )
      )
        .then((results) => {
          // Concatenate details from all departments
          const details = results.flat();

          setProdValues((prevValues) => ({
            target: details.reduce((acc, detail) => acc + detail.target, 0),
            actual: details.reduce((acc, detail) => acc + detail.actual, 0),
            percentage: details.reduce(
              (acc, detail) => acc + detail.percentage,
              0
            ),
            details: details,
          }));

          if (isLoading) {
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error("Error fetching data for all departments:", err);
        });
    } else {
      // Fetch data for a single department
      axiosApi
        .get(`production/${tempDept.current}/getHourlyTargetActualPage`)
        .then((res) => {
          console.log("error= ", res)
          if (res.data.data.err) {
            console.log("error= ", res.data.data)
            setError(true);
            return;
          }

          setProdValues({
            target: res.data.data.total_target,
            actual: res.data.data.total_actual,
            percentage: res.data.data.percentage,
            details: res.data.data.details,
          });

          if (isLoading) {
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("err => ", err);
          setLoading(false);
          setError(true);
        });
    }
  };

  useEffect(() => {
    fetchData();
    setInterval(() => {
      fetchData();
    }, 1000 * 60);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !error ? (
    <div style={{ maxWidth: "90%", margin: "2rem auto" }}>
      <Row gutter={16}>
        <Col span={6}>
          <Statistic
            title="Target Production"
            value={prodValues.target}
            loading={isLoading}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Actual Production"
            value={prodValues.actual}
            loading={isLoading}
          />
          {/* <Button style={{ marginTop: 16 }} type="primary">
        Recharge
      </Button> */}
        </Col>
        <Col span={6}>
          <Statistic
            title="Difference"
            value={prodValues.target - prodValues.actual}
            loading={isLoading}
            valueStyle={{
              color:
                !isLoading && prodValues.target - prodValues.actual <= 0
                  ? "#3f8600"
                  : "#cf1322",
            }}
          />
        </Col>
        <Col span={6}>
          <div>
            <Text type="secondary">Action</Text>
          </div>
          <div>
            {prodValues.percentage >= 30 && (
              <Title level={3} type="danger">
                Red Alert
              </Title>
            )}
            {prodValues.percentage >= 20 && prodValues.percentage < 30 && (
              <Title level={3} type={"warning"}>
                Orange Alert
              </Title>
            )}
            {prodValues.percentage >= 10 && prodValues.percentage < 20 && (
              <Title level={3} style={{ color: "#ebbd34" }}>
                Yellow Alert
              </Title>
            )}
            {prodValues.percentage < 10 && (
              <Title level={3} type="success">
                All Good
              </Title>
            )}
          </div>
        </Col>
      </Row>
      <Button
        type="link"
        onClick={() => {
          setShowDetails((prev) => !prev);
        }}
      >
        {!showDetails ? "Show Details" : "Hide Details"}
      </Button>
      {showDetails && <ProductionStatsDetails data={prodValues.details} />}
    </div>
  ) : (
    <div>Error while fetching...</div>
  );
};

export default ProductionStats;
