import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import React from 'react';

const ProblemCreated = () => {
    // const onFinish = values => {
    //     console.log('Received values of form:', values);
    // };

    return (
        <>
            <Form.List name="problem_created">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                            <>

                                <Form.Item
                                    label="Why was problem created?"
                                    name={[name, 'why_created']}
                                    fieldKey={[fieldKey, 'why_not_detected']}
                                    labelAlign="left"
                                    rules={[{ required: true, message: 'Please enter data' }]}
                                >
                                    <Input type="string" />
                                </Form.Item>
                                <Form.Item
                                    label="Corrective Actions for creation?"
                                    name={[name, 'corrective_action_created']}
                                    fieldKey={[fieldKey, 'corrective_action']}
                                    rules={[{ required: true, message: 'Please enter data!' }]}
                                    labelAlign="left"
                                >
                                    <Input type="string" />
                                </Form.Item>
                                <Form.Item
                                    label="Pilot/Resp."
                                    name={[name, 'pilot']}
                                    fieldKey={[fieldKey, 'pilot']}
                                    labelAlign="left"
                                    rules={[{ required: true, message: 'Please enter data' }]}
                                >
                                    <Input type="string" />
                                </Form.Item>
                                <Form.Item
                                    label="Done Date"
                                    name={[name, 'done_date']}
                                    fieldKey={[fieldKey, 'done_date']}
                                    labelAlign="left"
                                    rules={[{ required: true, message: 'Please enter data' }]}
                                >
                                    <Input type="date" />
                                </Form.Item>
                                <Form.Item
                                    label="Shift"
                                    name={[name, 'shift']}
                                    fieldKey={[fieldKey, 'shift']}
                                    labelAlign="left"
                                    rules={[{ required: true, message: 'Please enter data' }]}
                                >
                                    <Select>
                                        <Select.Option value="I">I</Select.Option>
                                        <Select.Option value="II">II</Select.Option>
                                        <Select.Option value="III">III</Select.Option>

                                    </Select>
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                            </>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>

        </>
    );
};

export default ProblemCreated;