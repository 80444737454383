import  { useState, useEffect } from "react";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Typography,
  Button,
} from "antd";
import "./style.css";
import axiosApi from "../../../api/axiosApi";

// import BackButton from "../../common/BackButton/backButton";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router";
import { getUser } from "../../../redux/users/userSlice";
import { useSelector } from "react-redux";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [editingKey, setEditingKey] = useState("");
  const userData = useSelector(getUser);
	// let dept = userData["dept"][0];
  let dept = userData["operation"][0];
  let history = useHistory();
  let department="injectionMoulding";
	
	if(dept=== "Injection Moulding")
	{
	  department="injectionMoulding";
	}
	else if(dept === "Die Casting")
	{
	  department="die_casting";
	}

  useEffect(() => {
      axiosApi.get(`production/${department}/getSupervisorHandoverData`)
        .then((res) => {
          const updatedData = res.data.data.map((item, index) => ({
            ...item,
            key: index.toString(), // Use a unique identifier for each machine
          }));
          setData(updatedData);
        })
          // setData(res.data.data);
          // console.log(res.data.data);
        // })
        .catch((e) => {
          console.log("Error in Fetching Data : ", e);
        });
  }, [department]);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      output: "",
      rejection: "",
      rej_assembly: "",
      rej_paintshop: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        newData.splice(index, 1, { ...newData[index], ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
      // const row = await form.validateFields();
      // const newData = [...data];
      // const index = newData.findIndex((item) => key === item.key);

      // if (index > -1) {
      //   const item = newData[index];
      //   newData.splice(index, 1, { ...item, ...row });
      //   setData(newData);
      //   setEditingKey("");
      // } else {
      //   newData.push(row);
      //   setData(newData);
      //   setEditingKey("");
      // }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: <h2 style={{ fontWeight: "bold" }}>MACHINE NAME</h2>,
      dataIndex: "machine_name",
      width: 100,
      editable: false,
    },
    // {
    //   title: <h2 style={{ fontWeight: "bold" }}>PARAMETERS</h2>,
    //   editable: true,
    //   children: [

    {
      title: "Output",
      dataIndex: "output",
      width: 100,
      editable: true,
    },
    {
      title: "Rejection",
      dataIndex: "rejection",
      width: 100,
      editable: true,
    },
    {
      title: "Rejection Assembly",
      dataIndex: "rej_assembly",
      width: 100,
      editable: true,
    },
    {
      title: "Rejection Paintshop",
      dataIndex: "rej_paint_shop",
      width: 100,
      editable: true,
    },
    //   ],
    // },
    {
      title: <h2 style={{ fontWeight: "bold" }}>OPERATION</h2>,
      dataIndex: "operation",
      width: 100,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Button>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Button>Cancel</Button>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            <Button>Edit</Button>
          </Typography.Link>
        );
      },
    },
  ];

  const onConfirm = () => {
    toast.success("🚀 Form Submitted Successfully", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
    });
    setTimeout(() => {
      history.goBack();
    }, 3000);
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "name" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return data ? (
    <div>
      <ToastContainer />
      <div>{/* <BackButton styleClass={"back-btn"} /> */}</div>
      <div>
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={data}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
            scroll={{ x: 2000 }}
            sticky
          />
          <div style={{ textAlign: "center" }}>
            <Button
              type="danger"
              style={{ margin: "10px" }}
              onClick={onConfirm}
            >
              Confirm Changes
            </Button>
          </div>
        </Form>
      </div>
    </div>
  ) : (
    <div>
      <h1>Loading....</h1>
    </div>
  );
};
export default EditableTable;
