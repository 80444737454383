/* App.js */
// import React, { Component } from 'react';
import CanvasJSReact from './canvasjs.react';
// var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const DIHkpi = (props) => {
    // console.log("props", props);
		const options = {
			title: {
				text: "DIH"
			},
			axisX: {
                title: "Month"
            },
			axisY: {
                title: "Average DIH Data"
            },
			data: [
			{
				type: "column",
				dataPoints: [
					{ label: "Jan",  y: 0 },
					{ label: "Feb",  y: 0  },
					{ label: "Mar",  y: 0  },
					{ label: "Apr",  y: 0  },
					{ label: "May",  y: 0  },
					{ label: "Jun",  y: 0  },
					{ label: "Jul",  y: 0  },
					{ label: "Aug",  y: 0  },
					{ label: "Sep",  y: 0  },
					{ label: "Oct",  y: 0  },
					{ label: "Nov",  y: 0  },
					{ label: "Dec",  y: 0  }
				]
			}
			]
		}
		let DIH = props.DIH;
		// console.log("option data", options.data[0].dataPoints[1].label); 
		let x1=0, x2=0, x3=0, x4=0, x5=0, x6=0, x7=0, x8=0, x9=0, x10=0, x11=0, x12=0;
        for(let i = 0; i < DIH.length; i++){
			
            let z = DIH[i].date.substr(5,2);
            if(z === "01"){
                options.data[0].dataPoints[0].y = options.data[0].dataPoints[0].y + DIH[i].dih;
                x1++;
            }else if(z === "02"){
                options.data[0].dataPoints[1].y = options.data[0].dataPoints[1].y + DIH[i].dih;
                x2++;
            }else if(z === "03"){
                options.data[0].dataPoints[2].y = options.data[0].dataPoints[2].y + DIH[i].dih;
                x3++;
            }else if(z === "04"){
                options.data[0].dataPoints[3].y = options.data[0].dataPoints[3].y + DIH[i].dih;
                x4++;
            }else if(z === "05"){
                options.data[0].dataPoints[4].y = options.data[0].dataPoints[4].y + DIH[i].dih;
                x5++;
            }else if(z === "06"){
                options.data[0].dataPoints[5].y = options.data[0].dataPoints[5].y + DIH[i].dih;
                x6++;
            }else if(z === "07"){
                options.data[0].dataPoints[6].y = options.data[0].dataPoints[6].y + DIH[i].dih;
                x7++;
            }else if(z === "08"){
                options.data[0].dataPoints[7].y = options.data[0].dataPoints[7].y + DIH[i].dih;
                x8++;
            }else if(z === "09"){
                options.data[0].dataPoints[8].y = options.data[0].dataPoints[8].y + DIH[i].dih;
                x9++;
            }else if(z === "10"){
                options.data[0].dataPoints[9].y = options.data[0].dataPoints[9].y + DIH[i].dih;
                x10++;
            }else if(z === "11"){
                options.data[0].dataPoints[10].y = options.data[0].dataPoints[10].y + DIH[i].dih;
                x11++;
            }else if(z === "12"){
                options.data[0].dataPoints[11].y = options.data[0].dataPoints[11].y + DIH[i].dih;
                x12++;
            }
        }
        options.data[0].dataPoints[0].y /= x1;
        options.data[0].dataPoints[1].y /= x2;
        options.data[0].dataPoints[2].y /= x3;
        options.data[0].dataPoints[3].y /= x4;
        options.data[0].dataPoints[4].y /= x5;
        options.data[0].dataPoints[5].y /= x6;
        options.data[0].dataPoints[6].y /= x7;
        options.data[0].dataPoints[7].y /= x8;
        options.data[0].dataPoints[8].y /= x9;
        options.data[0].dataPoints[9].y /= x10;
        options.data[0].dataPoints[10].y /= x11;
        options.data[0].dataPoints[11].y /= x12;
		return (
		<div>
			<CanvasJSChart options = {options}
				/* onRef={ref => this.chart = ref} */
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		);
	}

    export default DIHkpi;