import { Input, Select, Table, DatePicker, Button, Form } from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "../../../../api/axiosApi";
import BackButton from "../../../../components/common/BackButton/backButton";
import {
  getMachinesData,
  getPartsData,
} from "../../../../redux/dropDownData/dropDownDataSlice";

const NewProductionPlan = (props) => {
  const [form] = Form.useForm();
  
  const [tableData, setTableData] = useState([]);
  const machinesData = useSelector(getMachinesData);
  const partsData = useSelector(getPartsData);
  let history = useHistory();
  const [id, setId] = useState(0); // Initialize id as null
  const [initVal, setInitVal]=useState(null)

  const getId = async () => {
    try {
      const response = await axios.get(
        `production/injectionMoulding/getProductionMouldingPlanning`
      );
        // console.log("resp == ",response)
      if (response !== null && response !== undefined) {
        let data = response.data.data;
        data.sort((a, b) => b.key - a.key);
        let temp = data[0].key;
        console.log("Setting id as", temp);
        // setId(temp);
        setInitVal(temp) // Set the state with the fetched value
      }
      else
      {
        setInitVal(0)
      }
    } catch (error) {
      // Handle errors that occur during the Axios request
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    getId(); // Call the asynchronous function within useEffect
  }, []); 


  const onFinish = async (values) => {
    if (!tableData[tableData.length - 1].plannedTime) {    


      toast.error("🚀 First Save your plan", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    console.log("Success :", values);


    for(let i=0;i<tableData.length;i++)
    {
      tableData[i].key=initVal+i+1;
      tableData[i].SrNo=initVal+i+1;        
    }
    axios
      .post("/production/injectionMoulding/submitProductionMouldingPlanning", {
        production_planning: tableData,
      })
      .then(function (response) {
        console.log(response);
        if (response.data.err) {
          toast.error("🚀 Fill all fields correctly", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
          return;
        }

        toast.success("🚀 Form Submitted Successfully", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        setTimeout(() => {
          history.goBack();
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
        alert("Error Occured : ", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    alert("Error Occured : ", errorInfo);
  };

  const onSave = (key) => {
    if (
      tableData[parseInt(key) - 1].part_id &&
      tableData[parseInt(key) - 1].qtyRequired
    ) {
      axios
        .get(
          `production/injectionMoulding/getPlannedTime?part_id=${
            tableData[parseInt(key) - 1].part_id
          }&quantity=${tableData[parseInt(key) - 1].qtyRequired}`
        )
        .then((response) => {
          console.log("prod", response);

          let items = [...tableData];
          let item = { ...items[parseInt(key) - 1] };
          item.plannedTime = response.data.data.planned_time;
          item.partNo = response.data.data.part_number;
          items[parseInt(key) - 1] = item;
          setTableData(items);
        })
        .catch((error) => console.log(`ERRORS : ${error}`));
    } else {
      toast.error("🚀 Enter entire Data", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };

  const onDelete = (key) => {
    let filteredTableData = tableData.filter(
      (record) => parseInt(record.key) !== parseInt(key)
    );
    setTableData(filteredTableData);
    setId(id - 1);
  };

  const handleAddRowClicked = async(e, index) => {
    
    console.log("Id is = ",id)
    if (id !== 0) {
      if (
        !tableData[id - 1].date ||
        !tableData[id - 1].part_id ||
        !tableData[id - 1].partNo ||
        !tableData[id - 1].machine_id ||
        !tableData[id - 1].qtyRequired ||
        !tableData[id - 1].plannedTime
      ) {
        toast.error("🚀 Please Enter details", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        return;
      }
    }

    // if(id===0)
    // {
    //   await axios
    //   .get(`production/injectionMoulding/getProductionMouldingPlanning`)
    //   .then((response) => {
    //     // console.log('res',response) 
    //     if(response!==null || response!==undefined)
    //     {
          
    //       let data=response.data.data;          
    //       data.sort((a, b) => b.SrNo - a.SrNo);
    //       // console.log("resp plan = ",data);
    //       // response.sort({SrNo: -1})
    //       let temp=data[0].SrNo;
    //       console.log("Setting id as", temp)
    //       setId(temp);
    //       console.log("Id = ",id)
    //     }

    //   })
    // }

    console.log("Id is = ",id)
    const newData = {
      key: id + 1,
      SrNo: id + 1,
    };
    const newTable = [...tableData, newData];
    setTableData(newTable);
    setId(id + 1);
  };

  function secondsToTime(secs) {
    var hours = Math.floor(secs / (60 * 60));

    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    // var divisor_for_seconds = divisor_for_minutes % 60;
    // var seconds = Math.ceil(divisor_for_seconds);

    return `${hours}h:${minutes}m`;
  }

  const tableColumns = [
    {
      title: "Sr No",
      dataIndex: "SrNo",
      key: "SrNo",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <DatePicker
          style={{ width: "110%" }}
          onChange={(e) => {
            let items = [...tableData];
            let item = { ...items[parseInt(record.key) - 1] };
            item.date = e._d;
            items[parseInt(record.key) - 1] = item;
            setTableData(items);
          }}
        />
      ),
    },
    {
      title: "Machine Name",
      dataIndex: "machine_id",
      key: "machine_id",
      render: (text, record) => (
        <Select
          style={{ width: "180px" }}
          onChange={(value) => {
            let items = [...tableData];
            let item = { ...items[parseInt(record.key) - 1] };
            item.machine_id = value;
            item.part_id="";
            items[parseInt(record.key) - 1] = item;
            setTableData(items);
          }}
        >
          {machinesData &&
            machinesData.map((machine) => (
              <Select.Option key={machine._id} value={machine._id}>
                {machine.name}
              </Select.Option>
            ))}
        </Select>
      ),
    },
    {
      title: "Part Name",
      dataIndex: "part_id",
      key: "part_id",
      render: (text ,record) => (
        <Select
          style={{ width: "180px" }}
          onChange={(value) => {
            let items = [...tableData];
            let item = { ...items[parseInt(record.key) - 1] };
            item.part_id = value;
            items[parseInt(record.key) - 1] = item;
            setTableData(items);
          }}
        >
          {partsData &&
            partsData.map((part, index) => (
            tableData[parseInt(record.key) - 1].machine_id === part.machine_id &&  <Select.Option key={index} value={part._id}>
                {part.part_name}
              </Select.Option>
            ))}
        </Select>
      ),
    },
    {
      title: "Part No",
      dataIndex: "partNo",
      key: "partNo",
    },
    
    {
      title: "Qty Required",
      dataIndex: "qtyRequired",
      key: "qtyRequired",
      render: (text, record) => (
        <Input
          type="number"
          onChange={(e) => {
            let items = [...tableData];
            let item = { ...items[parseInt(record.key) - 1] };
            console.log(e.target.value);
            item.qtyRequired = e.target.value;
            items[parseInt(record.key) - 1] = item;
            setTableData(items);
          }}
        />
      ),
    },
    {
      title: "Plannned Time",
      dataIndex: "plannedTime",
      key: "plannedTime",
      render: (text, record) => {
        if (text) {
          let time = secondsToTime(parseInt(text));
          return <p>{time}</p>;
        }
      },
    },
    {
      title: "Review",
      dataIndex: "review",
      key: "review",
    },
    {
      title: "Actual Time",
      dataIndex: "actualTime",
      key: "actualTime",
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      render: (text, record) => (
        <span style={{ display: "inline-flex", gap: "5px" }}>
          <Button type="primary" onClick={() => onSave(record.key)}>
            <SaveOutlined />
          </Button>
          <Button type="danger" onClick={() => onDelete(record.key)}>
            <DeleteOutlined />
          </Button>
        </span>
      ),
    },
  ];
  console.log("tableData",tableData);
  console.log("parts",partsData);
  
  return (
    <div>
      <BackButton styleClass="back-btn" />
      <ToastContainer />
      <h1
        style={{
          paddingBottom: "10px",
          letterSpacing: "0.3rem",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        PRODUCTION MOULDING PLANNING
      </h1>
      <Form
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 16,
        }}
        form={form}
        layout="horizontal"
        colon={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Table style={{ marginBottom:"15px"}} dataSource={tableData} columns={tableColumns} />
        <div style={{ textAlign: "right" }}>
          <Button type="primary" onClick={handleAddRowClicked}>
            Add Row
          </Button>
        </div>
        <div className="submit-btn" textAlign="left">
          <Button type="danger" htmlType="submit">
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default NewProductionPlan;
