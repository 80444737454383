import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Select, Input, Button, Tabs, Row, Col } from "antd";

const { Option } = Select;
const { TabPane } = Tabs;
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const EffectivenessTracking = (props) => {
  const [data, setData] = useState({
    labels: [
      ...props.d7Data[props.name].previous_months,
      "break",
      ...props.d7Data[props.name].next_months,
    ],
    datasets: [
      {
        label: props.factor,
        data: [
          ...props.d7Data[props.name].previous_values,
          null,
          ...props.d7Data[props.name].next_values,
        ],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  });
  const [currentState, setCurrentState] = useState();
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    setCurrentState(props.d7Data);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddPreviousMonth = () => {
    let mainData = currentState;
    let data = currentState[props.name];
    data.previous_months.push("");
    data.previous_values.push(0);
    mainData[props.name] = data;
    setCurrentState(mainData);
    setUpdate(!update);
  };
  const onAddNextMonth = () => {
    let mainData = currentState;
    let data = currentState[props.name];
    data.next_months.push("");
    data.next_values.push(0);
    mainData[props.name] = data;
    setCurrentState(mainData);
    setUpdate(!update);
  };

  const onSave = () => {
    setData({
      labels: [
        ...currentState[props.name].previous_months,
        "break",
        ...currentState[props.name].next_months,
      ],
      datasets: [
        {
          label: props.factor,
          data: [
            ...currentState[props.name].previous_values,
            null,
            ...currentState[props.name].next_values,
          ],
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
        },
      ],
    });
    props.setD7Data(currentState);
  };

  return !loading ? (
    <div style={{ position: "relative" }} className="tracking-tabs">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Previous Months" key="1">
          <div className="tracking-dropdown">
            {currentState[props.name]?.previous_months.map((item, index) => (
              <Row>
                <Col>{`${index + 1}.`}</Col>
                <Col style={{ margin: "0 1em" }}>
                  <Select
                    defaultValue={item}
                    style={{ width: 120 }}
                    onChange={(value) => {
                      let mainData = currentState;
                      let data = currentState[props.name];
                      let items = data.previous_months;
                      items[index] = value;
                      data.previous_months = items;
                      mainData[props.name] = data;
                      setCurrentState(mainData);
                    }}
                    name="prev"
                  >
                    {months.map((month, index) => (
                      <Option value={month} key={month} name="prev">
                        {month}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col style={{ margin: "0 1em" }}>
                  <Input
                    type="number"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      let mainData = currentState;
                      let data = currentState[props.name];
                      let items = currentState[props.name].previous_values;
                      items[index] = parseInt(e.target.value);
                      data.previous_values = items;
                      mainData[props.name] = data;
                      setCurrentState(mainData);
                    }}
                    defaultValue={
                      currentState[props.name].previous_values[index]
                    }
                  />
                </Col>
              </Row>
            ))}
          </div>

          <Button
            style={{ marginTop: "1em" }}
            type="primary"
            onClick={onAddPreviousMonth}
          >
            Add Month
          </Button>
        </TabPane>
        <TabPane tab="Next Months" key="2">
          <div className="tracking-dropdown">
            {currentState[props.name].next_months.map((item, index) => (
              <Row>
                <Col>{`${index + 1}.`}</Col>
                <Col style={{ margin: "0 1em" }}>
                  <Select
                    defaultValue={item}
                    style={{ width: 120 }}
                    onChange={(value) => {
                      let mainData = currentState;
                      let data = currentState[props.name];
                      let items = data.next_months;
                      items[index] = value;
                      data.next_months = items;
                      mainData[props.name] = data;
                      setCurrentState(mainData);
                    }}
                    name="next"
                  >
                    {months.map((month, index) => (
                      <Option value={month} key={month} name="next">
                        {month}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col style={{ margin: "0 1em" }}>
                  <Input
                    type="number"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      let mainData = currentState;
                      let data = currentState[props.name];
                      let items = data.next_values;
                      items[index] = parseInt(e.target.value);
                      data.next_values = items;
                      mainData[props.name] = data;
                      setCurrentState(mainData);
                    }}
                    defaultValue={item}
                  />
                </Col>
              </Row>
            ))}
          </div>
          <Button
            type="primary"
            style={{ marginTop: "1em" }}
            onClick={onAddNextMonth}
          >
            Add Month
          </Button>
        </TabPane>
      </Tabs>
      <Button type="primary" style={{ margin: "1em 0" }} onClick={onSave}>
        Save
      </Button>
      <Line data={data} />
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default EffectivenessTracking;
