

import HardwareDashboard from "./hardware_dashboard"
//import StackedBar from "./stacked_bar"

const HardwareView = (props) => {
  console.log("props", props)
  return (
    <div className="App">
      <div className="container">
      <HardwareDashboard locationState={props.location.state} />   

        {/* <StackedBar locationState={props.location.state} /> */}
      </div>
    </div>
  )
}

export default HardwareView
