import { Table } from 'antd';
import React, { useEffect, useState } from "react";
import { Typography } from 'antd';
const { Text } = Typography;
const columns = [
  {
    title: 'Machine Name',
    dataIndex: 'machine_name',
    key: 'machine_name',
  },
  {
    title: 'Machine Stoppage Reason',
    dataIndex: 'machine_msg',
    key: 'machine_msg',
  },
	{
    title: 'Alert',
    dataIndex: 'alert',
    key: 'alert',
    render: alertColor => {
			
			return (
				<div>
					{alertColor ==='Red' && <Text level={3} type="danger">Red Alert</Text>}
					{alertColor ==='Orange' && <Text level={3} type={"warning"}>Orange Alert</Text>}
					{alertColor ==='Yellow' && <Text level={3} style={{color:'#ebbd34'}}>Yellow Alert</Text>}
					{alertColor ==='Green' && <Text level={3} type='success'>All Good</Text>}
				</div>
			);
  }
  },
];
const AlertStat = ({data,isVisible}) => {

	const [isLoading, setLoading] = useState(false);
	const [prodValues, setProdValues] = useState([]);
	useEffect(() => {
		if(data){
			setLoading(false);
			setProdValues(data);
		}
	},[data])
    if(data && isVisible){
        return (
          <Table columns={columns} dataSource={prodValues} loading={isLoading}/>
        )
      }
      else{
        return (<></>);
      }
    }
    export default AlertStat;