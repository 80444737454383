import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import BackButton from "../../../common/BackButton/backButton";
import { DatePicker } from "antd";
import { Bar } from "react-chartjs-2";
import axiosApi from "../../../../api/axiosApi";

const { RangePicker } = DatePicker;

const AlertHistory = (props) => {
	const [lineData, setLineData] = useState([]);
	const [plantData, setPlantData] = useState([]);

	useEffect(() => {
		// Fetch & set External Line QRCIs
		const fetchAndFillLineQrcis = async () => {
			try {
				const lineQrciResp = await axiosApi.get(
					"production/injectionMoulding/getLineQrciForm"
				);
				let allLineQrcis = lineQrciResp.data.data;
				console.log("allLineQrcis", allLineQrcis);

				/*
                    'dumped' status QRCIs NOT included in graph
                    total => status as 'ongoing' or 'completed'
                    complete => status as 'completed'
                */
				let lineQrciGraphData = [
					{ type: "HSE", total: 0, complete: 0 },
					{ type: "Cust. Complaint", total: 0, complete: 0 },
					{ type: "Production", total: 0, complete: 0 },
					{ type: "Logistics", total: 0, complete: 0 },
					{ type: "Quality", total: 0, complete: 0 },
				];
				for (let item of allLineQrcis) {
					if (item.current_status === "dumped") {
						continue;
					}
					// console.log(item);
					if (
						item.externally_created &&
						item.problem_as_per_operator === "HSE"
					) {
						lineQrciGraphData[0].total++;
						if (item.current_status === "completed") {
							lineQrciGraphData[0].complete++;
						}
					} else if (
						item.externally_created &&
						item.problem_as_per_operator === "Customer Complaint"
					) {
						lineQrciGraphData[1].total++;
						if (item.current_status === "completed") {
							lineQrciGraphData[1].complete++;
						}
					} else if (
						item.externally_created &&
						item.problem_as_per_operator === "Production"
					) {
						lineQrciGraphData[2].total++;
						if (item.current_status === "completed") {
							lineQrciGraphData[2].complete++;
						}
					} else if (
						item.externally_created &&
						item.problem_as_per_operator === "Logistics"
					) {
						lineQrciGraphData[3].total++;
						if (item.current_status === "completed") {
							lineQrciGraphData[3].complete++;
						}
					} else if (
						item.externally_created &&
						item.problem_as_per_operator === "Quality"
					) {
						lineQrciGraphData[4].total++;
						if (item.current_status === "completed") {
							lineQrciGraphData[4].complete++;
						}
					}
				}
				console.log("after filling", lineQrciGraphData);
				setLineData(() => lineQrciGraphData);
			} catch (err) {
				console.log(err);
			}
		};
		fetchAndFillLineQrcis();
		// Now for External Plant QRCIs
		const fetchAndFillPlantQrcis = async () => {
			try {
				const plantQrciResp = await axiosApi.get(
					"production/injectionMoulding/getPlantQrciForm"
				);
				let allPlantQrcis = plantQrciResp.data.data;
				console.log("allPlantQrcis", allPlantQrcis);
				/*
                    'dumped' status QRCIs NOT included in graph
                    total => status as 'ongoing' or 'completed'
                    complete => status as 'completed'
                */
				let plantQrciGraphData = [
					{ type: "HSE", total: 0, complete: 0 },
					{ type: "Cust. Complaint", total: 0, complete: 0 },
					{ type: "Production", total: 0, complete: 0 },
					{ type: "Logistics", total: 0, complete: 0 },
					{ type: "Quality", total: 0, complete: 0 },
				];
				for (let item of allPlantQrcis) {
					if (item.current_status === "dumped") {
						continue;
					}
					// console.log(item);
					if (
						item.externally_created &&
						item.problem_as_per_operator === "HSE"
					) {
						plantQrciGraphData[0].total++;
						if (item.current_status === "completed") {
							plantQrciGraphData[0].complete++;
						}
					} else if (
						item.externally_created &&
						item.problem_as_per_operator === "Customer Complaint"
					) {
						plantQrciGraphData[1].total++;
						if (item.current_status === "completed") {
							plantQrciGraphData[1].complete++;
						}
					} else if (
						item.externally_created &&
						item.problem_as_per_operator === "Production"
					) {
						plantQrciGraphData[2].total++;
						if (item.current_status === "completed") {
							plantQrciGraphData[2].complete++;
						}
					} else if (
						item.externally_created &&
						item.problem_as_per_operator === "Logistics"
					) {
						plantQrciGraphData[3].total++;
						if (item.current_status === "completed") {
							plantQrciGraphData[3].complete++;
						}
					} else if (
						item.externally_created &&
						item.problem_as_per_operator === "Quality"
					) {
						plantQrciGraphData[4].total++;
						if (item.current_status === "completed") {
							plantQrciGraphData[4].complete++;
						}
					}
				}
				console.log("after filling", plantQrciGraphData);
				setPlantData(() => plantQrciGraphData);
			} catch (err) {
				console.log(err);
			}
		};
		fetchAndFillPlantQrcis();
	}, []);

	return (
		<>
			<BackButton styleClass={"back-btn"} />

			<h1
				style={{
					padding: "2rem 1.5rem 1rem 1.5rem",
					letterSpacing: "0.25rem",
				}}
			>
				EXTERNAL ALERTS HISTORY
			</h1>
			<RangePicker
				picker="month"
				bordered={false}
				style={{ padding: "0.5rem 1rem 1.25rem 1rem" }}
			/>
			<div style={{ padding: "0.25rem 1.5rem" }}>
				<h2>External Line QRCIs</h2>
				<Bar
					style={{ padding: "0.25rem 1.5rem" }}
					options={{
						// plugins: {
						// 	title: {
						// 		display: true,
						// 		text: "External Line QRCIs",
						// 		padding: "20",
						// 	},
						// },
						responsive: true,
						interaction: {
							mode: "index",
							intersect: false,
						},
						scales: {
							x: {
								stacked: true,
							},
							y: {
								stacked: true,
							},
						},
					}}
					data={{
						labels: lineData.map((item) => item.type),
						datasets: [
							{
								label: "Completed",
								data: lineData.map((item) => item.complete),
								backgroundColor: "rgb(0, 153, 51)",
								stack: "Completed",
							},
							{
								label: "Total",
								data: lineData.map((item) => item.total),
								backgroundColor: "rgb(255, 0, 0)",
								stack: "Total",
							},
						],
					}}
				></Bar>
			</div>
			<div style={{ padding: "2.5rem 1.5rem" }}>
				<h2>External Plant QRCIs</h2>
				<Bar
					style={{ padding: "0.25rem 1.5rem" }}
					options={{
						responsive: true,
						interaction: {
							mode: "index",
							intersect: false,
						},
						scales: {
							x: {
								stacked: true,
							},
							y: {
								stacked: true,
							},
						},
					}}
					data={{
						labels: plantData.map((item) => item.type),
						datasets: [
							{
								label: "Completed",
								data: plantData.map((item) => item.complete),
								backgroundColor: "rgb(0, 153, 51)",
								stack: "Completed",
							},
							{
								label: "Total",
								data: plantData.map((item) => item.total),
								backgroundColor: "rgb(255, 0, 0)",
								stack: "Total",
							},
						],
					}}
				></Bar>
			</div>
		</>
	);
};

export default AlertHistory;
