import React, { useEffect } from "react";
import { Input, Table, Tag } from "antd";
import { toast, ToastContainer } from "react-toastify";
import BackButton from "../../../../components/common/BackButton/backButton";
import axiosApi from "../../../../api/axiosApi";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getUser } from "../../../../redux/users/userSlice";
import { useSelector } from "react-redux";
import { Button } from "antd/lib/radio";

const MDLineQrciDashboard = () => {
	const [data, setData] = useState(undefined);
	const userData = useSelector(getUser);
	// let dept = userData["dept"][0];
	let dept = userData["operation"][0];
	let role = userData["roles"][0];
	let displayAllMachine=false;
	let department="injectionMoulding";
	
	if(dept=== "Injection Moulding")
	{
	  department="injectionMoulding";
	}
	else if(dept === "Die Casting")
	{
	  department="die_casting";
	}

	if(dept==="All" || role==="ManagingDirector" || role==="GeneralManager")
	{
	  displayAllMachine=true;
	}

	useEffect(() => {
		// console.log(userData);
		
		if (!displayAllMachine) {
		  axiosApi
			.get(`production/${department}/getLineQrciForm`)
			.then((res) => {
			  console.log(res.data.data);
			  setData(res.data.data);
			})
			.catch((err) => alert(err));
		} else {
		  axiosApi
			.get(`production/${department}/getLineQrciFormForManager`)
			.then((res) => {
			  console.log(res.data.data);
			  setData(res.data.data);
			})
			.catch((err) => alert(err));
		}
	  }, [userData, department, displayAllMachine]);

	// useEffect(() => {
	// 	console.log(userData);
	// 	axiosApi
	// 		.get(`production/${department}/getLineQrciForm`)
	// 		.then((res) => {
	// 			console.log(res.data.data);
	// 			setData(res.data.data);
	// 		})
	// 		.catch((err) => alert(err));
	// }, [userData]);

	const columns = [
		{
			title: "Ref. No.",
			dataIndex: "qrci_ref_no",
			key: "qrci_ref_no",
			fixed: "left",
		},
		{
			title: "Status",
			dataIndex: "current_status",
			key: "current_status",
			render: (current_status) => {
				let color;
				if (current_status === "ongoing") {
					color = "red";
				} else if (current_status === "completed") {
					color = "green";
				} else {
					color = "geekblue";
				}
				return (
					<Tag color={color} key={current_status}>
						{current_status.toUpperCase()}
					</Tag>
				);
			},
		},
		{
			title: "Date & Time",
			dataIndex: "date_time",
			key: "date_time",
			render: (date_time) => {
				return (
					<div>
						{new Date(date_time).toLocaleDateString()}
						<br />
						{new Date(date_time).toLocaleTimeString()}
					</div>
				);
			},
		},
		{
			title: "Problem",
			dataIndex: "problem_as_per_operator",
			key: "problem_as_per_operator",
			render: (text, record) => (
				<div>
					<p>{record?.problem_as_per_operator}</p>
					<p>{record?.comment_from_operator}</p>
				</div>
			),
		},
		{
			title: "Department",
			dataIndex: "dept",
			key: "dept",
		},
		{
			title: "MD Feedback",
			dataIndex: "md_suggestion",
			key: "md_suggestion",
		},
		{
			title: "View",
			key: "link",
			fixed: "right",
			dataIndex: "qrci_ref_no",
			render: (qrci_ref_no, data) => (
				<Link
					to={{
						pathname: `/lineqrci/${(() => {
						switch (data.dept) {
							case "Injection Moulding":
								return "injectionMoulding";
							case "Die Casting":
								return "die_casting";
							default:
								return "injectionMoulding";
						}
						})()}/${qrci_ref_no}`,
						state: { id: qrci_ref_no }
					}}
					>
					View
					</Link>

			),
		},
	];

	const onFeedbackChange = (index, e) => {
		let items = data;
		items[index].md_suggestion = e.target.value;
		setData(items);
	};

	const onSave = (qrci_ref_no, index,depart) => {
		console.log(data[index].md_suggestion);
		
		let tempDepart;
		if(depart=== "Production")
		{
			tempDepart="injectionMoulding";
		}
		else if(depart === "Die Casting")
		{
			tempDepart="die_casting";
		}

		axiosApi
			.post(`production/${tempDepart}/submitCommentLineQrci`, {
				qrci_ref_no,
				md_suggestion: data[index].md_suggestion,
			})
			.then((res) => {
				toast.success("🚀 Feedback Submitted Successfully", {
					position: "bottom-center",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					progress: undefined,
				});
			})
			.catch((err) => {
				console.log(err);
				toast.error("🚀 Fill all fields correctly", {
					position: "bottom-center",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					progress: undefined,
				});
			});
	};

	const columnsMD = [
		{
			title: "Ref. No.",
			dataIndex: "qrci_ref_no",
			key: "qrci_ref_no",
			fixed: "left",
		},
		{ title: "Status", dataIndex: "current_status", key: "current_status" },
		{ title: "Date & Time", dataIndex: "date_time", key: "date_time" },
		{
			title: "Problem",
			dataIndex: "what_is_the_problem",
			key: "what_is_the_problem",
		},
		{
			title: "Department",
			dataIndex: "dept",
			key: "dept",
		},
		{
			title: "MD Feedback",
			dataIndex: "md_suggestion",
			key: "md_suggestion",
			render: (text, record, index) => (
				<Input
					onChange={(e) => onFeedbackChange(index, e)}
					defaultValue={text}
				/>
			),
		},
		{
			title: "Save",
			dataIndex: "save",
			key: "save",
			render: (text, record, index) => (
				<Button onClick={() => onSave(record.qrci_ref_no, index,record.dept)}>
					Save
				</Button>
			),
		},
		{
			title: "View",
			key: "link",
			fixed: "right",
			dataIndex: "qrci_ref_no",
			render: (qrci_ref_no, data) => (
				<Link
					to={{
						pathname: `/lineqrci/${(() => {
						switch (data.dept) {
							case "Injection Moulding":
								return "injectionMoulding";
							case "Die Casting":
								return "die_casting";
							default:
								return "injectionMoulding";
						}
						})()}/${qrci_ref_no}`,
						state: { id: qrci_ref_no }
					}}
					>
					View
					</Link>

			),
		},
	];

	return data ? (
		<div className="card-holder">
			<ToastContainer />
			<BackButton styleClass={"back-btn"} />
			<div className="form-card">
				<Table
					columns={
						userData.roles.includes("ManagingDirector") || userData.roles.includes("GeneralManager")
							? columnsMD
							: columns
					}
					dataSource={data}
					scroll={{ x: "max-content" }}
				/>
			</div>
		</div>
	) : (
		<div>Loading...</div>
	);
};

export default MDLineQrciDashboard;
