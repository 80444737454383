import { React, useState, useEffect } from "react";
import axios from "../../../api/axiosApi";

import { Divider, Radio, Space, DatePicker } from "antd";
import auth from "../../common/LoginComponent/auth";
// import BackButton from "../../common/BackButton/backButton";
import ShiftHandOverCard from "./ShiftHandOverCard";
import { getUser } from "../../../redux/users/userSlice";
import { useSelector } from "react-redux";


const HandoverHistory = (props) => {
  const [Allforms, setAllforms] = useState();
  const [currentShift, setCurrentShift] = useState("1");
  const [dateFilter, setDateFilter] = useState();
  const userData = useSelector(getUser);
	// let dept = userData["dept"][0];
  let dept = userData["operation"][0];
  let department="injectionMoulding";
	
	if(dept=== "Production")
	{
	  department="injectionMoulding";
	}
	else if(dept === "Die Casting")
	{
	  department="die_casting";
	}

  const changeShift = (e) => {
    setCurrentShift(e.target.value);
  };

  const changeDate = (e) => {
    const date = new Date();
    const offsetDate = new Date(e.getTime() - date.getTimezoneOffset() * 60000);
    const filterdate = offsetDate.toISOString().toString().slice(0, 10);
    setDateFilter(filterdate);
  };


  useEffect(() => {
    if (auth.isAuthenticated()) {
      const fetchAllForms = async () => {
        await axios
          .get(
            `/production/${department}/getAllHandoverOverForms?shift=${currentShift}`
          )
          .then((response) => {
            setAllforms(response.data.data);
          })
          .catch((error) => {
            console.log(`ERRORS : ${error}`);
            alert(`Error:"${error}`);
          });
      };
      fetchAllForms();
    }
  }, [currentShift, department]);

  const tablebody =
    Allforms &&
    Allforms.map((item) => {
      let when_created = new Date(
        (typeof item.when_created === "string"
          ? new Date(item.when_created)
          : item.when_created
        ).toLocaleString("en-US", { timeZone: "Asia/kolkata" })
      );
      //   let today_date = String(Date()).substr(0, 15);
      //   let when_created_str = String(when_created);
      var date = new Date();
      const offsetDate = new Date(
        when_created.getTime() - date.getTimezoneOffset() * 60000
      );

      const created = offsetDate.toISOString().toString().slice(0, 10);

      if (dateFilter === created) {
        return <ShiftHandOverCard item={item} key={item?._id} hist={true} />;
      }
      return false;
    });

  return (
    <div>
      {/* <BackButton styleClass="back-btn" /> */}
      <div style={{ display: "flex", justifyContent: "start", margin: "5%" }}>
        <Space style={{ marginBottom: 24 }}>
          <Radio.Group
            value={currentShift}
            onChange={changeShift}
            style={{ width: "200%" }}
          >
            <Radio.Button value="1">Shift 1</Radio.Button>
            <Radio.Button value="2">Shift 2</Radio.Button>
            <Radio.Button value="3">Shift 3</Radio.Button>
          </Radio.Group>
        </Space>
        <DatePicker
          style={{ marginLeft: "30%" }}
          onChange={(e) => {
            changeDate(e?._d);
          }}
        />
      </div>
      <Divider />
      {tablebody && tablebody.length === 0 ? (
        <div>No forms to be displayed</div>
      ) : (
        <div className="form-grid">{tablebody}</div>
      )}
    </div>
  );
};

export default HandoverHistory;
