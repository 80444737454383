import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Form, Select, Button, Divider } from "antd";

import { getMachinesData } from "../../../redux/dropDownData/dropDownDataSlice";
import EmployeeDetail from "../../../components/common/employeeDetail";
import { addOperatorData } from "../../../redux/operators/operatorSlice";
import PageFooter from "../../../components/common/Footer/footer";
import "./dc_op_dashboard.css";

const DCOpSelectMachinePage = () => {
  const [form] = Form.useForm();
  const machinesData = useSelector(getMachinesData);
  // console.log(machinesData);

  const dispatch = useDispatch();

  const onFinish = (values) => {
    // console.log(values);
    dispatch(
      addOperatorData({
        machineId: values.machine,
        shift: values.shift,
      })
    );
  };

  const onFinishFailed = (error) => {
    console.log("Some Error ");
    alert("Some Error in Operator Machine Selection : ", error);
  };

  return (
    <>
      <div className="dc-card-screen">
        <div className="card" style={{width:'650px'}}>
          <div style={{ margin: "20px 10px" }}>
            <Form
              labelCol={{
                span: 10,
              }}
              wrapperCol={{
                span: 15,
              }}
              form={form}
              layout="horizontal"
              colon={false}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <EmployeeDetail />

              <Form.Item
                label={<span style={{ fontSize: '26px' }}>Machine</span>}
                name="machine"
                labelAlign="left"
                rules={[
                  { required: true, message: "Please select machine name!" },
                ]}
              >
                <Select style={{width: "100%"}}>
                  {machinesData &&
                    machinesData.map((machine) => (
                      <Select.Option key={machine._id} value={machine._id}>
                        {machine.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={<span style={{ fontSize: '26px' }}>Shift</span>}
                name="shift"
                labelAlign="left"
                rules={[{ required: true, message: "Please enter shift Id!" }]}
              >
                <Select>
                  <Select.Option value="1">I</Select.Option>
                  <Select.Option value="2">II</Select.Option>
                  <Select.Option value="3">III</Select.Option>
                </Select>
              </Form.Item>

              <Divider className="divider" />

              <Button htmlType="submit" style={{borderRadius:'5px', backgroundColor:'lightgreen'}}>
                Confirm and Continue
              </Button>
            </Form>
          </div>
        </div>
      </div>
      <PageFooter />
    </>
  );
};

export default DCOpSelectMachinePage;
